import React, { Component } from 'react';

class SubscriptionDetail extends Component {

    render() {
        return (
            <div>
                {this.props.getSubscriptionTypesLoading ? <div className="loading" /> : ""}

                subscription detail
            </div>
        )
    }
}
export default SubscriptionDetail;