import React, { Component } from 'react'
import { Input, Button, FormControl, FormLabel, InputLabel } from "@material-ui/core";
import moment from 'moment';

class AddPeriodForm extends Component {

    state = {

    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" name="_id" value={this.props.period._id} />
                <FormControl fullWidth>
                    <FormLabel htmlFor="periodDate">Dönem Tarihi</FormLabel>
                    <Input
                        id="periodDate"
                        label="periodDate"
                        type="date"
                        defaultValue={moment(this.props.period.periodDate).format("YYYY-MM-DD")}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="invoiceBalance">Görünecek Ücreti</InputLabel>
                    <Input
                        id="invoiceBalance"
                        label="invoiceBalance"
                        type="text"
                        defaultValue={this.props.period.invoiceBalance}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="lastPaymentDate">Son Ödeme Tarihi</InputLabel>
                    <Input
                        id="lastPaymentDate"
                        label="lastPaymentDate"
                        type="text"
                        defaultValue={this.props.period.lastPaymentDate}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="lastPaymentDate">Harcama Dönemi</InputLabel>
                    <Input
                        id="spendingPeriod"
                        label="spendingPeriod"
                        type="text"
                        defaultValue={this.props.period.spendingPeriod}
                    />
                </FormControl>
                <FormControl fullWidth>

                    <InputLabel htmlFor="paymentPlace">Ödeme Yeri</InputLabel>
                    <Input
                        id="paymentPlace"
                        label="paymentPlace"
                        type="text"
                        defaultValue={this.props.period.paymentPlace}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="accountName">Hesap İsmi</InputLabel>
                    <Input
                        id="accountName"
                        label="accountName"
                        type="text"
                        defaultValue={this.props.period.accountName}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel htmlFor="ibanNumber">İban Numarası</InputLabel>
                    <Input
                        id="ibanNumber"
                        label="ibanNumber"
                        type="text"
                        defaultValue={this.props.period.ibanNumber}
                    />
                </FormControl>


                <FormControl fullWidth>
                    <InputLabel htmlFor="readingBalance">Okuma Ücreti</InputLabel>
                    <Input
                        id="readingBalance"
                        label="readingBalance"
                        type="text"
                        defaultValue={this.props.period.readingBalance}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="periodNote">Dönem Notu</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="periodNote"
                        name="periodNote"
                        type="text"
                        defaultValue={this.props.period.periodNote}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "15px" }}
                >
                    DÖNEMİ GÜNCELLE
                </Button>
            </form >
        )
    }
}
export default AddPeriodForm;