import React, { Component } from 'react'
import { Input, Button, FormControl, FormLabel, InputLabel } from "@material-ui/core";
import moment from 'moment';
class AddPeriodForm extends Component {

    state = {
        lastClosedPeriodDate: this.props.lastClosedPeriod ? this.props.lastClosedPeriod.periodDate : null,
        periodNote: this.props.lastClosedPeriod ? this.props.lastClosedPeriod.periodNote : null,
        paymentPlace: this.props.lastClosedPeriod ? this.props.lastClosedPeriod.paymentPlace : null,
        ibanNumber: this.props.lastClosedPeriod ? this.props.lastClosedPeriod.ibanNumber : null,
        accountName: this.props.lastClosedPeriod ? this.props.lastClosedPeriod.accountName : null,
        closedDate: this.props.lastClosedPeriod ? moment(this.props.lastClosedPeriod.closedDate).format("DD-MM-YYYY") : null,
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" name="project" value={this.props.project._id} />
                <input type="hidden" name="lastClosedPeriodDate" value={this.state.lastClosedPeriod} />
                <FormControl fullWidth>
                    <FormLabel htmlFor="periodDate">Dönem Tarihi</FormLabel>
                    <Input
                        id="periodDate"
                        label="periodDate"
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="invoiceBalance">Görünecek Ücreti</InputLabel>
                    <Input
                        id="invoiceBalance"
                        label="invoiceBalance"
                        type="number"
                        defaultValue={parseFloat(this.props.project.invoiceBalance)}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="lastPaymentDate">Son Ödeme Tarihi</InputLabel>
                    <Input
                        id="lastPaymentDate"
                        label="lastPaymentDate"
                        type="text"
                        defaultValue={""}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="paymentPlace">Ödeme Yeri</InputLabel>
                    <Input
                        id="paymentPlace"
                        label="paymentPlace"
                        type="text"
                        defaultValue={this.state.paymentPlace}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="accountName">Hesap İsmi</InputLabel>
                    <Input
                        id="accountName"
                        label="accountName"
                        type="text"
                        defaultValue={this.state.accountName}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="spendingPeriod">Harcama Dönemi</InputLabel>
                    <Input
                        id="spendingPeriod"
                        label="spendingPeriod"
                        type="text"
                        defaultValue={this.state.spendingPeriod}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="ibanNumber">İban Numarası</InputLabel>
                    <Input
                        id="ibanNumber"
                        label="ibanNumber"
                        type="text"
                        defaultValue={this.state.ibanNumber}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel htmlFor="readingBalance">Okuma Ücreti</InputLabel>
                    <Input
                        id="readingBalance"
                        label="readingBalance"
                        type="number"
                        defaultValue={this.props.project.readingPrice}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="periodNote">Dönem Notu</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="periodNote"
                        name="periodNote"
                        type="text"
                        defaultValue={this.state.periodNote}
                    />
                </FormControl>





                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "15px" }}
                >
                    DÖNEMİ EKLE
                </Button>
            </form >
        )
    }
}
export default AddPeriodForm;