import {
    LOGIN_PERSON_PENDING, LOGIN_PERSON_FULFILLED, LOGIN_PERSON_REJECTED,
    GET_PERSONS_PENDING, GET_PERSONS_FULFILLED, GET_PERSONS_REJECTED,
    GET_PERSON_PENDING, GET_PERSON_FULFILLED, GET_PERSON_REJECTED,
    ADD_PERSON_PENDING, ADD_PERSON_FULFILLED, ADD_PERSON_REJECTED,
    UPDATE_PERSON_PENDING, UPDATE_PERSON_FULFILLED, UPDATE_PERSON_REJECTED,
    DELETE_PERSON_PENDING, DELETE_PERSON_FULFILLED, DELETE_PERSON_REJECTED,
    RESET_PERSON_PASSWORD_PENDING, RESET_PERSON_PASSWORD_FULFILLED, RESET_PERSON_PASSWORD_REJECTED,
    SET_PAGE_TITLE, SET_BREADCRUMB,
    GET_RESELLERS_PENDING, GET_RESELLERS_FULFILLED, GET_RESELLERS_REJECTED,
    GET_RESELLER_PENDING, GET_RESELLER_FULFILLED, GET_RESELLER_REJECTED,
    GET_RESELLER_PERSONS_PENDING, GET_RESELLER_PERSONS_FULFILLED, GET_RESELLER_PERSONS_REJECTED,
    GET_DEPARTMENT_PERSONS_PENDING, GET_DEPARTMENT_PERSONS_FULFILLED, GET_DEPARTMENT_PERSONS_REJECTED,
    FAST_OPERATION
} from '../actions/person';

const initialState = {
    activePageTitle: "",
    breadCrumb: [],
    loginResult: {},
    person: JSON.parse(localStorage.getItem("person")),
    token: localStorage.getItem("token"),
    persons: [],
    addPersonResult: {},
    updatePersonResult: {},
    deletePersonResult: {},
    resellers: [],
    reseller: {
        city: {
            name: ''
        },
        county: {
            name: ''
        }
    },
    resellerPersons: [],
    resetPersonPasswordResult: {},
    departmentPersons: [],
    getDepartmentPersonsLoading: false,
    getPersonsLoading: false,
    addPersonLoading: false,
    updatePersonLoading: false,
    deletePersonLoading: false,
    getPersonLoading: false,
    getResellersLoading: false,
    getResellerLoading: false,
    getResellerPersonsLoading: false,
    fastOperation: null,
    getPersonPasswordLoading: false,
}

// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BREADCRUMB:
            return {
                ...state,
                breadCrumb: action.payload.breadCrumb,
            }
        case FAST_OPERATION:
            return {
                ...state,
                fastOperation: action.payload.fastOperation,
            }
        case SET_PAGE_TITLE:
            return {
                ...state,
                activePageTitle: action.payload.title,
            }
        case LOGIN_PERSON_PENDING:
            return {
                ...state,
                loginFetching: true,
            }
        case LOGIN_PERSON_FULFILLED:
            return {
                ...state,
                loginFetching: false,
                loginResult: action.payload
            }
        case LOGIN_PERSON_REJECTED:
            return {
                ...state,
                loginFetching: false,
                loginError: "Servis sağlayıcısına ulaşılamadı. Lütfen sistem yöneticinizle iletişime geçin."
            }

        case GET_DEPARTMENT_PERSONS_PENDING:
            return {
                ...state,
                getDepartmentPersonsLoading: true,
            }
        case GET_DEPARTMENT_PERSONS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getDepartmentPersonsLoading: false
            }
        case GET_DEPARTMENT_PERSONS_REJECTED:
            return {
                ...state,
                getDepartmentPersonsLoading: false,
            }

        case GET_PERSONS_PENDING:
            return {
                ...state,
                getPersonsLoading: true,
            }
        case GET_PERSONS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getPersonsLoading: false
            }
        case GET_PERSONS_REJECTED:
            return {
                ...state,
                getPersonsLoading: false,
            }
        case ADD_PERSON_PENDING:
            return {
                ...state,
                addPersonLoading: true,
            }
        case ADD_PERSON_FULFILLED:
            return {
                ...state,
                addPersonResult: action.payload,
                addPersonLoading: false,
            }
        case ADD_PERSON_REJECTED:
            return {
                ...state,
                addPersonLoading: false,
            }
        case UPDATE_PERSON_PENDING:
            return {
                ...state,
                updatePersonLoading: true,

            }
        case UPDATE_PERSON_FULFILLED:
            return {
                ...state,
                updatePersonResult: action.payload,
                updatePersonLoading: false,
            }
        case UPDATE_PERSON_REJECTED:
            return {
                ...state,
                updatePersonLoading: false,
            }
        case DELETE_PERSON_PENDING:
            return {
                ...state,
                deletePersonLoading: true,
            }
        case DELETE_PERSON_FULFILLED:
            return {
                ...state,
                deletePersonResult: action.payload,
                deletePersonLoading: true,
            }
        case DELETE_PERSON_REJECTED:
            return {
                ...state,
                deletePersonLoading: false,
            }
        case GET_PERSON_PENDING:
            return {
                ...state,
                getPersonLoading: true,

            }
        case GET_PERSON_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getPersonLoading: false,
            }
        case GET_PERSON_REJECTED:
            return {
                ...state,
                getPersonLoading: false,
            }
        case GET_RESELLERS_PENDING:
            return {
                ...state,
                getResellersLoading: true,
            }
        case GET_RESELLERS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getResellersLoading: false,
            }
        case GET_RESELLERS_REJECTED:
            return {
                ...state,
                getResellersLoading: false,
            }
        case GET_RESELLER_PENDING:
            return {
                ...state,
                getResellerLoading: true,
            }
        case GET_RESELLER_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getResellerLoading: false,
            }
        case GET_RESELLER_REJECTED:
            return {
                ...state,
                getResellerLoading: false,
            }

        case GET_RESELLER_PERSONS_PENDING:
            return {
                ...state,
                getResellerPersonsLoading: true,
            }
        case GET_RESELLER_PERSONS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getResellerPersonsLoading: false
            }
        case GET_RESELLER_PERSONS_REJECTED:
            return {
                ...state,
                getResellerPersonsLoading: false
            }

        case RESET_PERSON_PASSWORD_PENDING:
            return {
                ...state,
                getPersonPasswordLoading: true,
            }
        case RESET_PERSON_PASSWORD_FULFILLED:
            return {
                ...state,
                resetPersonPasswordResult: action.payload,
                getPersonPasswordLoading: false,
            }
        case RESET_PERSON_PASSWORD_REJECTED:
            return {
                ...state,
                getPersonPasswordLoading: false,
            }
        default:
            return state;
    }
}
