
import axios from 'axios';
import { API_URL } from '../config/config';

export const UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_PENDING = "UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_PENDING";
export const UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_FULFILLED = "UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_FULFILLED";
export const UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_REJECTED = "UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_REJECTED";

export const GET_PERIOD_COUNTERS_WITH_DATA_PENDING = "GET_PERIOD_COUNTERS_WITH_DATA_PENDING";
export const GET_PERIOD_COUNTERS_WITH_DATA_FULFILLED = "GET_PERIOD_COUNTERS_WITH_DATA_FULFILLED";
export const GET_PERIOD_COUNTERS_WITH_DATA_REJECTED = "GET_PERIOD_COUNTERS_WITH_DATA_REJECTED";

export const UPLOAD_COUNTER_EXCEL_LIST_PENDING = "UPLOAD_COUNTER_EXCEL_LIST_PENDING";
export const UPLOAD_COUNTER_EXCEL_LIST_FULFILLED = "UPLOAD_COUNTER_EXCEL_LIST_FULFILLED";
export const UPLOAD_COUNTER_EXCEL_LIST_REJECTED = "UPLOAD_COUNTER_EXCEL_LIST_REJECTED";

export const CHECK_PERIOD_ANALYSIS_PENDING = "CHECK_PERIOD_ANAYLYSIS_PENDING";
export const CHECK_PERIOD_ANALYSIS_FULFILLED = "CHECK_PERIOD_ANALYSIS_FULFILLED";
export const CHECK_PERIOD_ANALYSIS_REJECTED = "CHECK_PERIOD_ANALYSIS_REJECTED";

export const SET_COMPARISON_PENDING = "SET_COMPARISON_PENDING";
export const SET_COMPARISON_FULFILLED = "SET_COMPARISON_FULFILLED";
export const SET_COMPARISON_REJECTED = "SET_COMPARISON_REJECTED";

export const UPDATE_APARTMENT_COUNTER_DATA_PENDING = "UPDATE_APARTMENT_COUNTER_DATA_PENDING";
export const UPDATE_APARTMENT_COUNTER_DATA_FULFILLED = "UPDATE_APARTMENT_COUNTER_DATA_FULFILLED";
export const UPDATE_APARTMENT_COUNTER_DATA_REJECTED = "UPDATE_APARTMENT_COUNTER_DATA_REJECTED";

export const ADD_APARTMENT_COUNTER_DATA_PENDING = "ADD_APARTMENT_COUNTER_DATA_PENDING";
export const ADD_APARTMENT_COUNTER_DATA_FULFILLED = "ADD_APARTMENT_COUNTER_DATA_FULFILLED";
export const ADD_APARTMENT_COUNTER_DATA_REJECTED = "ADD_APARTMENT_COUNTER_DATA_REJECTED";

export const NOT_DEFECTIVE_PENDING = "NOT_DEFECTIVE_PENDING";
export const NOT_DEFECTIVE_FULFILLED = "NOT_DEFECTIVE_FULFILLED";
export const NOT_DEFECTIVE_REJECTED = "NOT_DEFECTIVE_REJECTED";

export const SET_DEFECTIVE_PENDING = "SET_DEFECTIVE_PENDING";
export const SET_DEFECTIVE_FULFILLED = "SET_DEFECTIVE_FULFILLED";
export const SET_DEFECTIVE_REJECTED = "SET_DEFECTIVE_REJECTED";

export const GET_BLOCK_APARTMENT_COUNTERS_PENDING = "GET_BLOCK_APARTMENT_COUNTERS_PENDING";
export const GET_BLOCK_APARTMENT_COUNTERS_FULFILLED = "GET_BLOCK_APARTMENT_COUNTERS_FULFILLED";
export const GET_BLOCK_APARTMENT_COUNTERS_REJECTED = "GET_BLOCK_APARTMENT_COUNTERS_REJECTED";

export const UPDATE_APARTMENT_COUNTER_PENDING = "UPDATE_APARTMENT_COUNTER_PENDING";
export const UPDATE_APARTMENT_COUNTER_FULFILLED = "UPDATE_APARTMENT_COUNTER_FULFILLED";
export const UPDATE_APARTMENT_COUNTER_REJECTED = "UPDATE_APARTMENT_COUNTER_REJECTED";

export const DELETE_APARTMENT_COUNTER_PENDING = "DELETE_APARTMENT_COUNTER_PENDING";
export const DELETE_APARTMENT_COUNTER_FULFILLED = "DELETE_APARTMENT_COUNTER_FULFILLED";
export const DELETE_APARTMENT_COUNTER_REJECTED = "DELETE_APARTMENT_COUNTER_REJECTED";

export const GET_PROJECT_APARTMENT_COUNTERS_PENDING = "GET_PROJECT_APARTMENT_COUNTERS_PENDING";
export const GET_PROJECT_APARTMENT_COUNTERS_FULFILLED = "GET_PROJECT_APARTMENT_COUNTERS_FULFILLED";
export const GET_PROJECT_APARTMENT_COUNTERS_REJECTED = "GET_PROJECT_APARTMENT_COUNTERS_REJECTED";

export const GET_APARTMENT_COUNTERS_PENDING = "GET_APARTMENT_COUNTERS_PENDING";
export const GET_APARTMENT_COUNTERS_FULFILLED = "GET_APARTMENT_COUNTERS_FULFILLED";
export const GET_APARTMENT_COUNTERS_REJECTED = "GET_APARTMENT_COUNTERS_REJECTED";

export const ADD_APARTMENT_COUNTER_PENDING = "ADD_APARTMENT_COUNTER_PENDING";
export const ADD_APARTMENT_COUNTER_FULFILLED = "ADD_APARTMENT_COUNTER_FULFILLED";
export const ADD_APARTMENT_COUNTER_REJECTED = "ADD_APARTMENT_COUNTER_REJECTED";

export const CHANGE_APARTMENT_COUNTER_PENDING = "CHANGE_APARTMENT_COUNTER_PENDING";
export const CHANGE_APARTMENT_COUNTER_FULFILLED = "CHANGE_APARTMENT_COUNTER_FULFILLED";
export const CHANGE_APARTMENT_COUNTER_REJECTED = "CHANGE_APARTMENT_COUNTER_REJECTED";


export function addApartmentCounter(counter, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_APARTMENT_COUNTER",
            payload: axios.post(`${API_URL}/apartment-counter`, counter, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function changeApartmentCounter(counter, token) {
    return async dispatch => {
        await dispatch({
            type: "CHANGE_APARTMENT_COUNTER",
            payload: axios.post(`${API_URL}/apartment-counter/change`, counter, { headers: { "x-access-token": token, 'content-type': 'multipart/form-data' } })
                .then(result => result.data)
        })
    }
}

export function updateApartmentCounter(counter, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_APARTMENT_COUNTER",
            payload: axios.put(`${API_URL}/apartment-counter`, counter, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteApartmentCounter(counter, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_APARTMENT_COUNTER",
            payload: axios.delete(`${API_URL}/apartment-counter`, { data: { ...counter, token } })
                .then(result => result.data)
        })
    }
}

export function getBlockApartmentCounters(block, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_BLOCK_APARTMENT_COUNTERS",
            payload: axios.get(`${API_URL}/apartment-counter/block-counters/${block}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getApartmentCounters(apartment, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_APARTMENT_COUNTERS",
            payload: axios.get(`${API_URL}/apartment-counter/apartment-counters/${apartment}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getProjectApartmentCounters(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_APARTMENT_COUNTERS",
            payload: axios.get(`${API_URL}/apartment-counter/project-counters/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function uploadCounterListExcel(formData, token) {
    return async dispatch => {
        await dispatch({
            type: "UPLOAD_COUNTER_EXCEL_LIST",
            payload: axios.post(`${API_URL}/apartment-counter/import-counter-list-excel`, formData, { headers: { "x-access-token": token, 'content-type': 'multipart/form-data' } })
                .then(result => result.data)
        })
    }
}

export function uploadCollectiveApartmentCountersExcel(formData, token) {
    return async dispatch => {
        await dispatch({
            type: "UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL",
            payload: axios.post(`${API_URL}/apartment-counter/import-collective-apartment-counter-list-excel`, formData, { headers: { "x-access-token": token, 'content-type': 'multipart/form-data' } })
                .then(result => result.data)
        })
    }
}

export function getPeriodCountersWithData(params, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PERIOD_COUNTERS_WITH_DATA",
            payload: axios.get(`${API_URL}/apartment-counter/period-counters-with-data`, { params, headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function checkPeriodAnalysis(period, project, token) {
    return async dispatch => {
        await dispatch({
            type: "CHECK_PERIOD_ANALYSIS",
            payload: axios.post(`${API_URL}/apartment-counter/check-analysis/`, { period, project }, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function setComparison(comparison, token) {
    return async dispatch => {
        await dispatch({
            type: "SET_COMPARISON",
            payload: axios.post(`${API_URL}/apartment-counter/set-comparison/`, comparison, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addApartmentCounterData(apartmentCounterData, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_APARTMENT_COUNTER_DATA",
            payload: axios.post(`${API_URL}/apartment-counter/add-new-data/`, apartmentCounterData, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updateApartmentCounterData(apartmentCounterData, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_APARTMENT_COUNTER_DATA",
            payload: axios.put(`${API_URL}/apartment-counter/update-last-new-data/`, apartmentCounterData, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function notDefective(apartmentCounterData, token) {
    return async dispatch => {
        await dispatch({
            type: "NOT_DEFECTIVE",
            payload: axios.put(`${API_URL}/apartment-counter/not-defective/`, { _id: apartmentCounterData }, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function setDefective(apartmentCounterData, token) {
    return async dispatch => {
        await dispatch({
            type: "SET_DEFECTIVE",
            payload: axios.put(`${API_URL}/apartment-counter/set-defective/`, { _id: apartmentCounterData }, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

