import React, { Component } from 'react'
import { Input, Button, FormControl, InputLabel, DialogContent, DialogTitle, DialogContentText } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../../styles/app';

class AddProblemForm extends Component {

    state = {
        _id: this.props.problem._id,
        solutionStatus: true,
        solverPerson: this.props.person._id,
    };

    handleChangeInput = (element) => {
        this.setState({ [element.target.name]: element.target.value })
    }

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <DialogTitle id="alert-dialog-title">
                    {this.props.problem.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.problem.description}
                    </DialogContentText>
                </DialogContent>

                <FormControl fullWidth>
                    <InputLabel htmlFor="solverDescription">Çözüm Açıklaması</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="solverDescription"
                        name="solverDescription"
                        type="text"
                        defaultValue={this.props.problem.solverDescription}
                    />
                </FormControl>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    PROBLEMİ ÇÖZ
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(AddProblemForm);