import React, { Component } from 'react';
// components
import Table from '../../Items/Table';
class ApartmentInvoicesTable extends Component {

    render() {
        const tableData = {
            title: "Daire Fatura Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                {
                    title: "Dönem", field: "period.name",
                    render: rowData => {
                        return (
                            <span style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#f52957",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}>{rowData.period.name}</span >
                        );
                    }
                },
                {
                    title: "Toplam Tutar", field: "total",
                    render: rowData => {
                        return (
                            rowData.total + " ₺"
                        );
                    }
                },
                {
                    title: "Fatura Sayısı", field: "count",
                    render: rowData => {
                        return (
                            rowData.count
                        );
                    }
                },
            ],
            data: this.props.invoices
        };

        let tableActions = [];

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ApartmentInvoicesTable;