import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_SHARING_TYPES_PENDING = "GET_SHARING_TYPES_PENDING";
export const GET_SHARING_TYPES_FULFILLED = "GET_SHARING_TYPES_FULFILLED";
export const GET_SHARING_TYPES_REJECTED = "GET_SHARING_TYPES_REJECTED";

export const GET_PROJECT_SHARING_TYPES_PENDING = "GET_PROJECT_SHARING_TYPES_PENDING";
export const GET_PROJECT_SHARING_TYPES_FULFILLED = "GET_PROJECT_SHARING_TYPES_FULFILLED";
export const GET_PROJECT_SHARING_TYPES_REJECTED = "GET_PROJECT_SHARING_TYPES_REJECTED";


export function getAllSharingTypes(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_SHARING_TYPES",
            payload: axios.get(`${API_URL}/sharing-type/all`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getProjectSharingTypes(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_SHARING_TYPES",
            payload: axios.get(`${API_URL}/sharing-type/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

