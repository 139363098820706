import {
    GET_ALL_TASKTYPE_PENDING, GET_ALL_TASKTYPE_FULFILLED, GET_ALL_TASKTYPE_REJECTED,
    GET_TASKTYPE_PENDING, GET_TASKTYPE_FULFILLED, GET_TASKTYPE_REJECTED
} from '../actions/taskType';
const initialState = {
    taskTypes: [],
    getAllTaskTypeLoading: false,
    getTaskTypeLoading: false,

}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TASKTYPE_PENDING:
            return {
                ...state,
                getAllTaskTypeLoading: true,
            }
        case GET_ALL_TASKTYPE_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAllTaskTypeLoading: false
            }
        case GET_ALL_TASKTYPE_REJECTED:
            return {
                ...state,
                getAllTaskTypeLoading: false
            }
        case GET_TASKTYPE_PENDING:
            return {
                ...state,
                getTaskTypeLoading: true
            }
        case GET_TASKTYPE_FULFILLED:
            return {
                ...state,
                selectedTaskType: action.payload.data.taskType,
                getTaskTypeLoading: false
            }
        case GET_TASKTYPE_REJECTED:
            return {
                ...state,
                getTaskTypeLoading: false
            }
        default:
            return state;
    }
}
