// export const API_INVOICE_IMAGE_URL = "http://localhost:4000/invoiceImages/";
// export const API_COUNTER_IMAGE_URL = "http://localhost:4000/counterImages/";
// export const API_URL = "http://localhost:4000";
// export const SITE_URL = "http://localhost:5000";

// export const API_INVOICE_IMAGE_URL = "http://192.168.0.165:4000/invoiceImages/";
// export const API_URL = "http://192.168.0.165:4000";
// export const SITE_URL = "http://192.168.0.165:5000";

// export const API_INVOICE_IMAGE_URL = "http://46.105.142.195:4000/invoiceImages/";
// export const API_URL = "http://46.105.142.195:4000";
// export const SITE_URL = "http://46.105.142.195:5000";

export const API_COUNTER_IMAGE_URL = "http://46.105.142.195:4000/counterImages/";
export const API_INVOICE_IMAGE_URL = "http://46.105.142.195:4000/invoiceImages/";
export const API_URL = "http://46.105.142.195:4000";
export const SITE_URL = "https://online.tebasenerji.com";