import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, Typography, AppBar, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ModalDialog from '../Items/ModalDialog';
import swal from 'sweetalert';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { setActivePageTitle, setBreadCrumb, setFastOperation } from '../../actions/person';
import { getBlock } from '../../actions/block';
import { getAllBlockApartments, addApartment, updateApartment, deleteApartment, } from '../../actions/apartment';
import { getBlockApartmentCounters, updateApartmentCounter, deleteApartmentCounter } from '../../actions/apartmentCounter';
import AddAparment from '../Apartments/AddApartment';
import EditApartment from '../Apartments/EditApartment';
import ApartmentsTable from '../Apartments/ApartmentsTable';
import ApartmentCountersTable from '../ApartmentCounters/ApartmentCountersTable';
import EditApartmentCounterForm from '../ApartmentCounters/EditApartmentCounterForm';
import FastOperation from '../Items/FastOperation';
import queryString from 'query-string';
import classNames from 'classnames';
const styles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

class BlockDetail extends Component {

    // component içerisinde kullanılacak state
    state = {
        editApartmentOpenDialog: false,
        addApartmentOpenDialog: false,
        value: null,
        messageKey: '',
        rowData: {},
        editCounterOpenDailog: false,
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {

        const { match: { params } } = this.props;
        await this.props.getBlock(params._id, this.props.token);
        this.props.setActivePageTitle(this.props.block.project.name + " / " + this.props.block.name);
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "link", path: "/projects", text: "Proje Yönetimi" },
            { type: "link", path: "/project/" + this.props.block.project._id, text: this.props.block.project.name },
            { type: "string", text: this.props.block.name },
        ];
        this.props.setBreadCrumb(breadCrumb);
        await this.props.setFastOperation(<FastOperation project={this.props.block.project} />);
        let string = window.location.href.split("?");
        if (string.length > 1) {
            const query = queryString.parse(string[1]);
            this.handleChangeTab(parseFloat(query.tab))
        }

    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editCounterOpenDailog: false, addApartmentOpenDialog: false, editApartmentOpenDialog: false });
    }

    addApartment = () => {
        this.setState({ addApartmentOpenDialog: true });
    }

    handleEditApartment = async (rowData) => {
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editApartmentOpenDialog: true });
    }

    handleAddApartmentSubmit = async (e, state) => {

        const apartment = {
            name: e.target.name.value,
            block: e.target.block.value,
            squareMeters: e.target.squareMeters.value,
            floorNumber: e.target.floorNumber.value,
            doorNumber: e.target.doorNumber.value,
            description: e.target.description.value,
            livingStatus: state.livingStatus,
            additionalExpenseStatus: state.additionalExpenseStatus,
            sharingStatus: state.sharingStatus,
            heatLossExpenseStatus: state.heatLossExpenseStatus,
        };


        return swal({
            title: "Emin misin?",
            text: "Bu bloğa yeni bir daire ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addApartment(apartment, this.props.token);
                    if (this.props.addApartmentResult.code === 200) {
                        this.setState({ addApartmentOpenDialog: false, value: 0 });
                        await this.props.getAllBlockApartments(this.props.block._id, this.props.token);
                        this.props.enqueueSnackbar('Daire Başarıyla Eklendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Daire eklenirken bir hata oluştu..' + this.props.addApartmentResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleUpdateApartmentSubmit = async (e, state) => {

        const apartment = {
            _id: e.target._id.value,
            name: e.target.name.value,
            squareMeters: e.target.squareMeters.value,
            floorNumber: e.target.floorNumber.value,
            doorNumber: e.target.doorNumber.value,
            description: e.target.description.value,
            livingStatus: state.livingStatus,
            additionalExpenseStatus: state.additionalExpenseStatus,
            sharingStatus: state.sharingStatus,
            heatLossExpenseStatus: state.heatLossExpenseStatus,
        };


        return swal({
            title: "Emin misin?",
            text: "Bu daire bilgilerini güncelliyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateApartment(apartment, this.props.token);
                    if (this.props.updateApartmentResult.code === 200) {
                        this.setState({ editApartmentOpenDialog: false, value: 0 });
                        await this.props.getAllBlockApartments(this.props.block._id, this.props.token);
                        this.props.enqueueSnackbar('Daire Başarıyla Güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Daire güncellenirken bir hata oluştu..' + this.props.updateApartmentResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleDeleteApartment = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (apartment) => {
                        await this.props.deleteApartment(apartment, this.props.token);
                        if (this.props.deleteApartmentResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteApartmentResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });

                    await this.props.getAllBlockApartments(this.props.block._id, this.props.token);

                }
            });


    }

    handleChangeTab = async (value) => {
        this.setState({ value });
        // eğer ki Daireler sekmesine tıklandıysa
        if (value === 0) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.block.project._id, text: this.props.block.project.name },
                { type: "link", path: "/block/" + this.props.block._id, text: this.props.block.name },
                { type: "string", text: "Daireler" },
            ];
            this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllBlockApartments(this.props.block._id, this.props.token);
        }
        else if (value === 1) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.block.project._id, text: this.props.block.project.name },
                { type: "link", path: "/block/" + this.props.block._id, text: this.props.block.name },
                { type: "string", text: "Tüm Daire Sayaçları" },
            ];
            this.props.setBreadCrumb(breadCrumb);
            await this.props.getBlockApartmentCounters(this.props.block._id, this.props.token);
        }
    };

    handleEditCounter = async (rowData) => {
        this.setState({ editCounterOpenDailog: true, apartmentCounter: Object.assign({}, rowData) });
    }

    handleUpdateApartmentCounterSubmit = async (e, state) => {

        const counter = {
            _id: e.target._id.value,
            name: e.target.name.value,
            serialNumber: e.target.serialNumber.value,
            status: state.status,
            shouldChangeStatus: state.shouldChangeStatus,
            description: e.target.description.value,
        };


        return swal({
            title: "Emin misin?",
            text: "Seçilen sayayacı güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateApartmentCounter(counter, this.props.token);
                    if (this.props.updateApartmentCounterResult.code === 200) {
                        this.setState({ editCounterOpenDailog: false, value: 1 });
                        await this.props.getBlockApartmentCounters(this.props.block._id, this.props.token);
                        this.props.enqueueSnackbar('Sayaç başarıyla güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Sayaç güncellenirken bir hata oluştu..' + this.props.updateApartmentCounterResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleDeleteApartmentCounter = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (apartmentCounter) => {
                        await this.props.deleteApartmentCounter(apartmentCounter, this.props.token);
                        if (this.props.deleteApartmentCounterResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteApartmentCounterResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });

                    await this.props.getBlockApartmentCounters(this.props.block._id, this.props.token);

                }
            });


    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                {this.props.getBlockApaertmenCountersLoading ? <div className="loading" /> : ""}
                {this.props.getBlockApartmentsLoading ? <div className="loading" /> : ""}
                {this.props.getApartmentLoading ? <div className="loading" /> : ""}
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Daha Fazla Detaylı Bilgi</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {this.props.addBlockLoading ? <div className="loading" /> : ""}
                        {this.props.getProjectBlocksLoading ? <div className="loading" /> : ""}
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Block Adı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.block.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Proje Adı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.block.project.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Kat Başlangıcı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.block.floorStart}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Kat Bitişi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.block.floorEnd}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Daire Sayısı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.block.apartments.length}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <br /><br /><br /><br />
                <Divider variant="middle" />
                <br /><br /><br /><br />
                <AppBar position="static" color="default">
                    <div className="tab">
                        <button onClick={() => { this.handleChangeTab(0) }} className={classNames(["tablinks", value === 0 ? "tabActive" : ""])}>Daireler</button>
                        <button onClick={() => { this.handleChangeTab(1) }} className={classNames(["tablinks", value === 1 ? "tabActive" : ""])}>Daire Sayaçları</button>
                    </div>
                </AppBar>
                {value === 0 && <div style={{ padding: 8 * 3 }}>
                    <ApartmentsTable {...this.props} addForm={this.addApartment} editForm={this.handleEditApartment} delete={this.handleDeleteApartment} />
                    <ModalDialog
                        open={this.state.addApartmentOpenDialog}
                        title={this.props.block.project.name + " / " + this.props.block.name + ' - Yeni Daire Ekleme'}
                        buttons={[
                            <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                        ]}>
                        <AddAparment onSubmitForm={this.handleAddApartmentSubmit} block={this.props.block} />
                    </ModalDialog>
                    <ModalDialog
                        open={this.state.editApartmentOpenDialog}
                        title={this.props.block.project.name + " / " + this.props.block.name + " / " + this.state.rowData.name + " - Güncelleme"}
                        buttons={[
                            <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                        ]}>
                        <EditApartment {...this.state.rowData} onSubmitForm={this.handleUpdateApartmentSubmit} />
                    </ModalDialog>
                </div>}
                {value === 1 && <div style={{ padding: 8 * 3 }}>
                    <ApartmentCountersTable {...this.props} editForm={this.handleEditCounter} delete={this.handleDeleteApartmentCounter} />
                    <ModalDialog
                        open={this.state.editCounterOpenDailog}
                        title={'Sayaç Güncelleme'}
                        buttons={[
                            <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                        ]}>
                        <EditApartmentCounterForm onSubmitForm={this.handleUpdateApartmentCounterSubmit} apartmentCounter={this.state.apartmentCounter} />
                    </ModalDialog>
                </div>}
            </div>
        )
    }
}

// componente gelecek propsların tipini tanımla
BlockDetail.propTypes = {
    //children: PropTypes.node.isRequired,
};

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ blockReducer, personReducer, apartmentReducer, apartmentCounterReducer }) => {
    return {
        ...personReducer,
        ...blockReducer,
        ...apartmentReducer,
        ...apartmentCounterReducer,
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setBreadCrumb,
    setActivePageTitle,
    getBlock,
    getAllBlockApartments,
    addApartment,
    updateApartment,
    deleteApartment,
    getBlockApartmentCounters,
    updateApartmentCounter,
    deleteApartmentCounter,
    setFastOperation

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(BlockDetail)));