import {
    GET_BLOCK_APARTMENTS_PENDING, GET_BLOCK_APARTMENTS_FULFILLED, GET_BLOCK_APARTMENTS_REJECTED,
    ADD_APARTMENT_PENDING, ADD_APARTMENT_FULFILLED, ADD_APARTMENT_REJECTED,
    UPDATE_APARTMENT_PENDING, UPDATE_APARTMENT_FULFILLED, UPDATE_APARTMENT_REJECTED,
    DELETE_APARTMENT_PENDING, DELETE_APARTMENT_FULFILLED, DELETE_APARTMENT_REJECTED,
    GET_APARTMENT_PENDING, GET_APARTMENT_FULFILLED, GET_APARTMENT_REJECTED,
    UPLOAD_COLELCTIVE_APARTMENT_EXCEL_PENDING, UPLOAD_COLELCTIVE_APARTMENT_EXCEL_FULFILLED, UPLOAD_COLELCTIVE_APARTMENT_EXCEL_REJECTED,
    GET_APARTMENT_PERIOD_INVOICES_PENDING, GET_APARTMENT_PERIOD_INVOICES_FULFILLED, GET_APARTMENT_PERIOD_INVOICES_REJECTED,
    DELETE_APARTMENT_INVOICE_PENDING, DELETE_APARTMENT_INVOICE_FULFILLED, DELETE_APARTMENT_INVOICE_REJECTED,
    GET_APARTMENT_PERIOD_INVOICES_PDF_PENDING, GET_APARTMENT_PERIOD_INVOICES_PDF_FULFILLED, GET_APARTMENT_PERIOD_INVOICES_PDF_REJECTED,
    GET_APARTMENT_INVOICES_PENDING, GET_APARTMENT_INVOICES_FULFILLED, GET_APARTMENT_INVOICES_REJECTED,
} from '../actions/apartment';
const initialState = {
    deleteApartmentLoading: false,
    updateApartmentLoading: false,
    addApartmentLoading: false,
    uploadColelctiveApartmentExcelLoading: false,
    getBlockApartmentsLoading: false,
    getApartmentLoading: false,
    getApartmentInvoicesLoading: false,
    getApartmentPeriodInvoicesPDFLoading: false,
    deleteApartmentInvoiceLoading: false,
    getApartmentPeriodInvoicesLoading: false,
    apartments: [],
    apartment: {
        additionalExpenseStatus: false,
        sharingStatus: false,
        heatLossExpenseStatus: false,
        livingStatus: false,
        block: {
            _id: '',
            name: "",
            project: {
                _id: '',
                name: ''
            }
        }
    },
    addApartmentResult: {},
    updateApartmentResult: {},
    deleteApartmentResult: {},
    uploadApartmentExcelListResult: {},
    periodInvoices: [],
    deleteApartmentInvoiceResult: {},
    periodInvoicesPdfResult: {
        apartments: [],
        periodInvoices: [],
    }
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_APARTMENT_INVOICES_PENDING:
            return {
                ...state,
                getApartmentInvoicesLoading: true,
            }
        case GET_APARTMENT_INVOICES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getApartmentInvoicesLoading: false,
            }
        case GET_APARTMENT_INVOICES_REJECTED:
            return {
                ...state,
                getApartmentInvoicesLoading: false
            }
        case GET_APARTMENT_PERIOD_INVOICES_PDF_PENDING:
            return {
                ...state,
                getApartmentPeriodInvoicesPDFLoading: true,
            }
        case GET_APARTMENT_PERIOD_INVOICES_PDF_FULFILLED:
            return {
                ...state,
                periodInvoicesPdfResult: action.payload,
                getApartmentPeriodInvoicesPDFLoading: false,
            }
        case GET_APARTMENT_PERIOD_INVOICES_PDF_REJECTED:
            return {
                ...state,
                getApartmentPeriodInvoicesPDFLoading: false,
            }
        case DELETE_APARTMENT_INVOICE_PENDING:
            return {
                ...state,
                deleteApartmentInvoiceLoading: true,
            }
        case DELETE_APARTMENT_INVOICE_FULFILLED:
            return {
                ...state,
                deleteApartmentInvoiceResult: action.payload,
                deleteApartmentInvoiceLoading: false,
            }
        case DELETE_APARTMENT_INVOICE_REJECTED:
            return {
                ...state,
                deleteApartmentInvoiceLoading: false,
            }
        case GET_APARTMENT_PERIOD_INVOICES_PENDING:
            return {
                ...state,
                getApartmentPeriodInvoicesLoading: true,
            }
        case GET_APARTMENT_PERIOD_INVOICES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getApartmentPeriodInvoicesLoading: false
            }
        case GET_APARTMENT_PERIOD_INVOICES_REJECTED:
            return {
                ...state,
                getApartmentPeriodInvoicesLoading: false,
            }
        case GET_APARTMENT_PENDING:
            return {
                ...state,
                getApartmentLoading: true,
            }
        case GET_APARTMENT_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getApartmentLoading: false,
            }
        case GET_APARTMENT_REJECTED:
            return {
                ...state,
                getApartmentLoading: false,
            }
        case GET_BLOCK_APARTMENTS_PENDING:
            return {
                ...state,
                getBlockApartmentsLoading: true,
            }
        case GET_BLOCK_APARTMENTS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getBlockApartmentsLoading: false,
            }
        case GET_BLOCK_APARTMENTS_REJECTED:
            return {
                ...state,
                getBlockApartmentsLoading: false,
            }

        case UPLOAD_COLELCTIVE_APARTMENT_EXCEL_PENDING:
            return {
                ...state,
                uploadColelctiveApartmentExcelLoading: true
            }
        case UPLOAD_COLELCTIVE_APARTMENT_EXCEL_FULFILLED:
            return {
                ...state,
                uploadApartmentExcelListResult: action.payload,
                uploadColelctiveApartmentExcelLoading: false,
            }
        case UPLOAD_COLELCTIVE_APARTMENT_EXCEL_REJECTED:
            return {
                ...state,
                uploadColelctiveApartmentExcelLoading: false
            }
        case ADD_APARTMENT_PENDING:
            return {
                ...state,
                addApartmentLoading: true,
            }
        case ADD_APARTMENT_FULFILLED:
            return {
                ...state,
                addApartmentResult: action.payload,
                addApartmentLoading: false,
            }
        case ADD_APARTMENT_REJECTED:
            return {
                ...state,
                addApartmentLoading: false,
            }

        case UPDATE_APARTMENT_PENDING:
            return {
                ...state,
                updateApartmentLoading: true,
            }
        case UPDATE_APARTMENT_FULFILLED:
            return {
                ...state,
                updateApartmentResult: action.payload,
                updateApartmentLoading: false,
            }
        case UPDATE_APARTMENT_REJECTED:
            return {
                ...state,
                updateApartmentLoading: false,
            }
        case DELETE_APARTMENT_PENDING:
            return {
                ...state,
                deleteApartmentLoading: true
            }
        case DELETE_APARTMENT_FULFILLED:
            return {
                ...state,
                deleteApartmentResult: action.payload,
                deleteApartmentLoading: false
            }
        case DELETE_APARTMENT_REJECTED:
            return {
                ...state,
                deleteApartmentLoading: false
            }
        default:
            return state;
    }
}