import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Grid, Divider, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ModalDialog from '../Items/ModalDialog';
import swal from 'sweetalert';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { setActivePageTitle, setBreadCrumb, getAllResellerPersons, addPerson, updatePerson, deletePerson, resetPersonPassword, getReseller } from '../../actions/person';
import { addAddress, updateAddress, deleteAddress, getAllAddresses } from '../../actions/address';
import { getResellerProjects, deleteProject } from '../../actions/project';
import { getAllSharingTypes } from '../../actions/sharingType';
import ResetPasswordForm from '../Items/ResetPasswordForm';
import ProjectsTable from '../Projects/ProjectsTable';
import AddPersonForm from '../Persons/AddPersonForm';
import EditPersonForm from '../Persons/EditPersonForm';
import ResellerPersonsTable from './ResellerPersons/ResellerPersonsTable';
import AddressesTable from '../Addresses/AddressesTable';
import AddAddressForm from '../Addresses/AddAddressForm';
import EditAddressForm from '../Addresses/EditAddressForm';

const styles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

class ResellerDetail extends Component {

    // component içerisinde kullanılacak state
    state = {
        addPersonOpenDialog: false,
        addAddressOpenDialog: false,
        editResellerPersonDialog: false,
        editAddressDialog: false,
        resellerPasswordDialog: false,
        value: 0,
        messageKey: '',
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {

        // yetki kontrolü
        if (!this.props.person.permissions.reseller.read) {
            return window.location.href = "/";
        }

        const { match: { params } } = this.props;
        await this.props.getReseller(params._id, this.props.token);
        this.props.setActivePageTitle(this.props.reseller.title);
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "link", path: "/resellers", text: "Müşteri Yönetimi" },
            { type: "string", text: this.props.reseller.title }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false, addOpenDialog: false, addAddressOpenDialog: false, addPersonOpenDialog: false, editAddressesDialog: false, editResellerPersonDialog: false, resellerPasswordDialog: false });
    }

    handleChangeTab = async (event, value) => {
        this.setState({ value });
        if (value === 1) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/resellers", text: "Müşteri Yönetimi" },
                { type: "link", path: "/reseller/" + this.props.reseller._id, text: this.props.reseller.title },
                { type: "string", text: "Mevcut Adresler" }
            ];
            this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllAddresses(this.props.reseller._id, this.props.token);
        }
        // egerki projeler sekmesine tıklandıysa
        else if (value === 2) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/resellers", text: "Müşteri Yönetimi" },
                { type: "link", path: "/reseller/" + this.props.reseller._id, text: this.props.reseller.title },
                { type: "string", text: "Mevcut Projeler" }
            ];
            this.props.setBreadCrumb(breadCrumb);
            await this.props.getResellerProjects(this.props.reseller._id, this.props.token);
            await this.props.getAllSharingTypes(this.props.token);
        }
        else if (value === 4) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/resellers", text: "Müşteri Yönetimi" },
                { type: "link", path: "/reseller/" + this.props.reseller._id, text: this.props.reseller.title },
                { type: "string", text: "Mevcut Personeller" }
            ];
            this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllResellerPersons(this.props.reseller._id, this.props.token);
        }

    };

    getAllResellerPerson = (resellerPersonId) => {
        this.props.getAllResellerPerson(resellerPersonId, this.props.token);
    }

    handleEditResellerPerson = async (rowData) => {
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editResellerPersonDialog: true });
    }

    handleEditResellerPersonSubmit = async (e, state) => {

        const resellerPerson = {
            personType: 2,
            _id: e.target._id.value,
            title: e.target.title.value,
            // lastName: e.target.lastName.value,
            // surname: e.target.surname.value,
            email: e.target.email.value,
            mobilePhone: e.target.mobilePhone.value,
            permissions: state.permissions
        };


        return swal({
            title: "Emin misin?",
            text: "Bu personeli güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })

            .then(async (value) => {
                if (value) {
                    await this.props.updatePerson(resellerPerson, this.props.token);
                    if (this.props.updatePersonResult.code === 200) {
                        this.setState({ editResellerPersonDialog: false });
                        await this.props.getAllResellerPersons(this.props.reseller._id, this.props.token);
                        this.props.enqueueSnackbar("Proje güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Proje güncelleme işlemi başarısız. " + this.props.updatePersonResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    // sil butonuna tıklandığında yapılacak işlem
    handleDeleteResellerPerson = (event, rowData) => {

        return swal({
            title: "Emin misin?",
            text: "Bu seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (resellerPerson) => {

                        await this.props.deletePerson(resellerPerson, this.props.token);

                        if (this.props.deletePersonResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deletePersonResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllResellerPersons(this.props.reseller._id, this.props.token);
                }
            });
    }

    // sil butonuna tıklandığında yapılacak işlem
    handleDeleteResellerProject = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (project) => {
                        await this.props.deleteProject(project, this.props.token);
                        if (this.props.deleteProjectResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteProjectResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getResellerProjects(this.props.reseller._id, this.props.token);
                    await this.props.getAllSharingTypes(this.props.token);
                }
            });


    }

    handleResetResellerPassword = async () => {
        this.setState({ resellerPasswordDialog: true });
    }

    handleResetResellerPasswordSubmit = async (e, state) => {

        if (e.target.password.value !== e.target.password_repeat.value) {
            this.props.enqueueSnackbar("Girmiş olduğunuz şifreler uyuşmuyor.. ", {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }


        const reseller = {
            personType: 1,
            _id: e.target._id.value,
            password: e.target.password.value,
        }

        return swal({
            title: "Emin misin?",
            text: "Şifreni Günecellemek İstediğinden!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })

            .then(async (value) => {
                if (value) {
                    await this.props.resetPersonPassword(reseller, this.props.token);
                    if (this.props.resetPersonPasswordResult.code === 200) {
                        this.setState({ resellerPasswordDialog: false });
                        this.props.enqueueSnackbar("Şifre güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Şifre sıfırlama işlemi başarısız.." + this.props.resetPersonPasswordResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }
    
    // yeni kayıt butonuna tıklandığında yapılacak işlem
    handleAddResellerPerson = async () => {
        this.setState({ addPersonOpenDialog: true });
    }

    handleAddResellerPersonSubmit = async (e, state) => {

        const resellerPerson = {
            personType: 2,
            title: e.target.title.value,
            // name: e.target.name.value,
            // lastName: e.target.lastName.value,
            // surname: e.target.surname.value,
            email: e.target.email.value,
            password: e.target.password.value,
            mobilePhone: e.target.mobilePhone.value,
            reseller: e.target.reseller.value,
            permissions: state.permissions
        };

        return swal({
            title: "Emin misin?",
            text: "Bu bayiye yeni bir personel ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addPerson(resellerPerson, this.props.token);
                    if (this.props.addPersonResult.code === 200) {
                        this.setState({ addPersonOpenDialog: false, value: 3 });
                        await this.props.getAllResellerPersons(this.props.reseller._id, this.props.token);
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addPersonResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleAddAddress = async () => {
        this.setState({ addAddressOpenDialog: true });
    }

    handleAddAddressSubmit = async (e, state) => {

        const address = {

            name: e.target.name.value,
            person: e.target.person.value,
            address: e.target.address.value,

        };

        return swal({
            title: "Emin misin?",
            text: "Yeni Bir Adres Eklenecek!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })

            .then(async (value) => {
                if (value) {
                    await this.props.addAddress(address, this.props.token);
                    if (this.props.addAddressResult.code === 200) {
                        this.setState({
                            addAddressOpenDialog: false, value: 1
                        });
                        await this.props.getAllAddresses(this.props.reseller._id, this.props.token);
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addAddressResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleEditAddress = async (rowData) => {
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editAddressDialog: true });
    }

    handleEditAddressSubmit = async (e, state) => {

        const address = {
            _id: e.target._id.value,
            name: e.target.name.value,
            address: e.target.address.value,
        };

        return swal({
            title: "Emin misin?",
            text: "Bu personeli güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })

            .then(async (value) => {
                if (value) {
                    await this.props.updateAddress(address, this.props.token);
                    if (this.props.updateAddressResult.code === 200) {
                        this.setState({ editAddressDialog: false, value: 1 });
                        await this.props.getAllAddresses(this.props.reseller._id, this.props.token);
                        this.props.enqueueSnackbar("Proje güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Proje güncelleme işlemi başarısız. " + this.props.updateAddressResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleDeleteAddress = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (address) => {
                        await this.props.deleteAddress(address, this.props.token);
                        if (this.props.deleteAddressResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteAddressResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllAddresses(this.props.reseller._id, this.props.token);
                }
            });
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                {this.props.getAdressesLoading ? <div className="loading" /> : ""}
                {this.props.getResellerProjectsLoading ? <div className="loading" /> : ""}
                {this.props.getResellerPersonsLoading ? <div className="loading" /> : ""}
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Daha Fazla Detaylı Bilgi</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Bayi Adı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Email
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Sabit Telefon
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.landPhone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                this.props.person.permissions.reseller.update ?
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Grid container>
                                            <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleResetResellerPassword} >
                                                Şifreni Yenile
                                    </Button>
                                            <ModalDialog
                                                open={this.state.resellerPasswordDialog}
                                                title={'Şifre Güncelleme'}
                                                buttons={[
                                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                                ]}>
                                                <ResetPasswordForm _id={this.props.reseller._id} onSubmitForm={this.handleResetResellerPasswordSubmit} />
                                            </ModalDialog>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Cep Telefonu
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.mobilePhone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Fax Numarası
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.faxPhone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Web Site
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.webSite}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Şehir
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.city.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            İlçe
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.county.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Vergi Dairesi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.taxOffice}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Vergi Numarası
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.taxNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Açıklama
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.reseller.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Divider variant="middle" />
                <br /><br /><br /><br />

                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTab}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"

                    >
                        <Tab label="" disabled />
                        <Tab label="Adresler" icon={<Business />} />
                        <Tab label="Projeler" icon={<Business />} />
                        <Tab label="Referans Olduğu Müşteriler" icon={<Business />} />
                        <Tab label="Personeller" icon={<Business />} />
                        <Tab label="Risk" icon={<Business />} />
                        <Tab label="İskontolar" icon={<Business />} />
                        <Tab label="Notlar & Uyarılar" icon={<Business />} />
                        <Tab label="Teminatlar" icon={<Business />} />
                    </Tabs>
                </AppBar>
                {value === 0 && <Typography component="div" style={{ padding: 8 * 3 }}>Bir Sekme Seçin..</Typography>}
                {
                    value === 1 && <div style={{ padding: 8 * 3 }}>
                        <AddressesTable {...this.props} addForm={this.handleAddAddress} editForm={this.handleEditAddress} delete={this.handleDeleteAddress} />
                        <ModalDialog
                            open={this.state.addAddressOpenDialog}
                            title={'Yeni Adres Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddAddressForm onSubmitForm={this.handleAddAddressSubmit} person={this.props.reseller} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editAddressDialog}
                            title={'Adres Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditAddressForm  {...this.state.rowData} onSubmitForm={this.handleEditAddressSubmit} />
                        </ModalDialog>
                    </div>

                }
                {
                    value === 2 && <div style={{ padding: 8 * 3 }}>
                        <ProjectsTable {...this.props} addForm={() => { }} editForm={() => { }} delete={this.handleDeleteResellerProject} />
                    </div>
                }
                {
                    value === 3 && <div style={{ padding: 8 * 3 }}>
                        Referans olan müşterileri
                    </div>
                }
                {
                    value === 4 && <div style={{ padding: 8 * 3 }}>
                        <ResellerPersonsTable {...this.props} addForm={this.handleAddResellerPerson} editForm={this.handleEditResellerPerson} delete={this.handleDeleteResellerPerson} />
                        <ModalDialog
                            open={this.state.addPersonOpenDialog}
                            title={'Bayiye Yeni Personel Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddPersonForm personType={2} onSubmitForm={this.handleAddResellerPersonSubmit} reseller={this.props.reseller._id} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editResellerPersonDialog}
                            title={'Bayinin Personelini Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditPersonForm personType={2} {...this.state.rowData} onSubmitForm={this.handleEditResellerPersonSubmit} />
                        </ModalDialog>
                    </div>
                }

            </div>
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer, cityReducer, projectReducer, sharingTypeReducer, addressReducer }) => {
    return {
        ...cityReducer,
        ...personReducer,
        ...projectReducer,
        ...sharingTypeReducer,
        ...addressReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    getReseller,
    getResellerProjects,
    getAllSharingTypes,
    setBreadCrumb,
    getAllResellerPersons,
    addPerson,
    updatePerson,
    deletePerson,
    resetPersonPassword,
    addAddress,
    getAllAddresses,
    updateAddress,
    deleteAddress,
    deleteProject

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(ResellerDetail)));