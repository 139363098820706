import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import classNames from "classnames";
import { SITE_URL } from './config/config';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux'
// material components
import { withStyles } from "@material-ui/core/styles";
import { Drawer, AppBar, Toolbar, List, CssBaseline, Typography, Divider, IconButton, ListItem, ListItemIcon, InputBase, Badge, MenuItem, Menu, Card, CardContent, Tooltip } from "@material-ui/core";
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import SettingsInputComponent from '@material-ui/icons/SettingsInputComponent';
import Business from '@material-ui/icons/Business';
import Attachment from '@material-ui/icons/Attachment';
import CommentIcon from '@material-ui/icons/Comment';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import EventNote from '@material-ui/icons/EventNote';
import FilterNone from '@material-ui/icons/FilterNone';
import swal from 'sweetalert';

import Person from '@material-ui/icons/Person';
import MailIcon from "@material-ui/icons/Mail";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Button } from "@material-ui/core";

// styles
import styles from "./styles/app";
import "./styles/app.css";
import "./styles/style.css";

// components
import Login from "./components/Login/index";
import Index from './components/Index/index';
import Departments from './components/Departments/index';
import Demand from './components/Demand/index';
import Persons from './components/Persons/index';
import Proposal from './components/Proposal/index';
import Resellers from './components/Resellers/index';
import Tasks from './components/Tasks/index';
import ResellerDetail from './components/Resellers/ResellerDetail';
import Projects from './components/Projects/index';
import ProjectDetail from './components/Projects/ProjectDetail';
import BlockDetail from './components/Blocks/BlockDetail';
import { setBreadCrumb, setFastOperation } from './actions/person';
import ApartmentDetail from "./components/Apartments/ApartmentDetail";
import PersonProfile from "./components/Persons/PersonProfile";
import PeriodDetail from "./components/Periods/PeriodDetail";
import Notes from "./components/Notes/index";
import InterwiewNotes from "./components/InterviewNotes/index";
import StockCard from "./components/StockCard/index";
import MainCounterDetail from "./components/MainCounters/MainCounterDetail";
import SubscriptionDetail from "./components/Subscriptions/SubscriptionDetail";
import PdfInvoicesList from './components/Apartments/Invoices/PdfInvoicesList';
import ModalDialog from './components/Items/ModalDialog';
import ProposalForm from './components/Proposal/ProposalForm';
import { addProposal, getAllProposals } from './actions/proposal';



class App extends Component {

    // #
    // # The default server
    // #

    // server {
    // listen	 80 default_server;
    // listen[::]: 80 default_server;
    // server_name  _;
    // root / usr / share / nginx / html;

    // # Load configuration files for the default server block.
    //     include / etc / nginx /default.d/*.conf;

    //     location / {
    //         proxy_pass http://localhost:3000;
    //     }

    //     error_page 404 /404.html;
    //         location = /40x.html {
    //     }

    //     error_page 500 502 503 504 /50x.html;
    //         location = /50x.html {
    //     }

    // }

    constructor(props) {
        super(props);
        const token = this.props.token;
        if (token === null && window.location.href !== (SITE_URL + "/login")) {
            return window.location.href = "/login";
        }
    }
    state = {
        open: false,
        anchorEl: null,
        mobileMoreAnchorEl: null,
        proposalOpenDialog: false,
    };

    async UNSAFE_componentWillMount() {
        let breadCrumb = [];
        await this.props.setBreadCrumb(breadCrumb);
        await this.props.setFastOperation(null)
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };
    handleCloseDialog = () => {
        this.setState({ proposalOpenDialog: false });
    }

    openProposal = async () => {
        this.setState({ proposalOpenDialog: true })
    }
    handleProposalSubmit = async (e, state) => {
        const proposal = {
            person: state.person,
            subject: e.target.subject.value,
            description: e.target.description.value,
        };
        return swal({
            title: "Emin misin?",
            text: "Yeni bir içerik ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addProposal(proposal, this.props.token);
                    if (this.props.addProposalResult.code === 200) {
                        await this.props.getAllProposals(this.props.token);
                        this.setState({ proposalOpenDialog: false });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addProposalResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }


    render() {
        const { classes, theme } = this.props;
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleMenuClose}>
                    <a style={{ color: "#000", textDecoration: "none" }} href={"/profile"}>Bilgilerim</a>
                </MenuItem>
                <MenuItem onClick={this.handleMenuClose}>
                    <a style={{ color: "#000", textDecoration: "none" }} href={"/login"}>Çıkış Yap</a>
                </MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMobileMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleMobileMenuClose}>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>Messages</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}>
                    <IconButton color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>Notifications</p>
                </MenuItem>
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p>Hesabım</p>
                </MenuItem>
            </Menu>
        );

        if (window.location.href === (SITE_URL + "/login")) {
            return <Route {...this.props} component={Login}></Route>;
        }
        else if (window.location.href.includes(SITE_URL + "/pdf-invioce-list")) {
            return <Route {...this.props} exact path="/pdf-invioce-list" component={PdfInvoicesList} />;
        }

        return (
            <div className="App" >


                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: this.state.open
                        })}
                    >
                        <Toolbar disableGutters={!this.state.open}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, {
                                    [classes.hide]: this.state.open
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" noWrap>
                                <Tooltip title="Tebaş Otomasyon Yönetimi">
                                    <a href="/" style={{ textDecoration: "none", color: "#fff", border: "none" }}>
                                        <h5 style={{ margin: "0" }}>TEBAŞ ONLİNE FATURA / PAYLAŞIM SİSTEMİ</h5>
                                    </a>
                                </Tooltip>
                            </Typography>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Bir şeyler arayın.."
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                />
                            </div>
                            <div className={classes.grow} />
                            <div className={classes.sectionDesktop}>

                                {
                                    this.props.person.personType !== 0 ? <div>
                                        <Button onClick={this.openProposal} variant="contained" color="secondary" className={classes.button}>GÖRÜŞ VE ÖNERİLER</Button>
                                        <ModalDialog
                                            open={this.state.proposalOpenDialog}
                                            title={'Görüş ve Önerileriniz'}
                                            buttons={[
                                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                            ]}>
                                            <ProposalForm onSubmitForm={this.handleProposalSubmit} person={this.props.person._id} />
                                        </ModalDialog>
                                    </div> : ""
                                }

                                <IconButton color="inherit">
                                    <Badge badgeContent={4} color="secondary">
                                        <MailIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton color="inherit">
                                    <Badge badgeContent={17} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    aria-owns={isMenuOpen ? "material-appbar" : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    aria-haspopup="true"
                                    onClick={this.handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {renderMenu}
                    {renderMobileMenu}
                    <Drawer
                        variant="permanent"
                        className={classNames(classes.drawer, {
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open
                        })}
                        classes={{
                            paper: classNames({
                                [classes.drawerOpen]: this.state.open,
                                [classes.drawerClose]: !this.state.open
                            })
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === "rtl" ? (
                                    <ChevronRightIcon />
                                ) : (
                                        <ChevronLeftIcon />
                                    )}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            {
                                this.props.person.permissions.department.read ? <Tooltip title={"DEPARTMAN YÖNETİMİ"} placement={"right-start"}>
                                    <a href="/departments" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <SupervisedUserCircle />
                                            </ListItemIcon>
                                            <span className="menuText">DEPARTMAN YÖNETİMİ</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.person.read ? <Tooltip title={"PERSONEL YÖNETİMİ"} placement={"right-start"}>
                                    <a href="/persons" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <span className="menuText">PERSONEL YÖNETİMİ</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.reseller.read ? <Tooltip title={"MÜŞTERİ YÖNETİMİ"} placement={"right-start"}>
                                    <a href="/resellers" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <SettingsInputComponent />
                                            </ListItemIcon>
                                            <span className="menuText">MÜŞTERİ YÖNETİMİ</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.project.read ? <Tooltip title={"PROJE YÖNETİMİ"} placement={"right-start"}>
                                    <a href="/projects" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Business />
                                            </ListItemIcon>
                                            <span className="menuText">PROJE YÖNETİMİ</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.task.read ? <Tooltip title={"İŞ HAVUZU"} placement={"right-start"}>
                                    <a href="/tasks" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Attachment />
                                            </ListItemIcon>
                                            <span className="menuText">İŞ HAVUZU</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.note.read ? <Tooltip title={"NOTLAR"} placement={"right-start"}>
                                    <a href="/notes" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <EventNote />
                                            </ListItemIcon>
                                            <span className="menuText">NOTLAR</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.stockCard.read ? <Tooltip title={"STOK YÖNETİMİ"} placement={"right-start"}>
                                    <a href="/stockCard" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <FilterNone />
                                            </ListItemIcon>
                                            <span className="menuText">STOK YÖNETİMİ</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }

                            {
                                this.props.person.permissions.stockCard.read ? <Tooltip title={"GÖRÜŞME NOTLARI"} placement={"right-start"}>
                                    <a href="/interviewNotes" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <SpeakerNotesIcon />
                                            </ListItemIcon>
                                            <span className="menuText">GÖRÜŞME NOTLARI</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }
                            {
                                this.props.person.permissions.stockCard.read ? <Tooltip title={"MÜŞTERİ GÖRÜŞ VE ÖNERİLERİ"} placement={"right-start"}>
                                    <a href="/proposal" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <CommentIcon />
                                            </ListItemIcon>
                                            <span className="menuText">MÜŞTERİ GÖRÜŞ VE ÖNERİLERİ</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }
                            {
                                this.props.person.personType !== 0 ? <Tooltip title={"TALEP VE İSTEKLER"} placement={"right-start"}>
                                    <a href="/demand" style={{ textDecoration: "none", color: "#000", border: "none" }}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <CommentIcon />
                                            </ListItemIcon>
                                            <span className="menuText">TALEP VE İSTEKLER</span>
                                        </ListItem>
                                    </a>
                                </Tooltip> : ""
                            }
                        </List>
                        <Divider />
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Card className={classNames(classes.card, classes.pageHeader)}>
                            <CardContent>
                                <Typography variant="h5" component="h3">
                                    {this.props.activePageTitle}
                                </Typography>
                                <br />
                                <Breadcrumbs separator="/" arial-label="Breadcrumb">
                                    {
                                        this.props.breadCrumb.map((crumb) => {
                                            switch (crumb.type) {
                                                case "link":
                                                    return (<a key={crumb.text} style={{ color: "#2e67b3" }} href={crumb.path}>{crumb.text}</a>)
                                                case "string":
                                                    return (<Typography key={crumb.text} style={{ color: "#ff1a6b" }}>{crumb.text}</Typography>)
                                                default:
                                                    return (null)
                                            }
                                        })
                                    }
                                </Breadcrumbs>
                                {
                                    this.props.fastOperation ? this.props.fastOperation : ""
                                }
                            </CardContent>
                        </Card>

                        <Card className={classes.card}>
                            <CardContent>
                                <Switch>
                                    <Route exact path="/" component={Index} />
                                    <Route exact path="/departments" component={Departments} />
                                    <Route exact path="/persons" component={Persons} />
                                    <Route exact path="/profile" component={PersonProfile} />
                                    <Route exact path="/resellers" component={Resellers} />
                                    <Route exact path="/reseller/:_id" component={ResellerDetail} />
                                    <Route exact path="/projects" component={Projects} />
                                    <Route exact path="/project/:_id" component={ProjectDetail} />
                                    <Route exact path="/block/:_id" component={BlockDetail} />
                                    <Route exact path="/apartment/:_id" component={ApartmentDetail} />
                                    <Route exact path="/tasks" component={Tasks} />
                                    <Route exact path="/stockCard" component={StockCard} />
                                    <Route exact path="/notes" component={Notes} />
                                    <Route exact path="/proposal" component={Proposal} />
                                    <Route exact path="/interview-notes" component={InterwiewNotes} />
                                    <Route exact path="/period/:_id" component={PeriodDetail} />
                                    <Route exact path="/main-counter/:_id" component={MainCounterDetail} />
                                    <Route exact path="/subscription/:_id" component={SubscriptionDetail} />
                                    <Route exact path="/demand" component={Demand} />
                                    <Redirect to="/" />
                                </Switch>
                            </CardContent>
                        </Card>
                    </main>
                </div >
            </div >
        );
    }
}

const mapStateToProps = ({ personReducer, proposalReducer }) => {
    return {
        ...personReducer,
        ...proposalReducer,
    }
}

const mapDispatchToProps = {
    setBreadCrumb,
    setFastOperation,
    addProposal,
    getAllProposals
}

App.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withSnackbar(App)));
