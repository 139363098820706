import {
    GET_PROJECT_PROBLEMS_PENDING, GET_PROJECT_PROBLEMS_FULFILLED, GET_PROJECT_PROBLEMS_REJECTED,
    ADD_PROJECT_PROBLEM_PENDING, ADD_PROJECT_PROBLEM_FULFILLED, ADD_PROJECT_PROBLEM_REJECTED,
    UPDATE_PROJECT_PROBLEM_PENDING, UPDATE_PROJECT_PROBLEM_FULFILLED, UPDATE_PROJECT_PROBLEM_REJECTED
} from '../actions/projectProblems';
const initialState = {
    projectProblems: [],
    getProcjectProblemsLoading: false,
    addProjectProblemResult: {},
    addProjectProblemLoading: false,
    updateProjectProblemResult: {},
    updateProjectProblemLoading: false,
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_PROBLEMS_PENDING:
            return {
                ...state,
                getProcjectProblemsLoading: true,
            }
        case GET_PROJECT_PROBLEMS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProcjectProblemsLoading: false,
            }
        case GET_PROJECT_PROBLEMS_REJECTED:
            return {
                ...state,
                getProcjectProblemsLoading: false,
            }
        case ADD_PROJECT_PROBLEM_PENDING:
            return {
                ...state,
                addProjectProblemLoading: true,
            }
        case ADD_PROJECT_PROBLEM_FULFILLED:
            return {
                ...state,
                addProjectProblemResult: action.payload,
                addProjectProblemLoading: false,
            }
        case ADD_PROJECT_PROBLEM_REJECTED:
            return {
                ...state,
                addProjectProblemLoading: false,
            }

        case UPDATE_PROJECT_PROBLEM_PENDING:
            return {
                ...state,
                updateProjectProblemLoading: true,
            }
        case UPDATE_PROJECT_PROBLEM_FULFILLED:
            return {
                ...state,
                updateProjectProblemResult: action.payload,
                updateProjectProblemLoading: false,
            }
        case UPDATE_PROJECT_PROBLEM_REJECTED:
            return {
                ...state,
                updateProjectProblemLoading: false,
            }
        default:
            return state;
    }
}