import React, { Component } from 'react'
import { Input, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../../styles/app';

class AddProblemForm extends Component {

    state = {
        project: this.props.project._id,
        solutionStatus: false,
        creator: this.props.person._id,
    };

    handleChangeInput = (element) => {
        this.setState({ [element.target.name]: element.target.value })
    }

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="title">Problemin Başlığı</InputLabel>
                    <Input
                        id="title"
                        name="title"
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Problemin Açıklaması</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="solutionStatus">Problemin Durumu</InputLabel>
                    <Select
                        value={this.state.solutionStatus}
                        onChange={this.handleChangeInput}
                        id="solutionStatus"
                        name="solutionStatus"
                    >
                        <MenuItem value="">
                            <em>Bir Durum Seçin..</em>
                        </MenuItem>
                        <MenuItem key={0} value={false}>Problem Devam Ediyor</MenuItem>
                        <MenuItem key={1} value={true}>Çözüldü</MenuItem>
                    </Select>
                </FormControl>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    PROBLEMİ EKLE
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(AddProblemForm);