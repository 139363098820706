import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import { Input, Button, FormControl, InputLabel } from "@material-ui/core";
import styles from '../../styles/app';

class EditStockCardForm extends Component {

    async UNSAFE_componentWillMount() {

    }
    state = {

    }

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <input
                        type="hidden"
                        name="_id"
                        defaultValue={this.props._id}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        defaultValue={this.props.name}
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="manufacturer">Üretici</InputLabel>
                    <Input
                        id="manufacturer"
                        name="manufacturer"
                        defaultValue={this.props.manufacturer}
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="WarrantyPeriod">Garanti Süresi</InputLabel>
                    <Input
                        id="WarrantyPeriod"
                        name="WarrantyPeriod"
                        defaultValue={this.props.WarrantyPeriod}
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="buyingPrice">Alış Fiyatı</InputLabel>
                    <Input
                        id="buyingPrice"
                        name="buyingPrice"
                        defaultValue={this.props.buyingPrice}
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="salePrice">Satış Fiyatı</InputLabel>
                    <Input
                        id="salePrice"
                        name="salePrice"
                        defaultValue={this.props.salePrice}
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="productionYear">Üretim Yılı</InputLabel>
                    <Input
                        id="productionYear"
                        label="productionYear"
                        type="datetime-local"
                        defaultValue={moment(this.props.productionYear).format("YYYY-MM-DDTHH:mm")}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Stock Kartını güncelle
                        </Button>
            </form>
        )
    }
}

EditStockCardForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditStockCardForm);