import React, { Component } from 'react';
import { Input, Button, FormControl, InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class ResetPasswordForm extends Component {
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e) }}>
                <input
                    type="hidden"
                    name="_id"
                    defaultValue={this.props._id}
                />
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Yeni Şifre</InputLabel>
                    <Input
                        id="password"
                        name="password"
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Yeni Şifreyi Tekrar Giriniz</InputLabel>
                    <Input
                        id="password_repeat"
                        name="password_repeat"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Şİfreyi Kaydet
                        </Button>
            </form>
        )
    }
}
export default withStyles(styles)(ResetPasswordForm);