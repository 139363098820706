import React, { Component } from 'react';
import { API_COUNTER_IMAGE_URL } from '../../config/config';
// components
import Table from '../Items/Table';
import { Button, Switch } from '@material-ui/core';
import FsLightbox from 'fslightbox-react';
class ApartmentCountersTable extends Component {
    state = {
        toggler: false,
    }
    render() {

        const tableData = {
            title: "Daire Sayaç Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000],

            },
            columns: [
                this.props.person.permissions.apartmentCounter.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <div style={{ width: "100%" }}>
                                <details>
                                    <summary className="dropdownLink">
                                        İşlem Menüsü
                                    </summary>
                                    <div className="dropdownWrapper">
                                        <span className="dropdownItem">
                                            <Button onClick={() => this.props.editForm(rowData)}>Sayacı Güncelle</Button>
                                        </span>
                                        <span className="dropdownItem">
                                            <Button onClick={() => this.props.changeCounter(rowData)}>Değişim Yap</Button>
                                        </span>
                                    </div>
                                </details>
                            </div >
                        )
                    },
                } : {},
                {
                    title: "Sayaç Görseli",
                    render: rowData => {
                        return (
                            <div>
                                <img
                                    onClick={() => this.setState({ [rowData._id]: !this.state[rowData._id] })}
                                    style={{ width: "100px", height: "80px", cursort: "pointer" }} src={rowData.image ? API_COUNTER_IMAGE_URL + rowData.image : "/none.png"} alt={rowData.name} />
                                <FsLightbox
                                    toggler={this.state[rowData._id]}
                                    sources={[
                                        rowData.image ? API_COUNTER_IMAGE_URL + rowData.image : "/none.png"
                                    ]}
                                />
                            </div>
                        );
                    }
                },
                {
                    title: "Daire Bilgisi", field: "apartment.name",
                    render: rowData => {
                        return (
                            <a href={"/apartment/" + rowData.apartment._id} style={{ color: "#000000", fontWeight: "500" }} >{rowData.apartment.name}</a>
                        );
                    }
                },
                {
                    title: "Sayaç Bilgisi", field: "name",
                    render: rowData => {
                        return (
                            rowData.name
                        );
                    }
                },
                {
                    title: "Sayaç Durumu", hidden: true,
                    render: rowData => {
                        return (
                            <Switch checked={rowData.status} aria-label="Collapse" />
                        )
                    }
                },
                {
                    title: "Sayaç Seri No", field: "serialNumber",
                    render: rowData => {
                        return (
                            rowData.serialNumber
                        );
                    }
                },
                {
                    title: "Sayacın Tipi", field: "type.name",
                    render: rowData => {
                        return (
                            rowData.type.name
                        );
                    }
                },
                {
                    title: "Son Alınan Endeks", hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastClosedData.length > 0 ? rowData.lastClosedData[0].counterValue : "veri yok"
                        );
                    }
                },
                {
                    title: "Ana Sayaç", field: "mainCounter.name", hidden: true,
                    render: rowData => {
                        return (
                            <a href={"/main-counter/" + rowData.mainCounter._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.mainCounter.name}</a>
                        );
                    }
                },
                {
                    title: "Fatura Aboneliği", field: "mainCounter.subscription.name", hidden: true,
                    render: rowData => {
                        return (
                            <a href={"/subscription/" + rowData.mainCounter.subscription._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.mainCounter.subscription.name}</a>
                        );
                    }
                },
            ],
            data: this.props.apartmentCounters
        };

        let tableActions = [];
        if (this.props.person.permissions.apartmentCounter.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.detailAdd) {
            if (this.props.person.permissions.apartmentCounter.create) {
                tableActions.push({
                    icon: 'plus_one',
                    isFreeAction: true,
                    tooltip: 'Yeni Kayıt Ekle',
                    onClick: this.props.addForm,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: 'green'
                        }
                    }
                });
            }
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ApartmentCountersTable;