import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, InputLabel, FormControl, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class EditApartment extends Component {

    state = {
        livingStatus: this.props.livingStatus,
        additionalExpenseStatus: this.props.additionalExpenseStatus,
        sharingStatus: this.props.sharingStatus,
        heatLossExpenseStatus: this.props.heatLossExpenseStatus
    };
    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };
    render() {
        const { classes } = this.props;

        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" name="_id" value={this.props._id} />
                <FormControl required fullWidth>
                    <InputLabel htmlFor="name">Daire Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={this.props.name}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="squareMeters">Daire m²</InputLabel>
                    <Input
                        id="squareMeters"
                        name="squareMeters"
                        type="number"
                        defaultValue={this.props.squareMeters}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="floorNumber">Kat Numarası</InputLabel>
                    <Input
                        id="floorNumber"
                        name="floorNumber"
                        type="number"
                        defaultValue={this.props.floorNumber}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="doorNumber">Kapı Numarası</InputLabel>
                    <Input
                        id="doorNumber"
                        name="doorNumber"
                        type="number"
                        defaultValue={this.props.doorNumber}
                    />
                </FormControl>
                <FormControl required style={{ width: "20%" }}>
                    <InputLabel htmlFor="livingStatus">Doluluk Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.livingStatus} onChange={this.handleChangeStatus('livingStatus')} />
                </FormControl>
                <FormControl required style={{ width: "20%" }}>
                    <InputLabel htmlFor="additionalExpenseStatus">Ek Gider Alınma Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.additionalExpenseStatus} onChange={this.handleChangeStatus('additionalExpenseStatus')} />
                </FormControl>
                <FormControl required style={{ width: "20%" }}>
                    <InputLabel htmlFor="sharingStatus">Paylaşım Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.sharingStatus} onChange={this.handleChangeStatus('sharingStatus')} />
                </FormControl>
                <FormControl required style={{ width: "20%" }}>
                    <InputLabel htmlFor="heatLossExpenseStatus">Isı Kayıp Alınma Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.heatLossExpenseStatus} onChange={this.handleChangeStatus('heatLossExpenseStatus')} />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                        defaultValue={this.props.description}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    DAİRE GÜNCELLE
                </Button>
            </form >
        )
    }
}

EditApartment.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditApartment);
