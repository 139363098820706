import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import Moment from 'react-moment';
import { Button, Chip } from '@material-ui/core';

class TaskTable extends Component {

    render() {
        const tableData = {
            title: "Mevcut İş Havuzu ",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.project.update ? {
                    title: 'İşlem',
                    render: rowData => {

                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )

                    },
                } : {},
                {
                    title: "Proje Adı",
                    field: "project",
                    render: rowData => {
                        return (
                            <a href={"/project/" + rowData.project._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.project.name}</a>
                        )
                    },
                },
                { title: "İşi Oluşturan Kişi", field: "creator.title" },
                { title: "İşin Başlığı", field: "name" },
                { title: "Açıklaması", field: "description" },
                {
                    title: "Talep Eden Kişi Tipi", field: "requestType",
                    render: rowData => {
                        let text = "";

                        if (rowData.requestType === 0) {
                            text = "Kayıtsız Müşteri";
                        }
                        else if (rowData.requestType === 1) {
                            text = "Kayıtlı Müşteri";
                        }
                        else {
                            text = "Müşteri Personeli";
                        }
                        return (
                            text
                        );
                    }
                },
                { title: "Talep eden kayıtsız müsteri adı", field: "requestPersonName" },
                { title: "Talep eden kayıtsız müsteri numarası", field: "requestPersonPhone" },
                {
                    title: "Talep eden kayıtlı müşteri",
                    field: "requestPerson",
                    render: rowData => {
                        let text = "";
                        if (rowData.requestType === 1) {
                            text = rowData.requestPerson.title
                        }
                        return (
                            text
                        );
                    }
                },
                // {
                //     title: "Talep eden müşteri personeli",
                //     field: "requestResellerPerson",
                //     render: rowData => {
                //         let text = "";
                //         if (rowData.requestType === 2) {
                //             text = rowData.requestResellerPerson.title
                //         }
                //         return (
                //             text
                //         );
                //     },
                // },

                {
                    title: "İşin Tipi",
                    field: "taskType",
                    render: rowData => {
                        return (
                            <a href={"/taskType/" + rowData.taskType._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.taskType.name}</a>
                        )
                    },
                },
                {
                    title: "Ücret Durumu", field: "priceStatus",
                    render: rowData => {
                        let text = "";

                        if (rowData.requestType === 0) {
                            text = "Ücretli";
                        }
                        else if (rowData.requestType === 1) {
                            text = "Garantili";
                        }
                        else {
                            text = "Ücretsiz";
                        }
                        return (
                            text
                        );
                    }
                },
                { title: "Ücreti", field: "price" },
                {
                    title: "Atanacak Departman",
                    field: "department",
                    render: rowData => {
                        return (
                            <a href={"/department/" + rowData.department._id} style={{ color: "#000000", fontWeight: "500" }} >{rowData.department.name}</a>
                        )
                    },
                },
                {
                    title: "Atanacak Personeller",
                    field: "persons",
                    render: rowData => {
                        return (
                            <div>{
                                rowData.persons.map((persons) => {
                                    return <Chip key={rowData._id + "-" + persons._id} label={persons.title} />
                                })
                            }</div>
                        )
                    },
                },
                {
                    title: "İş Başlangıç Tarihi", field: "startDate",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.startDate}
                            </Moment>
                        )
                    }
                },
                {
                    title: "Randevu Tarihi", field: "appointmentDate",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.appointmentDate}
                            </Moment>
                        )
                    }
                },
            ],
            data: this.props.tasks
        };
        let tableActions = [];
        if (this.props.person.permissions.project.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.project.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default TaskTable;