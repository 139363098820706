import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_PROJECT_BLOCKS_PENDING = "GET_PROJECT_BLOCKS_PENDING";
export const GET_PROJECT_BLOCKS_FULFILLED = "GET_PROJECT_BLOCKS_FULFILLED";
export const GET_PROJECT_BLOCKS_REJECTED = "GET_PROJECT_BLOCKS_REJECTED";

export const ADD_BLOCK_PENDING = "ADD_BLOCK_PENDING";
export const ADD_BLOCK_FULFILLED = "ADD_BLOCK_FULFILLED";
export const ADD_BLOCK_REJECTED = "ADD_BLOCK_REJECTED";

export const ADD_COLLECTIVE_BLOCK_PENDING = "ADD_COLLECTIVE_BLOCK_PENDING";
export const ADD_COLLECTIVE_BLOCK_FULFILLED = "ADD_COLLECTIVE_BLOCK_FULFILLED";
export const ADD_COLLECTIVE_BLOCK_REJECTED = "ADD_COLLECTIVE_BLOCK_REJECTED";

export const UPDATE_BLOCK_PENDING = "UPDATE_BLOCK_PENDING";
export const UPDATE_BLOCK_FULFILLED = "UPDATE_BLOCK_FULFILLED";
export const UPDATE_BLOCK_REJECTED = "UPDATE_BLOCK_REJECTED";


export const DELETE_BLOCK_PENDING = "DELETE_BLOCK_PENDING";
export const DELETE_BLOCK_FULFILLED = "DELETE_BLOCK_FULFILLED";
export const DELETE_BLOCK_REJECTED = "DELETE_BLOCK_REJECTED";

export const UPLOAD_COLELCTIVE_BLOCK_EXCEL_PENDING = "UPLOAD_COLELCTIVE_BLOCK_EXCEL_PENDING";
export const UPLOAD_COLELCTIVE_BLOCK_EXCEL_FULFILLED = "UPLOAD_COLELCTIVE_BLOCK_EXCEL_FULFILLED";
export const UPLOAD_COLELCTIVE_BLOCK_EXCEL_REJECTED = "UPLOAD_COLELCTIVE_BLOCK_EXCEL_REJECTED";


export function uploadCollectiveBlockExcel(formData, token) {
    return async dispatch => {
        await dispatch({
            type: "UPLOAD_COLELCTIVE_BLOCK_EXCEL",
            payload: axios.post(`${API_URL}/block/import-collective-block-list-excel`, formData, { headers: { "x-access-token": token, 'content-type': 'multipart/form-data' } })
                .then(result => result.data)
        })
    }
}

export function getAllBlocks(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_BLOCKS",
            payload: axios.get(`${API_URL}/block/project/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getBlock(block, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_BLOCKS",
            payload: axios.get(`${API_URL}/block/${block}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addBlock(block, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_BLOCK",
            payload: axios.post(`${API_URL}/block`, block, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addCollectiveBlock(block, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_COLLECTIVE_BLOCK",
            payload: axios.post(`${API_URL}/block/collective`, block, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function editBlock(block, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_BLOCK",
            payload: axios.put(`${API_URL}/block`, block, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteBlock(block, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_BLOCK",
            payload: axios.delete(`${API_URL}/block`, { data: { ...block, token } })
                .then(result => result.data)
        })
    }
}

