import React, { Component } from 'react'
import { Button } from "@material-ui/core";
class ProjectSharingTypes extends Component {
    state = {};
    render() {
        return (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>{
                this.props.sharingTypes.map((sharingType) => {
                    return <Button onClick={() => this.props.handleSharingType(sharingType)} key={sharingType._id} style={{ color: sharingType.color, padding: "20px", margin: "10px", border: "1px solid #666", cursor: "pointer" }} >{sharingType.name}</Button>;
                })}
            </div>
        )
    }
}
export default ProjectSharingTypes;
