import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, FormControl, InputLabel, FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import moment from 'moment';
import styles from '../../styles/app';


class AddNoteForm extends Component {

    state = {
        department: { value: "", label: "Departmanını Seçiniz" },
        persons: [],
        personsStatus: false,
    }

    departmentChange = async (department) => {
        if (department.value === "") {
            this.setState({ department, personsStatus: false })
        }
        else {
            // eger ki farklı bir departman seçildiyse
            if (department.value !== this.state.department.value) {
                this.setState({ persons: [] });
            }
            await this.props.getDepartmentPersons(department.value, this.props.token);
            this.setState({ department, personsStatus: true })
        }
    }
    render() {
        const { classes } = this.props;

        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="title">Not Başlığı</InputLabel>
                    <Input
                        id="title"
                        name="title"
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="description">Not Açıklaması</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>Departman</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.department}
                        onChange={this.departmentChange}
                        options={
                            this.props.departments.map((department) => {
                                return { value: department._id, label: department.name }
                            })
                        }
                    />
                </FormControl>
                {
                    this.state.personsStatus ? <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%", }}>
                        <FormLabel>Personel Listesi</FormLabel>
                        <br />
                        <SearchSelect
                            isMulti
                            value={this.state.persons}
                            onChange={(persons) => { this.setState({ persons }) }}
                            options={
                                this.props.departmentPersons.map((person) => {
                                    return { value: person._id, label: person.title }
                                })
                            }
                        />
                    </FormControl> : ""
                }

                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="type">Tipi</InputLabel>
                    <Input
                        id="type"
                        name="type"
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="validityDate"> Geçerlilik Tarihi</InputLabel>
                    <Input
                        id="validityDate"
                        label="validityDate"
                        type="datetime-local"
                        defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
                    />
                </FormControl>


                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Notu Kaydet
                </Button>
            </form>
        )
    }
}

AddNoteForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddNoteForm);