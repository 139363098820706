import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { getAllDeparments, editDepartment, deleteDepartment, addDepartment } from '../../actions/department';
import { getAllTaskTypes } from '../../actions/taskType';
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';

// components
import { Button } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog';
import EditDepartmentForm from './EditDepartmentForm';
import AddDepartmentForm from './AddDepartmentForm';
import DepartmentsTable from './DepartmentsTable';
import swal from 'sweetalert';

class Departments extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("DEPARTMAN YÖNETİMİ");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Departman Yönetimi" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }
    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        // yetki kontrolü
        if (!this.props.person.permissions.department.read) {
            return window.location.href = "/";
        }
        await this.props.getAllDeparments(this.props.token);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }

    // departman güncelleme işlemi
    handleEditDepartmentSubmit = (e, state) => {

        const department = {
            name: e.target.name.value,
            _id: e.target._id.value,
            taskType: state.taskType.value,
        };


        return swal({
            title: "Emin misin?",
            text: "Bu içeriği güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.editDepartment(department, this.props.token);
                    if (this.props.putDepartmentResult.code === 200) {
                        this.setState({ editOpenDialog: false });
                        await this.props.getAllDeparments(this.props.token);
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarısız. " + this.props.putDepartmentResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });


    }

    // yeni departman ekleme işlemi
    handleAddDepartmentSubmit = async (e, state) => {

        const department = {
            name: e.target.name.value,
            taskType: state.taskType.value
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir içerik ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addDepartment(department, this.props.token);
                    if (this.props.addDepartmentResult.code === 200) {
                        this.props.getAllDeparments(this.props.token);
                        this.setState({ addOpenDialog: false });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addDepartmentResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    // güncelle butonuna tıklandığında yapılacak işlem
    handleEditDeparment = async (rowData) => {
        await this.props.getAllTaskTypes(this.props.token);
        this.setState({ editOpenDialog: true, rowData: Object.assign({}, rowData) });
    }

    // yeni kayıt butonuna tıklandığında yapılacak işlem
    handleAddDeparment = async () => {
        await this.props.getAllTaskTypes(this.props.token);
        this.setState({ addOpenDialog: true })
    }

    // sil butonuna tıklandığında yapılacak işlem
    handleDeleteDeparment = (event, rowData) => {

        return swal({
            title: "Emin misin?",
            text: "Bu seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (department) => {
                        await this.props.deleteDepartment(department, this.props.token);
                        if (this.props.deleteResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                }

                await this.props.getAllDeparments(this.props.token);

            });
    }

    render() {
        return (
            <div>
                {this.props.addDepartmentLoading ? <div className="loading" /> : ""}
                {this.props.getDepartmentsLoading ? <div className="loading" /> : ""}
                {this.props.getAllTaskTypeLoading ? <div className="loading" /> : ""}
                <DepartmentsTable departments={this.props.departments} person={this.props.person} addForm={this.handleAddDeparment} editForm={this.handleEditDeparment} delete={this.handleDeleteDeparment} />

                {/* {this.props.addDepartmentLoading ? alert("oldu") : ""} */}
                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Departman güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditDepartmentForm {...this.state.rowData} onSubmitForm={this.handleEditDepartmentSubmit} taskTypes={this.props.taskTypes} />

                </ModalDialog>

                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Departman Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddDepartmentForm onSubmitForm={this.handleAddDepartmentSubmit} taskTypes={this.props.taskTypes} />
                </ModalDialog>


            </div>
        )
    }
}

// componente gelecek propsların tipini tanımla
Departments.propTypes = {
    getAllDeparments: PropTypes.func.isRequired,
    editDepartment: PropTypes.func.isRequired,
    deleteDepartment: PropTypes.func.isRequired,
    addDepartment: PropTypes.func.isRequired,
};

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ departmentReducer, personReducer, taskTypeReducer }) => {
    return {
        ...departmentReducer,
        ...personReducer,
        ...taskTypeReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getAllDeparments,
    editDepartment,
    deleteDepartment,
    addDepartment,
    setActivePageTitle,
    setBreadCrumb,
    getAllTaskTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Departments));