import React, { Component } from 'react';
import { API_INVOICE_IMAGE_URL } from '../../config/config';
import { FormControl, CardMedia, Card, } from "@material-ui/core";
import FsLightbox from 'fslightbox-react';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';
class AddInvoice extends Component {

    state = {
        toggler: false,
    };

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ width: "100%", display: "inline-block" }}>
                <div style={{ width: "50%", display: "inline-block" }}>
                    <FormControl required style={{ width: "100%", display: "inline-block" }}>
                        <label style={{ fontSize: "25px", }}>Abonelik / Tesisat Numarası: </label>
                        <br />
                        <input className="inputInvoice" disabled={true} defaultValue={this.props.subscription.subscriberNumber} style={{ width: "15%", padding: "10px", marginBottom: "15px", fontSize: "30px", color: "#000", fontWeight: "800", background: "#a7e6e8" }} type="text" />
                    </FormControl>
                    <br /><br />
                    <FormControl required style={{ width: "100%", display: "inline-block" }}>
                        <label style={{ fontSize: "25px", }}>Fatura Tutarı: </label>
                        <br />
                        <input className="inputInvoice" disabled={true} defaultValue={this.props.subscription.invoices[0].balance} style={{ width: "15%", padding: "10px", marginBottom: "15px", fontSize: "30px", color: "#000", fontWeight: "800", background: "#a7e6e8" }} name="balance" type="text" />
                        <label style={{ fontSize: "30px", color: "#000", fontWeight: "800" }}>&nbsp;TL</label>

                    </FormControl>
                    <FormControl required style={{ width: "100%", display: "inline-block" }}>
                        <label style={{ fontSize: "25px", }}>Tüketim Miktarı: </label>
                        <br />
                        <input className="inputInvoice" disabled={true} defaultValue={this.props.subscription.invoices[0].consumption} style={{ width: "15%", padding: "10px", marginBottom: "15px", fontSize: "30px", color: "#000", fontWeight: "800", background: "#a7e6e8" }} name="consumption" type="text" />
                        <label style={{ fontSize: "30px", color: "#000", fontWeight: "800" }}>&nbsp;m3 / kw</label>

                    </FormControl>
                </div>
                {
                    this.props.subscription.invoices[0].image ? <div style={{ width: "50%", display: "inline-block" }}>
                        <Card style={{ width: "345px", cursor: "pointer" }}>
                            <CardMedia
                                onClick={() => this.setState({ toggler: !this.state.toggler })}
                                className={classes.media}
                                image={API_INVOICE_IMAGE_URL + this.props.subscription.invoices[0].image}
                                title="Paella dish"
                            />
                        </Card>
                        <FsLightbox
                            toggler={this.state.toggler}
                            sources={[
                                API_INVOICE_IMAGE_URL + this.props.subscription.invoices[0].image,
                            ]}
                        />
                    </div> : ""
                }
            </div>
        )
    }
}
export default withStyles(styles)(AddInvoice);