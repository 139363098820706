import React, { Component } from 'react';
// components
import Table from '../../Items/Table';
class AdditionalExpensesTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Ek Giderler Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                {
                    title: "Ek Gider Başlığı", field: "title",
                    render: rowData => {
                        return (
                            rowData.title
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Daire Sayısı", field: "totalApartmentCount",
                    render: rowData => {
                        return (
                            rowData.totalApartmentCount
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Toplam Tutar", field: "totalBalance",
                    render: rowData => {
                        return (
                            rowData.totalBalance
                        );
                    }
                },
                {
                    title: "Fatura Tipi",
                    render: rowData => {
                        let text = "";
                        if (rowData.invoiceType === 0) {
                            text = "Sabit Tutarı Dağıt";
                        } else if (rowData.invoiceType === 1) {
                            text = "Faturayı Dağıt";
                        }
                        return (
                            text
                        );
                    }
                },
                {
                    title: "Girilen Fatura Tutarı", field: "invoiceBalance",
                    render: rowData => {
                        return (
                            rowData.invoiceBalance
                        );
                    }
                },
                {
                    title: "Birim Fiyat", field: "unitPrice",
                    render: rowData => {
                        return (
                            rowData.unitPrice
                        );
                    }
                },
                {
                    title: "Dağıtım Tipi",
                    render: rowData => {
                        let text = "";
                        if (rowData.sharingType === 0) {
                            text = "M² Üzerinden";
                        } else if (rowData.sharingType === 1) {
                            text = "Daire Sayısına Eşit";
                        }
                        return (
                            text
                        );
                    }
                },
                {
                    title: "Daire Durumu",
                    render: rowData => {
                        let text = "";
                        if (rowData.sharingType === 0) {
                            text = "Tüm Daireler";
                        } else if (rowData.sharingType === 1) {
                            text = "Sadece Dolu Daireler";
                        }
                        return (
                            text
                        );
                    }
                },
            ],
            data: this.props.additionalExpenses
        };

        let tableActions = [];

        if (this.props.period.status) {
            if (this.props.person.permissions.period.update) {
                tableActions.push({
                    icon: 'delete',
                    tooltip: 'Sil',
                    onClick: this.props.delete,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: 'red'
                        }
                    }
                });
            }
        }

        if (this.props.person.permissions.additonalExpenseData.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default AdditionalExpensesTable;