import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button, Switch } from '@material-ui/core';
class ApartmentsTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Daire Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.apartment.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Daire Adı", field: "name",
                    render: rowData => {
                        return (
                            <a href={"/apartment/" + rowData._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.name}</a>
                        );
                    }
                },
                {
                    title: "Daire m²", field: "squareMeters",
                    render: rowData => {
                        return (
                            <div>
                                {rowData.squareMeters} m²
                            </div>
                        );
                    }
                },
                {
                    title: "Kat Numarası", field: "floorNumber", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {rowData.floorNumber}
                            </div>
                        );
                    }
                },
                {
                    title: "Kapı Numarası", field: "doorNumber", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {rowData.doorNumber}
                            </div>
                        );
                    }
                },
                {
                    title: "Doluluk Durumu", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.livingStatus} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                {
                    title: "Ek Gider Alınma Durumu", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.additionalExpenseStatus} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                {
                    title: "Paylaşım Durumu", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.sharingStatus} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                {
                    title: "Isı Kayıp Alınma Durumu", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.heatLossExpenseStatus} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                {
                    title: "Açıklama", field: "description", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {rowData.description}
                            </div>
                        )
                    }
                },
            ],
            data: this.props.apartments
        };

        let tableActions = [];
        if (this.props.person.permissions.apartment.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.apartment.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }
        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ApartmentsTable;