import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class EditAddressesForm extends Component {
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e) }}>
                <FormControl margin="normal" required fullWidth>
                    <input
                        type="hidden"
                        name="_id"
                        defaultValue={this.props._id}
                    />
                    <InputLabel htmlFor="name">Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        defaultValue={this.props.name}
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="address">Adres</InputLabel>
                    <Input
                        multiline
                        id="address"
                        name="address"
                        defaultValue={this.props.address}
                        type="text"
                    />

                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Adresi güncelle
                        </Button>
            </form>
        )
    }
}

EditAddressesForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditAddressesForm);