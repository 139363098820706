import {
    ADD_MAIN_COUNTER_PENDING, ADD_MAIN_COUNTER_FULFILLED, ADD_MAIN_COUNTER_REJECTED,
    UPDATE_MAIN_COUNTER_PENDING, UPDATE_MAIN_COUNTER_FULFILLED, UPDATE_MAIN_COUNTER_REJECTED,
    DELETE_MAIN_COUNTER_PENDING, DELETE_MAIN_COUNTER_FULFILLED, DELETE_MAIN_COUNTER_REJECTED,
    GET_PROJECT_MAIN_COUNTERS_PENDING, GET_PROJECT_MAIN_COUNTERS_FULFILLED, GET_PROJECT_MAIN_COUNTERS_REJECTED,
    GET_PERIOD_MAIN_COUNTER_DATAS_PENDING, GET_PERIOD_MAIN_COUNTER_DATAS_FULFILLED, GET_PERIOD_MAIN_COUNTER_DATAS_REJECTED,
} from '../actions/mainCounter';
const initialState = {
    addMainCounterResult: {},
    updateMainCounterResult: {},
    deleteMainCounterResult: {},
    mainCounters: [],
    addMainCounterLoading: false,
    updateMainCounterLoading: false,
    deleteMainCounterLoading: false,
    getPeriodMainCounterDatasLoading: false,
    getProjectMainCountersLoading: false,
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PERIOD_MAIN_COUNTER_DATAS_PENDING:
            return {
                ...state,
                getPeriodMainCounterDatasLoading: true,
            }
        case GET_PERIOD_MAIN_COUNTER_DATAS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getPeriodMainCounterDatasLoading: false,
            }
        case GET_PERIOD_MAIN_COUNTER_DATAS_REJECTED:
            return {
                ...state,
                getPeriodMainCounterDatasLoading: false
            }

        case ADD_MAIN_COUNTER_PENDING:
            return {
                ...state,
                addMainCounterLoading: true,
            }
        case ADD_MAIN_COUNTER_FULFILLED:
            return {
                ...state,
                addMainCounterResult: action.payload,
                addMainCounterLoading: false,
            }
        case ADD_MAIN_COUNTER_REJECTED:
            return {
                ...state,
                addMainCounterLoading: false,
            }

        case UPDATE_MAIN_COUNTER_PENDING:
            return {
                ...state,
                updateMainCounterLoading: true,
            }
        case UPDATE_MAIN_COUNTER_FULFILLED:
            return {
                ...state,
                updateMainCounterResult: action.payload,
                updateMainCounterLoading: false,
            }
        case UPDATE_MAIN_COUNTER_REJECTED:
            return {
                ...state,
                updateMainCounterLoading: false
            }

        case DELETE_MAIN_COUNTER_PENDING:
            return {
                ...state,
                deleteMainCounterLoading: true,
            }
        case DELETE_MAIN_COUNTER_FULFILLED:
            return {
                ...state,
                deleteMainCounterResult: action.payload,
                deleteMainCounterLoading: false,

            }
        case DELETE_MAIN_COUNTER_REJECTED:
            return {
                ...state,
                deleteMainCounterLoading: false,
            }

        case GET_PROJECT_MAIN_COUNTERS_PENDING:
            return {
                ...state,
                getProjectMainCountersLoading: true,
            }
        case GET_PROJECT_MAIN_COUNTERS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectMainCountersLoading: false,
            }
        case GET_PROJECT_MAIN_COUNTERS_REJECTED:
            return {
                ...state,
                getProjectMainCountersLoading: false,
            }

        default:
            return state;
    }
}