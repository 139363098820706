import {
    ADD_INVOICE_PENDING, ADD_INVOICE_FULFILLED, ADD_INVOICE_REJECTED,
} from '../actions/subscriptionInvoice';

const initialState = {
    addSubscriptionInvoiceResult: {},
    addInvoiceLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_INVOICE_PENDING:
            return {
                ...state,
                addInvoiceLoading: true
            }
        case ADD_INVOICE_FULFILLED:
            return {
                ...state,
                addSubscriptionInvoiceResult: action.payload,
                addInvoiceLoading: false,
            }
        case ADD_INVOICE_REJECTED:
            return {
                ...state,
                addInvoiceLoading: false,
            }
        default:
            return state;
    }
}