import React, { Component } from 'react';
// components
import Table from '../Items/Table';

import Moment from 'react-moment';
import { Button, Chip } from '@material-ui/core';

class NotesTable extends Component {

    render() {
        const tableData = {
            title: "Mevcut Notlar ",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.project.update ? {
                    title: 'İşlem',
                    render: rowData => {

                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )

                    },
                } : {},
                { title: "Notu Oluşturan Kişi", field: "creator.title" },
                { title: "Başlığı", field: "title" },
                { title: "Açıklaması", field: "description" },
                {
                    title: "Departman",
                    field: "department",
                    render: rowData => {
                        return (
                            <a href={"/depatment/" + rowData.department._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.department.name}</a>
                        )
                    },
                },
                {
                    title: "Atanacak Personeller",
                    field: "persons",
                    render: rowData => {
                        return (
                            <div>{
                                rowData.persons.map((persons) => {
                                    return <Chip key={rowData._id + "-" + persons._id} label={persons.title} />
                                })
                            }</div>
                        )
                    },
                },
                { title: "Tipi", field: "type" },
                {
                    title: "Oluşturulma Tarihi", field: "createdAt",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.createdAt}
                            </Moment>
                        )
                    }
                },
                {
                    title: "Geçerlilik Tarihi", field: "appointmentDate",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.appointmentDate}
                            </Moment>
                        )
                    }
                },
            ],
            data: this.props.notes
        };
        let tableActions = [];
        if (this.props.person.permissions.project.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.project.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default NotesTable;