import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import { Input, Button, FormControl, InputLabel, FormLabel } from "@material-ui/core";
import styles from '../../styles/app';

class EditDepartmentForm extends Component {
    state = {
        taskType: { value: this.props.taskType._id, label: this.props.taskType.name },
    }


    handleTaskTypeChange = async (taskType) => {
        this.setState({ taskType })
    }

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <input
                        type="hidden"
                        name="_id"
                        defaultValue={this.props._id}
                    />
                    <input
                        type="hidden"
                        name="permissions[person][create]"
                        defaultValue="true"
                    />
                    <InputLabel htmlFor="name">Departman Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        // onChange={this.props.onChange}
                        defaultValue={this.props.name}
                        type="text"
                    />

                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>İş Tipi</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.taskType}
                        onChange={this.handleTaskTypeChange}
                        options={
                            this.props.taskTypes.map((taskType) => {
                                return { value: taskType._id, label: taskType.name }
                            })
                        }
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Departmanı güncelle
                        </Button>
            </form>
        )
    }
}

EditDepartmentForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditDepartmentForm);