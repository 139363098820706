import React, { Component } from 'react';
// components
import Table from '../../Items/Table';
import { Button, Chip } from '@material-ui/core';
import moment from 'moment';
class ProjectProblemsTable extends Component {
    render() {
        const tableData = {
            title: "Proje Sorunları Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: false,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                // notes: [{ // probleme eklenen notlar
                //     type: Schema.Types.ObjectId, ref: 'ProjectProblemNotes',
                // }],
                // {
                //     title: 'İşlem',
                //     render: rowData => {
                //         return (
                //             <div style={{ background: "orange", width: "10%" }}>
                //                 <details>
                //                     <summary className="dropdownLink">
                //                         İşlem Menüsü
                //                     </summary>
                //                     <div className="dropdownWrapper">
                //                         <span className="dropdownItem">
                //                             <Button onClick={() => { }}>Notlar</Button>
                //                         </span>
                //                         <span className="dropdownItem">
                //                             <Button onClick={() => { }}>Problemi Çöz</Button>
                //                         </span>
                //                     </div>
                //                 </details>
                //             </div>
                //         )
                //     },
                // },
                {
                    title: "Problem",
                    render: rowData => {
                        return (
                            <Button style={{ background: "orange", width: "23%", marginRight: "45px" }} onClick={() => { }}>Problem</Button>
                        )
                    }
                },
                {
                    title: "Problem Başlığı", field: "title",
                    render: rowData => {
                        return (
                            rowData.title
                        )
                    }
                },
                {
                    title: "Problem Açıklaması", field: "description",
                    render: rowData => {
                        return (
                            <div style={{ width: "250px" }}>

                                {rowData.description.substring(0, 50) + "..."}
                                <Button onClick={() => this.props.showDescription(rowData)}>Devamını Göster</Button>
                            </div>

                        )
                    }
                },
                {
                    title: "Problemi Çöz",
                    render: rowData => {
                        return (
                            <Button style={{ background: "orange", width: "20%" }} onClick={() => this.props.resolveProblem(rowData)}>Problemi Çöz</Button>
                        )
                    }
                },
                {
                    title: "Problem Çözümü", field: "solverDescription",
                    render: rowData => {
                        return (
                            <div style={{ width: "250px" }}>

                                {rowData.solverDescription.substring(0, 50) + "..."}
                                <Button onClick={() => this.props.showSolverDescription(rowData)}>Devamını Göster</Button>
                            </div>

                        )
                    }
                },
                {
                    title: "Problemin Durumu", field: "solutionStatus",
                    lookup: {
                        "false": "Problem Hala Devam Ediyor",
                        "true": "Problem Çözüldü"
                    },
                    render: rowData => {
                        return (
                            rowData.solutionStatus ?
                                <Chip
                                    style={{ color: "#fff", backgroundColor: "#eab51e" }}
                                    label={"Problem Çözüldü"}
                                />
                                : <Chip
                                    style={{ color: "#fff", backgroundColor: "#eab51e" }}
                                    label={"Problem Hala Devam Ediyor"}
                                />
                        )
                    }
                },
                {
                    title: "Problemi Çözen Personel", field: "solverPerson.title", hidden: true,
                    render: rowData => {
                        return (
                            rowData.solverPerson.title
                        )
                    },
                },
                {
                    title: "Oluşturma Tarihi", field: "createdAt", hidden: true,
                    render: rowData => {
                        return (
                            moment(rowData.createdAt).format("DD-MM-YYYY hh:ss")
                        )
                    },
                },
            ],
            data: this.props.projectProblems
        };
        let tableActions = [];
        // if (this.props.person.permissions.project.delete) {
        //     tableActions.push({
        //         icon: 'delete',
        //         tooltip: 'Sil',
        //         onClick: this.props.delete,
        //         iconProps: {
        //             style: {
        //                 fontSize: 40,
        //                 color: 'red'
        //             }
        //         }
        //     });
        // }
        tableActions.push({
            icon: 'plus_one',
            isFreeAction: true,
            tooltip: 'Yeni Kayıt Ekle',
            onClick: this.props.addForm,
            iconProps: {
                style: {
                    fontSize: 40,
                    color: 'green'
                }
            }
        });

        return (
            <div>
                <Table
                    isLoading={this.props.getResellersLoading}
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}

export default ProjectProblemsTable;