import React, { Component } from 'react'
// material data table
import MaterialTable from "material-table";

export default class index extends Component {

    render() {
        return (
            <div>
                <MaterialTable
                    isLoading={this.props.isLoading}
                    columns={this.props.tableData.columns}
                    data={this.props.tableData.data}
                    title={this.props.tableData.title}
                    options={this.props.tableData.options}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Veri bulunamadı",
                            filterRow: {
                                filterTooltip: "Filtrele"
                            },
                            editRow: {
                                saveTooltip: "Kaydet",
                                cancelTooltip: "İptal",
                                deleteText: "Sil"
                            },
                            addTooltip: "Yeni Ekle",
                            deleteTooltip: "Sil",
                            editTooltip: "Güncelle"
                        },
                        header: {
                            actions: "Actions header"
                        },
                        grouping: {
                            groupedBy: "Gruplanan",
                            placeholder: "Gruplama yapabilirsiniz"
                        },
                        pagination: {
                            firstTooltip: "İlk sayfa",
                            previousTooltip: "Önceki",
                            nextTooltip: "Sonraki",
                            labelDisplayedRows: "Görünen satırlar",
                            labelRowsPerPage: "Sayfa Başına İçerik Sayısı",
                            lastTooltip: "Son sayfa",
                            labelRowsSelect: "adet veri gösteriliyor"
                        },
                        toolbar: {
                            addRemoveColumns: "Kolonları Gizle/Göster",
                            nRowsSelected: "{0} satır seçildi",
                            showColumnsTitle: "Gösterilecek kolonlar",
                            showColumnsAriaLabel: "showColumnsAriaLabel",
                            exportTitle: "CSV ile dışarı aktar",
                            exportAriaLabel: "exportAriaLabel",
                            exportName: "CSV ile dışarı aktar",
                            searchTooltip: "Ara"
                        }
                    }}
                    actions={this.props.actions}
                />
            </div>
        )
    }
} 