import React, { Component } from 'react'
import { FormControl, InputLabel, Button, Input, FormLabel, FormControlLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import styles from '../../styles/app';

class AddSubscriptionForm extends Component {

    state = {
        subscriptionType: {
            value: this.props.type._id,
            label: this.props.type.name,
        },
        _id: this.props._id,
        sharingBlocks: this.props.sharingBlocks.map((item) => { return item._id })
    };

    handleSubscriptionType = async (subscriptionType) => {
        this.setState({ subscriptionType })
    }

    handleBlocks = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const sharingBlocks = state.sharingBlocks.concat(name.toString());
                return {
                    sharingBlocks
                };
            });
        }
        else {
            this.setState(state => {
                const sharingBlocks = state.sharingBlocks.filter(item => item.toString() !== name.toString());
                return {
                    sharingBlocks,
                };
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel style={{ marginBottom: "10px" }}>Abonelik Tipi</FormLabel>
                    <SearchSelect
                        value={this.state.subscriptionType}
                        onChange={this.handleSubscriptionType}
                        options={
                            this.props.subscriptionTypes.map((subscriptionType) => {
                                return { value: subscriptionType._id, label: subscriptionType.name }
                            })
                        }
                    />
                </FormControl>
                <FormControl required style={{ width: "100%" }}>
                    <InputLabel htmlFor="name">Abonelik Adı (Opsiyonel Başlık)</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={this.props.name}
                    />
                </FormControl>
                <FormControl required style={{ width: "100%" }}>
                    <InputLabel htmlFor="subscriberNumber">Abonelik Numarası</InputLabel>
                    <Input
                        id="subscriberNumber"
                        name="subscriberNumber"
                        type="text"
                        defaultValue={this.props.subscriberNumber}
                    />
                </FormControl>
                <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                    <InputLabel htmlFor="sharingBlocks">Paylaşım Yapılacak Bloklar</InputLabel>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        this.props.project ? this.props.project.blocks.map((block) => {
                            return (
                                <FormControlLabel key={block._id}
                                    control={
                                        <Switch checked={this.state.sharingBlocks.includes(block._id)} onChange={this.handleBlocks(block._id)} />
                                    }
                                    label={block.name}
                                />
                            )
                        }) : ""
                    }
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    ABONELİĞİ GÜNCELLE
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(AddSubscriptionForm);