import {
    GET_CITIES_PENDING, GET_CITIES_FULFILLED, GET_CITIES_REJECTED,
    GET_CITY_PENDING, GET_CITY_FULFILLED, GET_CITY_REJECTED,
} from '../actions/city.js';
const initialState = {
    cities: [],
    selectedCity: {},
    getCitiesLoading: false,
    getCityLoading: false,
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CITIES_PENDING:
            return {
                ...state,
                getCitiesLoading: true
            }
        case GET_CITIES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getCitiesLoading: false
            }
        case GET_CITIES_REJECTED:
            return {
                ...state,
                getCitiesLoading: false,
            }

        case GET_CITY_PENDING:
            return {
                ...state,
                getCityLoading: true
            }
        case GET_CITY_FULFILLED:
            return {
                ...state,
                selectedCity: action.payload.data.city,
                getCityLoading: false,
            }
        case GET_CITY_REJECTED:
            return {
                ...state,
                getCityLoading: false
            }
        default:
            return state;
    }
}