import {
    UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_PENDING, UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_FULFILLED, UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_REJECTED,
    GET_PERIOD_COUNTERS_WITH_DATA_PENDING, GET_PERIOD_COUNTERS_WITH_DATA_FULFILLED, GET_PERIOD_COUNTERS_WITH_DATA_REJECTED,
    UPLOAD_COUNTER_EXCEL_LIST_PENDING, UPLOAD_COUNTER_EXCEL_LIST_FULFILLED, UPLOAD_COUNTER_EXCEL_LIST_REJECTED,
    CHECK_PERIOD_ANALYSIS_PENDING, CHECK_PERIOD_ANALYSIS_FULFILLED, CHECK_PERIOD_ANALYSIS_REJECTED,
    SET_COMPARISON_PENDING, SET_COMPARISON_FULFILLED, SET_COMPARISON_REJECTED,
    UPDATE_APARTMENT_COUNTER_DATA_PENDING, UPDATE_APARTMENT_COUNTER_DATA_FULFILLED, UPDATE_APARTMENT_COUNTER_DATA_REJECTED,
    NOT_DEFECTIVE_PENDING, NOT_DEFECTIVE_FULFILLED, NOT_DEFECTIVE_REJECTED,
    GET_BLOCK_APARTMENT_COUNTERS_PENDING, GET_BLOCK_APARTMENT_COUNTERS_FULFILLED, GET_BLOCK_APARTMENT_COUNTERS_REJECTED,
    UPDATE_APARTMENT_COUNTER_PENDING, UPDATE_APARTMENT_COUNTER_FULFILLED, UPDATE_APARTMENT_COUNTER_REJECTED,
    DELETE_APARTMENT_COUNTER_PENDING, DELETE_APARTMENT_COUNTER_FULFILLED, DELETE_APARTMENT_COUNTER_REJECTED,
    GET_PROJECT_APARTMENT_COUNTERS_PENDING, GET_PROJECT_APARTMENT_COUNTERS_FULFILLED, GET_PROJECT_APARTMENT_COUNTERS_REJECTED,
    GET_APARTMENT_COUNTERS_PENDING, GET_APARTMENT_COUNTERS_FULFILLED, GET_APARTMENT_COUNTERS_REJECTED,
    ADD_APARTMENT_COUNTER_DATA_PENDING, ADD_APARTMENT_COUNTER_DATA_FULFILLED, ADD_APARTMENT_COUNTER_DATA_REJECTED,
    SET_DEFECTIVE_PENDING, SET_DEFECTIVE_FULFILLED, SET_DEFECTIVE_REJECTED,
    ADD_APARTMENT_COUNTER_PENDING, ADD_APARTMENT_COUNTER_FULFILLED, ADD_APARTMENT_COUNTER_REJECTED,
    CHANGE_APARTMENT_COUNTER_PENDING, CHANGE_APARTMENT_COUNTER_FULFILLED, CHANGE_APARTMENT_COUNTER_REJECTED
} from '../actions/apartmentCounter';
const initialState = {

    uploadApartmentCounterExcelListResult: {},
    apartmentCounters: [],
    uploadCounterListExcelResult: {},
    checkPeriodAnalysisResult: {},
    setComparisonResult: {},
    updateApartmentCounterDataResult: {},
    notDefectiveResult: {},
    setDefectiveResult: {},
    updateApartmentCounterResult: {},
    deleteApartmentCounterResult: {},
    addApartmentCounterResult: {},
    deleteApartmentCounterLoading: false,
    updateApartmentCounterDataLoading: false,
    updateApartmentCounterLoading: false,
    getApartmentCountersLoading: false,
    getProjectApartmentCountersLoading: false,
    getBlockApaertmenCountersLoading: false,
    checkPeriodAnalysisLoading: false,
    addApartmentCounterDataLoading: false,
    getPeriodCountersWithDataLoading: false,
    addApartmentCounterLoading: false,
    setDeactiveApartmentCounterLoading: false,
    setComparisonApartmentCounterLoading: false,
    changeApartmentCounterResult: false,
    changeApartmentCounterLoading: false,

}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_APARTMENT_COUNTER_PENDING:
            return {
                ...state,
                changeApartmentCounterLoading: true
            }
        case CHANGE_APARTMENT_COUNTER_FULFILLED:
            return {
                ...state,
                changeApartmentCounterResult: action.payload,
                changeApartmentCounterLoading: false
            }
        case CHANGE_APARTMENT_COUNTER_REJECTED:
            return {
                ...state,
                changeApartmentCounterLoading: false,
            }

        case ADD_APARTMENT_COUNTER_PENDING:
            return {
                ...state,
                addApartmentCounterLoading: true
            }
        case ADD_APARTMENT_COUNTER_FULFILLED:
            return {
                ...state,
                addApartmentCounterResult: action.payload,
                addApartmentCounterLoading: false
            }
        case ADD_APARTMENT_COUNTER_REJECTED:
            return {
                ...state,
                addApartmentCounterLoading: false,
            }
        case DELETE_APARTMENT_COUNTER_PENDING:
            return {
                ...state,
                deleteApartmentCounterLoading: true
            }
        case DELETE_APARTMENT_COUNTER_FULFILLED:
            return {
                ...state,
                deleteApartmentCounterResult: action.payload,
                deleteApartmentCounterLoading: false
            }
        case DELETE_APARTMENT_COUNTER_REJECTED:
            return {
                ...state,
                deleteApartmentCounterLoading: false,
            }
        case UPDATE_APARTMENT_COUNTER_PENDING:
            return {
                ...state,
                updateApartmentCounterLoading: true,
            }
        case UPDATE_APARTMENT_COUNTER_FULFILLED:
            return {
                ...state,
                updateApartmentCounterResult: action.payload,
                updateApartmentCounterLoading: false
            }
        case UPDATE_APARTMENT_COUNTER_REJECTED:
            return {
                ...state,
                updateApartmentCounterLoading: false
            }

        case GET_APARTMENT_COUNTERS_PENDING:
            return {
                ...state,
                getApartmentCountersLoading: true,
            }
        case GET_APARTMENT_COUNTERS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getApartmentCountersLoading: false

            }
        case GET_APARTMENT_COUNTERS_REJECTED:
            return {
                ...state,
                getApartmentCountersLoading: false,
            }
        case GET_PROJECT_APARTMENT_COUNTERS_PENDING:
            return {
                ...state,
                getProjectApartmentCountersLoading: true,
            }
        case GET_PROJECT_APARTMENT_COUNTERS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectApartmentCountersLoading: false
            }
        case GET_PROJECT_APARTMENT_COUNTERS_REJECTED:
            return {
                ...state,
                getProjectApartmentCountersLoading: false
            }

        case GET_BLOCK_APARTMENT_COUNTERS_PENDING:
            return {
                ...state,
                getBlockApaertmenCountersLoading: true,
            }
        case GET_BLOCK_APARTMENT_COUNTERS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getBlockApaertmenCountersLoading: false
            }
        case GET_BLOCK_APARTMENT_COUNTERS_REJECTED:
            return {
                ...state,
                getBlockApaertmenCountersLoading: false
            }

        case NOT_DEFECTIVE_PENDING:
            return {
                ...state,
            }
        case NOT_DEFECTIVE_FULFILLED:
            return {
                ...state,
                notDefectiveResult: action.payload
            }
        case NOT_DEFECTIVE_REJECTED:
            return {
                ...state,
            }

        case SET_DEFECTIVE_PENDING:
            return {
                ...state,
                setDefectiveApartmentCounterLoading: true,
            }
        case SET_DEFECTIVE_FULFILLED:
            return {
                ...state,
                setDefectiveResult: action.payload,
                setDefectiveApartmentCounterLoading: false,
            }
        case SET_DEFECTIVE_REJECTED:
            return {
                ...state,
                setDefectiveApartmentCounterLoading: false,
            }

        case CHECK_PERIOD_ANALYSIS_PENDING:
            return {
                ...state,
                checkPeriodAnalysisLoading: true,
            }
        case CHECK_PERIOD_ANALYSIS_FULFILLED:
            return {
                ...state,
                checkPeriodAnalysisResult: action.payload,
                checkPeriodAnalysisLoading: false
            }
        case CHECK_PERIOD_ANALYSIS_REJECTED:
            return {
                ...state,
                checkPeriodAnalysisLoading: false
            }
        case ADD_APARTMENT_COUNTER_DATA_PENDING:
            return {
                ...state,
                addApartmentCounterDataLoading: true,
            }
        case ADD_APARTMENT_COUNTER_DATA_FULFILLED:
            return {
                ...state,
                addApartmentCounterDataResult: action.payload,
                addApartmentCounterDataLoading: false,
            }
        case ADD_APARTMENT_COUNTER_DATA_REJECTED:
            return {
                ...state,
                addApartmentCounterDataLoading: false
            }

        case UPDATE_APARTMENT_COUNTER_DATA_PENDING:
            return {
                ...state,
            }
        case UPDATE_APARTMENT_COUNTER_DATA_FULFILLED:
            return {
                ...state,
                updateApartmentCounterDataResult: action.payload
            }
        case UPDATE_APARTMENT_COUNTER_DATA_REJECTED:
            return {
                ...state,
            }

        case SET_COMPARISON_PENDING:
            return {
                ...state,
                setComparisonApartmentCounterLoading: true,
            }
        case SET_COMPARISON_FULFILLED:
            return {
                ...state,
                setComparisonResult: action.payload,
                setComparisonApartmentCounterLoading: false,
            }
        case SET_COMPARISON_REJECTED:
            return {
                ...state,
                setComparisonApartmentCounterLoading: false,
            }

        case UPLOAD_COUNTER_EXCEL_LIST_PENDING:
            return {
                ...state,
            }
        case UPLOAD_COUNTER_EXCEL_LIST_FULFILLED:
            return {
                ...state,
                uploadCounterListExcelResult: action.payload
            }
        case UPLOAD_COUNTER_EXCEL_LIST_REJECTED:
            return {
                ...state,
            }

        case UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_PENDING:
            return {
                ...state,
            }
        case UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_FULFILLED:
            return {
                ...state,
                uploadApartmentCounterExcelListResult: action.payload
            }
        case UPLOAD_COLELCTIVE_APARTMENT_COUNTERS_EXCEL_REJECTED:
            return {
                ...state,
            }
        case GET_PERIOD_COUNTERS_WITH_DATA_PENDING:
            return {
                ...state,
                getPeriodCountersWithDataLoading: true,
            }
        case GET_PERIOD_COUNTERS_WITH_DATA_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getPeriodCountersWithDataLoading: false,
            }
        case GET_PERIOD_COUNTERS_WITH_DATA_REJECTED:
            return {
                ...state,
                getPeriodCountersWithDataLoading: false
            }

        default:
            return state;
    }
}