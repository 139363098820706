import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button } from '@material-ui/core';

class PersonsTable extends Component {

    state = {
        menuStatus: false,
        anchorEl: null,
    }

    render() {
        const tableData = {
            title: "Personel Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.person.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                { title: "Ünvanı", field: "title" },
                { title: "Telefon", field: "phone", hidden: true },
                { title: "Email", field: "email", hidden: true },
                { title: "Açıklama", field: "description", hidden: true },
                { title: "Departman Adı", field: "department.name" },
            ],
            data: this.props.persons
        };

        let tableActions = [];
        if (this.props.person.permissions.person.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }
        if (this.props.person.permissions.person.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default PersonsTable;