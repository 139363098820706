import {
    GET_RESELLER_PROJECTS_PENDING, GET_RESELLER_PROJECTS_FULFILLED, GET_RESELLER_PROJECTS_REJECTED,
    GET_PROJECTS_PENDING, GET_PROJECTS_FULFILLED, GET_PROJECTS_REJECTED,
    GET_PROJECT_PENDING, GET_PROJECT_FULFILLED, GET_PROJECT_REJECTED,
    ADD_PROJECT_PENDING, ADD_PROJECT_FULFILLED, ADD_PROJECT_REJECTED,
    UPDATE_PROJECT_PENDING, UPDATE_PROJECT_FULFILLED, UPDATE_PROJECT_REJECTED,
    DELETE_PROJECT_PENDING, DELETE_PROJECT_FULFILLED, DELETE_PROJECT_REJECTED,
} from '../actions/project';
const initialState = {
    projects: [],
    project: {
        city: {
            name: ''
        },
        county: {
            name: ''
        },
        reseller: {
            name: ''
        },
        status: false,
        sharingTypes: []
    },
    addProjectResult: {},
    updateProjectResult: {},
    deleteProjectResult: {},
    deleteProjectLoading: false,
    updateProjectLoading: false,
    addProjectLoading: false,
    getProjectsLoading: false,
    getProjectLoading: false,
    getResellerProjectsLoading: false,
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RESELLER_PROJECTS_PENDING:
            return {
                ...state,
                getResellerProjectsLoading: true,
            }
        case GET_RESELLER_PROJECTS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getResellerProjectsLoading: false,
            }
        case GET_RESELLER_PROJECTS_REJECTED:
            return {
                ...state,
                getResellerProjectsLoading: false,
            }
        case GET_PROJECTS_PENDING:
            return {
                ...state,
                getProjectsLoading: true
            }
        case GET_PROJECTS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectsLoading: false
            }
        case GET_PROJECTS_REJECTED:
            return {
                ...state,
                getProjectsLoading: false,
            }

        case GET_PROJECT_PENDING:
            return {
                ...state,
                getProjectLoading: true
            }
        case GET_PROJECT_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectLoading: false,
            }
        case GET_PROJECT_REJECTED:
            return {
                ...state,
                getProjectLoading: false,
            }
        case ADD_PROJECT_PENDING:
            return {
                ...state,
                addProjectLoading: true,
            }
        case ADD_PROJECT_FULFILLED:
            return {
                ...state,
                addProjectResult: action.payload,
                addProjectLoading: false,
            }
        case ADD_PROJECT_REJECTED:
            return {
                ...state,
                addProjectLoading: false,
            }
        case UPDATE_PROJECT_PENDING:
            return {
                ...state,
                updateProjectLoading: true,
            }
        case UPDATE_PROJECT_FULFILLED:
            return {
                ...state,
                updateProjectResult: action.payload,
                updateProjectLoading: false,
            }
        case UPDATE_PROJECT_REJECTED:
            return {
                ...state,
                updateProjectLoading: false,
            }

        case DELETE_PROJECT_PENDING:
            return {
                ...state,
                deleteProjectLoading: true,
            }
        case DELETE_PROJECT_FULFILLED:
            return {
                ...state,
                deleteProjectResult: action.payload,
                deleteProjectLoading: false,
            }
        case DELETE_PROJECT_REJECTED:
            return {
                ...state,
                deleteProjectLoading: false,
            }
        default:
            return state;
    }
}