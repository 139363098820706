import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { getAllDeparments } from '../../actions/department';
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
import { getDepartmentPersons } from '../../actions/person';
import { addNote, getAllNotes, deleteNote, updateNote } from '../../actions/note';

// components
import { Button } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog';

import AddNoteForm from './AddNoteForm';
import EditNoteForm from './EditNoteForm';

import NotesTable from './NotesTable';
import swal from 'sweetalert';


class Notes extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("NOTLAR");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Notlar" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }
    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        // yetki kontrolü
        if (!this.props.person.permissions.note.read) {
            return window.location.href = "/";
        }
        await this.props.getAllNotes(this.props.token);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }
    // yeni kayıt butonuna tıklandığında yapılacak işlem
    AddNoteForm = async () => {
        await this.props.getAllDeparments(this.props.token);
        this.setState({ addOpenDialog: true })
    }

    handleAddNoteSubmit = (e, state) => {
        const note = {
            creator: this.props.person._id,
            title: e.target.title.value,
            description: e.target.description.value,
            department: state.department.value,
            persons: state.persons.map(person => person.value),
            type: e.target.type.value,
            validityDate: e.target.validityDate.value,
        }

        return swal({
            title: "Emin misin?",
            text: "Yeni Not Eklensin mi?",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addNote(note, this.props.token);
                    if (this.props.addNoteResult.code === 200) {
                        this.setState({ addOpenDialog: false, value: 1 });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addNoteResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getAllNotes(this.props.token);

            });
    }

    handeleDeleteNote = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (note) => {
                        await this.props.deleteNote(note, this.props.token);
                        if (this.props.deleteNoteResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteNoteResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllNotes(this.props.token);
                }
            });
    }

    handleEditNote = async (rowData) => {
        await this.props.getAllDeparments(this.props.token);
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editOpenDialog: true });
    }

    handleEditNoteSubmit = (e, state) => {
        const note = {
            _id: e.target._id.value,
            title: e.target.title.value,
            description: e.target.description.value,
            department: state.department.value,
            persons: state.persons.map(person => person.value),
            type: e.target.type.value,
            validityDate: e.target.validityDate.value
        }
        return swal({
            title: "Emin misin?",
            text: "Bu içeriği güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateNote(note, this.props.token);
                    if (this.props.updateNoteResult.code === 200) {
                        this.setState({ editOpenDialog: false });
                        await this.props.getAllNotes(this.props.token);
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarısız. " + this.props.updateNoteResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getAllNotes(this.props.token);
            });
    }
    
    render() {
        return (
            <div>
                {this.props.addNoteLoading ? <div className="loading" /> : ""}
                {this.props.getAllNotesLoading ? <div className="loading" /> : ""}
                {this.props.getDepartmentPersonsLoading ? <div className="loading" /> : ""}
                <NotesTable {...this.props} addForm={this.AddNoteForm} editForm={this.handleEditNote} delete={this.handeleDeleteNote} />
                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Not Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddNoteForm onSubmitForm={this.handleAddNoteSubmit}  {...this.props} departments={this.props.departments} departmentPersons={this.props.departmentPersons} />
                </ModalDialog>

                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Notu güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditNoteForm onSubmitForm={this.handleEditNoteSubmit} note={this.state.rowData}  {...this.props} departments={this.props.departments} departmentPersons={this.props.departmentPersons} />
                </ModalDialog>
            </div>
        )
    }
}


// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ departmentReducer, personReducer, noteReducer }) => {
    return {
        ...departmentReducer,
        ...personReducer,
        ...noteReducer,

    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getAllDeparments,
    setActivePageTitle,
    addNote,
    getAllNotes,
    deleteNote,
    updateNote,
    getDepartmentPersons,
    setBreadCrumb,
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notes));