import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_PERIOD_PENDING = "ADD_PERIOD_PENDING";
export const ADD_PERIOD_FULFILLED = "ADD_PERIOD_FULFILLED";
export const ADD_PERIOD_REJECTED = "ADD_PERIOD_REJECTED";

export const GET_LAST_CLOSED_PERIOD_PENDING = "GET_LAST_CLOSED_PERIOD_PENDING";
export const GET_LAST_CLOSED_PERIOD_FULFILLED = "GET_LAST_CLOSED_PERIOD_FULFILLED";
export const GET_LAST_CLOSED_PERIOD_REJECTED = "GET_LAST_CLOSED_PERIOD_REJECTED";

export const GET_LAST_ACTIVE_PERIOD_PENDING = "GET_LAST_ACTIVE_PERIOD_PENDING";
export const GET_LAST_ACTIVE_PERIOD_FULFILLED = "GET_LAST_ACTIVE_PERIOD_FULFILLED";
export const GET_LAST_ACTIVE_PERIOD_REJECTED = "GET_LAST_ACTIVE_PERIOD_REJECTED";

export const GET_PERIODS_PENDING = "GET_PERIODS_PENDING";
export const GET_PERIODS_FULFILLED = "GET_PERIODS_FULFILLED";
export const GET_PERIODS_REJECTED = "GET_PERIODS_REJECTED";

export const GET_PERIOD_PENDING = "GET_PERIOD_PENDING";
export const GET_PERIOD_FULFILLED = "GET_PERIOD_FULFILLED";
export const GET_PERIOD_REJECTED = "GET_PERIOD_REJECTED";

export const SHARING_PERIOD_PENDING = "SHARING_PERIOD_PENDING";
export const SHARING_PERIOD_FULFILLED = "SHARING_PERIOD_FULFILLED";
export const SHARING_PERIOD_REJECTED = "SHARING_PERIOD_REJECTED";

export const CLOSE_PERIOD_PENDING = "CLOSE_PERIOD_PENDING";
export const CLOSE_PERIOD_FULFILLED = "CLOSE_PERIOD_FULFILLED";
export const CLOSE_PERIOD_REJECTED = "CLOSE_PERIOD_REJECTED";

export const ACTIVE_PERIOD_PENDING = "ACTIVE_PERIOD_PENDING";
export const ACTIVE_PERIOD_FULFILLED = "ACTIVE_PERIOD_FULFILLED";
export const ACTIVE_PERIOD_REJECTED = "ACTIVE_PERIOD_REJECTED";

export const UPDATE_PERIOD_PENDING = "UPDATE_PERIOD_PENDING";
export const UPDATE_PERIOD_FULFILLED = "UPDATE_PERIOD_FULFILLED";
export const UPDATE_PERIOD_REJECTED = "UPDATE_PERIOD_REJECTED";

export const DELETE_PERIOD_PENDING = "DELETE_PERIOD_PENDING";
export const DELETE_PERIOD_FULFILLED = "DELETE_PERIOD_FULFILLED";
export const DELETE_PERIOD_REJECTED = "DELETE_PERIOD_REJECTED";

export const UPDATE_REQUEST_SHARING_PENDING = "UPDATE_REQUEST_SHARING_PENDING";
export const UPDATE_REQUEST_SHARING_FULFILLED = "UPDATE_REQUEST_SHARING_FULFILLED";
export const UPDATE_REQUEST_SHARING_REJECTED = "UPDATE_REQUEST_SHARING_REJECTED";


export function getLastClosedPeriod(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_LAST_CLOSED_PERIOD",
            payload: axios.get(`${API_URL}/period/last-closed-period/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getLastActivePeriod(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_LAST_ACTIVE_PERIOD",
            payload: axios.get(`${API_URL}/period/last-active-period/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getPeriod(period, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PERIOD",
            payload: axios.get(`${API_URL}/period/${period}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getProjectPeriods(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PERIODS",
            payload: axios.get(`${API_URL}/period/all/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addPeriod(period, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_PERIOD",
            payload: axios.post(`${API_URL}/period`, period, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updatePeriod(period, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_PERIOD",
            payload: axios.put(`${API_URL}/period`, period, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function sharingPeriod(data, token) {
    return async dispatch => {
        await dispatch({
            type: "SHARING_PERIOD",
            payload: axios.post(`${API_URL}/period/sharing`, data, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function closePeriod(period, token) {
    return async dispatch => {
        await dispatch({
            type: "CLOSE_PERIOD",
            payload: axios.post(`${API_URL}/period/close`, period, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function activePeriod(period, token) {
    return async dispatch => {
        await dispatch({
            type: "ACTIVE_PERIOD",
            payload: axios.post(`${API_URL}/period/active`, period, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deletePeriod(period, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_PERIOD",
            payload: axios.delete(`${API_URL}/period`, {
                data: {
                    period, token
                }
            })
                .then(result => result.data)
        })
    }
}

export function updateRequestSharing(data, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_REQUEST_SHARING",
            payload: axios.put(`${API_URL}/period/request-sharing`, data, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}
