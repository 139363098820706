import React, { Component } from 'react';

class MainCounterDetail extends Component {

    render() {
        return (
            <div>
                mainCounter detail
            </div>
        )
    }
}
export default MainCounterDetail;