import React, { Component } from 'react'
import { FormControl, InputLabel, Button, Input, MenuItem, Select, ExpansionPanel, ExpansionPanelSummary, Typography, Grid, ExpansionPanelDetails, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '../../styles/app';

class AddInvoice extends Component {

    state = {
        type: this.props.subscription.type._id,
        gasSharingCriterion: this.props.project.gasSharingCriterion,
        hotWaterSharingCriterion: this.props.project.hotWaterSharingCriterion,
        sharingPeriod: this.props.project.sharingPeriod,
        coldWaterSharingCriterion: this.props.project.coldWaterSharingCriterion,
        coldWaterSharingUnitPrice: this.props.project.coldWaterSharingUnitPrice,
        invoiceCheck: false,
        image: null,
    };

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    onChangeHandlerFiles = async event => {
        if (event.target.files.length > 0) {
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (acceptedImageTypes.includes(event.target.files[0].type)) {
                await this.setState({
                    image: event.target.files,
                });
            } else {
                this.errorMessage();
            }
        }
    }

    errorMessage = () => {
        return this.props.enqueueSnackbar('Lütfen bir resim dosyası yükleyin.', {
            variant: "error",
            persist: true,
            action: (
                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
            ),
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" value={this.props.period} name="period" />
                <input type="hidden" value={this.props.subscription._id} name="subscription" />


                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                <Grid container>
                                    <FormControl required style={{ width: "100%", display: "inline-block" }}>
                                        <label style={{ fontSize: "25px" }}>Abonelik / Tesisat Numarası: </label>
                                        <br />
                                        <input className="inputInvoice" disabled={true} defaultValue={this.props.subscription.subscriberNumber} style={{ width: "15%", padding: "10px", marginBottom: "15px", fontSize: "30px", color: "#000", background: "#a7e6e8", fontWeight: "800" }} type="text" />
                                        <Switch checked={this.state.invoiceCheck} onChange={this.handleChangeStatus('invoiceCheck')} />
                                        {
                                            this.state.invoiceCheck ? <label style={{ fontSize: "18px", marginTop: "5px", color: "red" }}>Tesisat Numarası Onaylandı.</label> : <label style={{ fontSize: "18px", marginTop: "5px", color: "black" }}>Tesisat numarasını onaylayınız.</label>
                                        }

                                    </FormControl>
                                    <br /><br />
                                    <FormControl required style={{ width: "100%", display: "inline-block" }}>
                                        <label style={{ fontSize: "25px" }}>Fatura Tutarını Girin: </label>
                                        <br />
                                        <input required className="inputInvoice" style={{ width: "15%", padding: "10px", marginBottom: "15px", fontSize: "30px", color: "#000", fontWeight: "800", background: "#a7e6e8" }} name="balance" type="text" />
                                        <label>&nbsp;TL</label>

                                    </FormControl>
                                    <FormControl required style={{ width: "100%", display: "inline-block" }}>
                                        <label style={{ fontSize: "25px" }}>Tüketim Miktarini Girin: </label>
                                        <br />
                                        <input required className="inputInvoice" style={{ width: "15%", padding: "10px", marginBottom: "15px", fontSize: "30px", color: "#000", fontWeight: "800", background: "#a7e6e8" }} name="consumption" type="text" />
                                        <label>&nbsp;m3 / kw</label>

                                    </FormControl>
                                    <br /><br />

                                </Grid>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                <Grid container>

                                    <ExpansionPanel fullWidth style={{ /*backgroundColor: "#0e9e9e"*/ }}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Tüm Paylaşım Detayları</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {
                                                // dogalgaz faturası
                                                this.props.subscription.type._id.toString() === "5d0a312392706a151bc178df" ?
                                                    <div style={{ width: "100%" }}>
                                                        <FormControl required style={{ width: "100%" }}>
                                                            <InputLabel htmlFor="heatLossRate">Isı Kayıp Oranı</InputLabel>
                                                            <Input
                                                                id="heatLossRate"
                                                                name="heatLossRate"
                                                                type="text"
                                                                defaultValue={this.props.project.heatLossRate}
                                                            />
                                                        </FormControl>
                                                        <FormControl required fullWidth>
                                                            <InputLabel htmlFor="sharingCriterion">Isıtma Kalorimetre Paylaşım Kriteri</InputLabel>
                                                            <Select
                                                                value={this.state.gasSharingCriterion}
                                                                onChange={(element) => { this.setState({ gasSharingCriterion: element.target.value }) }}
                                                                id="gasSharingCriterion"
                                                                name="gasSharingCriterion"
                                                            >
                                                                <MenuItem key={0} value={0}>%30 - %70 Standart Paylaşım</MenuItem>
                                                                <MenuItem key={1} value={1}>%30 - Dairelere Sayısına Eşit</MenuItem>
                                                                <MenuItem key={2} value={2}>%30 - M² Göre</MenuItem>
                                                                <MenuItem key={3} value={3}>%30 - Sadece Dolu Dairelerin Sayısına Göre Eşit</MenuItem>
                                                                <MenuItem key={4} value={4}>%30 - Sadece Dolu Dairelerin M² Göre</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl required fullWidth>
                                                            <InputLabel htmlFor="sharingPeriod">Sıcak Su Paylaşım Dönemi</InputLabel>
                                                            <Select
                                                                value={this.state.sharingPeriod}
                                                                onChange={(element) => { this.setState({ sharingPeriod: element.target.value, hotWaterSharingCriterion: 0 }) }}
                                                                id="sharingPeriod"
                                                                name="sharingPeriod"
                                                            >
                                                                <MenuItem key={0} value={0}>Kış Paylaşımı</MenuItem>
                                                                <MenuItem key={1} value={1}>Yaz Paylaşımı</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl required fullWidth>
                                                            <InputLabel htmlFor="hotWaterSharingCriterion">Sıcak Su Paylaşım Kriteri</InputLabel>
                                                            <Select
                                                                value={this.state.hotWaterSharingCriterion}
                                                                onChange={(element) => { this.setState({ hotWaterSharingCriterion: element.target.value }) }}
                                                                id="hotWaterSharingCriterion"
                                                                name="hotWaterSharingCriterion"
                                                            >
                                                                <MenuItem key={0} value={0}>Formül Üzerinden</MenuItem>
                                                                {this.state.sharingPeriod === 1 ? <MenuItem key={1} value={1}>%30 - %70 Ayrılarak</MenuItem> : ""}
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.hotWaterSharingCriterion === 0 ? <div>
                                                                <FormControl required style={{ width: "100%" }}>
                                                                    <InputLabel htmlFor="boilerTemperature">Kazan Derecesi</InputLabel>
                                                                    <Input
                                                                        id="boilerTemperature"
                                                                        name="boilerTemperature"
                                                                        type="text"
                                                                        defaultValue={this.props.project.boilerTemperature}
                                                                    />
                                                                </FormControl>
                                                                <FormControl required style={{ width: "100%" }}>
                                                                    <InputLabel htmlFor="thermalCoefficient">Isıl Katsayısı</InputLabel>
                                                                    <Input
                                                                        id="thermalCoefficient"
                                                                        name="thermalCoefficient"
                                                                        type="text"
                                                                        defaultValue={this.props.project.thermalCoefficient}
                                                                    />
                                                                </FormControl>
                                                            </div> : ""
                                                        }

                                                    </div>
                                                    : ""
                                            }
                                            {
                                                // soguk su faturası
                                                this.props.subscription.type._id.toString() === "5d0a312392706a151bc178e0" ?
                                                    <div style={{ width: "100%" }}>
                                                        <FormControl required fullWidth>
                                                            <InputLabel htmlFor="coldWaterSharingCriterion">Soğuk Su Paylaşım Kriteri</InputLabel>
                                                            <Select
                                                                value={this.state.coldWaterSharingCriterion}
                                                                onChange={(element) => { this.setState({ coldWaterSharingCriterion: element.target.value }) }}
                                                                id="coldWaterSharingCriterion"
                                                                name="coldWaterSharingCriterion"
                                                            >
                                                                <MenuItem key={0} value={0}>Gelen Fatura Üzerinden Birim Fiyat</MenuItem>
                                                                <MenuItem key={1} value={1}>Gelen Faturanın Tamamı</MenuItem>
                                                                <MenuItem key={2} value={2}>Manuel Birim Fiyat</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.coldWaterSharingCriterion === 2 ?
                                                                <FormControl required fullWidth>
                                                                    <InputLabel htmlFor="coldWaterSharingUnitPrice">Soğuk Su Birim Fiyat</InputLabel>
                                                                    <Input
                                                                        id="coldWaterSharingUnitPrice"
                                                                        name="coldWaterSharingUnitPrice"
                                                                        type="text"
                                                                        defaultValue={this.state.coldWaterSharingUnitPrice}
                                                                    />
                                                                </FormControl>
                                                                : ""
                                                        }
                                                    </div>
                                                    : ""
                                            }
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <FormControl fullWidth style={{ margin: "35px", padding: "35px", color: "black", fontSize: "20px" }}>
                                        <InputLabel htmlFor="blockListExcel">FATURA GÖRSELİ YÜKLEYİNİZ</InputLabel>
                                        <br />
                                        <br />
                                        <br />
                                        <input
                                            type="file"
                                            onChange={this.onChangeHandlerFiles}
                                        />
                                    </FormControl>
                                    <Button style={{ background: "#27a4a8", color: "white", fontSize: "20px", width: "300px", height: "50px" }}
                                        type="submit"

                                        className={classes.submit}
                                    >
                                        FATURAYI KAYDET
                                       </Button>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>



            </form >
        )
    }
}
export default withStyles(styles)(AddInvoice);