import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography, Stepper, Step, StepLabel } from '@material-ui/core';
import swal from 'sweetalert';

import { addMainCounter, getProjectMainCounters } from '../../actions/mainCounter';
import { getAllTypeSubscriptions } from '../../actions/subscription';
import { uploadCollectiveApartmentCountersExcel } from '../../actions/apartmentCounter';
import { getCollectiveApartmentCounterExcelList } from '../../functions/fileDownload';


import ProjectSharingTypes from '../SharingTypes/ProjectSharingTypes';
import AddMainCounterForm from './AddMainCounterForm';
import AddHotWaterMainCounterForm from './AddHotWaterMainCounterForm';
import CreateApartmentCountersForm from '../ApartmentCounters/CreateApartmentCountersForm';
import ApartmentCountersExcelUploadForm from '../ApartmentCounters/ApartmentCountersExcelUploadForm';
import styles from '../../styles/app';

class AddMainCounterStepForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
    };

    state = {
        sharingType: 0,
        activeStep: 0,
        subscriptionType: null,
        skipped: new Set(),
        steps: ['Yeni Paylaşım Tipi', 'Yeni Paylaşım Bilgileri', 'Sayaç Oluşturma Excel Listesi', 'Daire Sayaçlarını Oluştur'],
        waterSubscriptions: [] // sadece sıcak su sayaçları için doluyor
    };

    isStepSkipped(step) {
        return this.state.skipped.has(step);
    }

    handleNext = () => {
        const { activeStep } = this.state;
        let { skipped } = this.state;
        if (this.isStepSkipped(activeStep)) {
            skipped = new Set(skipped.values());
            skipped.delete(activeStep);
        }
        this.setState({
            activeStep: activeStep + 1,
            skipped,
        });
    };

    handleBack = () => {
        const { activeStep } = this.state;
        const { steps } = this.state;


        if (activeStep === 1) {
            steps[0] = 'Yeni Abonelik Tipi';
        }
        else if (activeStep === 2) {
            steps[1] = 'Yeni Abonelik Bilgileri';
        }
        else if (activeStep === 3) {
            steps[2] = 'Daire Sayaçlarını Oluşturma';
        }

        this.setState({
            activeStep: activeStep - 1,
            steps
        });
    };

    handleSkip = () => {
        const { activeStep } = this.state;
        if (!this.isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }
        const skipped = new Set(this.state.skipped.values());
        skipped.add(activeStep);
        this.setState({
            activeStep: this.state.activeStep + 1,
            skipped,
        });
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    handleSharingType = async (sharingType) => {

        if (sharingType._id === "5cefd0dd45844c0a69b509d0") {
            await this.props.getAllTypeSubscriptions(this.props.project._id, sharingType.typeWater, this.props.token);
            this.setState({ waterSubscriptions: this.props.subscriptions });
        }

        await this.props.getAllTypeSubscriptions(this.props.project._id, sharingType.type, this.props.token);
        this.props.project.sharingTypes.map((item) => {
            if (item._id === sharingType._id) {
                const { steps } = this.state;
                steps[0] = item.name;
                this.setState({ steps, sharingType: sharingType._id, subscriptionType: sharingType.type, activeStep: 1 });
            }
            return '';
        });
    }

    handleAddMainCounter = async (e, state) => {

        if (e.target.name.value.length < 1) {
            this.props.enqueueSnackbar('Lütfen İsim Tanımlayın..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }

        if (state.subscription.value === "") {
            this.props.enqueueSnackbar('Lütfen Fatura Aboneliği Tanımlayın..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }

        const mainCounter = {
            project: e.target.project.value,
            name: e.target.name.value,
            type: e.target.type.value,
            subscription: state.subscription.value,
            sharingStatus: state.sharingStatus,
            distributionBlocks: state.distributionBlocks,
            heatingOnly: state.heatingOnly || false,
            filtrationCounterStatus: state.filtrationCounterStatus
        };

        if (e.target.type.value === "5cefd0dd45844c0a69b509d0" && !state.heatingOnly) {
            if (state.subscriptionWater.value === "") {
                this.props.enqueueSnackbar('Lütfen Soğuk Su Fatura Aboneliği Tanımlayın..', {
                    variant: "error",
                    persist: true,
                    action: (
                        <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                    ),
                });
                return;
            }
            mainCounter.subscriptionWater = state.subscriptionWater.value
        }

        // süzme sayaç varsa
        if (state.filtrationCounterStatus) {
            mainCounter.serialNumber = e.target.serialNumber.value;
            mainCounter.counterValue = e.target.counterValue.value;
        }

        await this.props.addMainCounter(mainCounter, this.props.token);
        if (this.props.addMainCounterResult.code === 200) {
            const { steps } = this.state;
            steps[1] = this.props.addMainCounterResult.data.mainCounter.name;
            this.setState({ steps, activeStep: 2 });
            this.props.enqueueSnackbar(this.props.addMainCounterResult.data.mainCounter.name + ' İsimli paylaşım başarıyla oluşturuldu..', {
                variant: "success",
                autoHideDuration: 3000,
            });
        } else {
            this.props.enqueueSnackbar('Paylaşım eklenirken bir hata oluştu..' + this.props.addMainCounterResult.message, {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
        }
    }

    handleGetApartmentCounters = async (e, state) => {
        await getCollectiveApartmentCounterExcelList(state.mainCounter, state.blocks, this.props.token);
        const { steps } = this.state;
        steps[2] = "Excel Liste İndirildi.";
        this.setState({ steps, activeStep: 3 });
    }

    handleUpdateCollectiveApartmentListSubmit = async (e, state) => {
        const formData = new FormData();
        if (state.apartmentCountersListExcel.length > 0) {
            for (var i = 0; i < state.apartmentCountersListExcel.length; i++) {
                formData.append('apartmentCountersListExcel', state.apartmentCountersListExcel[i])
            }
        }
        return swal({
            title: "Emin misin?",
            text: "Daire sayaçlarını toplu bir şekilde ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.uploadCollectiveApartmentCountersExcel(formData, this.props.token);
                    if (this.props.uploadApartmentCounterExcelListResult.code === 200) {
                        this.props.closeDialog();
                        await this.props.getProjectMainCounters(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar(this.props.uploadApartmentCounterExcelListResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar(this.props.uploadApartmentCounterExcelListResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    render() {
        const { classes } = this.props;
        const { activeStep } = this.state;
        return (
            <div className={classes.root} style={{ display: "block !important" }}>
                kadir
                {this.props.getApartmentCountersLoading ? <div className="loading" /> : ""}
                {this.props.getTypeProjectSubscriptions ? <div className="loading" /> : ""}
                {this.props.getProjectSubscriptionsLoading ? <div className="loading" /> : ""}
                <Stepper activeStep={activeStep}>
                    {this.state.steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {activeStep === this.state.steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                Tüm Adımları Başarıyla Bitirdiniz
                            </Typography>
                            <Button onClick={this.handleReset} className={classes.button}>
                                Sıfırla
                            </Button>
                        </div>
                    ) : (
                            <div>
                                {activeStep === 0 ? <ProjectSharingTypes handleSharingType={this.handleSharingType} sharingTypes={this.props.project.sharingTypes} /> : ""}
                                {activeStep === 1 ? <div>
                                    {/* sayaç tipi sıcak su ise ayrı form değilse hepsinde aynı form açılsın */}
                                    {this.state.sharingType === "5cefd0dd45844c0a69b509d0" ? <AddHotWaterMainCounterForm onSubmitForm={this.handleAddMainCounter} project={this.props.project}
                                        waterSubscriptions={this.state.waterSubscriptions} subscriptions={this.props.subscriptions} sharingType={this.state.sharingType} />
                                        : <AddMainCounterForm onSubmitForm={this.handleAddMainCounter} project={this.props.project} sharingType={this.state.sharingType} subscriptions={this.props.subscriptions} />}
                                </div> : ""}
                                {activeStep === 2 ? <CreateApartmentCountersForm onSubmitForm={this.handleGetApartmentCounters} mainCounter={this.props.addMainCounterResult.data.mainCounter} /> : ""}
                                {activeStep === 3 ? <ApartmentCountersExcelUploadForm enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleUpdateCollectiveApartmentListSubmit} /> : ""}
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={this.handleBack}
                                    className={classes.button}
                                >
                                    Geri
                                    </Button>

                            </div>
                        )}
                </div>
            </div >
        );
    }
}


// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ blockReducer, personReducer, projectReducer, sharingTypeReducer, mainCounterReducer, subscriptionReducer, apartmentCounterReducer }) => {
    return {
        ...personReducer,
        ...projectReducer,
        ...sharingTypeReducer,
        ...blockReducer,
        ...mainCounterReducer,
        ...subscriptionReducer,
        ...apartmentCounterReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    addMainCounter,
    getAllTypeSubscriptions,
    uploadCollectiveApartmentCountersExcel,
    getProjectMainCounters
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(AddMainCounterStepForm)));