import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_TASK_PENDING = "ADD_TASK_PENDING";
export const ADD_TASK_FULFILLED = "ADD_TASK_FULFILLED";
export const ADD_TASK_REJECTED = "ADD_TASK_REJECTED";

export const GET_ALL_TASKS_PENDING = "GET_ALL_TASKS_PENDING";
export const GET_ALL_TASKS_FULFILLED = "GET_ALL_TASKS_FULFILLED";
export const GET_ALL_TASKS_REJECTED = "GET_ALL_TASKS_REJECTED";

export const DELETE_TASK_PENDING = "DELETE_TASK_PENDING";
export const DELETE_TASK_FULFILLED = "DELETE_TASK_FULFILLED";
export const DELETE_TASK_REJECTED = "DELETE_TASK_REJECTED";

export const PUT_TASK_PENDING = "PUT_TASK_PENDING";
export const PUT_TASK_FULFILLED = "PUT_TASK_FULFILLED";
export const PUT_TASK_REJECTED = "PUT_TASK_REJECTED";

export const GET_ALL_RESELLER_DEMAND_PENDING = "GET_ALL_RESELLER_DEMAND_PENDING";
export const GET_ALL_RESELLER_DEMAND_FULFILLED = "GET_ALL_RESELLER_DEMAND_FULFILLED";
export const GET_ALL_RESELLER_DEMAND_REJECTED = "GET_ALL_RESELLER_DEMAND_REJECTED";


export function addTask(task, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_TASK",
            payload: axios.post(`${API_URL}/task`, task, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllTasks(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_TASKS",
            payload: axios.get(`${API_URL}/task/all`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function deleteTask(task, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_TASK",
            payload: axios.delete(`${API_URL}/task`, { data: { ...task, token: token } })
                .then(result => result.data)
        })
    }
}

export function updateTask(task, token) {
    return async dispatch => {
        await dispatch({
            type: "PUT_TASK",
            payload: axios.put(`${API_URL}/task`, task, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllResellerDemand(creator, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_RESELLER_DEMAND",
            payload: axios.get(`${API_URL}/task/reseller-demand/${creator}`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}
