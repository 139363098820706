export const priceFormat = (value) => {
    const doubleKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
    let returnValue = "";
    value = value.split("");
    for (let index = 0; index < value.length; index++) {
        if (doubleKeys.includes(value[index])) {
            returnValue += value[index];
        }
    }
    return returnValue;
}