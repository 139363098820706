import {
    GET_DEPARTMENTS_PENDING, GET_DEPARTMENTS_FULFILLED, GET_DEPARTMENTS_REJECTED,
    ADD_DEPARTMENT_PENDING, ADD_DEPARTMENT_FULFILLED, ADD_DEPARTMENT_REJECTED,
    PUT_DEPARTMENT_PENDING, PUT_DEPARTMENT_FULFILLED, PUT_DEPARTMENT_REJECTED,
    DELETE_DEPARTMENT_PENDING, DELETE_DEPARTMENT_FULFILLED, DELETE_DEPARTMENT_REJECTED,
} from '../actions/department';
const initialState = {
    fetchingDone: false,
    departments: [],
    fetchingError: '',
    addDepartmentLoading: false,
    getDepartmentsLoading: false,
    addDepartmentResult: {},
    putDepartmentLoading: false,
    putDepartmentResult: {},
    deleteResult: {},
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {

    switch (action.type) {
        // tüm departmanları çekme
        case GET_DEPARTMENTS_PENDING:
            return {
                ...state,
                getDepartmentsLoading: true,

            }
        case GET_DEPARTMENTS_FULFILLED:

            return {
                ...state,
                getDepartmentsLoading: false,
                fetchingDone: true,
                ...action.payload.data,

            }
        case GET_DEPARTMENTS_REJECTED:
            return {
                ...state,
                getDepartmentsLoading: false,
                fetchingError: "Servis sağlayıcısına ulaşılamadı. Lütfen yönetinizle iletişime geçin.",

            }
        // yeni bir departman ekleme
        case ADD_DEPARTMENT_PENDING:
            return {
                ...state,
                addDepartmentLoading: true
            }
        case ADD_DEPARTMENT_FULFILLED:
            return {
                ...state,
                addDepartmentLoading: false,
                addDepartmentResult: action.payload
            }
        case ADD_DEPARTMENT_REJECTED:
            return {
                ...state,
                addDepartmentLoading: false
            }
        // mevcut bir departmanı güncelleme 
        case PUT_DEPARTMENT_PENDING:
            return {
                ...state,
                putDepartmentLoading: true
            }
        case PUT_DEPARTMENT_FULFILLED:
            return {
                ...state,
                putDepartmentLoading: false,
                putDepartmentResult: action.payload
            }
        case PUT_DEPARTMENT_REJECTED:
            return {
                ...state,
                putDepartmentLoading: false
            }
        // mevcut bir departmanı silme
        case DELETE_DEPARTMENT_PENDING:
            return {
                ...state,
                deleteLoading: true
            }
        case DELETE_DEPARTMENT_FULFILLED:
            return {
                ...state,
                deleteLoading: false,
                deleteResult: action.payload
            }
        case DELETE_DEPARTMENT_REJECTED:
            return {
                ...state,
                deleteLoading: false
            }

        default:
            return state;
    }
}