import React, { Component } from 'react'
import { Button, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class GetFilesForm extends Component {

    state = {
        fileType: 0,
        sharingTypes: this.props.period.project.sharingTypes.map(type => type._id),
        blocks: this.props.period.project.blocks.map(block => block._id),
        project: this.props.period.project._id,
        period: this.props.period._id,
    };

    handleChangeFileType = (element) => {
        this.setState({ fileType: element.target.value })
    }

    handleSharingTypes = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const sharingTypes = state.sharingTypes.concat(name.toString());
                return {
                    sharingTypes
                };
            });
        }
        else {
            this.setState(state => {
                const sharingTypes = state.sharingTypes.filter(item => item.toString() !== name.toString());
                return {
                    sharingTypes,
                };
            });
        }
    };

    handleBlocks = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const blocks = state.blocks.concat(name.toString());
                return {
                    blocks
                };
            });
        }
        else {
            this.setState(state => {
                const blocks = state.blocks.filter(item => item.toString() !== name.toString());
                return {
                    blocks,
                };
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                    <InputLabel htmlFor="blocks">Listede İstenen Bloklar</InputLabel>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        this.props.period.project.blocks.map((block) => {
                            return (
                                <FormControlLabel key={block._id}
                                    control={
                                        <Switch checked={this.state.blocks.includes(block._id)} onChange={this.handleBlocks(block._id)} />
                                    }
                                    label={block.name}
                                />
                            )
                        })
                    }
                </FormControl>
                <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                    <InputLabel htmlFor="sharingTypes">Listede İstenen Sayaç Tipleri</InputLabel>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        this.props.period.project.sharingTypes.map((sharingType) => {
                            return (
                                <FormControlLabel key={sharingType._id}
                                    control={
                                        <Switch checked={this.state.sharingTypes.includes(sharingType._id)} onChange={this.handleSharingTypes(sharingType._id)} />
                                    }
                                    label={sharingType.name}
                                />
                            )
                        })
                    }
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="fileType">İndirilecek Dosya Türünü Seçin</InputLabel>
                    <Select
                        value={this.state.fileType}
                        onChange={this.handleChangeFileType}
                        id="fileType"
                        name="fileType"
                    >
                        <MenuItem key={0} value={0}>Excel</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Güncel Listeyi İndir
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(GetFilesForm);
