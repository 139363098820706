import React, { Component } from 'react';
// components
import Table from '../Items/Table';

class ProjectPersonsTable extends Component {


    render() {
           const tableData = {
            title: "Proje İle İlişkili Personeller ",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                { title: "Personel Adı", field: "title" },
                {
                    title: "Departman",
                    field: "department",
                    render: rowData => {
                        return (
                            <a href={"/department/" + rowData.department._id} style={{ color: "#000000", fontWeight: "500" }} >{rowData.department.name}</a>
                        )
                    },
                },
            ],
            data: this.props.persons
        };
        let tableActions = [];

        // if (this.props.person.permissions.project.delete) {
        //     tableActions.push({
        //         icon: 'delete',
        //         tooltip: 'Sil',
        //         onClick: this.props.delete,
        //         iconProps: {
        //             style: {
        //                 fontSize: 40,
        //                 color: 'red'
        //             }
        //         }
        //     });
        // }



        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ProjectPersonsTable;