import axios from 'axios';
import { API_URL } from '../config/config';


export const GET_BLOCK_APARTMENTS_PENDING = "GET_BLOCK_APARTMENTS_PENDING";
export const GET_BLOCK_APARTMENTS_FULFILLED = "GET_BLOCK_APARTMENTS_FULFILLED";
export const GET_BLOCK_APARTMENTS_REJECTED = "GET_BLOCK_APARTMENTS_REJECTED";

export const ADD_APARTMENT_PENDING = "ADD_APARTMENT_PENDING";
export const ADD_APARTMENT_FULFILLED = "ADD_APARTMENT_FULFILLED";
export const ADD_APARTMENT_REJECTED = "ADD_APARTMENT_REJECTED";

export const UPDATE_APARTMENT_PENDING = "UPDATE_APARTMENT_PENDING";
export const UPDATE_APARTMENT_FULFILLED = "UPDATE_APARTMENT_FULFILLED";
export const UPDATE_APARTMENT_REJECTED = "UPDATE_APARTMENT_REJECTED";

export const DELETE_APARTMENT_PENDING = "DELETE_APARTMENT_PENDING";
export const DELETE_APARTMENT_FULFILLED = "DELETE_APARTMENT_FULFILLED";
export const DELETE_APARTMENT_REJECTED = "DELETE_APARTMENT_REJECTED";

export const UPLOAD_COLELCTIVE_APARTMENT_EXCEL_PENDING = "UPLOAD_COLELCTIVE_APARTMENT_EXCEL_PENDING";
export const UPLOAD_COLELCTIVE_APARTMENT_EXCEL_FULFILLED = "UPLOAD_COLELCTIVE_APARTMENT_EXCEL_FULFILLED";
export const UPLOAD_COLELCTIVE_APARTMENT_EXCEL_REJECTED = "UPLOAD_COLELCTIVE_APARTMENT_EXCEL_REJECTED";

export const GET_APARTMENT_PENDING = "GET_APARTMENT_PENDING";
export const GET_APARTMENT_FULFILLED = "GET_APARTMENT_FULFILLED";
export const GET_APARTMENT_REJECTED = "GET_APARTMENT_REJECTED";

export const GET_APARTMENT_PERIOD_INVOICES_PENDING = "GET_APARTMENT_PERIOD_INVOICES_PENDING";
export const GET_APARTMENT_PERIOD_INVOICES_FULFILLED = "GET_APARTMENT_PERIOD_INVOICES_FULFILLED";
export const GET_APARTMENT_PERIOD_INVOICES_REJECTED = "GET_APARTMENT_PERIOD_INVOICES_REJECTED";

export const DELETE_APARTMENT_INVOICE_PENDING = "DELETE_APARTMENT_INVOICE_PENDING";
export const DELETE_APARTMENT_INVOICE_FULFILLED = "DELETE_APARTMENT_INVOICE_FULFILLED";
export const DELETE_APARTMENT_INVOICE_REJECTED = "DELETE_APARTMENT_INVOICE_REJECTED";

export const GET_APARTMENT_PERIOD_INVOICES_PDF_PENDING = "GET_APARTMENT_PERIOD_INVOICES_PDF_PENDING";
export const GET_APARTMENT_PERIOD_INVOICES_PDF_FULFILLED = "GET_APARTMENT_PERIOD_INVOICES_PDF_FULFILLED";
export const GET_APARTMENT_PERIOD_INVOICES_PDF_REJECTED = "GET_APARTMENT_PERIOD_INVOICES_PDF_REJECTED";

export const GET_APARTMENT_INVOICES_PENDING = "GET_APARTMENT_INVOICES_PENDING";
export const GET_APARTMENT_INVOICES_FULFILLED = "GET_APARTMENT_INVOICES_FULFILLED";
export const GET_APARTMENT_INVOICES_REJECTED = "GET_APARTMENT_INVOICES_REJECTED";

export function getApartmentInvoices(apartment, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_APARTMENT_INVOICES",
            payload: axios.get(`${API_URL}/apartment/invoices/${apartment}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getApartmentPeriodInvoices(period, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_APARTMENT_PERIOD_INVOICES",
            payload: axios.get(`${API_URL}/apartment/period-invoices/${period}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}


export function getApartmentPeriodInvoicesPdf(params, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_APARTMENT_PERIOD_INVOICES_PDF",
            payload: axios.get(`${API_URL}/apartment/period-invoices-pdf`, { params, headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteInvoice(rowDatas, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_APARTMENT_INVOICE",
            payload: axios.delete(`${API_URL}/apartment/invoice`, {
                data: {
                    invoices: rowDatas, token
                }
            })
                .then(result => result.data)
        })
    }
}

export function uploadCollectiveApartmentExcel(formData, token) {
    return async dispatch => {
        await dispatch({
            type: "UPLOAD_COLELCTIVE_APARTMENT_EXCEL",
            payload: axios.post(`${API_URL}/apartment/import-collective-apartment-list-excel`, formData, { headers: { "x-access-token": token, 'content-type': 'multipart/form-data' } })
                .then(result => result.data)
        })
    }
}

export function getAllBlockApartments(block, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_BLOCK_APARTMENTS",
            payload: axios.get(`${API_URL}/apartment/block/${block}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getApartment(apartment, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_APARTMENT",
            payload: axios.get(`${API_URL}/apartment/${apartment}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addApartment(apartment, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_APARTMENT",
            payload: axios.post(`${API_URL}/apartment`, apartment, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updateApartment(apartment, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_APARTMENT",
            payload: axios.put(`${API_URL}/apartment`, apartment, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteApartment(apartment, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_APARTMENT",
            payload: axios.delete(`${API_URL}/apartment`, { data: { ...apartment, token } })
                .then(result => result.data)
        })
    }
}
