import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class UploadCollectiveBlockExcelForm extends Component {
    state = {
        blockListExcel: null,
        status: false
    };

    onChangeHandlerFiles = async event => {
        if (event.target.files.length > 0) {

            if (event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                await this.setState({
                    blockListExcel: event.target.files,
                    status: true
                });
            } else {
                this.errorMessage();
            }
        }
    }

    errorMessage = () => {
        return this.props.enqueueSnackbar('Lütfen bir excel dosyası yükleyin.', {
            variant: "error",
            persist: true,
            action: (
                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
            ),
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.state.status ? this.props.onSubmitForm(e, this.state) : this.errorMessage() }}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="blockListExcel">Blok Listesi Excel Dosyası</InputLabel>
                    <br />
                    <br />
                    <br />
                    <input
                        required
                        type="file"
                        onChange={this.onChangeHandlerFiles}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    TOPLU BLOK GÜNCELLE
                </Button>
            </form >
        )
    }
}

UploadCollectiveBlockExcelForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UploadCollectiveBlockExcelForm);
