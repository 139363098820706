import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_SUBSCRIPTION_TYPES_PENDING = "GET_SUBSCRIPTION_TYPES_PENDING";
export const GET_SUBSCRIPTION_TYPES_FULFILLED = "GET_SUBSCRIPTION_TYPES_FULFILLED";
export const GET_SUBSCRIPTION_TYPES_REJECTED = "GET_SUBSCRIPTION_TYPES_REJECTED";

export function getAllSubscriptionTypes(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_SUBSCRIPTION_TYPES",
            payload: axios.get(`${API_URL}/subscription-type/all`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}
