import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { addStockCard, getAllStockCards, deleteStockCard, updateStockCard } from '../../actions/stockCard';


import { setActivePageTitle, setBreadCrumb } from '../../actions/person';

// components
import { Button } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog';
import StockCardTable from './StockCardTable';
import AddStokCardForm from './AddStockCardForm';
import EditStockCardForm from './EditStockCardForm';
import swal from 'sweetalert';

class StockCards extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("STOK KARTI");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Stok Kartı" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }
    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        // yetki kontrolü
        if (!this.props.person.permissions.stockCard.read) {
            return window.location.href = "/";
        }
        await this.props.getAllStockCards(this.props.token);

    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }

    handleAddStockCard = async () => {
        this.setState({ addOpenDialog: true })
    }

    handleAddStockCardSubmid = async (e, state) => {
        const stockCard = {
            name: e.target.name.value,
            productionYear: e.target.productionYear.value,
            manufacturer: e.target.manufacturer.value,
            serialNumberNecessity: state.serialNumberNecessity,
            WarrantyPeriod: e.target.WarrantyPeriod.value,
            buyingPrice: e.target.buyingPrice.value,
            salePrice: e.target.salePrice.value,
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir içerik ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addStockCard(stockCard, this.props.token);
                    if (this.props.addStockCardResult.code === 200) {
                        this.setState({ addOpenDialog: false });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addStockCardResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getAllStockCards(this.props.token);
            });
    }


    handeleDeleteStockCard = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (stockCard) => {
                        await this.props.deleteStockCard(stockCard, this.props.token);
                        if (this.props.deleteStockCardResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteStockCardResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllStockCards(this.props.token);
                }
            });
    }

    handleEditStockCard = async (rowData) => {
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editOpenDialog: true });
    }
    handleEditStockCardSubmit = (e, state) => {
        const stockCard = {
            _id: e.target._id.value,
            name: e.target.name.value,
            salePrice: e.target.salePrice.value,
            buyingPrice: e.target.buyingPrice.value,
            productionYear: e.target.productionYear.value,
            manufacturer: e.target.manufacturer.value,

        }
        return swal({
            title: "Emin misin?",
            text: "Bu içeriği güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateStockCard(stockCard, this.props.token);
                    if (this.props.updateStockCardResult.code === 200) {
                        this.setState({ editOpenDialog: false });
                        await this.props.getAllStockCards(this.props.token);
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarısız. " + this.props.updateStockCardResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getAllStockCards(this.props.token);
            });
    }


    render() {

        return (
            <div>
                {this.props.addStockCardloading ? <div className="loading" /> : ""}
                {this.props.getAllStockCardLoading ? <div className="loading" /> : ""}

                <StockCardTable {...this.props} person={this.props.person} addForm={this.handleAddStockCard} editForm={this.handleEditStockCard} delete={this.handeleDeleteStockCard} />

                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Stok kartı güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditStockCardForm {...this.state.rowData} onSubmitForm={this.handleEditStockCardSubmit} />

                </ModalDialog>

                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Stok Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddStokCardForm onSubmitForm={this.handleAddStockCardSubmid} {...this.props} />
                </ModalDialog>

            </div>
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ departmentReducer, personReducer, stockCardReducer }) => {
    return {
        ...departmentReducer,
        ...personReducer,
        ...stockCardReducer,

    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb,
    addStockCard,
    getAllStockCards,
    deleteStockCard,
    updateStockCard,

}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(StockCards));