import React from "react";
// snackbar message
import { withSnackbar } from 'notistack';
// redux bağlantısı
import { connect } from 'react-redux';
import queryString from 'query-string';
import { SITE_URL } from '../../../config/config';
import '../../../styles/pdf.css';
import { getApartmentPeriodInvoicesPdf } from '../../../actions/apartment';
import moment from 'moment';
import numeral from 'numeral';

class PdfInvoicesList extends React.Component {

    state = {


    }

    async UNSAFE_componentWillMount() {
        var string = window.location.href.replace(SITE_URL + "/pdf-invioce-list?", "");
        const params = queryString.parse(string)
        await this.props.getApartmentPeriodInvoicesPdf(params, this.props.token);
        if (this.props.periodInvoicesPdfResult.code !== 200) {
            return window.location.href = "/";
        }
    }


    render() {
        return (
            <div style={{ padding: "1%", fontWeight: "400", fontSize: "14px", color: "#67757c" }}>
                {this.props.getApartmentPeriodInvoicesPDFLoading ? <div className="loading" /> : ""}
                {this.props.getApartmentPeriodInvoicesLoading ? <div className="loading" /> : ""}
                {
                    this.props.periodInvoicesPdfResult.apartments.map((apartment, index) => {
                        let toplamTutar = 0;
                        return <div key={index}>
                            <div className="fatura">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="kutuItem">BİREYSEL TÜKETİM DETAYI</div>
                                        <div className="kutuItem">
                                            <div className="kutuIci">Düzenleme Tarihi: </div>
                                            <div className="kutuIci">{moment().format("DD.MM.YYYY")}</div>
                                            <div className="kutuIci">Proje Adı:</div>
                                            <div className="kutuIci">{this.props.periodInvoicesPdfResult.period.project.name}</div>
                                            <div className="kutuIci">Tüketici No:</div>
                                            <div className="kutuIci">{apartment.block.name} / {apartment.name}</div>
                                            <div className="kutuIci">Daire / Ofis m²:</div>
                                            <div className="kutuIci">{apartment.squareMeters} m²</div>
                                            <div className="kutuIci">Bölge:</div>
                                            <div className="kutuIci">{this.props.periodInvoicesPdfResult.period.project.county.areaCode}. Bölge</div>
                                            <div className="kutuIci">Harcama Dönemi:</div>
                                            <div className="kutuIci">{this.props.periodInvoicesPdfResult.period.lastClosedPeriodDate ? moment(this.props.periodInvoicesPdfResult.period.lastClosedPeriodDate).format("DD.MM.YYYY") : ""} - {moment(this.props.periodInvoicesPdfResult.period.createdAt).format("DD.MM.YYYY")}</div>
                                            <div className="kutuIci">Son Ödeme Tarihi:</div>
                                            <div className="kutuIci">{this.props.periodInvoicesPdfResult.period.lastPaymentDate}</div>
                                        </div>
                                    </div>
                                    <div className="col-6" style={{ textAlign: 'right' }}>
                                        <img src="muhur.jpg" alt="tebaş otomasyon" style={{ width: '360px' }} />
                                    </div>
                                    <div className="col-12" style={{ marginTop: '30px' }}>
                                        <div className="row">
                                            <div className="col-3" />
                                            <div className="col-1" style={{ textAlign: 'center' }}>
                                                <b>İlk Tüketim Endeks</b>
                                            </div>
                                            <div className="col-2" style={{ textAlign: 'center' }}>
                                                <b>Son Tüketim </b>
                                                <br />
                                                <b>Endeks</b>
                                            </div>
                                            <div className="col-1" style={{ textAlign: 'center' }}>
                                                <b>Tüketim Miktarı</b>
                                            </div>
                                            <div className="col-1" style={{ textAlign: 'center' }}>
                                                <b>Birim Fiyat</b>
                                            </div>
                                            <div className="col-1" style={{ textAlign: 'center' }}>
                                                <b>Tüketim Gideri</b>
                                            </div>
                                            <div className="col-1" style={{ textAlign: 'center' }}>
                                                <b>Isı Kayıp Gideri</b>
                                            </div>
                                            <div className="col-2" style={{ textAlign: 'center' }}>
                                                <b>Toplam Tüketim </b>
                                                <br />
                                                <b>Gideri</b>
                                            </div>
                                        </div>
                                        {
                                            apartment.invoices.map((invoice, index) => {
                                                let item = null;
                                                if (invoice.type === 0) {
                                                    toplamTutar = toplamTutar + parseFloat(numeral(invoice.balance + invoice.heatLossBalance).format("0.00"));
                                                    item =
                                                        <div key={index} className="row" style={{ marginTop: '15px' }}>
                                                            <div className="col-3">Isınma Faturası<br />Id: {invoice.counterData.counter.serialNumber}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.counterData.oldCounterValue}</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.counterData.counterValue}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unit}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unitPrice}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.balance} TL</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.heatLossBalance} TL</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{numeral(invoice.balance + invoice.heatLossBalance).format("0.00")} TL</div>
                                                            {
                                                                invoice.counterData.comparisonMakingStatus ?
                                                                    <div className="col-12 ">
                                                                        {invoice.counterData.counter.type.name} sayacına &nbsp;
                                                                        <b style={{ fontSize: "13px" }}>{numeral(invoice.counterData.periodConsumption).format('0[.]00')}&nbsp;{invoice.counterData.counter.type.unit}</b>
                                                                        &nbsp;kıyas uygulanmıştır.
                                                                        <br />
                                                                        Uygulanan Kıyas Yöntemi: &nbsp;
                                                                        {
                                                                            invoice.counterData.comparisonCriterion === 0 ? <b>Benzer bağımsız bölümlerin tüketim ortalamalarına göre</b> : ""
                                                                        }
                                                                        {
                                                                            invoice.counterData.comparisonCriterion === 1 ? <b>Tüketimi manuel olarak belirlendi</b> : ""
                                                                        }
                                                                        {
                                                                            invoice.counterData.comparisonCriterion === 2 ? <b>15 Derece Kıyas Değerine Göre</b> : ""
                                                                        }
                                                                    </div> : ""
                                                            }
                                                        </div>
                                                }
                                                else if (invoice.type === 1) {
                                                    toplamTutar = toplamTutar + parseFloat(numeral(invoice.balance + invoice.heatLossBalance).format("0.00"));
                                                    item =
                                                        <div key={index} className="row" style={{ marginTop: '15px' }}>
                                                            <div className="col-3">Su Faturası<br />Id: {invoice.counterData.counter.serialNumber}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.counterData.oldCounterValue}</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.counterData.counterValue}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unit}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unitPrice}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.balance} TL</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>0</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.balance} TL</div>
                                                        </div>
                                                }
                                                else if (invoice.type === 2) {
                                                    toplamTutar = toplamTutar + parseFloat(numeral(invoice.balance + invoice.heatLossBalance).format("0.00"));
                                                    item =
                                                        <div key={index} className="row" style={{ marginTop: '15px' }}>
                                                            <div className="col-3">Sıcak Su Isıtma Faturası<br />Id: {invoice.counterData.counter.serialNumber}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.counterData.oldCounterValue}</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.counterData.counterValue}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unit}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unitPrice}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.balance} TL</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.heatLossBalance} TL</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.balance + invoice.heatLossBalance} TL</div>
                                                        </div>
                                                }
                                                else if (invoice.type === 5) {
                                                    toplamTutar = toplamTutar + parseFloat(numeral(invoice.balance + invoice.heatLossBalance).format("0.00"));
                                                    item =
                                                        <div key={index} className="row" style={{ marginTop: '15px' }}>
                                                            <div className="col-3">Sıcak Su Isıtma Faturası<br />Id: {invoice.counterData.counter.serialNumber}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.counterData.oldCounterValue}</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.counterData.counterValue}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unit}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.unitPrice}</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>{invoice.balance} TL</div>
                                                            <div className="col-1" style={{ textAlign: 'center' }}>0</div>
                                                            <div className="col-2" style={{ textAlign: 'center' }}>{invoice.balance} TL</div>
                                                        </div>
                                                }
                                                return item;
                                            })
                                        }
                                    </div>
                                    <div className="col-6" style={{ marginTop: '30px' }} />
                                    <div className="col-6" style={{ textAlign: 'right' }}>
                                        <div className="kutuItem" style={{ padding: 0, borderTop: '1px solid #000', width: '80%', marginLeft: '20%' }} />
                                        <div className="kutuItem">
                                            <div className="kutuIci" style={{ width: '70%' }}>Toplam Tüketim Tutarı:</div>
                                            <div className="kutuIci" style={{ textAlign: 'center', width: '30%' }}>{toplamTutar} TL</div>
                                        </div>
                                        {
                                            apartment.invoices.map((invoice, index) => {
                                                let item = null;
                                                if (invoice.type === 4) {
                                                    toplamTutar = toplamTutar + parseFloat(numeral(invoice.balance + invoice.heatLossBalance).format("0.00"));
                                                    item =
                                                        <div key={index} className="kutuItem">
                                                            <div className="kutuIci" style={{ width: '70%' }}>{invoice.title}:</div>
                                                            <div className="kutuIci" style={{ textAlign: 'center', width: '30%' }}>{invoice.balance} TL</div>
                                                        </div>
                                                }
                                                return item;
                                            })
                                        }


                                        <div className="kutuItem" style={{ padding: 0, borderBottom: '1px solid #000', width: '80%', marginLeft: '20%' }} />
                                        <div className="kutuItem" style={{ fontSize: '16px' }}>
                                            <div className="kutuIci" style={{ width: '70%' }}>
                                                <b>Toplam Ödeme Tutarı:</b>
                                            </div>
                                            <div className="kutuIci" style={{ textAlign: 'center', width: '30%' }}>{toplamTutar} TL</div>
                                        </div>
                                    </div>
                                    <div className="col-6" style={{ marginTop: '20px' }}>
                                        <div className="kutuItem">
                                            <div className="kutuIci">{this.props.periodInvoicesPdfResult.period.periodNote}</div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ marginTop: '35px' }}>
                                        {
                                            this.props.periodInvoicesPdfResult.periodInvoices.map((invoice, i) => {
                                                let item = null;

                                                if (invoice.subscription.type._id.toString() === "5d0a312392706a151bc178df") {
                                                    item =
                                                        <div key={i} className="row">
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binaya Gelen Doğalgaz Faturası:</div>
                                                                    <div className="kutuIci">{numeral(invoice.balance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binanın Toplam m²:</div>
                                                                    <div className="kutuIci">{numeral(invoice.subscription.totalMainCounterSquareMeters).format('0[.]00')} m²</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binanın Toplam Tüketimi:</div>
                                                                    <div className="kutuIci">{numeral(invoice.subscription.totalMainCounterPeriodConsumption).format('0[.]00') + " " + invoice.subscription.type.unit}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Doğalgaz Faturasının Isınma Gideri:</div>
                                                                    <div className="kutuIci">{numeral(invoice.subscription.sharingBalance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Doğalgaz Faturasının %{invoice.heatLossRate} Isı Kayıp Gideri:</div>
                                                                    <div className="kutuIci">{numeral(invoice.totalHeatLossBalance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Doğalgaz Faturasının %{100 - invoice.heatLossRate} Isı Tüketim Gideri:</div>
                                                                    <div className="kutuIci">{numeral(invoice.totalConsumptionBalance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                        </div>;
                                                }

                                                else if (invoice.subscription.type._id.toString() === "5d0a312392706a151bc178e1") { // abonelik elektrik ise
                                                    item =
                                                        <div key={i} className="row">
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binaya Gelen Elektrik Faturası:</div>
                                                                    <div className="kutuIci">{numeral(invoice.balance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binanın Toplam m²:</div>
                                                                    <div className="kutuIci">{numeral(invoice.subscription.totalMainCounterSquareMeters).format('0[.]00')} m²</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binanın Toplam Tüketimi:</div>
                                                                    <div className="kutuIci">{numeral(invoice.subscription.totalMainCounterPeriodConsumption).format('0[.]00') + " " + invoice.subscription.type.unit}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Elektrik Faturasının Soğutma Gideri:</div>
                                                                    <div className="kutuIci">{numeral(invoice.sharingBalance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Elektrik Faturasının %{invoice.heatLossRate} Soğutma Kayıp Gideri:</div>
                                                                    <div className="kutuIci">{numeral(invoice.totalHeatLossBalance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Elektrik Faturasının %{100 - invoice.heatLossRate} Soğutma Tüketim Gideri:</div>
                                                                    <div className="kutuIci">{numeral(invoice.totalConsumptionBalance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                        </div>;
                                                }
                                                else if (invoice.subscription.type._id.toString() === "5d0a312392706a151bc178e0") { // abonelik tipi su ise 
                                                    item =
                                                        <div key={i} className="row">
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Binaya Gelen Su Faturası:</div>
                                                                    <div className="kutuIci">{numeral(invoice.balance).format('0[.]00')} TL</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="kutuItem">
                                                                    <div className="kutuIci">Su Sayaçları Üzerinden Alınan Toplam Tüketim:</div>
                                                                    <div className="kutuIci">{invoice.subscription.totalMainCounterPeriodConsumption + invoice.subscription.totalHotMainCounterPeriodConsumption + " " + invoice.subscription.type.unit} </div>
                                                                </div>
                                                            </div>
                                                        </div>;
                                                }
                                                return item;
                                            })
                                        }
                                    </div>
                                    <div className="col-12" style={{ marginTop: '35px' }}>
                                        <div className="row" >
                                            {
                                                this.props.periodInvoicesPdfResult.period.paymentPlace ? <div className="col-12">
                                                    <div className="kutuItem">
                                                        <b>Ödeme Yeri: {this.props.periodInvoicesPdfResult.period.paymentPlace}</b>
                                                    </div>
                                                </div> : ""
                                            }
                                            {
                                                this.props.periodInvoicesPdfResult.period.accountName ? <div className="col-12">
                                                    <div className="kutuItem">
                                                        <b>Hesap İsmi: {this.props.periodInvoicesPdfResult.period.accountName}</b>
                                                    </div>
                                                </div> : ""
                                            }
                                            {
                                                this.props.periodInvoicesPdfResult.period.ibanNumber ? <div className="col-12">
                                                    <div className="kutuItem">
                                                        <b>IBAN No: {this.props.periodInvoicesPdfResult.period.ibanNumber}</b>
                                                    </div>
                                                </div> : ""
                                            }
                                        </div>
                                    </div>
                                </div >
                            </div >
                            <div className="son" style={{ pageBreakAfter: 'always' }} />
                        </div >;
                    })
                }
            </div >
        );
    }
}

const mapStateToProps = ({ personReducer, apartmentReducer }) => {
    return {
        ...personReducer,
        ...apartmentReducer,
    }
}

const mapDispatchToProps = {
    getApartmentPeriodInvoicesPdf
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(PdfInvoicesList));
