import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Switch, Button } from '@material-ui/core';
import moment from 'moment';
import "../../styles/app.css";

class PeriodsTable extends Component {

    state = {
        menuStatus: false,
        anchorEl: null,
    }

    render() {
        const tableData = {
            title: "Mevcut Dönem Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                // this.props.person.permissions.period.update ? "" : {}
                {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <div style={{ width: "100%" }}>
                                <details>
                                    <summary className="dropdownLink">
                                        İşlem Menüsü
                                    </summary>
                                    <div className="dropdownWrapper">

                                        {
                                            !rowData.status ? <span className="dropdownItem">
                                                <span className="dropdownItem">
                                                    <Button style={{ background: "#56b191", marginTop: "10px" }} onClick={() => this.props.getInvoiceExport(rowData)}>Excel Fatura Çıktısı</Button>
                                                </span>
                                                <span className="dropdownItem">
                                                    <Button style={{ background: "#56b191", marginTop: "10px" }} onClick={() => this.props.getInvoicePdf(rowData)}>Pdf Fatura Çıktısı</Button>
                                                </span>
                                            </span> : ""
                                        }
                                        {
                                            this.props.person.permissions.period.update ? <span>
                                                <span className="dropdownItem">
                                                    <Button style={{ marginTop: "10px" }} onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                                                    {
                                                        this.props.person.permissions.period.delete ? this.props.lastClosedPeriod ? this.props.lastClosedPeriod._id === rowData._id ? <span className="dropdownItem">
                                                            <Button style={{ background: "#da4040", color: "#fff", marginTop: "10px" }} onClick={() => this.props.delete(rowData)}>Dönemi Sil</Button>
                                                        </span> : "" : "" : ""
                                                    }
                                                </span>
                                                {
                                                    rowData.status ? <span className="dropdownItem">
                                                        <Button style={{ background: "#56b191", marginTop: "10px" }} onClick={() => this.props.closePeriod(rowData)}>Dönemi Kapat</Button>
                                                    </span> : ""
                                                }
                                                {
                                                    !rowData.status ? <span className="dropdownItem">
                                                        <span className="dropdownItem">
                                                            <Button style={{ marginTop: "10px" }} onClick={() => this.props.activePeriod(rowData)}>Dönemi Aktif Et</Button>
                                                        </span>
                                                    </span> : ""
                                                }
                                            </span> : ""
                                        }


                                    </div>
                                </details>
                            </div>
                        )
                    },
                },
                {
                    title: "Adı", field: "name",
                    render: rowData => {
                        return (
                            <a href={"/period/" + rowData._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.name}</a>
                        );
                    }
                },
                {
                    title: "Dönem Durumu", field: "status",
                    render: rowData => {
                        return (
                            <Switch checked={rowData.status} aria-label="Collapse" />
                        );
                    }
                },
                {
                    title: "Dönem Tarihi", field: "periodDate",
                    render: rowData => {
                        return (
                            moment(rowData.periodDate).format('DD-MM-YYYY')
                        );
                    }
                },
                {
                    title: "Dönemin Kapanma Tarihi", field: "closedDate",
                    render: rowData => {
                        return (
                            rowData.closedDate ? moment(rowData.closedDate).format('DD-MM-YYYY') : ''
                        );
                    }
                },
                {
                    title: "Okuma Ücreti", field: "readingBalance", hidden: true,
                    render: rowData => {
                        return (
                            rowData.readingBalance
                        );
                    }
                },
                {
                    title: "Faturada Görünen Okuma Ücreti", field: "invoiceBalance", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoiceBalance
                        );
                    }
                },
                {
                    title: "Dönem Notu", field: "periodNote", hidden: true,
                    render: rowData => {
                        return (
                            rowData.periodNote
                        );
                    }
                },
                {
                    title: "Son Ödeme Tarihi", field: "lastPaymentDate", hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastPaymentDate
                        );
                    }
                },
                {
                    title: "Ödeme Yeri", field: "paymentPlace", hidden: true,
                    render: rowData => {
                        return (
                            rowData.paymentPlace
                        );
                    }
                },
                {
                    title: "İban Numarası", field: "ibanNumber", hidden: true,
                    render: rowData => {
                        return (
                            rowData.ibanNumber
                        );
                    }
                },
                {
                    title: "Hesap İsmi", field: "accountName", hidden: true,
                    render: rowData => {
                        return (
                            rowData.accountName
                        );
                    }
                },
            ],
            data: this.props.periods
        };

        let tableActions = [];
        // if (this.props.person.permissions.period.delete) {
        //     tableActions.push({
        //         icon: 'delete',
        //         tooltip: 'Sil',
        //         onClick: this.props.delete,
        //         iconProps: {
        //             style: {
        //                 fontSize: 40,
        //                 color: 'red'
        //             }
        //         }
        //     });
        // }

        if (this.props.lastActivePeriod === null) {
            if (this.props.person.permissions.period.create) {
                tableActions.push(
                    { // tekli ekle
                        icon: 'plus_one',
                        isFreeAction: true,
                        tooltip: 'Yeni Kayıt Ekle',
                        onClick: this.props.addForm,
                        iconProps: {
                            style: {
                                fontSize: 40,
                                color: 'green'
                            }
                        }
                    }
                );
            }
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}

export default PeriodsTable;