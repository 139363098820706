import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
import { FormControl, Button, InputLabel, Input, FormLabel, Grid, } from "@material-ui/core";
import SearchSelect from 'react-select';
import '../../styles/style.css';

class StockCards extends Component {

    state = {

    }

    async UNSAFE_componentWillMount() {
        this.props.setActivePageTitle("GÖRÜŞME NOTLARI");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Görüşme Notları" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    render() {

        return (
            <div>

                <Grid container>
                    <Grid item xs={12} sm={9} md={6} lg={3} xl={3}  >
                        <FormControl required fullWidth>
                            <FormLabel >PROJELER</FormLabel>
                            <br />
                            <SearchSelect
                                value={this.state.reseller}
                            // onChange={(project) => { this.setState({ project }) }}
                            // isClearable={true}
                            // options={
                            //     this.props.projects.map((project) => {
                            //         return { value: project._id, label: project.title }
                            //     })
                            // }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <br></br>

                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Grid container >

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Grid container>
                                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
                                                <div className="container">
                                                    <p style={{ display: "flex", lineHeight: "20px" }}>Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello.
                                                    How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today?</p>
                                                    <span className="time-right">11:00</span>

                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>


                                                    <span style={{
                                                        width: "100%",
                                                        textAlign: "right",
                                                        marginTop: "3px",
                                                        paddingTop: "8px",
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}>Kadir Saymadi</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Grid container>
                                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
                                                <div className="container darker">
                                                    <p style={{ display: "flex", lineHeight: "20px" }}>Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello.
                                                    How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today? Hello. How are you today?</p>
                                                    <span className="time-right">11:00</span>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <div className="noteFile">
                                                        <span style={{
                                                            width: "100%",
                                                            marginTop: "3px",
                                                            paddingTop: "8px",
                                                            color: "blue",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            fontSize: "13px",
                                                        }}>Akasya-sozlesme-dosyasi-1-donem.rar</span>
                                                    </div>
                                                    <span style={{
                                                        width: "100%",
                                                        textAlign: "right",
                                                        marginTop: "3px",
                                                        paddingTop: "8px",
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}>Kadir Saymadi</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        <br></br>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="">Mesajınızı Giriniz: </InputLabel>
                                        <Input
                                            multiline
                                            rows={4}
                                            id=""
                                            name=""
                                            type="text"

                                        />
                                    </FormControl>

                                </div>
                            </Grid>
                            <br></br>
                            <br></br>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="">Eklemek İstediğiniz Excel Dosyası</InputLabel>
                                        <br />
                                        <br />
                                        <br />
                                        <input
                                            required
                                            type="file"
                                            onChange={this.onChangeHandlerFiles}
                                        />
                                    </FormControl>

                                </div>
                            </Grid>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <div>
                                    <Button type="submit" fullWidth variant="contained" color="primary"  >
                                        GÖNDER
                                      </Button>

                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </div >
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer }) => {
    return {
        ...personReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb

}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(StockCards));