import React, { Component } from 'react';
import Table from '../Items/Table';
import moment from 'moment';
import { Button } from '@material-ui/core';
class AddressesTable extends Component {
    render() {

        const tableData = {
            title: "Adres Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.reseller.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Ad", field: "name",
                    render: rowData => {
                        return (
                            rowData.name
                        );
                    }
                },
                {
                    title: "Adres", field: "address",
                    render: rowData => {
                        return (
                            rowData.address
                        );
                    }
                },
                {
                    title: "Oluşturulma Tarihi", field: "createdAt", hidden: true,
                    render: rowData => {
                        return (
                            moment(rowData.createdAt).format("DD-MM-YYYY - HH:SS")
                        );
                    }
                },
            ],
            data: this.props.addresses
        };

        let tableActions = [];
        if (this.props.person.permissions.reseller.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.reseller.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}

export default AddressesTable;