import React, { Component } from 'react'
import { FormControlLabel, Switch, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class AddColdWaterForm extends Component {

    state = {
        blocks: this.props.mainCounter.distributionBlocks.map((item) => { return item._id }),
        mainCounter: this.props.mainCounter._id
    };

    handleBlocks = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const blocks = state.blocks.concat(name.toString());
                return {
                    blocks
                };
            });
        }
        else {
            this.setState(state => {
                const blocks = state.blocks.filter(item => item.toString() !== name.toString());
                return {
                    blocks,
                };
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                {
                    this.props.mainCounter ? this.props.mainCounter.distributionBlocks.map((block) => {
                        return (
                            <FormControlLabel key={block._id}
                                control={
                                    <Switch checked={this.state.blocks.includes(block._id)} onChange={this.handleBlocks(block._id)} />
                                }
                                label={block.name}
                            />
                        )
                    }) : ""
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    daire sayaçlarını oluştur
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(AddColdWaterForm);
