import React from "react";
import PropTypes from "prop-types";
// material components
import { Avatar, Button, CssBaseline, FormControl, Input, InputLabel, Paper, } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';
class LoginForm extends React.Component {

    state = {
        email: '',
        password: '',
        errors: {},
        messageKey: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar style={{ margin: "8px", width: "auto", height: "auto", borderRadius: "0px", backgroundColor: "#fff" }} className={classes.avatar}>
                        <img alt="tebaş online" style={{ width: "100%" }} src="/logo_png.png" />
                    </Avatar>
                    <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmit(this.state) }}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Adresiniz</InputLabel>
                            <Input
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                type="email"
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Şifreniz</InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                onChange={this.handleChange}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.props.login}
                        >
                            Sisteme Giriş
                    </Button>
                    </form>
                </Paper>
            </main >
        );
    }
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm);
