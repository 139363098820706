import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// redux altındaki createStoru sayfaya dahil et 
import { createStore, applyMiddleware } from 'redux';
// redux middleware'ları dahil et store'a bağla
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
// dom üzerinde redux'u takip edebilmek için extension'ı sayfaya dahil et
import { composeWithDevTools } from 'redux-devtools-extension';
// tüm reducer'ların tek bir yerde toplandığı rootReducer'ı sayfaya dahil et
import rootReducer from './reducers/rootReducer';
// provider'ı sayfaya dahil et  ve tum uygulamada storu kullanabilmek için app'i sarmala
import { Provider } from 'react-redux';
// roouter'ı sayfaya dahil et ve projenin her yerinde kullanmak için uygulamayı sarmala
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// storu oluştur
const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(promise, thunk)
    )
);

ReactDOM.render(<Router>
    <Provider store={store}>
        <SnackbarProvider
            maxSnack={10}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <App />
        </SnackbarProvider>
    </Provider>
</Router>, document.getElementById('root'));