import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ModalDialog extends React.Component {
  state = {
    fullWidth: true,
    maxWidth: 'xl',
  };

  render() {
    return (
      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
        style={this.props.style}
      >
        <DialogTitle id="max-width-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          {this.props.contextText !== '' ? <DialogContentText>
            {this.props.contextText}
          </DialogContentText> : null}
          {this.props.children}
        </DialogContent>
        <DialogActions>
          {this.props.buttons ? this.props.buttons.map((element) => {
            return (
              element
            )
          }) : null}
        </DialogActions>
      </Dialog>
    );
  }
}

ModalDialog.defaultProps = {
  title: 'Boş Geçilemez',
  contextText: '',
  open: false
}

ModalDialog.propTypes = {
  title: PropTypes.string.isRequired,
  contextText: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.element).isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired
};

export default ModalDialog;