import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_PROJECT_PROBLEMS_PENDING = "GET_PROJECT_PROBLEMS_PENDING";
export const GET_PROJECT_PROBLEMS_FULFILLED = "GET_PROJECT_PROBLEMS_FULFILLED";
export const GET_PROJECT_PROBLEMS_REJECTED = "GET_PROJECT_PROBLEMS_REJECTED";

export const ADD_PROJECT_PROBLEM_PENDING = "ADD_PROJECT_PROBLEM_PENDING";
export const ADD_PROJECT_PROBLEM_FULFILLED = "ADD_PROJECT_PROBLEM_FULFILLED";
export const ADD_PROJECT_PROBLEM_REJECTED = "ADD_PROJECT_PROBLEM_REJECTED";

export const UPDATE_PROJECT_PROBLEM_PENDING = "UPDATE_PROJECT_PROBLEM_PENDING";
export const UPDATE_PROJECT_PROBLEM_FULFILLED = "UPDATE_PROJECT_PROBLEM_FULFILLED";
export const UPDATE_PROJECT_PROBLEM_REJECTED = "UPDATE_PROJECT_PROBLEM_REJECTED";


export function getProjectProblems(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_PROBLEMS",
            payload: axios.get(`${API_URL}/project-problems/all/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addProjectProblem(problem, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_PROJECT_PROBLEM",
            payload: axios.post(`${API_URL}/project-problems/`, problem, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updateProjectProblem(problem, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_PROJECT_PROBLEM",
            payload: axios.put(`${API_URL}/project-problems/`, problem, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

