import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_PROPOSAL_PENDING = "ADD_PROPOSAL_PENDING";
export const ADD_PROPOSAL_FULFILLED = "ADD_PROPOSAL_FULFILLED";
export const ADD_PROPOSAL_REJECTED = "ADD_PROPOSAL_REJECTED";

export const GET_ALL_PROPOSAL_PENDING = "GET_ALL_PROPOSAL_PENDING";
export const GET_ALL_PROPOSAL_FULFILLED = "GET_ALL_PROPOSAL_FULFILLED";
export const GET_ALL_PROPOSAL_REJECTED = "GET_ALL_PROPOSAL_REJECTED";

export const DELETE_PROPOSAL_PENDING = "DELETE_PROPOSAL_PENDING";
export const DELETE_PROPOSAL_FULFILLED = "DELETE_PROPOSAL_FULFILLED";
export const DELETE_PROPOSAL_REJECTED = "DELETE_PROPOSAL_REJECTED";


export function addProposal(proposal, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_PROPOSAL",
            payload: axios.post(`${API_URL}/proposal`, proposal, { headers: { "x-access-token": token } })
                .then(result => result.data)

        })
    }
}

export function getAllProposals(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_PROPOSAL",
            payload: axios.get(`${API_URL}/proposal/all`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}


export function deleteProposal(proposal, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_PROPOSAL",
            payload: axios.delete(`${API_URL}/proposal`, { data: { ...proposal, token: token } })
                .then(result => result.data)
        })
    }
}