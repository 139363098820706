import React, { Component } from 'react';
import queryString from 'query-string';
import classNames from 'classnames';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import swal from 'sweetalert';
import { Button, Divider, Grid, Typography, AppBar, Switch, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Card, CardContent } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ModalDialog from '../Items/ModalDialog';
import styles from '../../styles/app';
import { getCollectiveBlockExcelList, getCollectiveApartmentCounterExcelList, getPeriodInvoicesExcelList, getCollectiveApartmentExcelList } from '../../functions/fileDownload';
import { setActivePageTitle, setBreadCrumb, setFastOperation } from '../../actions/person';
import { getProject, updateProject } from '../../actions/project';
import { getAllSharingTypes } from '../../actions/sharingType';
import { getAllBlocks, addBlock, editBlock, deleteBlock, addCollectiveBlock, uploadCollectiveBlockExcel } from '../../actions/block';

import { getAllSubscriptionTypes } from '../../actions/subscriptionType';
import { getAllTypeSubscriptions, addSubscription, getAllSubscriptions, editSubscription, deleteSubscription } from '../../actions/subscription';
import { getProjectMainCounters, deleteMainCounter, updateMainCounter } from '../../actions/mainCounter';
import { uploadCollectiveApartmentCountersExcel, getProjectApartmentCounters, updateApartmentCounter, deleteApartmentCounter, changeApartmentCounter } from '../../actions/apartmentCounter';
import { uploadCollectiveApartmentExcel } from '../../actions/apartment';
import { addPeriod, getLastClosedPeriod, getLastActivePeriod, getProjectPeriods, closePeriod, activePeriod, updatePeriod, deletePeriod } from '../../actions/period';
import { getPeriodAdditionalExpenseDatas, addAdditionalExpenseData, deleteAdditionalExpenseData } from '../../actions/additionalExpenseDatas';
import { getProjectProblems, addProjectProblem, updateProjectProblem } from '../../actions/projectProblems';
import AddBlockForm from '../Blocks/AddBlockForm';
import AddCollectiveBlockForm from '../Blocks/AddCollectiveBlockForm';
import EditBlockForm from '../Blocks/EditBlockForm';
import UploadCollectiveBlockExcelForm from '../Blocks/UploadCollectiveBlockExcelForm';
import BlocksTable from '../Blocks/BlocksTable';

import MainCountersTable from '../MainCounters/MainCountersTable';
import AddMainCounterStepForm from '../MainCounters/AddMainCounterStepForm';
import SubscriptionsTable from '../Subscriptions/SubscriptionsTable';
import AddSubscriptionForm from '../Subscriptions/AddSubscriptionForm';
import EditSubscriptionForm from '../Subscriptions/EditSubscriptionForm';
import CreateApartmentCountersForm from '../ApartmentCounters/CreateApartmentCountersForm';
import ApartmentCountersExcelUploadForm from '../ApartmentCounters/ApartmentCountersExcelUploadForm';
import EditMainCounterForm from '../MainCounters/EditMainCounterForm';
import EditHotWaterMainCounter from '../MainCounters/EditHotWaterMainCounter';
import UpdateSharingSettingsForm from './UpdateSharingSettingsForm';
import UpdateAnalysisSettingsForm from './UpdateAnalysisSettingsForm';
import GetApartmentsExcelList from '../Apartments/GetApartmentsExcelList'
import UploadCollectiveApartmentExcelForm from '../Apartments/UploadCollectiveApartmentExcelForm'
import PeriodsTable from '../Periods/PeriodsTable';
import AddPeriodForm from '../Periods/AddPeriodForm';
import EditPeriodForm from '../Periods/EditPeriodForm';
import ApartmentCountersTable from '../ApartmentCounters/ApartmentCountersTable';
import EditApartmentCounterForm from '../ApartmentCounters/EditApartmentCounterForm';
import ChangeApartmentCounterForm from '../ApartmentCounters/ChangeApartmentCounterForm';
import FastOperation from '../Items/FastOperation';
import AdditonalExpenseDatasTable from '../AdditionalExpensesData/AdditonalExpenseDatasTable';
import AdditionalExpenseForm from '../AdditionalExpensesData/AdditionalExpenseForm';
import GetInvoicesForm from '../Apartments/Invoices/GetInvoicesForm';
import ProjectProblemsTable from './Problems/ProjectProblemsTable';
import AddProblemForm from './Problems/AddProblemForm';
import ResollveProblemForm from './Problems/ResollveProblemForm';
import ProjectPersonsTable from './ProjecetPersonsTable';

class ProjectDetail extends Component {

    // component içerisinde kullanılacak state
    state = {
        addSubscriptionOpenDialog: false,
        editSubscriptionOpenDialog: false,
        editBlockOpenDialog: false,
        addBlockOpenDialog: false,
        addCounterOpenDialog: false,
        addCollectiveBlockOpenDialog: false,
        uploadCollectiveBlockExcelOpenDialog: false,
        createApartmentCounterExcelOpenDialog: false,
        apartmentCounterExcelUploadOpenDialog: false,
        editMainCounterOpenDialog: false,
        sharingSettingsOpenDialog: false,
        analysisSettingsOpenDialog: false,
        addPeriodOpenDialog: false,
        getApartmentListDialog: false,
        uploadCollectiveApartmentExcelOpenDialog: false,
        editPeriodOpenDialog: false,
        editCounterOpenDailog: false,
        getInvoicesFilesOpenDialog: false,
        getInvoicesPdfOpenDialog: false,
        addProjectProblemOpenDialog: false,
        showDescriptionOpenDailog: false,
        reslveProblemOpenDialog: false,
        showSolverDescriptionOpenDailog: false,
        changeCounterOpenDailog: false,
        value: null,
        messageKey: '',
        rowData: {
            name: ''
        },
        problem: {
            description: ""
        },
        waterSubscriptions: [] // sadece sıcak su sayaçları için doluyor
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {

        // yetki kontrolü
        if (!this.props.person.permissions.project.read) {
            return window.location.href = "/";
        }
        const { match: { params } } = this.props;
        await this.props.getProject(params._id, this.props.token);
        this.props.getAllSharingTypes(this.props.token);
        await this.props.setActivePageTitle(this.props.project.name + " - PROJESİ");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "link", path: "/projects", text: "Proje Yönetimi" },
            { type: "string", path: "/project/" + this.props.project._id, text: this.props.project.name },
        ];
        await this.props.setBreadCrumb(breadCrumb);
        await this.props.setFastOperation(<FastOperation project={this.props.project} />);
        let string = window.location.href.split("?");
        if (string.length > 1) {
            const query = queryString.parse(string[1]);
            this.handleChangeTab(parseFloat(query.tab))
        }

    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ changeCounterOpenDailog: false, showSolverDescriptionOpenDailog: false, reslveProblemOpenDialog: false, showDescriptionOpenDailog: false, addProjectProblemOpenDialog: false, getInvoicesPdfOpenDialog: false, getInvoicesFilesOpenDialog: false, addAdditionalExpenseDataOpenDialog: false, editCounterOpenDailog: false, editPeriodOpenDialog: false, uploadCollectiveApartmentExcelOpenDialog: false, getApartmentListDialog: false, analysisSettingsOpenDialog: false, addPeriodOpenDialog: false, sharingSettingsOpenDialog: false, apartmentCounterExcelUploadOpenDialog: false, editMainCounterOpenDialog: false, createApartmentCounterExcelOpenDialog: false, editSubscriptionOpenDialog: false, addSubscriptionOpenDialog: false, editBlockOpenDialog: false, addBlockOpenDialog: false, addCollectiveBlockOpenDialog: false, uploadCollectiveBlockExcelOpenDialog: false, addCounterOpenDialog: false });
    }

    handleAddBlock = () => {
        this.setState({ addBlockOpenDialog: true });
    }

    handleSharingSettings = () => {
        this.setState({ sharingSettingsOpenDialog: true });
    }

    handleUploadApartmentCounterExcelList = () => {
        this.setState({ apartmentCounterExcelUploadOpenDialog: true });
    }

    handleGetCreateApartmentCounterExcel = async (rowData) => {
        this.setState({ createApartmentCounterExcelOpenDialog: true, rowData: Object.assign({}, rowData) });
    }

    handleAddCounter = () => {
        this.setState({ addCounterOpenDialog: true });
    }

    handleAddSubscription = () => {
        this.setState({ addSubscriptionOpenDialog: true });
    }

    handleAddCollectiveBlock = () => {
        this.setState({ addCollectiveBlockOpenDialog: true });
    }

    handleEditBlock = async (rowData) => {
        this.setState({ editBlockOpenDialog: true, rowData: Object.assign({}, rowData) });
    }

    handleEditMainCounter = async (mainCounter) => {

        if (mainCounter.type._id === "5cefd0dd45844c0a69b509d0") {
            await this.props.getAllTypeSubscriptions(this.props.project._id, mainCounter.type.typeWater, this.props.token);
            this.setState({ waterSubscriptions: this.props.subscriptions });
        }

        await this.props.getAllTypeSubscriptions(this.props.project._id, mainCounter.type.type._id, this.props.token);
        this.setState({ mainCounter: Object.assign({}, mainCounter) });
        this.setState({ editMainCounterOpenDialog: true });
    }

    handleEditSubscription = async (rowData) => {
        this.setState({ editSubscriptionOpenDialog: true, rowData: Object.assign({}, rowData) });
    }

    handleUploadCollectiveBlockExcel = () => {
        this.setState({ uploadCollectiveBlockExcelOpenDialog: true });
    }

    handleAddBlockSubmit = async (e, state) => {
        const block = {
            name: e.target.name.value,
            floorStart: e.target.floorStart.value,
            floorEnd: e.target.floorEnd.value,
            project: e.target.project.value,
            apartmentCount: e.target.apartmentCount.value,
        };

        return swal({
            title: "Emin misin?",
            text: "Bu projeye yeni bir blok ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addBlock(block, this.props.token);
                    if (this.props.addBlockResult.code === 200) {
                        this.setState({ addBlockOpenDialog: false, value: 0 });
                        await this.props.getAllBlocks(this.props.project._id, this.props.token);
                        await this.props.getProject(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar('Blok Başarıyla Eklendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Blok eklenirken bir hata oluştu..' + this.props.addBlockResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleGetCollectiveBlockExcelList = (project) => {
        getCollectiveBlockExcelList(project, this.props.token);
    }

    handleAddCollectiveBlockSubmit = async (e, state) => {

        const block = {
            project: e.target.project.value,
            count: e.target.count.value,
        };

        return swal({
            title: "Emin misin?",
            text: "Bu projeye " + block.count + " adet blok ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addCollectiveBlock(block, this.props.token);
                    if (this.props.addCollectiveBlockResult.code === 200) {
                        this.setState({ addCollectiveBlockOpenDialog: false, value: 0 });
                        await this.props.getAllBlocks(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar(this.props.addCollectiveBlockResult.message, {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Blok eklenirken bir hata oluştu..' + this.props.addCollectiveBlockResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }

                    await this.props.getProject(this.props.project._id, this.props.token);
                }
            });

    }

    handleUpdateCollectiveBlockListSubmit = async (e, state) => {
        const formData = new FormData()
        if (state.blockListExcel.length > 0) {
            for (var i = 0; i < state.blockListExcel.length; i++) {
                formData.append('blockListExcel', state.blockListExcel[i])
            }
        }
        return swal({
            title: "Emin misin?",
            text: "Blok bilgilerini toplu şekilde güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.uploadCollectiveBlockExcel(formData, this.props.token);
                    if (this.props.uploadCollectiveBlockExcelResult.code === 200) {
                        this.setState({ uploadCollectiveBlockExcelOpenDialog: false, value: 0 });
                        await this.props.getAllBlocks(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar(this.props.uploadCollectiveBlockExcelResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar(this.props.uploadCollectiveBlockExcelResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }

                await this.props.getProject(this.props.project._id, this.props.token);
            });
    }

    handleEditBlockSubmit = async (e, state) => {

        const block = {
            _id: e.target._id.value,
            name: e.target.name.value,
            floorStart: e.target.floorStart.value,
            floorEnd: e.target.floorEnd.value,
            apartmentCount: e.target.apartmentCount.value,
        };

        return swal({
            title: "Emin misin?",
            text: "Bu projeye yeni bir blok ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.editBlock(block, this.props.token);
                    if (this.props.editBlockResult.code === 200) {
                        this.setState({ editBlockOpenDialog: false, value: 0 });
                        await this.props.getAllBlocks(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar('Blok başarıyla güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Blok eklenirken bir hata oluştu..' + this.props.editBlockResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                    await this.props.getProject(this.props.project._id, this.props.token);
                }
            });

    }

    handleAddSubscriptionSubmit = async (e, state) => {

        const subscription = {
            name: e.target.name.value,
            subscriberNumber: e.target.subscriberNumber.value,
            type: state.subscriptionType.value,
            project: e.target.project.value,
            sharingBlocks: state.sharingBlocks
        };

        return swal({
            title: "Emin misin?",
            text: "Bu projeye yeni bir abonelik ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addSubscription(subscription, this.props.token);
                    if (this.props.addSubscriptionResult.code === 200) {
                        this.setState({ addSubscriptionOpenDialog: false, value: 1 });
                        await this.props.getAllSubscriptions(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar('Abonelik başarıyla eklendi..', {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar('Abonelik eklenirken bir hata oluştu..' + this.props.addSubscriptionResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleEditSubscriptionSubmit = async (e, state) => {

        const subscription = {
            _id: state._id,
            name: e.target.name.value,
            subscriberNumber: e.target.subscriberNumber.value,
            type: state.subscriptionType.value,
            sharingBlocks: state.sharingBlocks
        };

        return swal({
            title: "Emin misin?",
            text: "Mevcut olan aboneliği güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.editSubscription(subscription, this.props.token);
                    if (this.props.editSubscriptionResult.code === 200) {
                        this.setState({ editSubscriptionOpenDialog: false, value: 1 });
                        await this.props.getAllSubscriptions(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar('Abonelik başarıyla güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar('Abonelik güncellenirken bir hata oluştu..' + this.props.editSubscriptionResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleDeleteSubscription = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (subscription) => {
                        await this.props.deleteSubscription(subscription, this.props.token);
                        if (this.props.deleteSubscriptionResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteSubscriptionResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllSubscriptions(this.props.project._id, this.props.token);
                }
            });
    }

    handleDeleteBlock = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (block) => {
                        await this.props.deleteBlock(block, this.props.token);
                        if (this.props.deleteBlockResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteBlockResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });

                    await this.props.getAllBlocks(this.props.project._id, this.props.token);

                }
            });
    }

    handleChangeTab = async (value) => {
        this.setState({ value });
        if (value === 0) { // eğer ki block sekmesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Bloklar" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllBlocks(this.props.project._id, this.props.token);
        }
        else if (value === 1) { // eğer ki abonelikler sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Abonelikler" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllSubscriptionTypes(this.props.token);
            await this.props.getAllSubscriptions(this.props.project._id, this.props.token);

        }
        else if (value === 2) { // eğer ki ana sayaç sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Ana Sayaçlar" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getProjectMainCounters(this.props.project._id, this.props.token);
        }
        else if (value === 3) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Tüm Daire Sayaçları" },
            ];
            this.props.setBreadCrumb(breadCrumb);
            await this.props.getProjectApartmentCounters(this.props.project._id, this.props.token);
        }
        else if (value === 4) { // eğer ki ana sayaç sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Dönem Yönetimi" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getProjectPeriods(this.props.project._id, this.props.token);
            await this.props.getLastActivePeriod(this.props.project._id, this.props.token);
            await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
        }
        else if (value === 5) { // eğer ki ana sayaç sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Ek Gider Yönetimi" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getPeriodAdditionalExpenseDatas(this.props.project._id, this.props.token);
        }
        else if (value === 6) { // eğer ki ana sayaç sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Proje Sorunları" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getProjectProblems(this.props.project._id, this.props.token);
        }
        else if (value === 7) { // eğer ki ana sayaç sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Sözleşme Dosyaları" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
        }
        else if (value === 8) {
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Proje İle İlişkili Personeller" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
        }

    };

    handleDeleteMainCounter = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (mainCounter) => {
                        await this.props.deleteMainCounter(mainCounter, this.props.token);
                        if (this.props.deleteMainCounterResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteMainCounterResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });

                    await this.props.getProjectMainCounters(this.props.project._id, this.props.token);
                }
            });
    }

    handleUpdateCollectiveApartmentCounterListSubmit = async (e, state) => {
        const formData = new FormData();
        if (state.apartmentCountersListExcel.length > 0) {
            for (var i = 0; i < state.apartmentCountersListExcel.length; i++) {
                formData.append('apartmentCountersListExcel', state.apartmentCountersListExcel[i])
            }
        }
        return swal({
            title: "Emin misin?",
            text: "Daire sayaçlarını toplu bir şekilde ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.uploadCollectiveApartmentCountersExcel(formData, this.props.token);
                    if (this.props.uploadApartmentCounterExcelListResult.code === 200) {
                        this.setState({ apartmentCounterExcelUploadOpenDialog: false });
                        await this.props.getProjectMainCounters(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar(this.props.uploadApartmentCounterExcelListResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar(this.props.uploadApartmentCounterExcelListResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleEditMainCounterSubmit = async (e, state) => {

        if (e.target.name.value.length < 1) {
            this.props.enqueueSnackbar('Lütfen İsim Tanımlayın..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }

        if (state.subscription.value === "") {
            this.props.enqueueSnackbar('Lütfen Fatura Aboneliği Tanımlayın..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }

        const mainCounter = {
            name: e.target.name.value,
            _id: e.target._id.value,
            subscription: state.subscription.value,
            sharingStatus: state.sharingStatus,
            distributionBlocks: state.distributionBlocks,
            serialNumber: null,
            counterValue: null,
            heatingOnly: state.heatingOnly,
            filtrationCounterStatus: state.filtrationCounterStatus
        };

        // süzme sayaç varsa
        if (state.filtrationCounterStatus) {
            mainCounter.serialNumber = e.target.serialNumber.value;
            mainCounter.counterValue = e.target.counterValue.value;
        }

        if (state.type === "5cefd0dd45844c0a69b509d0" && !state.heatingOnly) {
            if (state.subscriptionWater.value === "") {
                this.props.enqueueSnackbar('Lütfen Soğuk Su Fatura Aboneliği Tanımlayın..', {
                    variant: "error",
                    persist: true,
                    action: (
                        <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                    ),
                });
                return;
            }
            mainCounter.subscriptionWater = state.subscriptionWater.value
        }

        await this.props.updateMainCounter(mainCounter, this.props.token);
        if (this.props.updateMainCounterResult.code === 200) {
            this.setState({ editMainCounterOpenDialog: false });
            await this.props.getProjectMainCounters(this.props.project._id, this.props.token);
            this.props.enqueueSnackbar(this.props.updateMainCounterResult.data.mainCounter.name + ' İsimli paylaşım başarıyla güncellendi..', {
                variant: "success",
                autoHideDuration: 3000,
            });
        } else {
            this.props.enqueueSnackbar('Paylaşım güncellenirken bir hata oluştu..' + this.props.updateMainCounterResult.message, {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
        }
    }

    handleSharingSettingsSubmit = async (e, state) => {

        let project = {
            _id: state._id,
        }

        if (state.heatCalorimeter) {
            project.gasSharingCriterion = state.gasSharingCriterion;
        }

        if (state.coldWater || state.hotWater) {
            project.coldWaterSharingCriterion = state.coldWaterSharingCriterion;
            if (state.coldWaterSharingCriterion === 2) {
                project.coldWaterSharingUnitPrice = e.target.coldWaterSharingUnitPrice.value;
            }
        }

        if (state.hotWater) {
            project.sharingPeriod = state.sharingPeriod;
            project.hotWaterSharingCriterion = state.hotWaterSharingCriterion;
            project.boilerTemperature = e.target.boilerTemperature.value;
            project.thermalCoefficient = e.target.thermalCoefficient.value;
        }

        return swal({
            title: "Emin misin?",
            text: "Proje paylaşım ayarlarını güncelliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateProject(project, this.props.token);
                    if (this.props.updateProjectResult.code === 200) {
                        this.setState({ sharingSettingsOpenDialog: false });
                        await this.props.getProject(project._id, this.props.token);
                        this.props.enqueueSnackbar("Proje paylaşım ayarları güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Proje paylaşım ayarları güncelleme işlemi başarısız. " + this.props.updateProjectResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleAddPeriod = async () => {
        // son eklenmiş dönemi çek
        await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
        this.setState({ addPeriodOpenDialog: true });

    }

    handleAddPeriodSubmit = async (e, state) => {

        let period = {
            project: e.target.project.value,
            periodDate: e.target.periodDate.value,
            lastClosedPeriodDate: e.target.lastClosedPeriodDate.value,
            lastPaymentDate: e.target.lastPaymentDate.value,
            paymentPlace: e.target.paymentPlace.value,
            ibanNumber: e.target.ibanNumber.value,
            accountName: e.target.accountName.value,
            spendingPeriod: e.target.spendingPeriod.value
        }

        if (this.props.person.personType === 0) {
            period.readingBalance = parseFloat(e.target.readingBalance.value);
            period.periodNote = e.target.periodNote.value;
            period.invoiceBalance = parseFloat(e.target.invoiceBalance.value);
        }

        return swal({
            title: "Emin misin?",
            text: "Projeye Yeni Dönem Ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addPeriod(period, this.props.token);
                    if (this.props.addPeriodResult.code === 200) {
                        this.setState({ addPeriodOpenDialog: false });

                        await this.props.getProjectPeriods(this.props.project._id, this.props.token);
                        await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar("Dönem ekleme başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Dönem ekleme işlemi işlemi başarısız. " + this.props.addPeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleAnalysisSettings = () => {
        this.setState({ analysisSettingsOpenDialog: true });
    }

    handleAnalysisSettingsSubmit = async (e, state) => {

        let project = {
            _id: state._id,
        }
        if (state.heatCalorimeter) {
            project.fifteenDegreeStatus = state.fifteenDegreeStatus;
            project.inputOutputTemperature = state.inputOutputTemperature;
            project.maximumConsumption = e.target.maximumConsumption.value;
            project.heatCalorimeterComparison = state.heatCalorimeterComparison;
        }
        if (state.coldWater) {
            project.pastPeriodCount = state.pastPeriodCount;
            project.waterConsumptionDeviationRate = state.waterConsumptionDeviationRate;
            project.waterComparison = state.waterComparison;

        }
        return swal({
            title: "Emin misin?",
            text: "Proje paylaşım ayarlarını güncelliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateProject(project, this.props.token);
                    if (this.props.updateProjectResult.code === 200) {
                        this.setState({ analysisSettingsOpenDialog: false });
                        await this.props.getProject(project._id, this.props.token);
                        this.props.enqueueSnackbar("Proje paylaşım ayarları güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Proje paylaşım ayarları güncelleme işlemi başarısız. " + this.props.updateProjectResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleGetApartmentList = () => {
        this.setState({ getApartmentListDialog: true });
    }

    handleGetCollectiveApartmentExcelList = (e, state) => {
        getCollectiveApartmentExcelList(state.blocks, this.props.token);
        this.setState({ getApartmentListDialog: false });
    }

    handleUploadCollectiveApartmentExcel = () => {
        this.setState({ uploadCollectiveApartmentExcelOpenDialog: true });
    }

    handleUpdateCollectiveApartmentListSubmit = async (e, state) => {
        const formData = new FormData();
        if (state.apartmentListExcel.length > 0) {
            for (var i = 0; i < state.apartmentListExcel.length; i++) {
                formData.append('apartmentListExcel', state.apartmentListExcel[i])
            }
        }
        return swal({
            title: "Emin misin?",
            text: "Daire bilgilerini toplu şekilde güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.uploadCollectiveApartmentExcel(formData, this.props.token);
                    if (this.props.uploadApartmentExcelListResult.code === 200) {
                        this.setState({ uploadCollectiveApartmentExcelOpenDialog: false, value: 0 });
                        await this.props.getAllBlocks(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar(this.props.uploadApartmentExcelListResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar(this.props.uploadApartmentExcelListResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleClosePeriod = async (period) => {
        return swal({
            title: "Emin misin?",
            text: "Dönemi kapatacaksın!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.closePeriod(period, this.props.token);
                    if (this.props.closePeriodResult.code === 200) {
                        await this.props.getProject(this.props.project._id, this.props.token);
                        await this.props.getProjectPeriods(this.props.project._id, this.props.token);
                        await this.props.getLastActivePeriod(this.props.project._id, this.props.token);
                        await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar("Dönem başarıyla kapatıldı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Dönem kapatılırken bir hata oluştu. " + this.props.closePeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleActivePeriod = async (period) => {
        return swal({
            title: "Emin misin?",
            text: "Dönemi aktif edeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.activePeriod(period, this.props.token);
                    if (this.props.activePeriodResult.code === 200) {
                        await this.props.getProject(this.props.project._id, this.props.token);
                        await this.props.getProjectPeriods(this.props.project._id, this.props.token);
                        await this.props.getLastActivePeriod(this.props.project._id, this.props.token);
                        await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar("Dönem başarıyla aktif edildi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Dönem kapatılırken bir hata oluştu. " + this.props.activePeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleUpdatePeriod = async (period) => {
        this.setState({ editPeriodOpenDialog: true, period: Object.assign({}, period) });
    }

    handleUpdatePeriodSubmit = async (e, state) => {

        let period = {
            _id: e.target._id.value,
            periodDate: e.target.periodDate.value,
            lastPaymentDate: e.target.lastPaymentDate.value,
            paymentPlace: e.target.paymentPlace.value,
            ibanNumber: e.target.ibanNumber.value,
            accountName: e.target.accountName.value,
            spendingPeriod: e.target.spendingPeriod.value,
        }

        if (this.props.person.personType === 0) {
            period.readingBalance = e.target.readingBalance.value;
            period.periodNote = e.target.periodNote.value;
            period.invoiceBalance = e.target.invoiceBalance.value;
        }

        return swal({
            title: "Emin misin?",
            text: "Mevcut dönemi güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updatePeriod(period, this.props.token);
                    if (this.props.updatePeriodResult.code === 200) {
                        this.setState({ editPeriodOpenDialog: false });
                        await this.props.getProjectPeriods(this.props.project._id, this.props.token);
                        await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar("Dönem güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Dönem güncelleme işlemi başarısız. " + this.props.updatePeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleDeletePeriod = (rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan dönemleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.deletePeriod(rowData, this.props.token);
                    if (this.props.deletePeriodResult.code === 200) {
                        await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deletePeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }

                    await this.props.getProject(this.props.project._id, this.props.token);
                    await this.props.getProjectPeriods(this.props.project._id, this.props.token);
                    await this.props.getLastActivePeriod(this.props.project._id, this.props.token);
                    await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
                }
            });
    }

    handleEditCounter = async (rowData) => {
        this.setState({ editCounterOpenDailog: true, apartmentCounter: Object.assign({}, rowData) });
    }

    handleUpdateApartmentCounterSubmit = async (e, state) => {

        const counter = {
            _id: e.target._id.value,
            name: e.target.name.value,
            serialNumber: e.target.serialNumber.value,
            status: state.status,
            shouldChangeStatus: state.shouldChangeStatus,
            description: e.target.description.value,
        };


        return swal({
            title: "Emin misin?",
            text: "Seçilen sayayacı güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateApartmentCounter(counter, this.props.token);
                    if (this.props.updateApartmentCounterResult.code === 200) {
                        this.setState({ editCounterOpenDailog: false, value: 3 });
                        await this.props.getProjectApartmentCounters(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar('Sayaç başarıyla güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Sayaç güncellenirken bir hata oluştu..' + this.props.updateApartmentCounterResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleDeleteApartmentCounter = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (apartmentCounter) => {
                        await this.props.deleteApartmentCounter(apartmentCounter, this.props.token);
                        if (this.props.deleteApartmentCounterResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteApartmentCounterResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });

                    await this.props.getProjectApartmentCounters(this.props.project._id, this.props.token);

                }
            });


    }

    handleAddAdditionalExpenseData = async () => {
        this.setState({ addAdditionalExpenseDataOpenDialog: true });
    }

    handleAddAdditionalExpenseDataSubmit = async (e, state) => {

        let data = {
            title: state.title,
            project: state.project,
            blocks: state.blocks,
            installmentStatus: state.installmentStatus,
            installmentCount: state.installmentCount,
            invoiceType: state.invoiceType,
            invoiceBalance: state.invoiceBalance,
            sharingType: state.sharingType,
            unitPrice: state.invoiceType === 0 ? state.unitPrice : 0,
            apartmentStatus: state.apartmentStatus,
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir ek gider ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addAdditionalExpenseData(data, this.props.token);
                    if (this.props.addAdditionalExpenseDataResult.code === 200) {
                        await this.props.enqueueSnackbar("Ek Gider Başarıyla Eklendi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                        this.setState({ value: 5, addAdditionalExpenseDataOpenDialog: false });

                    } else {
                        await this.props.enqueueSnackbar(this.props.addAdditionalExpenseDataResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                    await this.props.getPeriodAdditionalExpenseDatas(this.props.project._id, this.props.token);
                }
            });
    }

    handleDeleteAdditionalExpenseData = (event, rowDatas) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikler silinecek",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await Promise.all(rowDatas.map(async (data) => {
                        await this.props.deleteAdditionalExpenseData(data, this.props.token);
                        if (this.props.deleteAdditionalExpenseDataResult.code === 200) {
                            await this.props.enqueueSnackbar("İşlem Tamamlandı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            this.props.enqueueSnackbar("Veri güncellenirken hata oluştu. " + this.props.deleteAdditionalExpenseDataResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                        return "";
                    }));
                    this.setState({ value: 5 });
                    await this.props.getPeriodAdditionalExpenseDatas(this.props.project._id, this.props.token);
                }
            });
    }

    handleSendInvoiceList = async (tab) => {
        let activePeriod = {};

        await this.props.getLastActivePeriod(this.props.project._id, this.props.token);
        if (!this.props.lastActivePeriod) {
            await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
            let newPeriod = {
                project: this.props.project._id,
                periodDate: new Date(),
                readingBalance: parseFloat(this.props.project.readingPrice),
                invoiceBalance: parseFloat(this.props.project.invoiceBalance),
            };

            if (this.props.lastClosedPeriod) {
                newPeriod.lastClosedPeriodDate = this.props.lastClosedPeriod.periodDate;
                newPeriod.lastPaymentDate = this.props.lastClosedPeriod.lastPaymentDate;
                newPeriod.paymentPlace = this.props.lastClosedPeriod.paymentPlace;
                newPeriod.ibanNumber = this.props.lastClosedPeriod.ibanNumber;
                newPeriod.accountName = this.props.lastClosedPeriod.accountName;
                newPeriod.periodNote = this.props.lastClosedPeriod.periodNote;
            }

            await this.props.addPeriod(newPeriod, this.props.token);
            if (this.props.addPeriodResult.code === 200) {
                activePeriod = this.props.addPeriodResult.data.period;
            } else {
                this.setState({ addInvoiceOpenDialog: false });
                this.props.enqueueSnackbar("Fatura ekleme işlemi işlemi başarısız. Sistem yetkilisi ile görüşün.", {
                    variant: "error",
                    persist: true,
                    action: (
                        <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                    ),
                });
            }
        }
        else {
            activePeriod = this.props.lastActivePeriod;
        }


        window.location.href = "/period/" + activePeriod._id + "?tab=" + tab;
    }

    handleGetInvoiceExport = async (selectedPeriod) => {
        this.setState({ getInvoicesFilesOpenDialog: true, selectedPeriod: Object.assign({}, selectedPeriod) });
    }

    handleGetInvoicePdf = async (selectedPeriod) => {
        this.setState({ getInvoicesPdfOpenDialog: true, selectedPeriod: Object.assign({}, selectedPeriod) });
    }

    handleGetInvoiceExportSubmit = async (e, state) => {
        await getPeriodInvoicesExcelList(state, this.props.token);
        this.setState({ getInvoicesFilesOpenDialog: false });
    }

    handleGetInvoiceExportPdfSubmit = (e, state) => {
        this.setState({ getInvoicesFilesOpenDialog: false });
        return window.open("/pdf-invioce-list?" + queryString.stringify(state), '_blank').focus()
    }

    handleAddProjectProblem = () => {
        this.setState({ addProjectProblemOpenDialog: true });
    }

    handleAddProjectProblemSubmit = async (e, state) => {

        let problem = {
            title: e.target.title.value,
            description: e.target.description.value,
            solutionStatus: state.solutionStatus,
            creator: state.creator,
            project: state.project,
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir problem ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addProjectProblem(problem, this.props.token);
                    if (this.props.addProjectProblemResult.code === 200) {
                        await this.props.enqueueSnackbar("Problem Başarıyla Eklendi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                        this.setState({ value: 6, addProjectProblemOpenDialog: false });

                    } else {
                        await this.props.enqueueSnackbar(this.props.addProjectProblemResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                    await this.props.getProjectProblems(this.props.project._id, this.props.token);
                }
            });
    }

    handleShowDescription = async (rowData) => {
        this.setState({ showDescriptionOpenDailog: true, problem: Object.assign({}, rowData) });
    }
    handleShowSolverDescription = async (rowData) => {
        this.setState({ showSolverDescriptionOpenDailog: true, problem: Object.assign({}, rowData) });
    }

    handleResolveProblem = async (rowData) => {
        this.setState({ reslveProblemOpenDialog: true, problem: Object.assign({}, rowData) });
    }

    handleEditProjectProblemSubmit = async (e, state) => {

        let problem = {
            _id: state._id,
            solverPerson: state.solverPerson,
            solutionStatus: state.solutionStatus,
            solverDescription: e.target.solverDescription.value,
        };

        return swal({
            title: "Emin misin?",
            text: "Mevcut problemi güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateProjectProblem(problem, this.props.token);
                    if (this.props.updateProjectProblemResult.code === 200) {
                        await this.props.enqueueSnackbar("Problem Başarıyla Güncellendi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                        this.setState({ value: 6, reslveProblemOpenDialog: false });

                    } else {
                        await this.props.enqueueSnackbar(this.props.updateProjectProblemResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                    await this.props.getProjectProblems(this.props.project._id, this.props.token);
                }
            });
    }

    handleChangeCounter = async (rowData) => {
        this.setState({ changeCounterOpenDailog: true, apartmentCounter: Object.assign({}, rowData) });
    }

    handleChangeCounterSubmit = async (e, state) => {

        let counter = new FormData();
        counter.append('name', e.target.name.value);
        counter.append('serialNumber', e.target.serialNumber.value);
        counter.append('counterValue', e.target.counterValue.value);
        counter.append('oldDescription', e.target.oldDescription.value);
        counter.append('description', e.target.description.value);
        counter.append('consumptionDifference', state.consumptionDifference);
        counter.append('oldCounterId', state.oldCounterId);
        counter.append('oldCounterValue', state.oldCounterValue);
        counter.append('transfer', state.transfer);

        if (state.oldCounterImage) {
            if (state.oldCounterImage.length > 0) {
                for (let i = 0; i < state.oldCounterImage.length; i++) {
                    counter.append('oldCounterImage', state.oldCounterImage[i]);
                }
            }
        }

        if (state.newCounterImage) {
            if (state.newCounterImage.length > 0) {
                for (let q = 0; q < state.newCounterImage.length; q++) {
                    counter.append('newCounterImage', state.newCounterImage[q]);
                }
            }
        }

        return swal({
            title: "Emin misin?",
            text: "Sayaç değişimi gerçekleşecek!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.changeApartmentCounter(counter, this.props.token);
                    if (this.props.changeApartmentCounterResult.code === 200) {
                        this.setState({ changeCounterOpenDailog: false, value: 3 });
                        await this.props.getProjectApartmentCounters(this.props.project._id, this.props.token);
                        this.props.enqueueSnackbar('Sayaç başarıyla değişti..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Sayaç değişirken bir hata oluştu..' + this.props.changeApartmentCounterResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                {this.props.getResellerProjects ? <div className="loading" /> : ""}
                {this.props.getProjectLoading ? <div className="loading" /> : ""}
                {this.props.getProjectsLoading ? <div className="loading" /> : ""}
                {this.props.getPeriodsLoading ? <div className="loading" /> : ""}
                {this.props.getLastActivePeriodLoading ? <div className="loading" /> : ""}
                {this.props.getLastClosedPeriodLoading ? <div className="loading" /> : ""}
                {this.props.getProjectBlocksLoading ? <div className="loading" /> : ""}
                {this.props.getProjectApartmentCountersLoading ? <div className="loading" /> : ""}
                {this.props.getSharingTypesLoading ? <div className="loading" /> : ""}
                {this.props.getProjectSubscriptionsLoading ? <div className="loading" /> : ""}
                {this.props.getProjectMainCountersLoading ? <div className="loading" /> : ""}
                {this.props.addBlockLoading ? <div className="loading" /> : ""}
                {this.props.editBlockLoading ? <div className="loading" /> : ""}
                {this.props.getAdditionalExpenseDatasLoading ? <div className="loading" /> : ""}
                {this.props.addAdditionalExpenseDataLoading ? <div className="loading" /> : ""}
                {this.props.deleteAdditionalExpenseDataLoading ? <div className="loading" /> : ""}
                {this.props.getProcjectProblemsLoading ? <div className="loading" /> : ""}
                {this.props.addProjectProblemLoading ? <div className="loading" /> : ""}
                {this.props.updateProjectProblemLoading ? <div className="loading" /> : ""}
                {this.props.changeApartmentCounterLoading ? <div className="loading" /> : ""}
                <Grid container>
                    {
                        this.props.person.permissions.period.addInvoice ?
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card className={classes.card} style={{ cursor: "pointer", width: "25%", margin: "5%", padding: "2.5%", fontSize: "40px" }}>
                                    <CardContent>
                                        <Typography style={{ fontSize: "40px", textAlign: "center" }} className={classes.title} color="textSecondary" gutterBottom onClick={() => { this.handleSendInvoiceList(0) }}>Fatura Ekle</Typography>
                                    </CardContent>
                                </Card>
                            </Grid> : ""
                    }
                    {
                        this.props.person.permissions.period.updateApartmentCounterData ?
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card className={classes.card} style={{ cursor: "pointer", width: "25%", margin: "5%", padding: "2.5%", fontSize: "40px" }}>
                                    <CardContent>
                                        <Typography style={{ fontSize: "40px", textAlign: "center" }} className={classes.title} color="textSecondary" gutterBottom onClick={() => { this.handleSendInvoiceList(2) }}>Veri Girişi</Typography>
                                    </CardContent>
                                </Card>
                            </Grid> : ""
                    }
                </Grid>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Daha Fazla Detaylı Bilgi</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        {this.props.addProjectLoading ? <div className="loading" /> : ""}
                        {this.props.getProjectsLoading ? <div className="loading" /> : ""}
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Project Adı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.name || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Projenin Durumu
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            <Switch checked={this.props.project.status || false} aria-label="Collapse" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Ait Olduğu Müşteri
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.reseller.title || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Kat İstasyonu Durumu
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Switch checked={this.props.project.floorStation || false} aria-label="Collapse" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Okuma Ücreti
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.readingPrice || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Faturada Görünen Okuma Ücreti
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.invoiceBalance || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Projenin Oluşturulma Tarihi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={moment(this.props.project.createdAt).format("DD-MM-YYYY") || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                this.props.person.personType === 0 ?
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>Komisyon</Typography>
                                            </Grid>
                                            <br></br>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    disabled={true}
                                                    className={classes.textField}
                                                    value={this.props.project.commission || ""}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            {
                                this.props.person.personType === 0 ?
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>Zam Oranı</Typography>
                                            </Grid>
                                            <br></br>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    disabled={true}
                                                    className={classes.textField}
                                                    value={this.props.project.raiseRate || ""}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            {
                                this.props.person.personType === 0 ?
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>Projenin Açıklaması</Typography>
                                            </Grid>
                                            <br></br>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    disabled={true}
                                                    className={classes.textField}
                                                    multiline={true}
                                                    rows="4"
                                                    value={this.props.project.description || ""}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Okuma dönemi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.readingPeriodId === 0 ? "Sadece Kış" : "Yaz Kış" || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Okuma Bilgisi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.meterReadingInformation === 0 ? "OPtik" : this.props.project.meterReadingInformation === 1 ? "Mbus" : " GPRS"}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Isı Kayıp Oranı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.heatLossRate || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Şehir
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.city.name || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            İlçe
                                </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.county.name || ""}
                                            onChange={null}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Adres
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.project.address || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Paylaşım Yapılan Sayaç Tipleri
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {
                                                this.props.project.sharingTypes.map((type) => {
                                                    return (
                                                        <div key={type._id} style={{ width: "100%", margin: "3px", display: "flex" }} >
                                                            <Button key={type._id} style={{ fontSize: "10px", color: "#fff", backgroundColor: type.color }} >{type.name}</Button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>Projede Yapılan Paylaşım Ayarları</Typography>
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button onClick={this.handleSharingSettings} variant="contained" color="primary" className={classes.button}>Ayarları Görüntüle</Button>
                                            <ModalDialog
                                                open={this.state.sharingSettingsOpenDialog}
                                                title={this.props.project.name + ' Projesi Paylaşım Ayarları'}
                                                buttons={[
                                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                                ]}>
                                                <UpdateSharingSettingsForm onSubmitForm={this.handleSharingSettingsSubmit} project={this.props.project} />
                                            </ModalDialog>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>Analiz ve Kıyas Ayarları</Typography>
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button onClick={this.handleAnalysisSettings} variant="contained" style={{ backgroundColor: "#217965", color: "#fff" }} className={classes.button}>Ayarları Görüntüle</Button>
                                            <ModalDialog
                                                open={this.state.analysisSettingsOpenDialog}
                                                title={this.props.project.name + ' Analiz ve Kıyas Ayarları'}
                                                buttons={[
                                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                                ]}>
                                                <UpdateAnalysisSettingsForm onSubmitForm={this.handleAnalysisSettingsSubmit} project={this.props.project} />
                                            </ModalDialog>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Divider />
                <br /><br /><br />
                <AppBar position="static" color="default">
                    <div className="tab">
                        <button onClick={() => { this.handleChangeTab(0) }} className={classNames(["tablinks", value === 0 ? "tabActive" : ""])}>Bloklar</button>
                        <button onClick={() => { this.handleChangeTab(1) }} className={classNames(["tablinks", value === 1 ? "tabActive" : ""])}>Abonelikler</button>
                        <button onClick={() => { this.handleChangeTab(2) }} className={classNames(["tablinks", value === 2 ? "tabActive" : ""])}>Ana Sayaçlar</button>
                        <button onClick={() => { this.handleChangeTab(3) }} className={classNames(["tablinks", value === 3 ? "tabActive" : ""])}>Tüm Daire Sayaçları</button>
                        <button onClick={() => { this.handleChangeTab(5) }} className={classNames(["tablinks", value === 5 ? "tabActive" : ""])}>Ek Gider Yönetimi</button>
                        <button onClick={() => { this.handleChangeTab(4) }} className={classNames(["tablinks", value === 4 ? "tabActive" : ""])}>Dönem yönetimi</button>
                        {
                            this.props.person.personType === 0 ?
                                <button onClick={() => { this.handleChangeTab(6) }} className={classNames(["tablinks", value === 6 ? "tabActive" : ""])}>Proje Sorunları</button>
                                : ""
                        }

                        <button onClick={() => { this.handleChangeTab(7) }} className={classNames(["tablinks", value === 7 ? "tabActive" : ""])}>Sözleşme Dosyaları</button>
                        <button onClick={() => { this.handleChangeTab(8) }} className={classNames(["tablinks", value === 8 ? "tabActive" : ""])}>Proje ile İlişkili Personeller </button>
                    </div>
                </AppBar>
                {
                    value === 0 && <div style={{ padding: 8 * 3 }}>
                        <BlocksTable {...this.props} uploadCollectiveApartmentExcelList={this.handleUploadCollectiveApartmentExcel} getApartmentList={this.handleGetApartmentList} addForm={this.handleAddBlock} uploadCollectiveBlockExcelList={this.handleUploadCollectiveBlockExcel} downloadCollectiveBlockExcelList={this.handleGetCollectiveBlockExcelList} addCollectiveForm={this.handleAddCollectiveBlock} editForm={this.handleEditBlock} delete={this.handleDeleteBlock} />
                        <ModalDialog
                            open={this.state.addBlockOpenDialog}
                            title={this.props.project.name + ' Projesine - Yeni Blok Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddBlockForm onSubmitForm={this.handleAddBlockSubmit} project={this.props.project} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.addCollectiveBlockOpenDialog}
                            title={this.props.project.name + ' Projesine - Toplu Blok Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddCollectiveBlockForm onSubmitForm={this.handleAddCollectiveBlockSubmit} project={this.props.project} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editBlockOpenDialog}
                            title={this.props.project.name + ' Projesi Blok -  Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditBlockForm {...this.state.rowData} onSubmitForm={this.handleEditBlockSubmit} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.uploadCollectiveBlockExcelOpenDialog}
                            title={'Toplu Blok Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <UploadCollectiveBlockExcelForm enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleUpdateCollectiveBlockListSubmit} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.getApartmentListDialog}
                            title={'Daire Listesini Excel Olarak İndir'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <GetApartmentsExcelList onSubmitForm={this.handleGetCollectiveApartmentExcelList} blocks={this.props.project.blocks} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.uploadCollectiveApartmentExcelOpenDialog}
                            title={'Toplu Daire Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <UploadCollectiveApartmentExcelForm enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleUpdateCollectiveApartmentListSubmit} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 1 && <div style={{ padding: 8 * 3 }}>
                        <SubscriptionsTable {...this.props} addForm={this.handleAddSubscription} editForm={this.handleEditSubscription} delete={this.handleDeleteSubscription} />
                        <ModalDialog
                            open={this.state.addSubscriptionOpenDialog}
                            title={this.props.project.name + ' Projesine - Yeni Abonelik Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddSubscriptionForm onSubmitForm={this.handleAddSubscriptionSubmit} subscriptionTypes={this.props.subscriptionTypes} project={this.props.project} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editSubscriptionOpenDialog}
                            title={this.props.project.name + ' Abonelik Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditSubscriptionForm {...this.state.rowData} onSubmitForm={this.handleEditSubscriptionSubmit} subscriptionTypes={this.props.subscriptionTypes} project={this.props.project} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 2 && <div style={{ padding: 8 * 3 }}>
                        <MainCountersTable {...this.props} uploadApartmentCounterExcelList={this.handleUploadApartmentCounterExcelList} getCreateApartmentCounterExcel={this.handleGetCreateApartmentCounterExcel} addForm={this.handleAddCounter} editForm={this.handleEditMainCounter} delete={this.handleDeleteMainCounter} />
                        <ModalDialog
                            open={this.state.addCounterOpenDialog}
                            title={this.props.project.name + ' Projesine - Yeni Ana Sayaç Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddMainCounterStepForm closeDialog={this.handleCloseDialog} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editMainCounterOpenDialog}
                            title={this.props.project.name + ' Projesinde - Mevcut Paylaşım Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            {this.state.mainCounter ? <div>
                                {this.state.mainCounter.type._id === "5cefd0dd45844c0a69b509d0" ? <EditHotWaterMainCounter {...this.state.mainCounter} onSubmitForm={this.handleEditMainCounterSubmit} project={this.props.project} sharingType={this.state.sharingType}
                                    waterSubscriptions={this.state.waterSubscriptions} subscriptions={this.props.subscriptions} /> :
                                    <EditMainCounterForm {...this.state.mainCounter} onSubmitForm={this.handleEditMainCounterSubmit} project={this.props.project} subscriptions={this.props.subscriptions} />}
                            </div> : ""}
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.createApartmentCounterExcelOpenDialog}
                            title={this.state.rowData.name + " - Paylaşımı Altına Daire Sayacı Ekleme Excel'i"}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <CreateApartmentCountersForm onSubmitForm={async (e, state) => (await getCollectiveApartmentCounterExcelList(state.mainCounter, state.blocks, this.props.token))} mainCounter={this.state.rowData} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.apartmentCounterExcelUploadOpenDialog}
                            title={"Toplu Daire Sayacı Ekleme"}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <ApartmentCountersExcelUploadForm enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleUpdateCollectiveApartmentCounterListSubmit} />
                        </ModalDialog>

                    </div>
                }
                {
                    value === 3 && <div style={{ padding: 8 * 3 }}>
                        <ApartmentCountersTable {...this.props} editForm={this.handleEditCounter} delete={this.handleDeleteApartmentCounter} changeCounter={this.handleChangeCounter} />
                        <ModalDialog
                            open={this.state.editCounterOpenDailog}
                            title={'Sayaç Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditApartmentCounterForm onSubmitForm={this.handleUpdateApartmentCounterSubmit} apartmentCounter={this.state.apartmentCounter} />
                        </ModalDialog>

                        <ModalDialog
                            open={this.state.changeCounterOpenDailog}
                            title={'Sayaç Değişimi'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <ChangeApartmentCounterForm enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleChangeCounterSubmit} apartmentCounter={this.state.apartmentCounter} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 4 && <div style={{ padding: 8 * 3 }}>
                        <PeriodsTable {...this.props} lastClosedPeriod={this.props.lastClosedPeriod} getInvoicePdf={this.handleGetInvoicePdf} getInvoiceExport={this.handleGetInvoiceExport} activePeriod={this.handleActivePeriod} closePeriod={this.handleClosePeriod}
                            addForm={this.handleAddPeriod} editForm={this.handleUpdatePeriod} delete={this.handleDeletePeriod} />
                        <ModalDialog
                            open={this.state.addPeriodOpenDialog}
                            title={this.props.project.name + ' - Yeni Dönem Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddPeriodForm {...this.props} lastClosedPeriod={this.props.lastClosedPeriod} project={this.props.project} onSubmitForm={this.handleAddPeriodSubmit} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editPeriodOpenDialog}
                            title={'Dönem Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditPeriodForm {...this.props} period={this.state.period} onSubmitForm={this.handleUpdatePeriodSubmit} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.getInvoicesFilesOpenDialog}
                            title={'Güncel Fatura Excel Listesi'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <GetInvoicesForm onSubmitForm={this.handleGetInvoiceExportSubmit} period={this.state.selectedPeriod} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.getInvoicesPdfOpenDialog}
                            title={'Güncel Fatura Excel Listesi'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <GetInvoicesForm onSubmitForm={this.handleGetInvoiceExportPdfSubmit} period={this.state.selectedPeriod} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 5 && <div style={{ padding: 8 * 3 }}>
                        <AdditonalExpenseDatasTable {...this.props} addForm={this.handleAddAdditionalExpenseData} delete={this.handleDeleteAdditionalExpenseData} />
                        <ModalDialog
                            open={this.state.addAdditionalExpenseDataOpenDialog}
                            title={this.props.project.name + ' - Yeni Ek Gider Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AdditionalExpenseForm project={this.props.project} onSubmitForm={this.handleAddAdditionalExpenseDataSubmit} />
                        </ModalDialog>
                    </div>
                }
                {
                    this.props.person.personType === 0 ?
                        value === 6 && <div style={{ padding: 8 * 3 }}>
                            <ProjectProblemsTable {...this.props} addForm={this.handleAddProjectProblem} showDescription={this.handleShowDescription} showSolverDescription={this.handleShowSolverDescription} resolveProblem={this.handleResolveProblem} />
                            <ModalDialog
                                open={this.state.addProjectProblemOpenDialog}
                                title={this.props.project.name + ' - Yeni Problem Ekleme'}
                                buttons={[
                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                ]}>
                                <AddProblemForm project={this.props.project} person={this.props.person} onSubmitForm={this.handleAddProjectProblemSubmit} />
                            </ModalDialog>
                            <ModalDialog
                                open={this.state.showDescriptionOpenDailog}
                                title={'Problem Detayı'}
                                buttons={[
                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                ]}>
                                <div>{this.state.problem.description}</div>
                            </ModalDialog>
                            <ModalDialog
                                open={this.state.showSolverDescriptionOpenDailog}
                                title={'Çözüm Detayı'}
                                buttons={[
                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                ]}>
                                <div>{this.state.problem.solverDescription}</div>
                            </ModalDialog>
                            <ModalDialog
                                open={this.state.reslveProblemOpenDialog}
                                title={'Problem çözümü'}
                                buttons={[
                                    <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                ]}>
                                <ResollveProblemForm problem={this.state.problem} person={this.props.person} onSubmitForm={this.handleEditProjectProblemSubmit} />
                            </ModalDialog>
                        </div> : ""
                }
                {value === 7 && <Typography component="div" style={{ padding: 8 * 3 }}>Sözleşme Dosyaları..</Typography>}
                {
                    value === 8 && <div style={{ padding: 8 * 3 }}>
                        <ProjectPersonsTable persons={this.props.project.persons} />
                    </div>
                }
            </div>

        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ blockReducer, personReducer, projectReducer, sharingTypeReducer, mainCounterReducer, subscriptionTypeReducer, subscriptionReducer, apartmentCounterReducer, periodReducer, apartmentReducer, additionalExpenseDataReducer, projectProblemsReducer }) => {
    return {
        ...personReducer,
        ...projectReducer,
        ...sharingTypeReducer,
        ...blockReducer,
        ...mainCounterReducer,
        ...subscriptionTypeReducer,
        ...subscriptionReducer,
        ...apartmentCounterReducer,
        ...periodReducer,
        ...apartmentReducer,
        ...additionalExpenseDataReducer,
        ...projectProblemsReducer,
    }
}

// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    getAllSharingTypes,
    getProject,
    updateProject,
    getAllBlocks,
    addBlock,
    addCollectiveBlock,
    editBlock,
    deleteBlock,
    setBreadCrumb,
    getCollectiveBlockExcelList,
    uploadCollectiveBlockExcel,
    getAllSubscriptionTypes,
    addSubscription,
    getAllSubscriptions,
    editSubscription,
    deleteSubscription,
    getProjectMainCounters,
    getAllTypeSubscriptions,
    deleteMainCounter,
    uploadCollectiveApartmentCountersExcel,
    updateMainCounter,
    addPeriod,
    getLastClosedPeriod,
    getProjectPeriods,
    getLastActivePeriod,
    uploadCollectiveApartmentExcel,
    closePeriod,
    activePeriod,
    updatePeriod,
    deletePeriod,
    getProjectApartmentCounters,
    updateApartmentCounter,
    deleteApartmentCounter,
    setFastOperation,
    getPeriodAdditionalExpenseDatas,
    addAdditionalExpenseData,
    deleteAdditionalExpenseData,
    getProjectProblems,
    addProjectProblem,
    updateProjectProblem,
    changeApartmentCounter
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(ProjectDetail)));