import {
    ADD_TASK_PENDING, ADD_TASK_FULFILLED, ADD_TASK_REJECTED,
    GET_ALL_TASKS_PENDING, GET_ALL_TASKS_FULFILLED, GET_ALL_TASKS_REJECTED,
    DELETE_TASK_PENDING, DELETE_TASK_FULFILLED, DELETE_TASK_REJECTED,
    PUT_TASK_PENDING, PUT_TASK_FULFILLED, PUT_TASK_REJECTED,
    GET_ALL_RESELLER_DEMAND_PENDING, GET_ALL_RESELLER_DEMAND_FULFILLED, GET_ALL_RESELLER_DEMAND_REJECTED
} from '../actions/task';
const initialState = {
    tasks: [],
    addTaskResult: {},
    deleteTaskResult: {},
    updateTaskResult: {},
    addTaskLoading: false,
    getAllTasksLoading: false,
    putTaskLoading: false,
    deleteTaskLoading: false,
    getAllResellerDemandLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_TASK_PENDING:
            return {
                ...state,
                addTaskLoading: true,
            }
        case ADD_TASK_FULFILLED:
            return {
                ...state,
                addTaskResult: action.payload,
                addTaskLoading: false

            }
        case ADD_TASK_REJECTED:
            return {
                ...state,
                addTaskLoading: false
            }
        case GET_ALL_TASKS_PENDING:
            return {
                ...state,
                getAllTasksLoading: true
            }
        case GET_ALL_TASKS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAllTasksLoading: false
            }
        case GET_ALL_TASKS_REJECTED:
            return {
                ...state,
                getAllTasksLoading: false,
            }
        case GET_ALL_RESELLER_DEMAND_PENDING:
            return {
                ...state,
                getAllResellerDemandLoading: true
            }
        case GET_ALL_RESELLER_DEMAND_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAllResellerDemandLoading: false
            }
        case GET_ALL_RESELLER_DEMAND_REJECTED:
            return {
                ...state,
                getAllResellerDemandLoading: false,
            }
        case PUT_TASK_PENDING:
            return {
                ...state,
                putTaskLoading: true,
            }
        case PUT_TASK_FULFILLED:
            return {
                ...state,
                updateTaskResult: action.payload,
                putTaskLoading: false
            }
        case PUT_TASK_REJECTED:
            return {
                ...state,
                putTaskLoading: false,
            }
        case DELETE_TASK_PENDING:
            return {
                ...state,
                deleteTaskLoading: true,
            }
        case DELETE_TASK_FULFILLED:
            return {
                ...state,
                deleteTaskResult: action.payload,
                deleteTaskLoading: false
            }
        case DELETE_TASK_REJECTED:
            return {
                ...state,
                deleteTaskLoading: false,
            }

        default:
            return state;
    }
}