import {
    GET_SHARING_TYPES_PENDING, GET_SHARING_TYPES_FULFILLED, GET_SHARING_TYPES_REJECTED,
    GET_PROJECT_SHARING_TYPES_PENDING, GET_PROJECT_SHARING_TYPES_FULFILLED, GET_PROJECT_SHARING_TYPES_REJECTED,
} from '../actions/sharingType';
const initialState = {
    sharingTypes: [],
    projectSharingTypes: [],
    getSharingTypesLoading: false,
    getProjectSharingTypes: false,
}
// sharingType işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SHARING_TYPES_PENDING:
            return {
                ...state,
                getSharingTypesLoading: true,
            }
        case GET_SHARING_TYPES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getSharingTypesLoading: false,
            }
        case GET_SHARING_TYPES_REJECTED:
            return {
                ...state,
                getSharingTypesLoading: false,
            }

        case GET_PROJECT_SHARING_TYPES_PENDING:
            return {
                ...state,
                getProjectSharingTypes: true,
            }
        case GET_PROJECT_SHARING_TYPES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectSharingTypes: false,
            }
        case GET_PROJECT_SHARING_TYPES_REJECTED:
            return {
                ...state,
                getProjectSharingTypes: false,
            }

        default:
            return state;
    }
}