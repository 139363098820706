import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_NOTE_PENDING = "ADD_NOTE_PENDING";
export const ADD_NOTE_FULFILLED = "ADD_NOTE_FULFILLED";
export const ADD_NOTE_REJECTED = "ADD_NOTE_REJECTED";

export const DELETE_NOTE_PENDING = "DELETE_NOTE_PENDING";
export const DELETE_NOTE_FULFILLED = "DELETE_NOTE_FULFILLED";
export const DELETE_NOTE_REJECTED = "DELETE_NOTE_REJECTED";

export const GET_ALL_NOTES_PENDING = "GET_ALL_NOTES_PENDING";
export const GET_ALL_NOTES_FULFILLED = "GET_ALL_NOTES_FULFILLED";
export const GET_ALL_NOTES_REJECTED = "GET_ALL_NOTES_REJECTED";

export const UPDATE_NOTE_PENDING = "UPDATE_NOTE_PENDING";
export const UPDATE_NOTE_FULFILLED = "UPDATE_NOTE_FULFILLED";
export const UPDATE_NOTE_REJECTED = "UPDATE_NOTE_REJECTED";



export function addNote(note, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_NOTE",
            payload: axios.post(`${API_URL}/note`, note, { headers: { "x-access-token": token } })
                .then(result => result.data)

        })
    }
}

export function getAllNotes(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_NOTES",
            payload: axios.get(`${API_URL}/note/all`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function deleteNote(note, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_NOTE",
            payload: axios.delete(`${API_URL}/note`, { data: { ...note, token: token } })
                .then(result => result.data)
        })
    }
}

export function updateNote(note, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_NOTE",
            payload: axios.put(`${API_URL}/note`, note, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

