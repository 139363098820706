import {
    GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_PENDING, GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_FULFILLED, GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_REJECTED,
    ADD_ADDITIONAL_EXPENSE_DATA_PENDING, ADD_ADDITIONAL_EXPENSE_DATA_FULFILLED, ADD_ADDITIONAL_EXPENSE_DATA_REJECTED,
    DELETE_ADDITIONAL_EXPENSE_DATA_PENDING, DELETE_ADDITIONAL_EXPENSE_DATA_FULFILLED, DELETE_ADDITIONAL_EXPENSE_DATA_REJECTED

} from '../actions/additionalExpenseDatas';

const initialState = {
    getAdditionalExpenseDatasLoading: false,
    additionalExpenseDatas: [],
    addAdditionalExpenseDataLoading: false,
    addAdditionalExpenseDataResult: {},
    deleteAdditionalExpenseDataResult: {},
    deleteAdditionalExpenseDataLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ADDITIONAL_EXPENSE_DATA_PENDING:
            return {
                ...state,
                addAdditionalExpenseDataLoading: true,
            }
        case ADD_ADDITIONAL_EXPENSE_DATA_FULFILLED:
            return {
                ...state,
                addAdditionalExpenseDataResult: action.payload,
                addAdditionalExpenseDataLoading: false,
            }
        case ADD_ADDITIONAL_EXPENSE_DATA_REJECTED:
            return {
                ...state,
                addAdditionalExpenseDataLoading: false,
            }
        case GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_PENDING:
            return {
                ...state,
                getAdditionalExpenseDatasLoading: true,
            }
        case GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAdditionalExpenseDatasLoading: false,
            }
        case GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_REJECTED:
            return {
                ...state,
                getAdditionalExpenseDatasLoading: false,
            }
        case DELETE_ADDITIONAL_EXPENSE_DATA_PENDING:
            return {
                ...state,
                deleteAdditionalExpenseDataLoading: true,
            }
        case DELETE_ADDITIONAL_EXPENSE_DATA_FULFILLED:
            return {
                ...state,
                deleteAdditionalExpenseDataResult: action.payload,
                deleteAdditionalExpenseDataLoading: false
            }
        case DELETE_ADDITIONAL_EXPENSE_DATA_REJECTED:
            return {
                ...state,
                deleteAdditionalExpenseDataLoading: false
            }
        default:
            return state;
    }
}