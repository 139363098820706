
import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import Moment from 'react-moment';
import { Button, Chip } from '@material-ui/core';

class DemandTable extends Component {

    render() {
        const tableData = {
            title: "Talep ve İsteklerim ",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.project.update ? {
                    title: 'İşlem',
                    render: rowData => {

                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )

                    },
                } : {},
                { title: "İşi Oluşturan Kişi", field: "creator.title" },
                { title: "İşin Başlığı", field: "name" },
                { title: "Açıklaması", field: "description" },
               
                {
                    title: "Proje Adı",
                    field: "project",
                    render: rowData => {
                        return (
                            <a href={"/project/" + rowData.project._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.project.name}</a>
                        )
                    },
                },
                {
                    title: "İşin Tipi",
                    field: "taskType",
                    render: rowData => {
                        return (
                            <a href={"/taskType/" + rowData.taskType._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.taskType.name}</a>
                        )
                    },
                },
                {
                    title: "Ücret Durumu", field: "priceStatus",
                    render: rowData => {
                        let text = "";

                        if (rowData.requestType === 0) {
                            text = "Ücretli";
                        }
                        else if (rowData.requestType === 1) {
                            text = "Garantili";
                        }
                        else {
                            text = "Ücretsiz";
                        }
                        return (
                            text
                        );
                    }
                },
                { title: "Ücreti", field: "price" },
                {
                    title: "Atanacak Departman",
                    field: "department",
                    render: rowData => {
                        return (
                            <a href={"/depatment/" + rowData.department._id} style={{ color: "#000000", fontWeight: "500" }} >{rowData.department.name}</a>
                        )
                    },
                },
                {
                    title: "Atanacak Personeller",
                    field: "persons",
                    render: rowData => {
                        return (
                            <div>{
                                rowData.persons.map((persons) => {
                                    return <Chip key={rowData._id + "-" + persons._id} label={persons.title} />
                                })
                            }</div>
                        )
                    },
                },
                {
                    title: "İş Başlangıç Tarihi", field: "startDate",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.startDate}
                            </Moment>
                        )
                    }
                },
                {
                    title: "Randevu Tarihi", field: "appointmentDate",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.appointmentDate}
                            </Moment>
                        )
                    }
                },
            ],
            data: this.props.tasks
        };
        let tableActions = [];
      
        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default DemandTable;