import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { getAllResellers, addPerson, updatePerson, deletePerson } from '../../actions/person';
import { getAllCities, getCity } from '../../actions/city';
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
// components
import { Button } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog'
import swal from 'sweetalert';
import AddPersonForm from '../Persons/AddPersonForm';
import ResellersTable from './ResellersTable';
import EditPersonForm from '../Persons/EditPersonForm';

class Resellers extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("MÜŞTERİ YÖNETİMİ");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Müşteri Yönetimi" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false,
        addResellerPasswordDialog: false,

    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        // yetki kontrolü
        if (!this.props.person.permissions.reseller.read) {
            return window.location.href = "/";
        }
        await this.props.getAllResellers(this.props.token);

    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
        this.setState({ addResellerPasswordDialog: false });
    }

    // güncelle butonuna tıklandığında yapılacak işlem
    getCity = (cityId) => {
        this.props.getCity(cityId, this.props.token);
    }

    // // personel güncelleme işlemi
    handleEditResellerSubmit = async (e, state) => {
        /*
            validasyon işlemlerini yap işlemler bitmeden ilermesin
        */
        const reseller = {
            _id: e.target._id.value,
            title: e.target.title.value,
            personType: state.personType,
            // name: e.target.name.value,
            // lastName: e.target.lastName.value,
            // surname: e.target.surname.value,
            landPhone: e.target.landPhone.value,
            mobilePhone: e.target.mobilePhone.value,
            faxPhone: e.target.faxPhone.value,
            email: e.target.email.value,
            webSite: e.target.webSite.value,
            taxOffice: e.target.taxOffice.value,
            taxNumber: e.target.taxNumber.value,
            description: e.target.description.value,
            city: state.city.value,
            county: state.county.value,
            reference: state.reference ? state.reference.value : '',
            permissions: state.permissions
        };

        return swal({
            title: "Emin misin?",
            text: "Bayiyi Güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updatePerson(reseller, this.props.token);
                    if (this.props.updatePersonResult.code === 200) {
                        this.props.getAllResellers(this.props.token);
                        this.setState({ editOpenDialog: false });
                        this.props.enqueueSnackbar("Güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Güncelleme işlemi başarısız. " + this.props.updatePersonResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    // yeni müşteri ekleme işlemi
    handleAddResellerSubmit = async (e, state) => {

        const reseller = {
            personType: state.personType,
            title: e.target.title.value,
            // name: e.target.name.value,
            // lastName: e.target.lastName.value,
            // surname: e.target.surname.value,
            landPhone: e.target.landPhone.value,
            mobilePhone: e.target.mobilePhone.value,
            faxPhone: e.target.faxPhone.value,
            email: e.target.email.value,
            password: e.target.password.value,
            webSite: e.target.webSite.value,
            taxOffice: e.target.taxOffice.value,
            taxNumber: e.target.taxNumber.value,
            description: e.target.description.value,
            city: state.city.value,
            county: state.county.value,
            reference: state.reference ? state.reference.value : '',
            permissions: state.permissions
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir bayi ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addPerson(reseller, this.props.token);
                    if (this.props.addPersonResult.code === 200) {
                        this.props.getAllResellers(this.props.token);
                        this.setState({ addOpenDialog: false });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addPersonResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    // güncelle butonuna tıklandığında yapılacak işlem
    handleEditReseller = (rowData) => {
        this.props.getAllCities(this.props.token);
        this.props.getCity(rowData.city._id, this.props.token);
        this.props.getAllResellers(this.props.token);
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editOpenDialog: true });
    }

    // yeni kayıt butonuna tıklandığında yapılacak işlem
    handleAddReseller = () => {
        this.props.getAllCities(this.props.token);
        this.props.getAllResellers(this.props.token);
        this.setState({ addOpenDialog: true })
    }

    // // sil butonuna tıklandığında yapılacak işlem
    handleDeleteReseller = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (reseller) => {
                        await this.props.deletePerson(reseller, this.props.token);
                        if (this.props.deletePersonResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deletePersonResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                }

                await this.props.getAllResellers(this.props.token);
            });


    }

    render() {
        return (
            <div>
                {this.props.getResellersLoading ? <div className="loading" /> : ""}
                {this.props.getResellersLoading ? <div className="loading" /> : ""}
                <ResellersTable resellers={this.props.resellers} person={this.props.person} addForm={this.handleAddReseller} editForm={this.handleEditReseller} delete={this.handleDeleteReseller} />
                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Müşteri Güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditPersonForm {...this.state.rowData} onSubmitForm={this.handleEditResellerSubmit} cities={this.props.cities} selectedCity={this.props.selectedCity} getCity={this.getCity} references={this.props.resellers} />
                </ModalDialog>
                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Müşteri Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddPersonForm personType={1} onSubmitForm={this.handleAddResellerSubmit} cities={this.props.cities} selectedCity={this.props.selectedCity} getCity={this.getCity} references={this.props.resellers} />
                </ModalDialog>
            </div>
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer, cityReducer }) => {
    return {
        ...cityReducer,
        ...personReducer,
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getAllCities,
    getCity,
    setActivePageTitle,
    setBreadCrumb,
    addPerson,
    updatePerson,
    deletePerson,
    getAllResellers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Resellers));