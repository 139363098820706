import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_STOCK_CARD_PENDING = "ADD_STOCK_CARD_PENDING";
export const ADD_STOCK_CARD_FULFILLED = "ADD_STOCK_CARD_FULFILLED";
export const ADD_STOCK_CARD_REJECTED = "ADD_STOCK_CARD_REJECTED";


export const GET_ALL_STOCK_CARD_PENDING = "GET_ALL_STOCK_CARD_PENDING";
export const GET_ALL_STOCK_CARD_FULFILLED = "GET_ALL_STOCK_CARD_FULFILLED";
export const GET_ALL_STOCK_CARD_REJECTED = "GET_ALL_STOCK_CARD_REJECTED";

export const DELETE_STOCK_CARD_PENDING = "DELETE_STOCK_CARD_PENDING";
export const DELETE_STOCK_CARD_FULFILLED = "DELETE_STOCK_CARD_FULFILLED";
export const DELETE_STOCK_CARD_REJECTED = "DELETE_STOCK_CARD_REJECTED";


export const PUT_STOCK_CARD_PENDING = "PUT_STOCK_CARD_PENDING";
export const PUT_STOCK_CARD_FULFILLED = "PUT_STOCK_CARD_FULFILLED";
export const PUT_STOCK_CARD_REJECTED = "PUT_STOCK_CARD_REJECTED";

export function addStockCard(stockCard, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_STOCK_CARD",
            payload: axios.post(`${API_URL}/stock-card`, stockCard, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllStockCards(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_STOCK_CARD",
            payload: axios.get(`${API_URL}/stock-card/all`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}


export function deleteStockCard(stockCard, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_STOCK_CARD",
            payload: axios.delete(`${API_URL}/stock-card`, { data: { ...stockCard, token: token } })
                .then(result => result.data)
        })
    }
}

export function updateStockCard(stockCard, token) {
    return async dispatch => {
        await dispatch({
            type: "PUT_STOCK_CARD",
            payload: axios.put(`${API_URL}/stock-card`, stockCard, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}
