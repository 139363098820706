import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class AddCollectiveBlockForm extends Component {

    state = {

    };
    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" name="project" value={this.props.project._id} />
                <FormControl required fullWidth>
                    <InputLabel htmlFor="count">Blok Sayısı</InputLabel>
                    <Input
                        id="count"
                        name="count"
                        type="number"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    TOPLU BLOK EKLE
                </Button>
            </form >
        )
    }
}

AddCollectiveBlockForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddCollectiveBlockForm);
