import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import ModalDialog from '../Items/ModalDialog';
import { Button } from '@material-ui/core';
import { getAllProjects } from '../../actions/project';
import { getAllResellers, getDepartmentPersons, getAllResellerPersons, getAllPersons } from '../../actions/person';
import { getAllDeparments } from '../../actions/department';
import { getAllTaskTypes } from '../../actions/taskType';
import { addTask, getAllTasks, deleteTask, updateTask } from '../../actions/task';
import swal from 'sweetalert';

// components
import TaskTable from './TaskTable';
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
import AddTaskForm from './AddTaskForm';
import EditTaskForm from './EditTaskForm';



class Tasks extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("İŞ HAVUZU");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "iş Havuzu" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false,
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        await this.props.getAllPersons(this.props.token);
        // yetki kontrolü
        if (!this.props.person.permissions.task.read) {
            return window.location.href = "/";
        }

        await this.props.getAllTasks(this.props.token);
    }

    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }

    handleAddTask = async () => {
        await this.props.getAllTaskTypes(this.props.token); // gerekli
        await this.props.getAllDeparments(this.props.token); // gerekli
        await this.props.getAllProjects(this.props.token); // gerekli
        this.setState({ addOpenDialog: true })
    }

    handleAddTaskSubmit = (e, state) => {

        if (e.target.name.value.length < 1) {
            this.props.enqueueSnackbar('Lütfen İsim Tanımlayın..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }
        if (state.project.value.length < 1) {
            this.props.enqueueSnackbar('Lütfen Projesini Belirtin..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }
        if (state.department.value === "") {
            return swal({
                title: "Hata!",
                text: "Lütfen bir departman seçin!",
                icon: "error",
            });
        }
        if (state.priceStatus.value.length < 1) {
            this.props.enqueueSnackbar('Lütfen Fiyat Durumunu Belirtin..', {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }

        let task = {
            creator: this.props.person._id,
            name: e.target.name.value,
            description: e.target.description.value,
            requestType: state.requestType.value,
            project: state.project.value,
            taskType: state.taskType.value,
            priceStatus: state.priceStatus.value,
            department: state.department.value,
            persons: state.persons.map(person => person.value),
            startDate: e.target.startDate.value,
            appointmentDate: e.target.appointmentDate.value,
        }

        if (state.priceStatus.value === 0) {
            task.price = e.target.price.value
        }
        if (state.requestType.value === 0) {
            task.requestPersonName = e.target.requestPersonName.value
            task.requestPersonPhone = e.target.requestPersonPhone.value
        }
        if (state.requestType.value === 1) {
            task.requestPerson = state.requestPerson.value

        }
        if (state.requestType.value === 2) {
            task.requestResellerPerson = state.requestResellerPerson.value
        }

        return swal({
            title: "Emin misin?",
            text: "Bu bayiye yeni bir proje ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addTask(task, this.props.token);
                    if (this.props.addTaskResult.code === 200) {
                        this.setState({ addOpenDialog: false, value: 1 });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addTaskResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getAllTasks(this.props.token);
            });
    }

    handeleDeleteTask = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await Promise.all(await rowData.map(async (task) => {
                        await this.props.deleteTask(task, this.props.token);
                        if (this.props.deleteTaskResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteTaskResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    }));
                    await this.props.getAllTasks(this.props.token);
                }
            });
    }

    handleEditTask = async (rowData) => {
        await this.props.getAllTaskTypes(this.props.token); // gerekli
        await this.props.getAllDeparments(this.props.token); // gerekli
        await this.props.getAllProjects(this.props.token); // gerekli
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editOpenDialog: true });
    }

    handleEditTaskSubmit = (e, state) => {
        const task = {
            _id: e.target._id.value,
            name: e.target.name.value,
            description: e.target.description.value,
            requestType: state.requestType.value,
            project: state.project.value,
            taskType: state.taskType.value,
            priceStatus: state.priceStatus.value,
            department: state.department.value,
            persons: state.persons.map(person => person.value),
            startDate: e.target.startDate.value,
            appointmentDate: e.target.appointmentDate.value,
        }
        if (state.priceStatus.value === 0) {
            task.price = e.target.price.value
        }
        if (state.requestType.value === 0) {
            task.requestPersonName = e.target.requestPersonName.value
            task.requestPersonPhone = e.target.requestPersonPhone.value
        }
        if (state.requestType.value === 1) {
            task.requestPerson = state.requestPerson.value
        }
        if (state.requestType.value === 2) {
            task.requestResellerPerson = state.requestResellerPerson.value
        }

        return swal({
            title: "Emin misin?",
            text: "Bu içeriği güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateTask(task, this.props.token);
                    if (this.props.updateTaskResult.code === 200) {
                        this.setState({ editOpenDialog: false });
                        await this.props.getAllTasks(this.props.token);
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarısız. " + this.props.updateTaskResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getAllTasks(this.props.token);
            });
    }
    render() {

        return (
            <div>
                {this.props.addTaskLoading ? <div className="loading" /> : ""}
                {this.props.getAllTasksLoading ? <div className="loading" /> : ""}
                {this.props.getDepartmentPersonsLoading ? <div className="loading" /> : ""}
                {this.props.getResellersLoading ? <div className="loading" /> : ""}

                <TaskTable {...this.props} addForm={this.handleAddTask} editForm={this.handleEditTask} delete={this.handeleDeleteTask} />
                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Görev Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddTaskForm onSubmitForm={this.handleAddTaskSubmit} {...this.props} selectedrequestPerson={this.props.selectedrequestPerson} />
                </ModalDialog>
                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Görev Güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditTaskForm onSubmitForm={this.handleEditTaskSubmit} selectedTask={this.state.rowData} {...this.props} selectedrequestPerson={this.props.selectedrequestPerson} />
                </ModalDialog>
            </div>

        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer, departmentReducer, taskTypeReducer, projectReducer, taskReducer }) => {
    return {
        ...personReducer,
        ...departmentReducer,
        ...taskTypeReducer,
        ...projectReducer,
        ...taskReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getAllDeparments,
    getAllTaskTypes,
    getAllProjects,
    setActivePageTitle,
    setBreadCrumb,
    getAllResellers,
    addTask,
    getDepartmentPersons,
    getAllTasks,
    deleteTask,
    updateTask,
    getAllResellerPersons,
    getAllPersons
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Tasks));