import React, { Component } from 'react';
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from '@material-ui/core/Switch';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormLabel from '@material-ui/core/FormLabel';
import SearchSelect from 'react-select';
import styles from '../../styles/app';

class EditPersonForm extends Component {

    state = {
        department: this.props.department ? { value: this.props.department._id, label: this.props.department.name } : {},
        permissions: this.props.permissions,
        fades: {
            main: false,
            person: false,
            department: false,
            reseller: false,
            project: false,
            block: false,
            apartment: false,
            subscription: false,
            mainCounter: false,
            apartmentCounter: false,
            period: false,
            task: false,
            note: false,
            stockCard: false,
            product: false,
            additonalExpenseData: false,
        },

        statuses: {
            main: false,
            person: false,
            department: false,
            reseller: false,
            project: false,
            block: false,
            apartment: false,
            subscription: false,
            mainCounter: false,
            apartmentCounter: false,
            period: false,
            task: false,
            note: false,
            stockCard: false,
            product: false,
            additonalExpenseData: false,
        },


        city: this.props.city ? { value: this.props.city._id, label: this.props.city.name } : {},
        county: this.props.county ? { value: this.props.county._id, label: this.props.county.name } : {},
        reference: this.props.reference ? { value: this.props.reference._id, label: this.props.reference.name } : { value: "", label: "Lütfen Referans Seçiniz.." },
        countyStatus: true,
        personType: this.props.personType

    };


    UNSAFE_componentWillMount() {
        this.checkSwitchsUpdate();
    }

    departmentChange = (department) => {
        this.setState({ department });
    };

    handleChangeChildSwitch = async (parentKey, childKey, value) => {

        await this.setState({
            permissions: {
                ...this.state.permissions,
                [parentKey]: {
                    ...this.state.permissions[parentKey],
                    [childKey]: value
                }
            }
        });
        await this.checkSwitchs();
    }

    handleChangeAllSwitch = async (value) => {
        await this.setState({
            statuses: { ...this.state.statuses, main: value }
        });
        await Object.keys(this.state["permissions"]).map(async (key) => {
            await this.setState({
                statuses: { ...this.state.statuses, [key]: value }
            });
            await Object.keys(this.state["permissions"][key]).map(async (objKey) => {

                await this.setState({
                    permissions: { ...this.state.permissions, [key]: { ...this.state.permissions[key], [objKey]: value } }
                });
            });
        });
    }

    handleChangeParentSwitch = async (key, value) => {
        await this.setState({
            statuses: { ...this.state.statuses, [key]: value }
        });
        await Object.keys(this.state["permissions"][key]).map(async (objKey) => {

            await this.setState({
                permissions: { ...this.state.permissions, [key]: { ...this.state.permissions[key], [objKey]: value } }
            });
        });

        await this.checkSwitchs();
    }

    checkSwitchs = () => {
        let parentCount = 0;
        Object.keys(this.state.permissions).map(async (parentKey) => {
            let childCount = 0;
            Object.keys(this.state.permissions[parentKey]).map(async (childKey) => {
                if (this.state.permissions[parentKey][childKey]) {
                    childCount++;
                }
            });
            if (Object.keys(this.state.permissions[parentKey]).length === childCount) {
                parentCount++;
                this.setState({
                    statuses: { ...this.state.statuses, [parentKey]: true }
                });
            } else {
                this.setState({ statuses: { ...this.state.statuses, [parentKey]: false } });
            }
        });
        if (Object.keys(this.state.permissions).length === parentCount) {
            parentCount++;
            this.setState({
                statuses: { ...this.state.statuses, main: true }
            });
        } else {
            this.setState({
                statuses: { ...this.state.statuses, main: false }
            });
        }
    }

    checkSwitchsUpdate = () => {
        let parentCount = 0;
        const statuses = Object.assign({}, this.state.statuses);
        Object.keys(this.state.permissions).map(async (parentKey) => {
            let childCount = 0;
            Object.keys(this.state.permissions[parentKey]).map(async (childKey) => {
                if (this.state.permissions[parentKey][childKey]) {
                    childCount++;
                    if (Object.keys(this.state.permissions[parentKey]).length === childCount) {
                        parentCount++;
                        statuses[parentKey] = true;
                    }
                }
            });
            if (Object.keys(this.state.permissions).length === parentCount) {
                parentCount++;
                statuses.main = true;
            }
        });
        this.setState({
            statuses
        });
    }

    handleCityChange = async (city) => {
        if (city.value === "") {
            this.setState({ city, county: { value: "", label: "Bir İlçe Seçiniz.." }, countyStatus: false })
        }
        else {
            await this.props.getCity(city.value);
            this.setState({ city, county: { value: "", label: "Bir İlçe Seçiniz.." }, countyStatus: true })
        }
    }

    render() {
        const { classes } = this.props;
        let references;
        if (this.props.personType === 1) {
            references = this.props.references.map((reseller) => {
                return this.props._id !== reseller._id ? { value: reseller._id, label: reseller.title } : false
            }) || [];
            references = references.filter(function (el) {
                return el !== false;
            });
        }
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>

                <input type="hidden" name="_id" defaultValue={this.props._id} />
                {this.props.personType === 1 ? // tebaş müşterisi
                    <FormControl style={{ marginBottom: "15px", width: "100%" }}>
                        <FormLabel>Referans Müşteri</FormLabel>
                        <SearchSelect
                            isClearable={true}
                            value={this.state.reference}
                            onChange={(reference) => { this.setState({ reference }) }}
                            options={references}
                        />
                    </FormControl>
                    : ""}
                {
                    /* <FormControl required fullWidth>
                    <InputLabel htmlFor="name">Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={this.props.name}
                    />
                </FormControl>
                
                <FormControl fullWidth>
                    <InputLabel htmlFor="lastName">İkicinci Adı</InputLabel>
                    <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        defaultValue={this.props.lastName}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel htmlFor="surname">Soyadı</InputLabel>
                    <Input
                        id="surname"
                        name="surname"
                        type="text"
                        defaultValue={this.props.surname}
                    />
                </FormControl> */
                
                }

                <FormControl fullWidth>
                    <InputLabel htmlFor="title">Ünvanı</InputLabel>
                    <Input
                        id="title"
                        name="title"
                        type="text"
                        defaultValue={this.props.title}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="mobilePhone">Cep Telefonu</InputLabel>
                    <Input
                        id="mobilePhone"
                        name="mobilePhone"
                        type="text"
                        defaultValue={this.props.mobilePhone}
                    />
                </FormControl>

                {
                    this.props.personType === 1 ? <div>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="landPhone">Sabit Telefon</InputLabel>
                            <Input
                                id="landPhone"
                                name="landPhone"
                                type="text"
                                defaultValue={this.props.landPhone}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="faxPhone">Fax Numarası</InputLabel>
                            <Input
                                id="faxPhone"
                                name="faxPhone"
                                type="text"
                                defaultValue={this.props.faxPhone}
                            />
                        </FormControl>
                        <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%" }}>
                            <FormLabel>İl</FormLabel>
                            <SearchSelect
                                value={this.state.city}
                                onChange={this.handleCityChange}
                                options={
                                    this.props.cities.map((city) => {
                                        return { value: city._id, label: city.name }
                                    })
                                }
                            />
                        </FormControl>
                        <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", marginLeft: "20%" }}>
                            <FormLabel>İlçe</FormLabel>
                            <SearchSelect
                                value={this.state.county}
                                onChange={(county) => { this.setState({ county }) }}
                                isDisabled={!this.state.countyStatus}
                                options={
                                    this.props.selectedCity.counties ? this.props.selectedCity.counties.map((county) => {
                                        return { value: county._id, label: county.name }
                                    }) : []
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="webSite">Web Site</InputLabel>
                            <Input
                                id="webSite"
                                name="webSite"
                                type="text"
                                defaultValue={this.props.webSite}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="taxOffice">Vergi Dairesi</InputLabel>
                            <Input
                                id="taxOffice"
                                name="taxOffice"
                                type="text"
                                defaultValue={this.props.taxOffice}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="taxNumber">Vergi Numarası / TC</InputLabel>
                            <Input
                                id="taxNumber"
                                name="taxNumber"
                                type="text"
                                defaultValue={this.props.taxNumber}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="description">Açıklama</InputLabel>
                            <Input
                                multiline
                                rows={4}
                                id="description"
                                name="description"
                                type="text"
                                defaultValue={this.props.description}
                            />
                        </FormControl>

                    </div> : ""
                }
                {
                    this.props.personType === 0 ? <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%" }}>
                        <FormLabel>Departman</FormLabel>
                        <SearchSelect
                            value={this.state.department}
                            onChange={this.departmentChange}
                            options={
                                this.props.departments.map((department) => {
                                    return { value: department._id, label: department.name }
                                })
                            }
                        />
                    </FormControl> : ""
                }
                <FormControl required fullWidth>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        defaultValue={this.props.email}
                    />
                </FormControl>
                <Card>
                    <CardContent style={{ width: "100%", padding: "5px 15px", }}>
                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, main: !this.state.fades.main } })}>
                            <h3>Yetkiler <Switch checked={this.state.statuses.main} onChange={() => this.handleChangeAllSwitch(!this.state.statuses.main)} aria-label="Collapse" /> (Tam Yetki)</h3>
                            <h3 >{this.state.fades.main ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h3>
                        </div>

                        <div style={{ display: this.state.fades.main ? "block" : "none" }}>
                            {this.props.personType !== 1 && this.props.personType !== 2 ? <div>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, person: !this.state.fades.person } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Personel Yönetimi <Switch checked={this.state.statuses.person} onChange={() => this.handleChangeParentSwitch("person", !this.state.statuses.person)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.person ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.person ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["person"]["read"]} onChange={() => this.handleChangeChildSwitch("person", "read", !this.state["permissions"]["person"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["person"]["create"]} onChange={() => this.handleChangeChildSwitch("person", "create", !this.state["permissions"]["person"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["person"]["update"]} onChange={() => this.handleChangeChildSwitch("person", "update", !this.state["permissions"]["person"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["person"]["delete"]} onChange={() => this.handleChangeChildSwitch("person", "delete", !this.state["permissions"]["person"]["delete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, department: !this.state.fades.department } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Departman Yönetimi <Switch checked={this.state.statuses.department} onChange={() => this.handleChangeParentSwitch("department", !this.state.statuses.department)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.department ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.department ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["department"]["read"]} onChange={() => this.handleChangeChildSwitch("department", "read", !this.state["permissions"]["department"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["department"]["create"]} onChange={() => this.handleChangeChildSwitch("department", "create", !this.state["permissions"]["department"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["department"]["update"]} onChange={() => this.handleChangeChildSwitch("department", "update", !this.state["permissions"]["department"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["department"]["delete"]} onChange={() => this.handleChangeChildSwitch("department", "delete", !this.state["permissions"]["department"]["delete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, reseller: !this.state.fades.reseller } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Bayi Yönetimi <Switch checked={this.state.statuses.reseller} onChange={() => this.handleChangeParentSwitch("reseller", !this.state.statuses.reseller)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.reseller ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.reseller ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["reseller"]["read"]} onChange={() => this.handleChangeChildSwitch("reseller", "read", !this.state["permissions"]["reseller"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["reseller"]["create"]} onChange={() => this.handleChangeChildSwitch("reseller", "create", !this.state["permissions"]["reseller"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["reseller"]["update"]} onChange={() => this.handleChangeChildSwitch("reseller", "update", !this.state["permissions"]["reseller"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["reseller"]["delete"]} onChange={() => this.handleChangeChildSwitch("reseller", "delete", !this.state["permissions"]["reseller"]["delete"])} aria-label="Collapse" /></h5>

                                                <h5>Personel Görme <Switch checked={this.state["permissions"]["reseller"]["personRead"]} onChange={() => this.handleChangeChildSwitch("reseller", "personRead", !this.state["permissions"]["reseller"]["personRead"])} aria-label="Collapse" /></h5>
                                                <h5>Personel Ekleme <Switch checked={this.state["permissions"]["reseller"]["personCreate"]} onChange={() => this.handleChangeChildSwitch("reseller", "personCreate", !this.state["permissions"]["reseller"]["personCreate"])} aria-label="Collapse" /></h5>
                                                <h5>Personel Güncelleme <Switch checked={this.state["permissions"]["reseller"]["personUpdate"]} onChange={() => this.handleChangeChildSwitch("reseller", "personUpdate", !this.state["permissions"]["reseller"]["personUpdate"])} aria-label="Collapse" /></h5>
                                                <h5>Personel Silme <Switch checked={this.state["permissions"]["reseller"]["personDelete"]} onChange={() => this.handleChangeChildSwitch("reseller", "personDelete", !this.state["permissions"]["reseller"]["personDelete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ width: "100%", padding: "0px 25px", cursor: "pointer" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => this.setState({ fades: { ...this.state.fades, task: !this.state.fades.task } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Görev Yönetimi <Switch checked={this.state.statuses.task} onChange={() => this.handleChangeParentSwitch("task", !this.state.statuses.task)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.task ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.task ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["task"]["read"]} onChange={() => this.handleChangeChildSwitch("task", "read", !this.state["permissions"]["task"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["task"]["create"]} onChange={() => this.handleChangeChildSwitch("task", "create", !this.state["permissions"]["task"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["task"]["update"]} onChange={() => this.handleChangeChildSwitch("task", "update", !this.state["permissions"]["task"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["task"]["delete"]} onChange={() => this.handleChangeChildSwitch("task", "delete", !this.state["permissions"]["task"]["delete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ cursor: "pointer", width: "100%", padding: "0px 25px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => this.setState({ fades: { ...this.state.fades, note: !this.state.fades.note } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Not Yönetimi <Switch checked={this.state.statuses.note} onChange={() => this.handleChangeParentSwitch("note", !this.state.statuses.note)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.note ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.note ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["note"]["read"]} onChange={() => this.handleChangeChildSwitch("note", "read", !this.state["permissions"]["note"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["note"]["create"]} onChange={() => this.handleChangeChildSwitch("note", "create", !this.state["permissions"]["note"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["note"]["update"]} onChange={() => this.handleChangeChildSwitch("note", "update", !this.state["permissions"]["note"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["note"]["delete"]} onChange={() => this.handleChangeChildSwitch("note", "delete", !this.state["permissions"]["note"]["delete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, stockCard: !this.state.fades.stockCard } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Stok Yönetimi <Switch checked={this.state.statuses.stockCard} onChange={() => this.handleChangeParentSwitch("stockCard", !this.state.statuses.stockCard)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.stockCard ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.stockCard ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["stockCard"]["read"]} onChange={() => this.handleChangeChildSwitch("stockCard", "read", !this.state["permissions"]["stockCard"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["stockCard"]["create"]} onChange={() => this.handleChangeChildSwitch("stockCard", "create", !this.state["permissions"]["stockCard"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["stockCard"]["update"]} onChange={() => this.handleChangeChildSwitch("stockCard", "update", !this.state["permissions"]["stockCard"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["stockCard"]["delete"]} onChange={() => this.handleChangeChildSwitch("stockCard", "delete", !this.state["permissions"]["stockCard"]["delete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginBottom: "15px" }}>
                                    <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, product: !this.state.fades.product } })}>
                                            <h4 style={{ padding: "0px", margin: "0px" }}>Ürün Yönetimi <Switch checked={this.state.statuses.product} onChange={() => this.handleChangeParentSwitch("product", !this.state.statuses.product)} aria-label="Collapse" /></h4>
                                            <h4>{this.state.fades.product ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                        </div>
                                        <div style={{ display: this.state.fades.product ? "block" : "none" }}>
                                            <div style={{ padding: "0px 25px" }}>
                                                <h5>Görme <Switch checked={this.state["permissions"]["product"]["read"]} onChange={() => this.handleChangeChildSwitch("product", "read", !this.state["permissions"]["product"]["read"])} aria-label="Collapse" /></h5>
                                                <h5>Ekleme <Switch checked={this.state["permissions"]["product"]["create"]} onChange={() => this.handleChangeChildSwitch("product", "create", !this.state["permissions"]["product"]["create"])} aria-label="Collapse" /></h5>
                                                <h5>Güncelleme <Switch checked={this.state["permissions"]["product"]["update"]} onChange={() => this.handleChangeChildSwitch("product", "update", !this.state["permissions"]["product"]["update"])} aria-label="Collapse" /></h5>
                                                <h5>Silme <Switch checked={this.state["permissions"]["product"]["delete"]} onChange={() => this.handleChangeChildSwitch("product", "delete", !this.state["permissions"]["product"]["delete"])} aria-label="Collapse" /></h5>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>


                            </div> : ""
                            }
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, block: !this.state.fades.block } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Blok Yönetimi <Switch checked={this.state.statuses.block} onChange={() => this.handleChangeParentSwitch("block", !this.state.statuses.block)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.block ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.block ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["block"]["read"]} onChange={() => this.handleChangeChildSwitch("block", "read", !this.state["permissions"]["block"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["block"]["create"]} onChange={() => this.handleChangeChildSwitch("block", "create", !this.state["permissions"]["block"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["block"]["update"]} onChange={() => this.handleChangeChildSwitch("block", "update", !this.state["permissions"]["block"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["block"]["delete"]} onChange={() => this.handleChangeChildSwitch("block", "delete", !this.state["permissions"]["block"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, project: !this.state.fades.project } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Proje Yönetimi <Switch checked={this.state.statuses.project} onChange={() => this.handleChangeParentSwitch("project", !this.state.statuses.project)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.project ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.project ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["project"]["read"]} onChange={() => this.handleChangeChildSwitch("project", "read", !this.state["permissions"]["project"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["project"]["create"]} onChange={() => this.handleChangeChildSwitch("project", "create", !this.state["permissions"]["project"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["project"]["update"]} onChange={() => this.handleChangeChildSwitch("project", "update", !this.state["permissions"]["project"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["project"]["delete"]} onChange={() => this.handleChangeChildSwitch("project", "delete", !this.state["permissions"]["project"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, apartment: !this.state.fades.apartment } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Daire Yönetimi <Switch checked={this.state.statuses.apartment} onChange={() => this.handleChangeParentSwitch("apartment", !this.state.statuses.apartment)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.apartment ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.apartment ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["apartment"]["read"]} onChange={() => this.handleChangeChildSwitch("apartment", "read", !this.state["permissions"]["apartment"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["apartment"]["create"]} onChange={() => this.handleChangeChildSwitch("apartment", "create", !this.state["permissions"]["apartment"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["apartment"]["update"]} onChange={() => this.handleChangeChildSwitch("apartment", "update", !this.state["permissions"]["apartment"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["apartment"]["delete"]} onChange={() => this.handleChangeChildSwitch("apartment", "delete", !this.state["permissions"]["apartment"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, subscription: !this.state.fades.subscription } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Abonelik Yönetimi <Switch checked={this.state.statuses.subscription} onChange={() => this.handleChangeParentSwitch("subscription", !this.state.statuses.subscription)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.subscription ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.subscription ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["subscription"]["read"]} onChange={() => this.handleChangeChildSwitch("subscription", "read", !this.state["permissions"]["subscription"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["subscription"]["create"]} onChange={() => this.handleChangeChildSwitch("subscription", "create", !this.state["permissions"]["subscription"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["subscription"]["update"]} onChange={() => this.handleChangeChildSwitch("subscription", "update", !this.state["permissions"]["subscription"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["subscription"]["delete"]} onChange={() => this.handleChangeChildSwitch("subscription", "delete", !this.state["permissions"]["subscription"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, mainCounter: !this.state.fades.mainCounter } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Ana Sayaç Yönetimi <Switch checked={this.state.statuses.mainCounter} onChange={() => this.handleChangeParentSwitch("mainCounter", !this.state.statuses.mainCounter)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.mainCounter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.mainCounter ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["mainCounter"]["read"]} onChange={() => this.handleChangeChildSwitch("mainCounter", "read", !this.state["permissions"]["mainCounter"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["mainCounter"]["create"]} onChange={() => this.handleChangeChildSwitch("mainCounter", "create", !this.state["permissions"]["mainCounter"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["mainCounter"]["update"]} onChange={() => this.handleChangeChildSwitch("mainCounter", "update", !this.state["permissions"]["mainCounter"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["mainCounter"]["delete"]} onChange={() => this.handleChangeChildSwitch("mainCounter", "delete", !this.state["permissions"]["mainCounter"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, apartmentCounter: !this.state.fades.apartmentCounter } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Daire Sayaç Yönetimi <Switch checked={this.state.statuses.apartmentCounter} onChange={() => this.handleChangeParentSwitch("apartmentCounter", !this.state.statuses.apartmentCounter)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.apartmentCounter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.apartmentCounter ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["apartmentCounter"]["read"]} onChange={() => this.handleChangeChildSwitch("apartmentCounter", "read", !this.state["permissions"]["apartmentCounter"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["apartmentCounter"]["create"]} onChange={() => this.handleChangeChildSwitch("apartmentCounter", "create", !this.state["permissions"]["apartmentCounter"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["apartmentCounter"]["update"]} onChange={() => this.handleChangeChildSwitch("apartmentCounter", "update", !this.state["permissions"]["apartmentCounter"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["apartmentCounter"]["delete"]} onChange={() => this.handleChangeChildSwitch("apartmentCounter", "delete", !this.state["permissions"]["apartmentCounter"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, period: !this.state.fades.period } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Dönem Yönetimi <Switch checked={this.state.statuses.period} onChange={() => this.handleChangeParentSwitch("period", !this.state.statuses.period)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.period ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.period ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["period"]["read"]} onChange={() => this.handleChangeChildSwitch("period", "read", !this.state["permissions"]["period"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["period"]["create"]} onChange={() => this.handleChangeChildSwitch("period", "create", !this.state["permissions"]["period"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["period"]["update"]} onChange={() => this.handleChangeChildSwitch("period", "update", !this.state["permissions"]["period"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["period"]["delete"]} onChange={() => this.handleChangeChildSwitch("period", "delete", !this.state["permissions"]["period"]["delete"])} aria-label="Collapse" /></h5>
                                            <h5>Fatura Ekleme <Switch checked={this.state["permissions"]["period"]["addInvoice"]} onChange={() => this.handleChangeChildSwitch("period", "addInvoice", !this.state["permissions"]["period"]["addInvoice"])} aria-label="Collapse" /></h5>
                                            <h5>Fatura Güncelleme <Switch checked={this.state["permissions"]["period"]["updateInvoice"]} onChange={() => this.handleChangeChildSwitch("period", "updateInvoice", !this.state["permissions"]["period"]["updateInvoice"])} aria-label="Collapse" /></h5>
                                            <h5>Daire Sayaç Verisi Ekleme <Switch checked={this.state["permissions"]["period"]["updateApartmentCounterData"]} onChange={() => this.handleChangeChildSwitch("period", "updateApartmentCounterData", !this.state["permissions"]["period"]["updateApartmentCounterData"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ marginBottom: "15px" }}>
                                <CardContent style={{ width: "100%", padding: "0px 25px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => this.setState({ fades: { ...this.state.fades, additonalExpenseData: !this.state.fades.additonalExpenseData } })}>
                                        <h4 style={{ padding: "0px", margin: "0px" }}>Ek Gider Yönetimi <Switch checked={this.state.statuses.additonalExpenseData} onChange={() => this.handleChangeParentSwitch("additonalExpenseData", !this.state.statuses.additonalExpenseData)} aria-label="Collapse" /></h4>
                                        <h4>{this.state.fades.additonalExpenseData ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h4>
                                    </div>
                                    <div style={{ display: this.state.fades.additonalExpenseData ? "block" : "none" }}>
                                        <div style={{ padding: "0px 25px" }}>
                                            <h5>Görme <Switch checked={this.state["permissions"]["additonalExpenseData"]["read"]} onChange={() => this.handleChangeChildSwitch("additonalExpenseData", "read", !this.state["permissions"]["additonalExpenseData"]["read"])} aria-label="Collapse" /></h5>
                                            <h5>Ekleme <Switch checked={this.state["permissions"]["additonalExpenseData"]["create"]} onChange={() => this.handleChangeChildSwitch("additonalExpenseData", "create", !this.state["permissions"]["additonalExpenseData"]["create"])} aria-label="Collapse" /></h5>
                                            <h5>Güncelleme <Switch checked={this.state["permissions"]["additonalExpenseData"]["update"]} onChange={() => this.handleChangeChildSwitch("additonalExpenseData", "update", !this.state["permissions"]["additonalExpenseData"]["update"])} aria-label="Collapse" /></h5>
                                            <h5>Silme <Switch checked={this.state["permissions"]["additonalExpenseData"]["delete"]} onChange={() => this.handleChangeChildSwitch("additonalExpenseData", "delete", !this.state["permissions"]["additonalExpenseData"]["delete"])} aria-label="Collapse" /></h5>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </CardContent>
                </Card>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    GÜNCELLE
                </Button>
            </form>
        )
    }
}
export default withStyles(styles)(EditPersonForm);