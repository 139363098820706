import axios from 'axios';
import { API_URL } from '../config/config';


export const ADD_MAIN_COUNTER_PENDING = "ADD_MAIN_COUNTER_PENDING";
export const ADD_MAIN_COUNTER_FULFILLED = "ADD_MAIN_COUNTER_FULFILLED";
export const ADD_MAIN_COUNTER_REJECTED = "ADD_MAIN_COUNTER_REJECTED";

export const UPDATE_MAIN_COUNTER_PENDING = "UPDATE_MAIN_COUNTER_PENDING";
export const UPDATE_MAIN_COUNTER_FULFILLED = "UPDATE_MAIN_COUNTER_FULFILLED";
export const UPDATE_MAIN_COUNTER_REJECTED = "UPDATE_MAIN_COUNTER_REJECTED";

export const GET_PROJECT_MAIN_COUNTERS_PENDING = "GET_PROJECT_MAIN_COUNTERS_PENDING";
export const GET_PROJECT_MAIN_COUNTERS_FULFILLED = "GET_PROJECT_MAIN_COUNTERS_FULFILLED";
export const GET_PROJECT_MAIN_COUNTERS_REJECTED = "GET_PROJECT_MAIN_COUNTERS_REJECTED";

export const DELETE_MAIN_COUNTER_PENDING = "DELETE_MAIN_COUNTER_PENDING";
export const DELETE_MAIN_COUNTER_FULFILLED = "DELETE_MAIN_COUNTER_FULFILLED";
export const DELETE_MAIN_COUNTER_REJECTED = "DELETE_MAIN_COUNTER_REJECTED";

export const GET_PERIOD_MAIN_COUNTER_DATAS_PENDING = "GET_PERIOD_MAIN_COUNTER_DATAS_PENDING";
export const GET_PERIOD_MAIN_COUNTER_DATAS_FULFILLED = "GET_PERIOD_MAIN_COUNTER_DATAS_FULFILLED";
export const GET_PERIOD_MAIN_COUNTER_DATAS_REJECTED = "GET_PERIOD_MAIN_COUNTER_DATAS_REJECTED";

export function addMainCounter(mainCounter, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_MAIN_COUNTER",
            payload: axios.post(`${API_URL}/main-counter`, mainCounter, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updateMainCounter(mainCounter, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_MAIN_COUNTER",
            payload: axios.put(`${API_URL}/main-counter`, mainCounter, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getPeriodMainCounterDatas(period, project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PERIOD_MAIN_COUNTER_DATAS",
            payload: axios.get(`${API_URL}/main-counter/period/${period}/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getProjectMainCounters(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_MAIN_COUNTERS",
            payload: axios.get(`${API_URL}/main-counter/project/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteMainCounter(mainCounter, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_MAIN_COUNTER",
            payload: axios.delete(`${API_URL}/main-counter`, { data: { ...mainCounter, token } })
                .then(result => result.data)
        })
    }
}

