import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_ALL_TASKTYPE_PENDING = "GET_ALL_TASKTYPE_PENDING";
export const GET_ALL_TASKTYPE_FULFILLED = "GET_ALL_TASKTYPE_FULFILLED";
export const GET_ALL_TASKTYPE_REJECTED = "GET_ALL_TASKTYPE_REJECTED";

export const GET_TASKTYPE_PENDING = "GET_TASKTYPE_PENDING";
export const GET_TASKTYPE_FULFILLED = "GET_TASKTYPE_FULFILLED";
export const GET_TASKTYPE_REJECTED = "GET_TASKTYPE_REJECTED";

export function getAllTaskTypes(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_TASKTYPE",
            payload: axios.get(`${API_URL}/task-type/all`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function getTaskType(taskTypeId, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_CITY",
            payload: axios.get(`${API_URL}/taskType/${taskTypeId}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}