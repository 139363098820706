import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_PENDING = "GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_PENDING";
export const GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_FULFILLED = "GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_FULFILLED";
export const GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_REJECTED = "GET_PROJECT_ADDITIONAL_EXPENSE_DATAS_REJECTED";

export const ADD_ADDITIONAL_EXPENSE_DATA_PENDING = "ADD_ADDITIONAL_EXPENSE_DATA_PENDING";
export const ADD_ADDITIONAL_EXPENSE_DATA_FULFILLED = "ADD_ADDITIONAL_EXPENSE_DATA_FULFILLED";
export const ADD_ADDITIONAL_EXPENSE_DATA_REJECTED = "ADD_ADDITIONAL_EXPENSE_DATA_REJECTED";

export const DELETE_ADDITIONAL_EXPENSE_DATA_PENDING = "DELETE_ADDITIONAL_EXPENSE_DATA_PENDING";
export const DELETE_ADDITIONAL_EXPENSE_DATA_FULFILLED = "DELETE_ADDITIONAL_EXPENSE_DATA_FULFILLED";
export const DELETE_ADDITIONAL_EXPENSE_DATA_REJECTED = "DELETE_ADDITIONAL_EXPENSE_DATA_REJECTED";

export function addAdditionalExpenseData(additionalExpenseData, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_ADDITIONAL_EXPENSE_DATA",
            payload: axios.post(`${API_URL}/additional-expense-data/`, additionalExpenseData, { headers: { "x-access-token": token } })
                .then(result => result.data)
        });
    }
}

export function getPeriodAdditionalExpenseDatas(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_ADDITIONAL_EXPENSE_DATAS",
            payload: axios.get(`${API_URL}/additional-expense-data/project/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        });
    }
}

export function deleteAdditionalExpenseData(additionalExpenseData, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_ADDITIONAL_EXPENSE_DATA",
            payload: axios.delete(`${API_URL}/additional-expense-data`, { data: { ...additionalExpenseData, token: token } })
                .then(result => result.data)
        })
    }
}