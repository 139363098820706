import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import Moment from 'react-moment';


class ProposalTable extends Component {

    render() {
        const tableData = {
            title: "Görüş ve Öneriler",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [

                { title: "Oluşturan Kişi", field: "person.title" },
                { title: "Konu", field: "subject" },
                { title: "Açıklaması", field: "description" },
                {
                    title: "Oluşturulma Tarihi", field: "createdAt",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.createdAt}
                            </Moment>
                        )
                    }
                },
            ],
            data: this.props.proposals
        };
        let tableActions = [];

        // if (this.props.person.permissions.project.delete) {
        //     tableActions.push({
        //         icon: 'delete',
        //         tooltip: 'Sil',
        //         onClick: this.props.delete,
        //         iconProps: {
        //             style: {
        //                 fontSize: 40,
        //                 color: 'red'
        //             }
        //         }
        //     });
        // }

        // if (this.props.person.permissions.project.create) {
        //     tableActions.push({
        //         icon: 'plus_one',
        //         isFreeAction: true,
        //         tooltip: 'Yeni Kayıt Ekle',
        //         onClick: this.props.addForm,
        //         iconProps: {
        //             style: {
        //                 fontSize: 40,
        //                 color: 'green'
        //             }
        //         }
        //     });
        // }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ProposalTable;