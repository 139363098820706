import {
    UPDATE_MAIN_COUNTER_DATA_PENDING, UPDATE_MAIN_COUNTER_DATA_FULFILLED, UPDATE_MAIN_COUNTER_DATA_REJECTED
} from '../actions/mainCounterData';
const initialState = {
    updateMainCounterDataResult: {},
    updateMainCounterDataLoading: false,
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {


        case UPDATE_MAIN_COUNTER_DATA_PENDING:
            return {
                ...state,
                updateMainCounterDataLoading: true,
            }
        case UPDATE_MAIN_COUNTER_DATA_FULFILLED:
            return {
                ...state,
                updateMainCounterDataResult: action.payload,
                updateMainCounterDataLoading: false,
            }
        case UPDATE_MAIN_COUNTER_DATA_REJECTED:
            return {
                ...state,
                updateMainCounterDataLoading: false,
            }

        default:
            return state;
    }
}