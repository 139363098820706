import {
    ADD_STOCK_CARD_PENDING, ADD_STOCK_CARD_FULFILLED, ADD_STOCK_CARD_REJECTED,
    GET_ALL_STOCK_CARD_PENDING, GET_ALL_STOCK_CARD_FULFILLED, GET_ALL_STOCK_CARD_REJECTED,
    DELETE_STOCK_CARD_PENDING, DELETE_STOCK_CARD_FULFILLED, DELETE_STOCK_CARD_REJECTED,
    PUT_STOCK_CARD_REJECTED, PUT_STOCK_CARD_FULFILLED, PUT_STOCK_CARD_PENDING,
} from '../actions/stockCard';

const initialState = {
    addStockCardloading: false,
    getAllStockCardLoading: false,
    updateStockCardloading: false,
    deleteStockCardLoading: false,
    stockCards: [],
    addStockCardResult: {},
    deleteStockCardResult: {},
    updateStockCardResult: {},
}
export default (state = initialState, action) => {
    switch (action.type) {

        case ADD_STOCK_CARD_PENDING:
            return {
                ...state,
                addStockCardloading: true,
            }
        case ADD_STOCK_CARD_FULFILLED:
            return {
                ...state,
                addStockCardResult: action.payload,
                addStockCardloading: false,
            }
        case ADD_STOCK_CARD_REJECTED:
            return {
                ...state,
                addStockCardloading: false,
            }
        case GET_ALL_STOCK_CARD_PENDING:
            return {
                ...state,
                getAllStockCardLoading: true,
            }
        case GET_ALL_STOCK_CARD_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAllStockCardLoading: false,
            }
        case GET_ALL_STOCK_CARD_REJECTED:
            return {
                ...state,
                getAllStockCardLoading: false,
            }
        case PUT_STOCK_CARD_REJECTED:
            return {
                ...state,
                updateStockCardloading: true,
            }
        case PUT_STOCK_CARD_FULFILLED:
            return {
                ...state,
                updateStockCardResult: action.payload,
                updateStockCardloading: false,
            }
        case PUT_STOCK_CARD_PENDING:
            return {
                ...state,
                updateStockCardloading: false,
            }
        case DELETE_STOCK_CARD_PENDING:
            return {
                ...state,
                deleteStockCardLoading: true,
            }
        case DELETE_STOCK_CARD_FULFILLED:
            return {
                ...state,
                deleteStockCardResult: action.payload,
                deleteStockCardLoading: false
            }
        case DELETE_STOCK_CARD_REJECTED:
            return {
                ...state,
                deleteStockCardLoading: false
            }

        default:
            return state;
    }
}