import {
    GET_SUBSCRIPTION_TYPES_PENDING, GET_SUBSCRIPTION_TYPES_FULFILLED, GET_SUBSCRIPTION_TYPES_REJECTED,
} from '../actions/subscriptionType';
const initialState = {
    subscriptionTypes: [],
    getSubscriptionTypesLoading: false,
}
// sharingType işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_TYPES_PENDING:
            return {
                ...state,
                getSubscriptionTypesLoading: true,
            }
        case GET_SUBSCRIPTION_TYPES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getSubscriptionTypesLoading: false,
            }
        case GET_SUBSCRIPTION_TYPES_REJECTED:
            return {
                ...state,
                getSubscriptionTypesLoading: false,
            }
        default:
            return state;
    }
}