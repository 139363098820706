import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button, Switch, Chip } from '@material-ui/core';
import moment from 'moment';

import "../../styles/app.css";

class MainCountersTable extends Component {

    state = {
        menuStatus: false,
        anchorEl: null,
    }

    render() {

        const tableData = {
            title: "Mevcut Ana Sayaç Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },

            columns: [
                this.props.person.permissions.mainCounter.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <div style={{ width: "100%" }}>
                                <details>
                                    <summary className="dropdownLink">
                                        İşlem Menüsü
                                        </summary>
                                    <div className="dropdownWrapper">
                                        <span className="dropdownItem">
                                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                                        </span>
                                        <span className="dropdownItem">
                                            <Button onClick={() => this.props.getCreateApartmentCounterExcel(rowData)}>Sayaç Ekleme Excel Dosyası Al</Button>
                                        </span>
                                        <span className="dropdownItem">
                                            <Button onClick={this.props.uploadApartmentCounterExcelList}>Eklenecek Sayaç Excel'ini Yükle</Button>
                                        </span>
                                    </div>
                                </details>
                            </div>
                        )
                    },
                } : {},
                {
                    title: "Bulunduğu Proje", field: "project.name", hidden: true,
                    render: rowData => {
                        return (
                            <a href={"/project/" + rowData.project._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.project.name}</a>
                        );
                    }
                },
                {
                    title: "Abonelik", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                <a href={"/subscription/" + rowData.subscription._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.subscription.name}</a>
                                {rowData.type._id === "5cefd0dd45844c0a69b509d0" && rowData.subscriptionWater ?
                                    <a style={{ color: "#e61515", fontWeight: "500", display: "inline-block", paddingTop: "6px" }} href={"/subscription/" + rowData.subscriptionWater._id}>{rowData.subscriptionWater.name}</a>
                                    : ""}
                            </div>
                        );
                    }
                },
                {
                    title: "Adı", field: "name",
                    render: rowData => {
                        return (
                            rowData.name
                        );
                    }
                },
                {
                    title: "Tipi", field: "name",
                    render: rowData => {
                        return (
                            <Button style={{ fontSize: "10px", color: "#fff", backgroundColor: rowData.type.color }} >{rowData.type.name} {rowData.heatingOnly ? <div style={{ color: "#1defb4", fontWeight: "800" }}> (Sadece Isıtma)</div> : ""
                            }</Button>
                        );
                    }
                },
                {
                    title: "Paylaşım Durumu",
                    render: rowData => {
                        return (
                            <div>
                                <Switch checked={rowData.sharingStatus} aria-label="Collapse" />
                            </div>
                        );
                    }
                },
                {
                    title: "Seri Numarası", field: "serialNumber", hidden: true,
                    render: rowData => {
                        return (
                            rowData.serialNumber
                        );
                    }
                },
                {
                    title: "Son Alınan Endeks", hidden: true,
                    render: rowData => {
                        return (
                            rowData.counterValue
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Bloklar",
                    render: rowData => {
                        return (
                            rowData.distributionBlocks.map((block) => {
                                return <Chip key={block._id} style={{ backgroundColor: "#2ebd7b", color: "#fff" }} label={block.name} />
                            })
                        );
                    }
                },
                {
                    title: "Bağlı Sayaç Sayısı",
                    render: rowData => {
                        return (
                            <div>
                                {rowData.apartmentCounters.length}
                            </div>
                        );
                    }
                },
                {
                    title: "Oluşturma Tarihi", hidden: true,
                    render: rowData => {
                        return (
                            moment(rowData.createdAt).format("YYYY-MM-DDTHH:mm")
                        );
                    }
                },
            ],
            data: this.props.mainCounters
        };

        let tableActions = [];
        if (this.props.person.permissions.mainCounter.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.mainCounter.create) {
            tableActions.push(
                { // tekli ekle
                    icon: 'plus_one',
                    isFreeAction: true,
                    tooltip: 'Yeni Kayıt Ekle',
                    onClick: this.props.addForm,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: 'green'
                        }
                    }
                }
            );
        }
        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default MainCountersTable;