
import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
// import Business from '@material-ui/icons/Business';
import swal from 'sweetalert';
import { Button, Divider, Grid, Typography, Tab, Tabs, AppBar, TextField } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog'
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
import ResetPasswordForm from '../Items/ResetPasswordForm';
import { resetPersonPassword } from '../../actions/person';
import '../../styles/permissionsTree.css';
import moment from 'moment';

const styles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        fontWeight: 800
    },
    pos: {
        marginBottom: 12,
    },
};

class PersonProfile extends Component {
    // component içerisinde kullanılacak state
    state = {
        resetPersonPasswordDialog: false,
        value: 0,
        messageKey: '',
        rowData: {
            name: ''
        },
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        await this.props.setActivePageTitle(this.props.person.title);
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", path: "/person/" + this.props.person._id, text: this.props.person.title },
        ];
        await this.props.setBreadCrumb(breadCrumb);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ resetPersonPasswordDialog: false });
        this.handleChangeTab("", this.state.value)
    }

    handleResetPersonPassword = async () => {
        this.setState({ resetPersonPasswordDialog: true });
    }

    handleResetPersonPasswordSubmit = async (e, state) => {

        if (e.target.password.value !== e.target.password_repeat.value) {
            this.props.enqueueSnackbar("Girmiş olduğunuz şifreler uyuşmuyor.. ", {
                variant: "error",
                persist: true,
                action: (
                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                ),
            });
            return;
        }

        const person = {
            personType: this.props.personType,
            _id: e.target._id.value,
            password: e.target.password.value,
        }

        return swal({
            title: "Emin misin?",
            text: "Şifreni Günecellemek İstediğinden!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })

            .then(async (value) => {
                if (value) {
                    await this.props.resetPersonPassword(person, this.props.token);
                    if (this.props.resetPersonPasswordResult.code === 200) {
                        this.setState({ resetPersonPasswordDialog: false });
                        this.props.enqueueSnackbar("Şifre güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Şifre sıfırlama işlemi başarısız.." + this.props.resetPersonPasswordResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleChangeTab = async (event, value) => {
        this.setState({ value });
        if (value === 2) { // eğer ki block sekmesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Bloklar" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllBlocks(this.props.project._id, this.props.token);
        }
        else if (value === 3) { // eğer ki abonelikler sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Abonelikler" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllSubscriptionTypes(this.props.token);
            await this.props.getAllSubscriptions(this.props.project._id, this.props.token);

        }
        else if (value === 4) { // eğer ki ana sayaç sekemesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.project._id, text: this.props.project.name },
                { type: "string", text: "Paylaşım Tipleri" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getProjectMainCounters(this.props.project._id, this.props.token);
        }
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>

                {this.props.getProjectSubscriptionsLoading ? <div className="loading" /> : ""}
                {this.props.getProjectMainCountersLoading ? <div className="loading" /> : ""}
                {this.props.getProjectBlocksLoading ? <div className="loading" /> : ""}
                {this.props.getSubscriptionTypesLoading ? <div className="loading" /> : ""}


                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Personel Unvanı
                                </Typography>
                            </Grid>
                            <br></br>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disabled={true}
                                    className={classes.textField}
                                    value={this.props.person.title || ""}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Personel Cep Telefon Numarası
                                </Typography>
                            </Grid>
                            <br></br>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disabled={true}
                                    className={classes.textField}
                                    value={this.props.person.mobilePhone || ""}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Personelin Mail Adresi
                                </Typography>
                            </Grid>
                            <br></br>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disabled={true}
                                    className={classes.textField}
                                    value={this.props.person.email || ""}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Personelin Sisteme Eklenme Tarihi
                                </Typography>
                            </Grid>
                            <br></br>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disabled={true}
                                    className={classes.textField}
                                    value={moment(this.props.person.createdAt).format("DD-MM-YYYY hh:ss") || ""}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        this.props.personType === 0 ? <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Personelin Departmanı
                                </Typography>
                                </Grid>
                                <br></br>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        disabled={true}
                                        className={classes.textField}
                                        value={this.props.person.department.name}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid> : ""
                    }
                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="tree">
                            <ul>
                                <li>
                                    <a href="#test">
                                        YETKİLER
                                    </a>
                                    <ul>
                                        <li><a href="#test">PERSONEL</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.person.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.person.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.person.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.person.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#test">DEPARTMAN</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.department.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.department.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.department.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.department.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#test">MÜŞTERİ</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.personalRead ? "yesil" : "kirmizi"}>Personel Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.personalCreate ? "yesil" : "kirmizi"}>Personel Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.personalUpdate ? "yesil" : "kirmizi"}>Personel Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.reseller.personalDelete ? "yesil" : "kirmizi"}>Personel Silme</a>
                                                </li>

                                            </ul>
                                        </li>

                                        <li>
                                            <a href="#test">PROJE</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.project.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.project.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.project.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.project.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#test">BLOK</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.block.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.block.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.block.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.block.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#test">DAİRE</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartment.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartment.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartment.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartment.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#test">ANA SAYAÇ</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.mainCounter.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.mainCounter.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.mainCounter.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.mainCounter.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#test">DAİRE SAYAÇI</a>
                                            <ul>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartmentCounter.read ? "yesil" : "kirmizi"}>Görme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartmentCounter.create ? "yesil" : "kirmizi"}>Ekleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartmentCounter.update ? "yesil" : "kirmizi"}>Güncelleme</a>
                                                </li>
                                                <li>
                                                    <a href="#test" className={this.props.person.permissions.apartmentCounter.delete ? "yesil" : "kirmizi"}>Silme</a>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleResetPersonPassword} >
                                    Şifreni Yenile
                                </Button>
                                <ModalDialog
                                    open={this.state.resetPersonPasswordDialog}
                                    title={'Şifre Güncelleme'}
                                    buttons={[
                                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                                    ]}>
                                    <ResetPasswordForm _id={this.props.person._id} onSubmitForm={this.handleResetPersonPasswordSubmit} />
                                </ModalDialog>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br /> <br /> <br /> <br />
                <Divider variant="middle" />
                <br /> <br /> <br /> <br />
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTab}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="" disabled />
                        {/* <Tab label="123 " icon={<Business />} /> */}
                    </Tabs>
                </AppBar>
                {value === 0 && <Typography component="div" style={{ padding: 8 * 3 }}>Bir Sekme Seçin..</Typography>}
            </div >
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer }) => {
    return {
        ...personReducer,
    }
}

// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb,
    resetPersonPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(PersonProfile)));
