import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles";
import { Input, Button, FormControl, InputLabel, FormLabel } from "@material-ui/core";
import moment from 'moment';
import styles from '../../styles/app';

class RequestSharingForm extends Component {

    state = {

    }
    
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <input
                        type="hidden"
                        name="_id"
                        defaultValue={this.props.activePeriod._id}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <FormLabel htmlFor="periodDate">Dönem Tarihi</FormLabel>
                    <Input
                        id="periodDate"
                        label="periodDate"
                        type="date"
                        defaultValue={moment(this.props.activePeriod.periodDate).format("YYYY-MM-DD")}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="lastPaymentDate">Son Ödeme Tarihi</InputLabel>
                    <Input
                        id="lastPaymentDate"
                        label="lastPaymentDate"
                        type="text"
                        defaultValue={this.props.activePeriod.lastPaymentDate}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="spendingPeriod">Harcama Dönemi</InputLabel>
                    <Input
                        id="spendingPeriod"
                        label="spendingPeriod"
                        type="text"
                        defaultValue={this.props.activePeriod.spendingPeriod}
                    />
                </FormControl>
                <FormControl fullWidth>

                    <InputLabel htmlFor="paymentPlace">Ödeme Yeri</InputLabel>
                    <Input
                        id="paymentPlace"
                        label="paymentPlace"
                        type="text"
                        defaultValue={this.props.activePeriod.paymentPlace}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="accountName">Hesap İsmi</InputLabel>
                    <Input
                        id="accountName"
                        label="accountName"
                        type="text"
                        defaultValue={this.props.activePeriod.accountName}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="ibanNumber">İban Numarası</InputLabel>
                    <Input
                        id="ibanNumber"
                        label="ibanNumber"
                        type="text"
                        defaultValue={this.props.activePeriod.ibanNumber}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="periodNote">Dönem Notu</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="periodNote"
                        name="periodNote"
                        type="text"
                        defaultValue={this.props.activePeriod.periodNote}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    KAYDET
                        </Button>
            </form>
        )
    }
}

RequestSharingForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RequestSharingForm);