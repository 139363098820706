import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_ADDRESS_PENDING = "ADD_ADDRESS_PENDING";
export const ADD_ADDRESS_FULFILLED = "ADD_ADDRESS_FULFILLED";
export const ADD_ADDRESS_REJECTED = " ADD_ADDRESS_REJECTED";

export const GET_ALL_ADDRESSES_PENDING = "GET_ALL_ADDRESSES_PENDING";
export const GET_ALL_ADDRESSES_FULFILLED = "GET_ALL_ADDRESSES_FULFILLED";
export const GET_ALL_ADDRESSES_REJECTED = "GET_ALL_ADDRESSES_REJECTED";

export const PUT_ADDRESS_PENDING = "PUT_ADDRESS_PENDING";
export const PUT_ADDRESS_FULFILLED = "PUT_ADDRESS_FULFILLED";
export const PUT_ADDRESS_REJECTED = "PUT_ADDRESS_REJECTED";

export const DELETE_ADDRESS_PENDING = "DELETE_ADDRESS_PENDING";
export const DELETE_ADDRESS_FULFILLED = "DELETE_ADDRESS_FULFILLED";
export const DELETE_ADDRESS_REJECTED = "DELETE_ADDRESS_REJECTED";

export function addAddress(address, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_ADDRESS",
            payload: axios.post(`${API_URL}/address`, address, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllAddresses(person, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_ADDRESSES",
            payload: axios.get(`${API_URL}/address/all/${person}`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function updateAddress(address, token) {
    return async dispatch => {
        await dispatch({
            type: "PUT_ADDRESS",
            payload: axios.put(`${API_URL}/address`, address, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteAddress(address, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_ADDRESS",
            payload: axios.delete(`${API_URL}/address`, { data: { ...address, token: token } })
                .then(result => result.data)
        })
    }
}

