import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { setActivePageTitle, setBreadCrumb, } from '../../actions/person';
import { getAllProposals, deleteProposal } from '../../actions/proposal';

import ProposalTable from './ProposalTable';
import swal from 'sweetalert';


class Proposal extends Component {

    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false
    }

    async UNSAFE_componentWillMount() {
        await this.props.getAllProposals(this.props.token);
        this.props.setActivePageTitle("GÖRÜŞ VE ÖNERİLER");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Müşteri Görüş ve Önerileri" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }

    handleDeleteProposal = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Bu seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (proposal) => {
                        await this.props.deleteProposal(proposal, this.props.token);
                        if (this.props.deleteProposalResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteProposalResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                }
                await this.props.getAllProposals(this.props.token);
            });
    }

    render() {
        return (
            <div>
                {this.props.addProposalLoading ? <div className="loading" /> : ""}
                {this.props.getAllProposalLoading ? <div className="loading" /> : ""}
                <ProposalTable {...this.props} delete={this.handleDeleteProposal} />
            </div>
        )
    }
}


// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer, proposalReducer }) => {
    return {
        ...personReducer,
        ...proposalReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb,
    getAllProposals,
    deleteProposal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Proposal));