import {
    ADD_ADDRESS_PENDING, ADD_ADDRESS_FULFILLED, ADD_ADDRESS_REJECTED,
    GET_ALL_ADDRESSES_PENDING, GET_ALL_ADDRESSES_FULFILLED, GET_ALL_ADDRESSES_REJECTED,
    PUT_ADDRESS_PENDING, PUT_ADDRESS_FULFILLED, PUT_ADDRESS_REJECTED,
    DELETE_ADDRESS_PENDING, DELETE_ADDRESS_FULFILLED, DELETE_ADDRESS_REJECTED
} from '../actions/address';

const initialState = {
    addresses: [],
    addAddressResult: {},
    updateAddressResult: {},
    deleteAddressResult: {},
    addAdressesLoading: false,
    putAdressesLoading: false,
    deleteAdresesLoading: false,
    getAdressesLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ADDRESS_PENDING:
            return {
                ...state,
                addAdressesLoading: true,
            }
        case ADD_ADDRESS_FULFILLED:
            return {
                ...state,
                addAddressResult: action.payload,
                addAdressesLoading: false,
            }
        case ADD_ADDRESS_REJECTED:
            return {
                ...state,
                addAdressesLoading: false,
            }

        case GET_ALL_ADDRESSES_PENDING:
            return {
                ...state,
                getAdressesLoading: true,
            }
        case GET_ALL_ADDRESSES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAdressesLoading: false,
            }
        case GET_ALL_ADDRESSES_REJECTED:
            return {
                ...state,
                getAdressesLoading: false,
            }

        case PUT_ADDRESS_PENDING:
            return {
                ...state,
                putAdressesLoading: true,
            }
        case PUT_ADDRESS_FULFILLED:
            return {
                ...state,
                updateAddressResult: action.payload,
                putAdressesLoading: false,
            }
        case PUT_ADDRESS_REJECTED:
            return {
                ...state,
                putAdressesLoading: false,
            }

        case DELETE_ADDRESS_PENDING:
            return {
                ...state,
                deleteAdresesLoading: true
            }
        case DELETE_ADDRESS_FULFILLED:
            return {
                ...state,
                deleteAddressResult: action.payload,
                deleteAdresesLoading: false
            }
        case DELETE_ADDRESS_REJECTED:
            return {
                ...state,
                deleteAdresesLoading: false
            }
        default:
            return state;
    }
}