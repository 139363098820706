import React, { Component } from 'react'
import { Input, Button, FormControl, InputLabel, Select, MenuItem, Typography, Divider } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class UpdateSharingSettingsForm extends Component {

    state = {
        _id: this.props.project._id,
        gasSharingCriterion: this.props.project.gasSharingCriterion,
        coldWaterSharingCriterion: this.props.project.coldWaterSharingCriterion,
        hotWaterSharingCriterion: this.props.project.hotWaterSharingCriterion,
        sharingPeriod: this.props.project.sharingPeriod,
        heatCalorimeter: false,
        coldCalorimeter: false,
        coldWater: false,
        hotWater: false,
        heatPaymentMeter: false,
        boilerElectric: false,
        vrfAirConditioning: false,
        individualGas: false,
        individualElectric: false
    };

    UNSAFE_componentWillMount() {

        this.props.project.sharingTypes.map((item) => {
            if (item._id === "5cefd0dd45844c0a69b509cd") {
                this.setState({ heatCalorimeter: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509ce") {
                this.setState({ coldCalorimeter: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509cf") {
                this.setState({ coldWater: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d0") {
                this.setState({ hotWater: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d1") {
                this.setState({ heatPaymentMeter: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d2") {
                this.setState({ boilerElectric: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d3") {
                this.setState({ vrfAirConditioning: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d4") {
                this.setState({ individualGas: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d5") {
                this.setState({ individualElectric: true });
            }
            return "";
        });

    }

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>

                {
                    this.state.heatCalorimeter ?
                        <div>
                            <Typography variant="h5">Isıtma Ayaları</Typography>
                            <Divider variant="middle" />
                            <FormControl required fullWidth>
                                <InputLabel htmlFor="gasSharingCriterion">Isıtma Kalorimetre Paylaşım Kriteri</InputLabel>
                                <Select
                                    value={this.state.gasSharingCriterion}
                                    onChange={(element) => { this.setState({ gasSharingCriterion: element.target.value }) }}
                                    id="gasSharingCriterion"
                                    name="gasSharingCriterion"
                                >
                                    <MenuItem key={0} value={0}>%30 - %70 Standart Paylaşım</MenuItem>
                                    <MenuItem key={1} value={1}>%30 - Dairelere Sayısına Eşit</MenuItem>
                                    <MenuItem key={2} value={2}>%30 - M² Göre</MenuItem>
                                    <MenuItem key={3} value={3}>%30 - Sadece Dolu Dairelerin Sayısına Göre Eşit</MenuItem>
                                    <MenuItem key={4} value={4}>%30 - Sadece Dolu Dairelerin M² Göre</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        : ""
                }
                {
                    this.state.coldWater || this.state.hotWater ?
                        <div>
                            <br /><br />
                            <Typography variant="h5">Soğuk Su Ayaları</Typography>
                            <Divider variant="middle" />
                            <br />
                            <FormControl required fullWidth>
                                <InputLabel htmlFor="coldWaterSharingCriterion">Soğuk Su Paylaşım Kriteri</InputLabel>
                                <Select
                                    value={this.state.coldWaterSharingCriterion}
                                    onChange={(element) => { this.setState({ coldWaterSharingCriterion: element.target.value }) }}
                                    id="coldWaterSharingCriterion"
                                    name="coldWaterSharingCriterion"
                                >
                                    <MenuItem key={0} value={0}>Gelen Fatura Üzerinden Birim Fiyat</MenuItem>
                                    <MenuItem key={1} value={1}>Gelen Faturanın Tamamı</MenuItem>
                                    <MenuItem key={2} value={2}>Manuel Birim Fiyat</MenuItem>
                                </Select>
                            </FormControl>

                            {
                                this.state.coldWaterSharingCriterion === 2 ?
                                    <FormControl required fullWidth>
                                        <InputLabel htmlFor="coldWaterSharingUnitPrice">Soğuk Su Birim Fiyat</InputLabel>
                                        <Input
                                            id="coldWaterSharingUnitPrice"
                                            name="coldWaterSharingUnitPrice"
                                            type="text"
                                            defaultValue={this.props.project.coldWaterSharingUnitPrice}
                                        />
                                    </FormControl>
                                    : ""
                            }

                        </div>
                        : ""
                }
                {
                    this.state.hotWater ?
                        <div>
                            < br /> <br />
                            <Typography variant="h5">Sıcak Su Ayaları</Typography>
                            <Divider variant="middle" />
                            <br />
                            <FormControl required fullWidth>
                                <InputLabel htmlFor="sharingPeriod">Sıcak Su Paylaşım Kriteri</InputLabel>
                                <Select
                                    value={this.state.sharingPeriod}
                                    onChange={(element) => { this.setState({ sharingPeriod: element.target.value, hotWaterSharingCriterion: 0 }) }}
                                    id="sharingPeriod"
                                    name="sharingPeriod"
                                >
                                    <MenuItem key={0} value={0}>Kış Paylaşımı</MenuItem>
                                    <MenuItem key={1} value={1}>Yaz Paylaşımı</MenuItem>
                                </Select>
                            </FormControl>
                            {
                                this.state.sharingPeriod === 0 ?
                                    <div>
                                        <FormControl required fullWidth>
                                            <InputLabel htmlFor="hotWaterSharingCriterion">Sıcak Su Paylaşım Kriteri</InputLabel>
                                            <Select
                                                value={this.state.hotWaterSharingCriterion}
                                                onChange={(element) => { this.setState({ hotWaterSharingCriterion: element.target.value }) }}
                                                id="hotWaterSharingCriterion"
                                                name="hotWaterSharingCriterion"
                                            >
                                                <MenuItem key={0} value={0}>Formül Üzerinden</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl required fullWidth>
                                            <InputLabel htmlFor="boilerTemperature">Kazan Derecesi</InputLabel>
                                            <Input
                                                id="boilerTemperature"
                                                name="boilerTemperature"
                                                type="number"
                                                defaultValue={this.props.project.boilerTemperature}
                                            />
                                        </FormControl>
                                        <FormControl required fullWidth>
                                            <InputLabel htmlFor="thermalCoefficient">Isıl Katsayısı</InputLabel>
                                            <Input
                                                id="thermalCoefficient"
                                                name="thermalCoefficient"
                                                type="number"
                                                defaultValue={this.props.project.thermalCoefficient}
                                            />
                                        </FormControl>
                                    </div> : <div>
                                        <FormControl required fullWidth>
                                            <InputLabel htmlFor="hotWaterSharingCriterion">Sıcak Su Paylaşım Kriteri</InputLabel>
                                            <Select
                                                value={this.state.hotWaterSharingCriterion}
                                                onChange={(element) => { this.setState({ hotWaterSharingCriterion: element.target.value }) }}
                                                id="hotWaterSharingCriterion"
                                                name="hotWaterSharingCriterion"
                                            >
                                                <MenuItem key={0} value={0}>Formül Üzerinden</MenuItem>
                                                <MenuItem key={1} value={1}>%30 - %70 Ayrılarak</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            this.state.hotWaterSharingCriterion === 0 ? <div>
                                                <FormControl required fullWidth>
                                                    <InputLabel htmlFor="boilerTemperature">Kazan Derecesi</InputLabel>
                                                    <Input
                                                        id="boilerTemperature"
                                                        name="boilerTemperature"
                                                        type="number"
                                                        defaultValue={this.props.project.boilerTemperature}
                                                    />
                                                </FormControl>
                                                <FormControl required fullWidth>
                                                    <InputLabel htmlFor="thermalCoefficient">Isıl Katsayısı</InputLabel>
                                                    <Input
                                                        id="thermalCoefficient"
                                                        name="thermalCoefficient"
                                                        type="number"
                                                        defaultValue={this.props.project.thermalCoefficient}
                                                    />
                                                </FormControl>
                                            </div> : ""
                                        }

                                    </div>
                            }

                        </div>
                        : ""
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Ayaları Kaydet
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(UpdateSharingSettingsForm);