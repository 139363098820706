import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button, Chip } from '@material-ui/core';
class PeriodApartmentCounterDatas extends Component {
    render() {

        const tableData = {
            title: "Daire Sayaç Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000],

            },

            columns: [
                this.props.person.permissions.apartmentCounter.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <div style={{ width: "100%" }}>
                                <details>
                                    <summary className="dropdownLink">
                                        İşlem Menüsü
                                    </summary>
                                    <div className="dropdownWrapper">
                                        <span className="dropdownItem">
                                            <Button onClick={() => this.props.addForm(rowData)}>Veri Ekle</Button>
                                        </span>
                                        {
                                            rowData.lastNewData.length > 0 ?
                                                <span className="dropdownItem">
                                                    <Button onClick={() => this.props.editForm(rowData)}>Veriyi Güncelle</Button>
                                                </span> : ""
                                        }
                                    </div>
                                </details>
                            </div >
                        )
                    },
                } : {},
                {
                    title: "Blok Bilgisi", field: "apartment.block.name",
                    render: rowData => {
                        return (
                            rowData.apartment.block.name
                        );
                    }
                },
                {
                    title: "Daire Bilgisi", field: "apartment.name",
                    render: rowData => {
                        return (
                            rowData.apartment.name
                        );
                    }
                },
                {
                    title: "Sayaç Bilgisi", field: "name",
                    render: rowData => {
                        return (
                            rowData.name
                        );
                    }
                },
                {
                    title: "Sayaç Seri No", field: "serialNumber",
                    render: rowData => {
                        return (
                            <div style={{ color: "#000", fontSize: "20px", fontWeight: "800", cursor: "pointer" }} onClick={() => this.props.addForm(rowData)}>{rowData.serialNumber}</div>
                        );
                    }
                },
                {
                    title: "Sayacın Tipi", field: "type.name",
                    render: rowData => {
                        return (
                            rowData.type.name
                        );
                    }
                },
                {
                    title: "İlk Endeks",

                    render: rowData => {
                        return (
                            rowData.lastClosedData.length > 0 ? rowData.lastClosedData[0].counterValue : "veri yok"
                        );
                    }
                },
                {
                    title: "Son Endeks",
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].counterValue : "veri yok"
                        );
                    }
                },
                {
                    title: "Dönem Tüketimi",
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].periodConsumption + " " + rowData.type.unit : "veri yok"
                        );
                    }
                },
                {
                    title: "Arıza Durumu",
                    field: "lastNewData[0].problemState",
                    lookup: {
                        "true": "Arızalı",
                        "false": "Arıza Tespit Edilemedi"
                    },
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].analysisStatus ? rowData.lastNewData[0].problemState ? <Chip
                                style={{ color: "#fff", backgroundColor: "#cc2020" }}
                                label={"Arızalı"}
                            /> : <Chip
                                    style={{ color: "#fff", backgroundColor: "#29bd29" }}
                                    label={"Arıza Tespit Edilmedi"}
                                /> : <Chip
                                    style={{ color: "#fff", backgroundColor: "#cc2020" }}
                                    label={"Analiz Yapılmadı"}
                                /> : "veri yok"
                        );
                    }
                },
                {
                    title: "15 Derece Arıza Durumu",
                    field: "lastNewData[0].fifteenDegreesProblem",
                    lookup: {
                        "true": "15 Derece Problemli",
                        "false": "15 Derece Problemsiz"
                    },
                    hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].problemState ?
                                rowData.lastNewData[0].fifteenDegreesProblem ?
                                    <Chip
                                        style={{ color: "#fff", backgroundColor: "#cc2020" }}
                                        label={rowData.lastNewData[0].fifteenDegreesMessage}
                                    />
                                    : ""
                                : "" : "veri yok"
                        );
                    }
                },
                {
                    title: "Minimum Tüketim Arıza Durumu",
                    field: "lastNewData[0].minusConsumptionProblem",
                    lookup: {
                        "true": "Minimum Tüketimi Problemli",
                        "false": "Minimum Tüketimi Problemsiz"
                    },
                    hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].problemState ?
                                rowData.lastNewData[0].minusConsumptionProblem ?
                                    <Chip
                                        style={{ color: "#fff", backgroundColor: "#cc2020" }}
                                        label={rowData.lastNewData[0].minusConsumptionMessage}
                                    />
                                    : ""
                                : "" : "veri yok"
                        );
                    }
                },
                {
                    title: "Maksimum Tüketim Arıza Durumu",
                    field: "lastNewData[0].maximumConsumptionProblem",
                    lookup: {
                        "true": "Maksimum Tüketimi Problemli",
                        "false": "Maksimum Tüketimi Problemsiz"
                    },
                    hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].problemState ?
                                rowData.lastNewData[0].maximumConsumptionProblem ?
                                    <Chip
                                        style={{ color: "#fff", backgroundColor: "#cc2020" }}
                                        label={rowData.lastNewData[0].maximumConsumptionMessage}
                                    />
                                    : ""
                                : "" : "veri yok"
                        );
                    }
                },
                {
                    title: "Geçmiş Dönem Ortalaması Arıza Durumu",
                    field: "lastNewData[0].lastThreeMonthProblem",
                    lookup: {
                        "true": "Geçmiş Dönem Tüketim Ortalaması Problemli",
                        "false": "Geçmiş Dönem Tüketim Ortalaması Problemsiz"
                    },
                    hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].problemState ?
                                rowData.lastNewData[0].lastThreeMonthProblem ?
                                    <Chip
                                        style={{ color: "#fff", backgroundColor: "#cc2020" }}
                                        label={rowData.lastNewData[0].lastThreeMonthMessage}
                                    />
                                    : ""
                                : "" : "veri yok"
                        );
                    }
                },
                {
                    title: "Kıyas Durumu",
                    field: "lastNewData[0].comparisonMakingStatus",
                    lookup: {
                        "true": "Kıyas Yapıldı",
                        "false": "Kıyas Yapılmadı"
                    },
                    hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].comparisonMakingStatus ? <Chip
                                style={{ color: "#fff", backgroundColor: "#eab51e" }}
                                label={"Kıyas Yapıldı"}
                            /> : <Chip
                                    style={{ color: "#fff", backgroundColor: "#29bd29" }}
                                    label={"Kıyas Yapılmadı"}
                                /> : "veri yok"
                        );
                    }
                },
                {
                    title: "Yapılan Kıyas",
                    field: "lastNewData[0].comparisonCriterion",
                    lookup: {
                        0: "Kendi Dengi Dairelerin Ortalamasına Göre",
                        1: "Manuel Belirtilen Tüketim",
                        2: "15 Derece Kıyas Değerine Göre",

                    },
                    hidden: true,
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].comparisonMakingStatus ?
                                <div>
                                    {
                                        rowData.lastNewData[0].comparisonCriterion === 0 ?
                                            <Chip
                                                style={{ color: "#fff", backgroundColor: "#eab51e" }}
                                                label={"Kendi Dengi Dairelerin Ortalamasına Göre"}
                                            />
                                            : ""}
                                    {
                                        rowData.lastNewData[0].comparisonCriterion === 1 ?
                                            <Chip
                                                style={{ color: "#fff", backgroundColor: "#eab51e" }}
                                                label={"Manuel Belirtilen Tüketim"}
                                            />
                                            : ""}
                                    {
                                        rowData.lastNewData[0].comparisonCriterion === 2 ?
                                            <Chip
                                                style={{ color: "#fff", backgroundColor: "#eab51e" }}
                                                label={"15 Derece Kıyas Değerine Göre"}
                                            />
                                            : ""}

                                </div >
                                : "" : "veri yok"
                        );
                    }
                },
                {
                    title: "Dönem Notu", field: "lastNewData[0].periodDescription",
                    render: rowData => {
                        return (
                            rowData.lastNewData.length > 0 ? rowData.lastNewData[0].periodDescription : "veri yok"
                        );
                    }
                },
            ],
            data: this.props.apartmentCounters
        };

        let tableActions = [];
        if (this.props.period.status) {
            tableActions.push(
                { // toplu block listesi exceli
                    icon: 'cloud_download',
                    isFreeAction: true,
                    tooltip: 'Dosya Alma',
                    onClick: this.props.getFiles,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#56b191'
                        }
                    }
                }
            );
            tableActions.push(
                { // toplu block listesi exceli
                    icon: 'cloud_upload',
                    isFreeAction: true,
                    tooltip: 'Excel Yükleme',
                    onClick: this.props.uploadExcel,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#17c5da'
                        }
                    }
                }
            );
            tableActions.push(
                { // dönem analizi
                    icon: 'insert_chart',
                    isFreeAction: true,
                    tooltip: 'Dönem Analizi',
                    onClick: this.props.checkAnalysis,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#e88e17'
                        }
                    }
                }
            );

            tableActions.push(
                { // toplu block listesi exceli
                    icon: 'sync_problem',
                    tooltip: 'Kıyas Yap',
                    onClick: this.props.setComparison,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#ec0007'
                        }
                    }
                }
            );

            tableActions.push(
                { // toplu block listesi exceli
                    icon: 'alarm_off',
                    tooltip: 'Arızalı Olarak Güncelle',
                    onClick: this.props.setDefective,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#ec0007'
                        }
                    }
                }
            );

            tableActions.push(
                { // toplu block listesi exceli
                    icon: 'done_all',
                    tooltip: 'Arızalı Değil',
                    onClick: this.props.notDefective,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#71ab08'
                        }
                    }
                }
            );
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default PeriodApartmentCounterDatas;