import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { addPerson, updatePerson, getAllPersons, deletePerson, setActivePageTitle, setBreadCrumb } from '../../actions/person';
import { getAllDeparments } from '../../actions/department';

// components
import PersonsTable from './PersonsTable';
import { Button } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog'
import swal from 'sweetalert';
import AddPersonForm from '../Persons/AddPersonForm';
import EditPersonForm from '../Persons/EditPersonForm';

class Persons extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("PERSONEL YÖNETİMİ");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Personel Yönetimi" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        // yetki kontrolü
        if (!this.props.person.permissions.person.read) {
            return window.location.href = "/";
        }

        await this.props.getAllPersons(this.props.token);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }

    // personel güncelleme işlemi
    handleEditPersonSubmit = async (e, state) => {

        if (state.department.value === "") {
            return swal({
                title: "Hata!",
                text: "Lütfen bir departman seçin!",
                icon: "error",
            });
        }

        const person = {
            _id: e.target._id.value,
            title: e.target.title.value,
            personType: state.personType,
            // name: e.target.name.value,
            // lastName: e.target.lastName.value,
            // surname: e.target.surname.value,
            mobilePhone: e.target.mobilePhone.value,
            email: e.target.email.value,
            department: state.department.value,
            permissions: state.permissions
        };

        return swal({
            title: "Emin misin?",
            text: "Personeli Güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updatePerson(person, this.props.token);
                    if (this.props.updatePersonResult.code === 200) {
                        this.setState({ editOpenDialog: false });
                        await this.props.getAllPersons(this.props.token);
                        this.props.enqueueSnackbar("Güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Güncelleme işlemi başarısız. " + this.props.updatePersonResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });


    }

    // yeni personel ekleme işlemi
    handleAddPersonSubmit = async (e, state) => {

        if (state.department.value === "") {
            return swal({
                title: "Hata!",
                text: "Lütfen bir departman seçin!",
                icon: "error",
            });
        }

        const person = {
            personType: state.personType,
            title: e.target.title.value,
            // name: e.target.name.value,
            // lastName: e.target.lastName.value,
            // surname: e.target.surname.value,
            mobilePhone: e.target.mobilePhone.value,
            email: e.target.email.value,
            password: e.target.password.value,
            department: state.department.value,
            permissions: state.permissions
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir personel ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addPerson(person, this.props.token);
                    if (this.props.addPersonResult.code === 200) {
                        this.props.getAllPersons(this.props.token);
                        this.setState({ addOpenDialog: false });
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addPersonResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    // güncelle butonuna tıklandığında yapılacak işlem
    handleEditPerson = async (rowData) => {
        await this.props.getAllDeparments(this.props.token);
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editOpenDialog: true })
    }

    // yeni kayıt butonuna tıklandığında yapılacak işlem
    handleAddPerson = async () => {
        await this.props.getAllDeparments(this.props.token);
        this.setState({ addOpenDialog: true })
    }

    // sil butonuna tıklandığında yapılacak işlem
    handleDeletePerson = (event, rowData) => {

        return swal({
            title: "Emin misin?",
            text: "Bu seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (person) => {
                        await this.props.deletePerson(person, this.props.token);
                        if (this.props.deletePersonResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deletePersonResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllPersons(this.props.token);
                }
            });
    }

    render() {
        return (
            <div>
                {this.props.addPersonLoading ? <div className="loading" /> : ""}
                {this.props.getPersonsLoading ? <div className="loading" /> : ""}
                <PersonsTable persons={this.props.persons} person={this.props.person} addForm={this.handleAddPerson} editForm={this.handleEditPerson} delete={this.handleDeletePerson} />
                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Personel Güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditPersonForm {...this.state.rowData} onSubmitForm={this.handleEditPersonSubmit} departments={this.props.departments} />
                </ModalDialog>

                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Personel Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddPersonForm personType={0} onSubmitForm={this.handleAddPersonSubmit} departments={this.props.departments} />
                </ModalDialog>
            </div>
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ departmentReducer, personReducer }) => {
    return {
        ...departmentReducer,
        ...personReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getAllDeparments,
    getAllPersons,
    addPerson,
    updatePerson,
    deletePerson,
    setActivePageTitle,
    setBreadCrumb
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Persons));