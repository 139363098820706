import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, FormControl, InputLabel, Select, MenuItem, Switch, FormLabel, FormControlLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import styles from '../../styles/app';

class AddProjectForm extends Component {

    state = {
        reseller: { value: "", label: "Lütfen Bir Müşteri Seçiniz.." },
        persons: { value: "", label: "Lütfen Bir Personel Seçiniz.." },
        city: { value: "", label: "Lütfen Bir Şehir Seçiniz.." },
        county: { value: "", label: "Lütfen Bir İlçe Seçiniz.." },
        countyStatus: false,
        readingPeriodId: 0,
        meterReadingInformation: 0,
        status: true,
        floorStation: false,
        sharingTypes: [],
    };

    handleCityChange = async (city) => {
        if (city.value === "") {
            this.setState({ city, county: { value: "", label: "Bir İlçe Seçiniz.." }, countyStatus: false })
        }
        else {
            await this.props.getCity(city.value);
            this.setState({ city, county: { value: "", label: "Bir İlçe Seçiniz.." }, countyStatus: true })
        }
    }

    handleChangeReadingPeriodId = (element) => {
        this.setState({ readingPeriodId: element.target.value })
    }

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleSharingTypes = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const sharingTypes = state.sharingTypes.concat(name.toString());
                return {
                    sharingTypes
                };
            });
        }
        else {
            this.setState(state => {
                const sharingTypes = state.sharingTypes.filter(item => item.toString() !== name.toString());
                return {
                    sharingTypes,
                };
            });
        }
    };

    handleChangemeterReadingInformation = (element) => {
        this.setState({ meterReadingInformation: element.target.value })
    }


    render() {
     
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl required style={{ width: "50%" }}>
                    <InputLabel htmlFor="name">Proje Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ width: "20%", marginLeft: "5%" }}>
                    <InputLabel htmlFor="name">Proje Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.status} onChange={this.handleChangeStatus('status')} value="status" />
                </FormControl>
                <FormControl required style={{ width: "20%", marginLeft: "5%" }}>
                    <InputLabel htmlFor="name">Kat istasyonu var mı?</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.floorStation} onChange={this.handleChangeStatus('floorStation')} value="kadir" />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%" }}>
                    <FormLabel>Ait Olduğu bayi</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.reseller}
                        onChange={(reseller) => { this.setState({ reseller }) }}
                        isClearable={true}
                        options={
                            this.props.resellers.map((reseller) => {
                                return { value: reseller._id, label: reseller.title }
                            })
                        }
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", marginLeft: "20%" }}>
                    <FormLabel>Proje ile İlişkili Peronel</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.persons}
                        isMulti
                        onChange={(persons) => { this.setState({ persons }) }}
                        options={
                            this.props.persons.map((person) => {
                                return { value: person._id, label: person.title }
                            })
                        }
                    />
                </FormControl>
                <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                    <InputLabel htmlFor="sharingTypes">Paylaşım Yapılacak Sayaç Tipleri</InputLabel>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        this.props.sharingTypes.map((sharingType) => {
                            return (
                                <FormControlLabel key={sharingType._id}
                                    control={
                                        <Switch checked={this.state.sharingTypes.includes(sharingType._id)} onChange={this.handleSharingTypes(sharingType._id)} />
                                    }
                                    label={sharingType.name}
                                />
                            )
                        })
                    }
                </FormControl>

                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%" }}>
                    <FormLabel>İl</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.city}
                        onChange={this.handleCityChange}
                        options={
                            this.props.cities.map((city) => {
                                return { value: city._id, label: city.name }
                            })
                        }
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", marginLeft: "20%" }}>
                    <FormLabel>İlçe</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.county}
                        onChange={(county) => { this.setState({ county }) }}
                        isDisabled={!this.state.countyStatus}
                        options={
                            this.props.selectedCity.counties ? this.props.selectedCity.counties.map((county) => {
                                return { value: county._id, label: county.name }
                            }) : []
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="address">Adres</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="address"
                        name="address"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="readingPrice">Okuma Ücreti</InputLabel>
                    <Input
                        id="readingPrice"
                        name="readingPrice"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="invoiceBalance">Faturada Görünecek Tutar</InputLabel>
                    <Input
                        id="invoiceBalance"
                        name="invoiceBalance"
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="raiseRate">Zam Oranı</InputLabel>
                    <Input
                        id="raiseRate"
                        name="raiseRate"
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="commission">Komisyon Oranı</InputLabel>
                    <Input
                        id="commission"
                        name="commission"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="readingPeriodId">Okuma Dönemi</InputLabel>
                    <Select
                        value={this.state.readingPeriodId}
                        onChange={this.handleChangeReadingPeriodId}
                        id="readingPeriodId"
                        name="readingPeriodId"
                    >
                        <MenuItem value="">
                            <em>Bir Okuma Dönemi Seçin..</em>
                        </MenuItem>
                        <MenuItem key={0} value={0}>Sadece Kış</MenuItem>
                        <MenuItem key={1} value={1}>Yaz Kış</MenuItem>
                    </Select>
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="meterReadingInformation">Okuma Bilgisi</InputLabel>
                    <Select
                        value={this.state.meterReadingInformation}
                        onChange={this.handleChangemeterReadingInformation}
                        id="meterReadingInformation"
                        name="meterReadingInformation"
                    >
                        <MenuItem value="">
                            <em>Projenin Okuma Yönetmini Seçin..</em>
                        </MenuItem>
                        <MenuItem key={0} value={0}>Optik</MenuItem>
                        <MenuItem key={1} value={1}>mBus</MenuItem>
                        <MenuItem key={2} value={2}>GPRS</MenuItem>
                    </Select>
                </FormControl>
                <FormControl required style={{ width: "20%" }}>
                    <InputLabel htmlFor="heatLossRate">Isı Kayıp Oranı (%)</InputLabel>
                    <Input
                        id="heatLossRate"
                        name="heatLossRate"
                        type="number"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    PROJEYİ EKLE
                </Button>
            </form >
        )
    }
}

AddProjectForm.propTypes = {
    classes: PropTypes.object.isRequired,
    cities: PropTypes.array.isRequired,
};
export default withStyles(styles)(AddProjectForm);