import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import SearchSelect from 'react-select';
import { Input, Button, FormControl, InputLabel, FormLabel } from "@material-ui/core";
import styles from '../../styles/app';


class EditTaskForm extends Component {

    state = {
        taskType: { value: this.props.selectedTask.taskType._id, label: this.props.selectedTask.taskType.name },
        requestType: this.props.selectedTask.requestType === 0 ? { value: this.props.selectedTask.requestType, label: "Kayıtsız müşteri" } : { value: this.props.selectedTask.requestType, label: "Kayıtlı müşteri" },
        priceStatus: this.props.selectedTask.priceStatus === 0 ? { value: this.props.selectedTask.priceStatus, label: "Ücretli" } : this.props.selectedTask.priceStatus === 1 ? { value: this.props.selectedTask.priceStatus, label: "Garantili" } : { value: this.props.selectedTask.priceStatus, label: "Ücretsiz" },
        requestPersonStatus: this.props.selectedTask.requestPersonStatus,
        department: { value: this.props.selectedTask.department._id, label: this.props.selectedTask.department.name },
        persons: this.props.selectedTask.persons.map((person) => { return { value: person._id, label: person.title } }),
        project: { value: this.props.selectedTask.project._id, label: this.props.selectedTask.project.name },
        requestPerson: this.props.selectedTask.requestType === 1 ? { value: this.props.selectedTask.requestPerson._id, label: this.props.selectedTask.requestPerson.title } : ""

    }

    async UNSAFE_componentWillMount() {
        await this.props.getDepartmentPersons(this.props.selectedTask.department._id, this.props.token);
        await this.props.getAllProjects(this.props.selectedTask.project._id, this.props.token);
        if (this.props.selectedTask.requestType === 1) {
            await this.props.getAllResellers(this.props.token);
        }
    }

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    resellerChange = event => {
        this.setState({ reseller: event.target.value });
    };

    handleRequestType = async (requestType) => {
        await this.props.getAllResellers(this.props.token);
        this.setState({ requestType });
    }


    handleChangeProject = async (project) => {
        this.setState({ project })
    }

    handlePriceStatusChange = async (priceStatus) => {
        this.setState({ priceStatus })
    }

    departmentChange = async (department) => {
        await this.props.getDepartmentPersons(department.value, this.props.token);
        this.setState({ department });
    };

    handleTaskTypeChange = async (taskType) => {
        this.setState({ taskType })
    }

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <input
                        type="hidden"
                        name="_id"
                        defaultValue={this.props.selectedTask._id}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">İş Başlığı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        defaultValue={this.props.selectedTask.name}
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        id="description"
                        name="description"
                        defaultValue={this.props.selectedTask.description}
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>İşi Talep Eden Kişi Tipi</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.requestType}
                        onChange={this.handleRequestType}
                        options={
                            [{ value: 0, label: "Kayıtsız Müşteri" }, { value: 1, label: "Kayıtlı Müşteri" }]
                        }
                    />
                </FormControl>

                {
                    this.state.requestType.value === 0 ? <div>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="requestPersonName">İşi Talep Eden Kişinin Adı</InputLabel>
                            <Input
                                id="requestPersonName"
                                name="requestPersonName"
                                defaultValue={this.props.selectedTask.requestPersonName}
                                type="text"
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="requestPersonPhone">İşi Talep Eden Kişinin Telefonu</InputLabel>
                            <Input
                                id="requestPersonPhone"
                                name="requestPersonPhone"
                                defaultValue={this.props.selectedTask.requestPersonPhone}
                                type="text"
                            />
                        </FormControl>
                    </div> : ""
                }
                {
                    this.state.requestType.value === 1 ?
                        <div>
                            <FormControl style={{ marginBottom: "15px", width: "100%" }}>
                                <FormLabel>Kayıtlı Müşteri</FormLabel>
                                <br />
                                <SearchSelect
                                    value={this.state.requestPerson}
                                    onChange={(requestPerson) => { this.setState({ requestPerson }) }}
                                    options={
                                        this.props.resellers.map((reseller) => {
                                            return { value: reseller._id, label: reseller.title }
                                        }) || []
                                    }
                                />
                            </FormControl>
                        </div>
                        : ""}
                {
                    // this.state.requestType.value === 2 ?
                    //     <div>
                    //         <FormControl style={{ marginBottom: "15px", width: "100%" }}>
                    //             <FormLabel>Kayıtlı Müşteriyi Seçiniz</FormLabel>
                    //             <br />
                    //             <SearchSelect
                    //                 value={this.state.requestPerson}
                    //                 onChange={this.handleRequestPersonChange}
                    //                 options={
                    //                     this.props.resellers.map((reseller) => {
                    //                         return { value: reseller._id, label: reseller.title }
                    //                     }) || []
                    //                 }
                    //             />
                    //         </FormControl>
                    //         <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", marginLeft: "20%" }}>
                    //             <FormLabel>Müşteri Personeli</FormLabel>
                    //             <br />
                    //             <SearchSelect
                    //                 value={this.state.requestResellerPerson}
                    //                 onChange={(requestResellerPerson) => { this.setState({ requestResellerPerson }) }}
                    //                 isDisabled={!this.state.requestPersonStatus}
                    //                 options={
                    //                     this.props.resellerPersons.map((resellerPerson) => {
                    //                         return {
                    //                             value: resellerPerson._id, label: resellerPerson.title
                    //                         }
                    //                     })
                    //                 }
                    //             />
                    //         </FormControl>
                    //     </div>
                    //     : "" 
                }

                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>İşin Projesi</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.project}
                        onChange={this.handleChangeProject}
                        options={
                            this.props.projects.map((project) => {
                                return { value: project._id, label: project.name }
                            })
                        }
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>İş Tipi</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.taskType}
                        onChange={this.handleTaskTypeChange}
                        options={
                            this.props.taskTypes.map((taskType) => {
                                return { value: taskType._id, label: taskType.name }
                            })
                        }
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>Ücret Durumu</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.priceStatus}
                        onChange={this.handlePriceStatusChange}
                        options={
                            [{ value: 0, label: "Ücretli" }, { value: 1, label: "Garantili" }, { value: 2, label: "Ücretsiz" }]
                        }
                    />
                </FormControl>
                {
                    this.state.priceStatus.value === 0 ? <div>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="price">Ücreti</InputLabel>
                            <Input
                                id="price"
                                name="price"
                                defaultValue={this.props.selectedTask.price}
                                type="number"
                            />
                        </FormControl>
                    </div> : ""
                }
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>Atanacak Departman</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.department}
                        onChange={this.departmentChange}
                        options={
                            this.props.departments.map((department) => {
                                return { value: department._id, label: department.name }
                            })
                        }
                    />
                </FormControl>
                {
                    this.state.department.value !== "" ? <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%", }}>
                        <FormLabel>departmana bağlı personel listesi</FormLabel>
                        <br />
                        <SearchSelect
                            isMulti
                            value={this.state.persons}
                            onChange={(persons) => { this.setState({ persons }) }}
                            options={
                                this.props.departmentPersons.length > 0 ? this.props.departmentPersons.map((person) => {
                                    return { value: person._id, label: person.title }
                                }) : []
                            }
                        />
                    </FormControl> : ""
                }
                <FormControl fullWidth>
                    <InputLabel htmlFor="startDate">İş Başlama Tarihi</InputLabel>
                    <Input
                        id="startDate"
                        label="startDate"
                        type="datetime-local"
                        defaultValue={moment(this.props.startDate).format("YYYY-MM-DDTHH:mm")}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="appointmentDate">Randevu Tarihi</InputLabel>
                    <Input
                        id="appointmentDate"
                        label="appointmentDate"
                        type="datetime-local"
                        defaultValue={moment(this.props.appointmentDate).format("YYYY-MM-DDTHH:mm")}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Görevi Güncelle
                            </Button>
            </form>
        )
    }
}
EditTaskForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditTaskForm);