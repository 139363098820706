import {
    GET_PROJECT_BLOCKS_PENDING, GET_PROJECT_BLOCKS_FULFILLED, GET_PROJECT_BLOCKS_REJECTED,
    ADD_BLOCK_PENDING, ADD_BLOCK_FULFILLED, ADD_BLOCK_REJECTED,
    ADD_COLLECTIVE_BLOCK_PENDING, ADD_COLLECTIVE_BLOCK_FULFILLED, ADD_COLLECTIVE_BLOCK_REJECTED,
    UPDATE_BLOCK_PENDING, UPDATE_BLOCK_FULFILLED, UPDATE_BLOCK_REJECTED,
    DELETE_BLOCK_PENDING, DELETE_BLOCK_FULFILLED, DELETE_BLOCK_REJECTED,
    UPLOAD_COLELCTIVE_BLOCK_EXCEL_PENDING, UPLOAD_COLELCTIVE_BLOCK_EXCEL_FULFILLED, UPLOAD_COLELCTIVE_BLOCK_EXCEL_REJECTED,
} from '../actions/block';
const initialState = {
    getProjectBlocksLoading: false,
    addBlockLoading: false,
    editBlockLoading: false,
    deleteBlockLoading: false,
    blocks: [],
    block: {
        project: {
            name: ""
        },
        apartments: []
    },
    addBlockResult: {},
    addCollectiveBlockResult: {},
    editBlockResult: {},
    deleteBlockResult: {},
    uploadCollectiveBlockExcelResult: {},
}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_BLOCKS_PENDING:
            return {
                ...state,
                getProjectBlocksLoading: true,
            }
        case GET_PROJECT_BLOCKS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectBlocksLoading: false,

            }
        case GET_PROJECT_BLOCKS_REJECTED:
            return {
                ...state,
                getProjectBlocksLoading: false,
            }
        case UPLOAD_COLELCTIVE_BLOCK_EXCEL_PENDING:
            return {
                ...state,
            }
        case UPLOAD_COLELCTIVE_BLOCK_EXCEL_FULFILLED:
            return {
                ...state,
                uploadCollectiveBlockExcelResult: action.payload
            }
        case UPLOAD_COLELCTIVE_BLOCK_EXCEL_REJECTED:
            return {
                ...state,
            }
        case ADD_BLOCK_PENDING:
            return {
                ...state,
                addBlockLoading: true,
            }
        case ADD_BLOCK_FULFILLED:
            return {
                ...state,
                addBlockResult: action.payload,
                addBlockLoading: false,
            }
        case ADD_BLOCK_REJECTED:
            return {
                ...state,
                addBlockLoading: false,
            }

        case ADD_COLLECTIVE_BLOCK_PENDING:
            return {
                ...state,
            }
        case ADD_COLLECTIVE_BLOCK_FULFILLED:
            return {
                ...state,
                addCollectiveBlockResult: action.payload
            }
        case ADD_COLLECTIVE_BLOCK_REJECTED:
            return {
                ...state,
            }
        case UPDATE_BLOCK_PENDING:
            return {
                ...state,
                editBlockLoading: true,
            }
        case UPDATE_BLOCK_FULFILLED:
            return {
                ...state,
                editBlockResult: action.payload,
                editBlockLoading: false,
            }
        case UPDATE_BLOCK_REJECTED:
            return {
                ...state,
                editBlockLoading: false
            }

        case DELETE_BLOCK_PENDING:
            return {
                ...state,
                deleteBlockLoading: true,
            }
        case DELETE_BLOCK_FULFILLED:
            return {
                ...state,
                deleteBlockResult: action.payload,
                deleteBlockLoading: false,
            }
        case DELETE_BLOCK_REJECTED:
            return {
                ...state,
                deleteBlockLoading: false
            }
        default:
            return state;
    }
}