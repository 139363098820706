import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from 'notistack';
import { Grid, Tooltip, Button } from "@material-ui/core";
import styles from "../../styles/app";
//home page icons
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Person from '@material-ui/icons/Person';
import SettingsInputComponent from '@material-ui/icons/SettingsInputComponent';
import Business from '@material-ui/icons/Business';
import Attachment from '@material-ui/icons/Attachment';
import CommentIcon from '@material-ui/icons/Comment';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import EventNote from '@material-ui/icons/EventNote';
import FilterNone from '@material-ui/icons/FilterNone';


import { setActivePageTitle, setBreadCrumb } from '../../actions/person';

class Index extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("TEBAŞ OTOMASYON YÖNETİM SİSTEMİ");

        let breadCrumb = [
            { type: "string", text: "Tebaş Otomasyon" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container>
                    {
                        this.props.person.permissions.department.read ? <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="DEPARTMAN YÖNETİMİ">
                                <a href="/departments" style={{ textDecoration: "none" }}>
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                DEPARTMAN YÖNETİMİ
                                              </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <SupervisedUserCircle className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid> : null
                    }
                    {
                        this.props.person.permissions.person.read ? <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="PERSONEL YÖNETİMİ" style={{ textDecoration: "none" }}>
                                <a href="/persons">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                PERSONEL YÖNETİMİ
                                              </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Person className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid> : null
                    }
                    {
                        this.props.person.permissions.reseller.read ? <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="MÜŞTERİ YÖNETİMİ" style={{ textDecoration: "none" }}>
                                <a href="/resellers">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                MÜŞTERİ YÖNETİMİ
                                              </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <SettingsInputComponent className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid> : null
                    }
                    {
                        this.props.person.permissions.project.read ? (<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="PROJE YÖNETİMİ" style={{ textDecoration: "none" }}>
                                <a href="/projects">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                PROJE YÖNETİMİ
                                              </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Business className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid>) : null
                    }
                    {
                        this.props.person.permissions.task.read ? <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="İŞ HAVUZU" style={{ textDecoration: "none" }}>
                                <a href="/tasks">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                İŞ HAVUZU
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Attachment className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid> : null
                    }
                    {
                        this.props.person.permissions.note.read ? (<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="NOTLAR" style={{ textDecoration: "none" }}>
                                <a href="/notes">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                NOTLAR
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <EventNote className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid>) : null
                    }
                    {
                        this.props.person.permissions.department.read ? (<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="STOK KARTI" style={{ textDecoration: "none" }}>
                                <a href="/stockCard">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                STOK YÖNETİMİ
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FilterNone className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid>) : null
                    }
                    {
                        this.props.person.permissions.department.read ? (<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="GÖRÜŞME NOTLARI" style={{ textDecoration: "none" }}>
                                <a href="/interview-notes">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                GÖRÜŞME NOTLARI
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <SpeakerNotesIcon className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid>) : null
                    }
                    {
                        this.props.person.permissions.department.read ? (<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="MÜŞTERİ GÖRÜŞ VE ÖNERİLERİ" style={{ textDecoration: "none" }}>
                                <a href="/proposal">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                MÜŞTERİ GÖRÜŞ VE ÖNERİLERİ
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <CommentIcon className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid>) : null
                    }
                    {
                        this.props.person.personType !== 0 ? < Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Tooltip title="TALEP ve İSTEKLER" style={{ textDecoration: "none" }}>
                                <a href="/demand">
                                    <Button>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                TALEP ve İSTEKLER
                                        </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <CommentIcon className={classes.homePageIcon} />
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </a>
                            </Tooltip>
                        </Grid> : ""

                    }
                </Grid>
            </div >
        )
    }
}


const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb
}

const mapStateToProps = ({ personReducer, taskTypeReducer }) => {
    return {
        ...personReducer,
        ...taskTypeReducer,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withSnackbar(Index)));