import React, { Component } from 'react'
import { Input, Button, FormControl, InputLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class EditApartmentCounterForm extends Component {

    state = {
        status: this.props.apartmentCounter.status,
        shouldChangeStatus: this.props.apartmentCounter.shouldChangeStatus
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" value={this.props.apartmentCounter._id} name="_id" />
                <FormControl required fullWidth>
                    <InputLabel htmlFor="name">Sayaç Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={this.props.apartmentCounter.name}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="serialNumber">Seri Nuamrası</InputLabel>
                    <Input
                        id="serialNumber"
                        name="serialNumber"
                        type="text"
                        defaultValue={this.props.apartmentCounter.serialNumber}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="status">Sayaç Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch name="status" checked={this.state.status} onChange={event => { this.setState({ [event.target.name]: event.target.checked }); }} value="status" />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="shouldChangeStatus">Sayaç Değişmesi Gerekiyor Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch name="shouldChangeStatus" checked={this.state.shouldChangeStatus} onChange={event => { this.setState({ [event.target.name]: event.target.checked }); }} value="shouldChangeStatus" />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                        defaultValue={this.props.apartmentCounter.description}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sayacı Güncelle
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(EditApartmentCounterForm);
