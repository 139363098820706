import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button, Switch } from '@material-ui/core';
import Moment from 'react-moment';

class StockCardTable extends Component {

    state = {
        menuStatus: false,
        anchorEl: null,
    }

    render() {
        const tableData = {
            title: "Stok",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.department.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Seri No zorunluluğu", field: "serialNumberNecessity",
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.serialNumberNecessity} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                { title: "Adı", field: "name" },
                { title: "Ürünler", field: "" },
                {
                    title: "Üretim Yılı", field: "productionYear",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.productionYear}
                            </Moment>
                        )
                    }
                },
                { title: "Üretici", field: "manufacturer" },
                {
                    title: "Oluşturulma Tarihi", field: "createdAt",
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.createdAt}
                            </Moment>
                        )
                    }
                },
                { title: "garanti süresi", field: "WarrantyPeriod" },
                { title: "alış fiyatı", field: "buyingPrice" },
                { title: "satış fiyatı", field: "salePrice" },
            ],
            data: this.props.stockCards

        };

        let tableActions = [];
        if (this.props.person.permissions.department.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            })
        }
        if (this.props.person.permissions.department.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            })
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default StockCardTable;