import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { getAllResellers, getAllPersons } from '../../actions/person';
import { getAllProjects, addProject, updateProject, deleteProject, getResellerProjects } from '../../actions/project';
import { getAllCities, getCity } from '../../actions/city';
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
import { getAllSharingTypes } from '../../actions/sharingType';


// components
import ProjectsTable from './ProjectsTable';
import { Button } from '@material-ui/core';
import ModalDialog from '../Items/ModalDialog'
import swal from 'sweetalert';
import AddProjectForm from './AddProjectForm';
import EditProjectForm from './EditProjectForm';

class Projects extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("PROJE YÖNETİMİ");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Proje Yönetimi" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    // component içerisinde kullanılacak state
    state = {
        editOpenDialog: false,
        addOpenDialog: false,
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        if (!this.props.person.permissions.project.read) {
            return window.location.href = "/";
        }
        if (this.props.person.personType === 0) {
            await this.props.getAllProjects(this.props.token);
            await this.props.getAllSharingTypes(this.props.token);
        }
        else if (this.props.person.personType === 1) {
            await this.props.getResellerProjects(this.props.person._id, this.props.token);
            await this.props.getAllSharingTypes(this.props.token);
        }
        await this.props.getAllPersons(this.props.token);
    }

    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false, addOpenDialog: false });
    }

    getCity = (cityId) => {
        this.props.getCity(cityId, this.props.token);
    }

    handleAddProject = async () => {
        await this.props.getAllCities(this.props.token);
        await this.props.getAllResellers(this.props.token);
        this.setState({ addOpenDialog: true });
    }

    handleAddProjectSubmit = async (e, state) => {

        let project = {
            name: e.target.name.value,
            readingPrice: e.target.readingPrice.value,
            address: e.target.address.value,
            invoiceBalance: e.target.invoiceBalance.value,
            raiseRate: e.target.raiseRate.value,
            reseller: state.reseller.value,
            commission: e.target.commission.value,
            heatLossRate: e.target.heatLossRate.value,
            description: e.target.description.value,
            readingPeriodId: state.readingPeriodId,
            meterReadingInformation: state.meterReadingInformation,
            city: state.city.value,
            county: state.county.value,
            status: state.status,
            floorStation: state.floorStation,
            sharingTypes: state.sharingTypes,
        }

        if (state.persons.value) {
            project.persons = state.persons.map(person => person.value);
        }

        return swal({
            title: "Emin misin?",
            text: "Bu bayiye yeni bir proje ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addProject(project, this.props.token);
                    if (this.props.addProjectResult.code === 200) {
                        this.setState({ addOpenDialog: false, value: 1 });
                        await this.props.getAllProjects(this.props.token);
                        await this.props.getAllSharingTypes(this.props.token);
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt ekleme işlemi başarısız. " + this.props.addProjectResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleEditProject = async (rowData) => {
        this.props.getAllCities(this.props.token);
        this.props.getCity(rowData.city._id, this.props.token);
        this.props.getAllResellers(this.props.token);
        this.setState({ rowData: Object.assign({}, rowData) });
        this.setState({ editOpenDialog: true });
    }

    handleEditProjectSubmit = async (e, state) => {

        let project = {
            _id: e.target._id.value,
            name: e.target.name.value,
            readingPrice: e.target.readingPrice.value,
            address: e.target.address.value,
            invoiceBalance: e.target.invoiceBalance.value,
            raiseRate: e.target.raiseRate.value,
            reseller: state.reseller.value,
            commission: e.target.commission.value,
            heatLossRate: e.target.heatLossRate.value,
            description: e.target.description.value,
            readingPeriodId: state.readingPeriodId,
            city: state.city.value,
            county: state.county.value,
            status: state.status,
            floorStation: state.floorStation,
            sharingTypes: state.sharingTypes,
        }
        if (state.persons) {
            project.persons = state.persons.map(person => person.value);
        }

        return swal({
            title: "Emin misin?",
            text: "Bu projeyi güncelliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateProject(project, this.props.token);

                    if (this.props.updateProjectResult.code === 200) {
                        this.setState({ editOpenDialog: false, value: 1 });
                        await this.props.getAllProjects(this.props.token);
                        await this.props.getAllSharingTypes(this.props.token);
                        this.props.enqueueSnackbar("Proje güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Proje güncelleme işlemi başarısız. " + this.props.updateProjectResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    // sil butonuna tıklandığında yapılacak işlem
    handleDeleteProject = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (project) => {
                        await this.props.deleteProject(project, this.props.token);
                        if (this.props.deleteProjectResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteProjectResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getAllProjects(this.props.token);
                    await this.props.getAllSharingTypes(this.props.token);
                }
            });
    }
    render() {

        return (
            <div>
                {this.props.addProjectLoading ? <div className="loading" /> : ""}
                {this.props.getProjectsLoading ? <div className="loading" /> : ""}
                {this.props.getSharingTypesLoading ? <div className="loading" /> : ""}
                {this.props.getCitiesLoading ? <div className="loading" /> : ""}
                {this.props.getResellersLoading ? <div className="loading" /> : ""}
                <ProjectsTable {...this.props} addForm={this.handleAddProject} editForm={this.handleEditProject} delete={this.handleDeleteProject} />
                <ModalDialog
                    open={this.state.editOpenDialog}
                    title={'Proje Güncelleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <EditProjectForm {...this.state.rowData} onSubmitForm={this.handleEditProjectSubmit} sharingTypesList={this.props.sharingTypes} cities={this.props.cities} selectedCity={this.props.selectedCity} getCity={this.getCity} resellers={this.props.resellers} persons={this.props.persons} />
                </ModalDialog>
                <ModalDialog
                    open={this.state.addOpenDialog}
                    title={'Yeni Proje Ekleme'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <AddProjectForm onSubmitForm={this.handleAddProjectSubmit} sharingTypes={this.props.sharingTypes} cities={this.props.cities} selectedCity={this.props.selectedCity} getCity={this.getCity} resellers={this.props.resellers} persons={this.props.persons} />
                </ModalDialog>
            </div>
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ resellerReducer, personReducer, cityReducer, projectReducer, sharingTypeReducer }) => {
    return {
        ...resellerReducer,
        ...cityReducer,
        ...personReducer,
        ...projectReducer,
        ...sharingTypeReducer
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getAllResellers,
    getAllCities,
    getCity,
    setActivePageTitle,
    setBreadCrumb,
    getAllProjects,
    getAllSharingTypes,
    getResellerProjects,
    addProject,
    updateProject,
    deleteProject,
    getAllPersons

}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Projects));