import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, FormControl, InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';


class ProposalForm extends Component {

    state = {
        person: this.props.person
    }
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name"> Konu</InputLabel>
                    <Input
                        id="subject"
                        name="subject"
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Açıklama </InputLabel>
                    <Input
                        id="description"
                        name="description"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    KAYDET
                </Button>
            </form>
        )
    }
}

ProposalForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ProposalForm);