import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button } from '@material-ui/core';
class ResellersTable extends Component {

    state = {
        menuStatus: false,
        anchorEl: null,
    }

    render() {
        const tableData = {
            title: "Müşteri Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.reseller.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Ünvan",
                    field: "title",
                    render: rowData => {
                        return (
                            <a href={"/reseller/" + rowData._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.title}</a>
                        )
                    },
                },
                {

                    title: "Referans",
                    field: "reference.name",
                    render: rowData => {
                        return (
                            rowData.reference ? <a href={"/reseller/" + rowData.reference._id} style={{ color: "red", fontWeight: "500" }}>{rowData.reference.name}</a> : ""
                        )
                    },
                },

                { title: "Sabit Telefon", field: "landPhone", hidden: true },
                { title: "Cep Telefonu", field: "mobilePhone", hidden: true },
                { title: "İl", field: "city.name", hidden: true },
                { title: "İlçe", field: "county.name", hidden: true },
                { title: "Email", field: "email", hidden: true },
                { title: "Web Sitesi", field: "webSite", hidden: true },
                { title: "Vergi Dairesi", field: "taxOffice", hidden: true },
                { title: "Vergi Numarası", field: "taxNumber", hidden: true },
                { title: "Açıklama", field: "description", hidden: true },
            ],
            data: this.props.resellers
        };

        let tableActions = [];
        if (this.props.person.permissions.reseller.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }
        if (this.props.person.permissions.reseller.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ResellersTable;