// tüm reducer'ları export edebilmek için redux altındaki combineReducers ı sayfaya dahil et
import { combineReducers } from 'redux';

// person Reducer ı sayfaya dahil et
import personReducer from './person';

// department Reducer ı sayfaya dahil et
import departmentReducer from './department';


// city Reducer ı sayfaya dahil et
import cityReducer from './city';

// city Reducer ı sayfaya dahil et
import projectReducer from './project';

// sharingType reducer ı sayfaya dahil et
import sharingTypeReducer from './sharingType';

// subscriptionType reducer ı sayfaya dahil et
import subscriptionTypeReducer from './subscriptionType';

// subscription reducer ı sayfaya dahil et
import subscriptionReducer from './subscription';

// block reducer ı sayfaya dahil et
import blockReducer from './block';

// block reducer ı sayfaya dahil et
import apartmentReducer from './apartment';

// block reducer ı sayfaya dahil et
import mainCounterReducer from './mainCounter';

// block reducer ı sayfaya dahil et
import apartmentCounterReducer from './apartmentCounter';

// addresses reducer ı sayfaya dahil et
import addressReducer from './address';

// period reducer ı sayfaya dahil et
import periodReducer from './period';

// taskType reducer ı sayfaya dahil et
import taskTypeReducer from './taskType';

// task reducer ı sayfaya dahil et
import taskReducer from './task';

// note reducer ı sayfaya dahil et
import noteReducer from './note';

// proposal reducer ı sayfaya dahil et
import proposalReducer from './proposal';

import stockCardReducer from './stockCard';

// subscriptionInvoice reducer ı sayfaya dahil et
import subscriptionInvoiceReducer from './subscriptionInvoice';

// mainCounterData reducer ı sayfaya dahil et
import mainCounterDataReducer from './mainCounterData';

// additionalExpense reducer ı sayfaya dahil et
import additionalExpenseReducer from './additionalExpense';

// additionalExpense reducer ı sayfaya dahil et
import additionalExpenseDataReducer from './additionalExpenseDatas';

// projectProblems reducer ı sayfaya dahil et
import projectProblemsReducer from './projectProblems';

// combineReducers ile tüm reducerları export et
export default combineReducers({
    personReducer,
    departmentReducer,
    cityReducer,
    projectReducer,
    sharingTypeReducer,
    subscriptionTypeReducer,
    subscriptionReducer,
    blockReducer,
    apartmentReducer,
    mainCounterReducer,
    apartmentCounterReducer,
    stockCardReducer,
    addressReducer,
    periodReducer,
    taskTypeReducer,
    taskReducer,
    noteReducer,
    subscriptionInvoiceReducer,
    mainCounterDataReducer,
    additionalExpenseReducer,
    additionalExpenseDataReducer,
    proposalReducer,
    projectProblemsReducer,
});