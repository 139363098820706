import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import { connect } from 'react-redux';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';

import { getAllProjects } from '../../actions/project';
import { getAllResellers, getDepartmentPersons, getAllResellerPersons, getAllPersons } from '../../actions/person';
import { getAllDeparments } from '../../actions/department';
import { getAllTaskTypes } from '../../actions/taskType';
import { getAllResellerDemand } from '../../actions/task';
import DemandTable from './DemandTable';

class Notes extends Component {

    constructor(props) {
        super(props);
        this.props.setActivePageTitle("TALEP VE İSTEKLER");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "string", text: "Talep ve İstekler" }
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    state = {
        editOpenDialog: false,
        addOpenDialog: false
    }

    async UNSAFE_componentWillMount() {
        await this.props.getAllResellerDemand(this.props.person._id, this.props.token);
    }


    handleCloseDialog = () => {
        this.setState({ editOpenDialog: false });
        this.setState({ addOpenDialog: false });
    }


    render() {
        return (
            <div>
                <DemandTable addForm={this.handleAddDemand} {...this.props} />
            </div>
        )
    }
}


const mapStateToProps = ({ personReducer, departmentReducer, taskTypeReducer, projectReducer, taskReducer }) => {
    return {
        ...personReducer,
        ...departmentReducer,
        ...taskTypeReducer,
        ...projectReducer,
        ...taskReducer
    }
}

const mapDispatchToProps = {
    getAllDeparments,
    getAllTaskTypes,
    getAllProjects,
    setActivePageTitle,
    setBreadCrumb,
    getAllResellers,
    getDepartmentPersons,
    getAllResellerPersons,
    getAllPersons,
    getAllResellerDemand,
 
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notes));