import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_RESELLER_PROJECTS_PENDING = "GET_RESELLERS_PENDING";
export const GET_RESELLER_PROJECTS_FULFILLED = "GET_RESELLER_PROJECTS_FULFILLED";
export const GET_RESELLER_PROJECTS_REJECTED = "GET_RESELLER_PROJECTS_REJECTED";

export const ADD_PROJECT_PENDING = "ADD_PROJECT_PENDING";
export const ADD_PROJECT_FULFILLED = "ADD_PROJECT_FULFILLED";
export const ADD_PROJECT_REJECTED = "ADD_PROJECT_REJECTED";

export const UPDATE_PROJECT_PENDING = "UPDATE_PROJECT_PENDING";
export const UPDATE_PROJECT_FULFILLED = "UPDATE_PROJECT_FULFILLED";
export const UPDATE_PROJECT_REJECTED = "UPDATE_PROJECT_REJECTED";

export const DELETE_PROJECT_PENDING = "DELETE_PROJECT_PENDING";
export const DELETE_PROJECT_FULFILLED = "DELETE_PROJECT_FULFILLED";
export const DELETE_PROJECT_REJECTED = "DELETE_PROJECT_REJECTED";

export const GET_PROJECTS_PENDING = "GET_PROJECTS_PENDING";
export const GET_PROJECTS_FULFILLED = "GET_PROJECTS_FULFILLED";
export const GET_PROJECTS_REJECTED = "GET_PROJECTS_REJECTED";

export const GET_PROJECT_PENDING = "GET_PROJECT_PENDING";
export const GET_PROJECT_FULFILLED = "GET_PROJECT_FULFILLED";
export const GET_PROJECT_REJECTED = "GET_PROJECT_REJECTED";

export function getResellerProjects(reseller, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_RESELLER_PROJECTS",
            payload: axios.get(`${API_URL}/project/reseller/${reseller}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllProjects(token) {

    return async dispatch => {
        await dispatch({
            type: "GET_PROJECTS",
            payload: axios.get(`${API_URL}/project/all`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getProject(projectId, token) {

    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT",
            payload: axios.get(`${API_URL}/project/${projectId}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addProject(project, token) {

    return async dispatch => {
        await dispatch({
            type: "ADD_PROJECT",
            payload: axios.post(`${API_URL}/project`, project, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updateProject(project, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_PROJECT",
            payload: axios.put(`${API_URL}/project`, project, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteProject(project, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_PROJECT",
            payload: axios.delete(`${API_URL}/project`, { data: { ...project, token: token } })
                .then(result => result.data)
        })
    }
}
