import React, { Component } from 'react'
import { Input, Button, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../../styles/app';

class AddPeriodAdditionalExpenseForm extends Component {

    state = {

        period: this.props.period._id,
        blocks: this.props.period.project.blocks.map(block => block._id),
        title: "",
        unitPrice: 1,
        invoiceBalance: 0,
        apartmentStatus: 0,
        invoiceType: 0,
        sharingType: 0,
        installmentStatus: 0,
    };

    handleChange = (element) => {
        this.setState({ [element.target.name]: element.target.value })
    }

    handleBlocks = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const blocks = state.blocks.concat(name.toString());
                return {
                    blocks
                };
            });
        }
        else {
            this.setState(state => {
                const blocks = state.blocks.filter(item => item.toString() !== name.toString());
                return {
                    blocks,
                };
            });
        }
    };

    render() {

        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl fullWidth required>
                    <InputLabel htmlFor="title">Görünecek İsim</InputLabel>
                    <Input
                        id="title"
                        name="title"
                        type="text"
                        onChange={this.handleChange}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="invoiceType">Fatura Tipi</InputLabel>
                    <Select
                        value={this.state.invoiceType}
                        onChange={this.handleChange}
                        id="invoiceType"
                        name="invoiceType"
                    >
                        <MenuItem key={0} value={0}>Sabit Tutarı Dağıt</MenuItem>
                        <MenuItem key={1} value={1}>Faturayı Dağıt</MenuItem>
                    </Select>
                </FormControl>
                {
                    this.state.invoiceType === 0 ?
                        <FormControl required fullWidth>
                            <InputLabel htmlFor="unitPrice">Tutar</InputLabel>
                            <Input
                                id="unitPrice"
                                name="unitPrice"
                                type="unitPrice"
                                onChange={this.handleChange}
                                defaultValue={this.state.unitPrice}
                            />
                        </FormControl> : ""
                }
                {
                    this.state.invoiceType === 1 ?
                        <div>
                            <FormControl required fullWidth>
                                <InputLabel htmlFor="invoiceBalance">Fatura Tutarı</InputLabel>
                                <Input
                                    id="invoiceBalance"
                                    name="invoiceBalance"
                                    type="invoiceBalance"
                                    onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl required fullWidth>
                                <InputLabel htmlFor="sharingType">Dağılım Şekli</InputLabel>
                                <Select
                                    value={this.state.sharingType}
                                    onChange={this.handleChange}
                                    id="sharingType"
                                    name="sharingType"
                                >
                                    <MenuItem key={0} value={0}>M² Üzerinden</MenuItem>
                                    <MenuItem key={1} value={1}>Daire Sayısına Eşit</MenuItem>
                                </Select>
                            </FormControl>


                        </div> : ""
                }
                <FormControl required fullWidth>
                    <InputLabel htmlFor="sharingType">Daire Durumu</InputLabel>
                    <Select
                        value={this.state.apartmentStatus}
                        onChange={this.handleChange}
                        id="apartmentStatus"
                        name="apartmentStatus"
                    >
                        <MenuItem key={0} value={0}>Tüm Daireler</MenuItem>
                        <MenuItem key={1} value={1}>Sadece Dolu Daireler</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                    <InputLabel htmlFor="blocks">Dağıtım Yapılacak Bloklar</InputLabel>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        this.props.period.project.blocks.map((block) => {
                            return (
                                <FormControlLabel key={block._id}
                                    control={
                                        <Switch checked={this.state.blocks.includes(block._id)} onChange={this.handleBlocks(block._id)} />
                                    }
                                    label={block.name}
                                />
                            )
                        })
                    }
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Faturayı Dağıt
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(AddPeriodAdditionalExpenseForm);