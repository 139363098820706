import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_DEPARTMENTS_PENDING = "GET_DEPARTMENTS_PENDING";
export const GET_DEPARTMENTS_FULFILLED = "GET_DEPARTMENTS_FULFILLED";
export const GET_DEPARTMENTS_REJECTED = "GET_DEPARTMENTS_REJECTED";

export const ADD_DEPARTMENT_PENDING = "ADD_DEPARTMENT_PENDING";
export const ADD_DEPARTMENT_FULFILLED = "ADD_DEPARTMENT_FULFILLED";
export const ADD_DEPARTMENT_REJECTED = "ADD_DEPARTMENT_REJECTED";

export const PUT_DEPARTMENT_PENDING = "PUT_DEPARTMENT_PENDING";
export const PUT_DEPARTMENT_FULFILLED = "PUT_DEPARTMENT_FULFILLED";
export const PUT_DEPARTMENT_REJECTED = "PUT_DEPARTMENT_REJECTED";

export const DELETE_DEPARTMENT_PENDING = "DELETE_DEPARTMENT_PENDING";
export const DELETE_DEPARTMENT_FULFILLED = "DELETE_DEPARTMENT_FULFILLED";
export const DELETE_DEPARTMENT_REJECTED = "DELETE_DEPARTMENT_REJECTED";

export function getAllDeparments(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_DEPARTMENTS",
            payload: axios.get(`${API_URL}/department/all`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function addDepartment(department, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_DEPARTMENT",
            payload: axios.post(`${API_URL}/department`, department, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function editDepartment(department, token) {
    return async dispatch => {
        await dispatch({
            type: "PUT_DEPARTMENT",
            payload: axios.put(`${API_URL}/department`, department, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteDepartment(department, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_DEPARTMENT",
            payload: axios.delete(`${API_URL}/department`, { data: { ...department, token: token } })
                .then(result => result.data)
        })
    }
}