import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button, Chip } from '@material-ui/core';
class SubscriptionsTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Abonelik Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },

            columns: [
                this.props.person.permissions.subscription.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Abonelik Adı", field: "name",
                    render: rowData => {
                        return (
                            rowData.name
                        );
                    }
                },
                {
                    title: "Abonelik Numarası",
                    render: rowData => {
                        return (
                            rowData.subscriberNumber
                        );
                    }
                },
                {
                    title: "Abonelik Tipi",
                    render: rowData => {
                        return (
                            rowData.type.name
                        );
                    }
                },
                {
                    title: "Paylaşım Yapılan Bloklar",
                    render: rowData => {
                        return (
                            rowData.sharingBlocks.map((block) => {
                                return <Chip key={block._id} style={{ backgroundColor: "#2ebd7b", color: "#fff" }} label={block.name} />
                            })
                        );
                    }
                },
            ],
            data: this.props.subscriptions
        };

        let tableActions = [];
        if (this.props.person.permissions.subscription.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.subscription.create) {
            tableActions.push(
                { // tekli ekle
                    icon: 'plus_one',
                    isFreeAction: true,
                    tooltip: 'Yeni Kayıt Ekle',
                    onClick: this.props.addForm,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: 'green'
                        }
                    }
                }
            );
        }
        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default SubscriptionsTable;