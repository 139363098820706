import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_CITIES_PENDING = "GET_CITIES_PENDING";
export const GET_CITIES_FULFILLED = "GET_CITIES_FULFILLED";
export const GET_CITIES_REJECTED = "GET_CITIES_REJECTED";

export const GET_CITY_PENDING = "GET_CITY_PENDING";
export const GET_CITY_FULFILLED = "GET_CITY_FULFILLED";
export const GET_CITY_REJECTED = "GET_CITY_REJECTED";


export function getAllCities(token) {
    return async dispatch => {
        await dispatch({
            type: "GET_CITIES",
            payload: axios.get(`${API_URL}/city/all`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getCity(cityId, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_CITY",
            payload: axios.get(`${API_URL}/city/${cityId}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}