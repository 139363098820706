import React, { Component } from 'react'
import { Input, Button, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class UpdateSharingSettingsForm extends Component {

    state = {
        _id: this.props.project._id,
        pastPeriodCount: this.props.project.pastPeriodCount,
        waterConsumptionDeviationRate: this.props.project.waterConsumptionDeviationRate,
        fifteenDegreeStatus: this.props.project.fifteenDegreeStatus,
        inputOutputTemperature: this.props.project.inputOutputTemperature,
        maximumConsumption: this.props.project.maximumConsumption,
        heatCalorimeterComparison: this.props.project.heatCalorimeterComparison,
        waterComparison: this.props.project.waterComparison,

        heatCalorimeter: false,
        coldCalorimeter: false,
        water: false,
        heatPaymentMeter: false,
        boilerElectric: false,
        vrfAirConditioning: false,
        individualGas: false,
        individualElectric: false
    };

    UNSAFE_componentWillMount() {

        this.props.project.sharingTypes.map((item) => {
            if (item._id === "5cefd0dd45844c0a69b509cd") {
                this.setState({ heatCalorimeter: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509ce") {
                this.setState({ coldCalorimeter: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509cf") {
                this.setState({ coldWater: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d1") {
                this.setState({ heatPaymentMeter: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d2") {
                this.setState({ boilerElectric: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d3") {
                this.setState({ vrfAirConditioning: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d4") {
                this.setState({ individualGas: true });
            }
            else if (item._id === "5cefd0dd45844c0a69b509d5") {
                this.setState({ individualElectric: true });
            }
            return "";
        });

    }

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleChangePastPeriodCount = (element) => {
        this.setState({ pastPeriodCount: element.target.value })
    }
    handleChangeConsumptionDeviationRate = (element) => {
        this.setState({ waterConsumptionDeviationRate: element.target.value })
    }

    handleChangeHeatCalorimeterComparison = (element) => {
        this.setState({ heatCalorimeterComparison: element.target.value })
    }

    handleChangeWaterComparison = (element) => {
        this.setState({ waterComparison: element.target.value })
    }

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                {
                    this.state.heatCalorimeter ?
                        <div>
                            <Typography variant="h5">Isıtma Ayaları</Typography>
                            <Divider variant="middle" />
                            <FormControl required style={{ width: "20%" }}>
                                <InputLabel htmlFor="fifteenDegreeStatus">15 Derece Analizi Yapılsın Mı?</InputLabel>
                                <br />
                                <br />
                                <Switch checked={this.state.fifteenDegreeStatus} onChange={this.handleChangeStatus('fifteenDegreeStatus')} value="fifteenDegreeStatus" />
                            </FormControl>
                            <FormControl required style={{ width: "20%" }}>
                                <InputLabel htmlFor="inputOutputTemperature">Giriş Çıkış Sıcaklık Kontrolü?</InputLabel>
                                <br />
                                <br />
                                <Switch checked={this.state.inputOutputTemperature} onChange={this.handleChangeStatus('inputOutputTemperature')} value="inputOutputTemperature" />
                            </FormControl>
                            <br />
                            <FormControl required style={{ width: "16%" }}>
                                <InputLabel htmlFor="maximumConsumption">Maksimum Tüketim</InputLabel>
                                <Input
                                    id="maximumConsumption"
                                    name="maximumConsumption"
                                    type="text"
                                    defaultValue={this.state.maximumConsumption}
                                />
                            </FormControl>
                            <FormControl required style={{ width: "16%", marginLeft: "5%" }}>
                                <InputLabel htmlFor="heatCalorimeterComparison">Isıtma Kalorimetre Kıyası</InputLabel>
                                <Select
                                    value={this.state.heatCalorimeterComparison}
                                    onChange={this.handleChangeHeatCalorimeterComparison}
                                    id="heatCalorimeterComparison"
                                    name="heatCalorimeterComparison"
                                >
                                    <MenuItem key={0} value={0}>Metrekareye Göre</MenuItem>
                                    <MenuItem key={1} value={1}>Dairenin Geçen Seneki Kullanımı</MenuItem>
                                    <MenuItem key={2} value={2}>Belirtilen Maksimum Tüketim</MenuItem>
                                </Select>
                            </FormControl>


                        </div>
                        : ""
                }
                {
                    this.state.coldWater ?
                        <div>
                            <br /><br />
                            <Typography variant="h5">Su Ayaları</Typography>
                            <Divider variant="middle" />
                            <br />
                            <FormControl required style={{ width: "16%" }}>
                                <InputLabel htmlFor="readingPeriodId">Gemişe Dönük Analiz Periyodu</InputLabel>
                                <Select
                                    value={this.state.pastPeriodCount}
                                    onChange={this.handleChangePastPeriodCount}
                                    id="pastPeriodCount"
                                    name="pastPeriodCount"
                                >
                                    <MenuItem key={1} value={1}>1 Aylık</MenuItem>
                                    <MenuItem key={2} value={2}>2 Aylık</MenuItem>
                                    <MenuItem key={3} value={3}>3 Aylık</MenuItem>
                                    <MenuItem key={4} value={4}>4 Aylık</MenuItem>
                                    <MenuItem key={5} value={5}>5 Aylık</MenuItem>
                                    <MenuItem key={6} value={6}>6 Aylık</MenuItem>
                                    <MenuItem key={7} value={7}>7 Aylık</MenuItem>
                                    <MenuItem key={8} value={8}>8 Aylık</MenuItem>
                                    <MenuItem key={9} value={9}>9 Aylık</MenuItem>
                                    <MenuItem key={10} value={10}>10 Aylık</MenuItem>
                                    <MenuItem key={11} value={11}>11 Aylık</MenuItem>
                                    <MenuItem key={12} value={12}>12 Aylık</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl required style={{ width: "16%", marginLeft: "5%" }}>
                                <InputLabel htmlFor="readingPeriodId">Tüketim Sapma Oranı</InputLabel>
                                <Select
                                    value={this.state.waterConsumptionDeviationRate}
                                    onChange={this.handleChangeConsumptionDeviationRate}
                                    id="waterConsumptionDeviationRate"
                                    name="waterConsumptionDeviationRate"
                                >
                                    <MenuItem key={10} value={10}>%10 Sapma</MenuItem>
                                    <MenuItem key={20} value={20}>%20 Sapma</MenuItem>
                                    <MenuItem key={30} value={30}>%30 Sapma</MenuItem>
                                    <MenuItem key={40} value={40}>%40 Sapma</MenuItem>
                                    <MenuItem key={50} value={50}>%50 Sapma</MenuItem>
                                    <MenuItem key={60} value={60}>%60 Sapma</MenuItem>
                                    <MenuItem key={70} value={70}>%70 Sapma</MenuItem>
                                    <MenuItem key={80} value={80}>%80 Sapma</MenuItem>
                                    <MenuItem key={90} value={90}>%90 Sapma</MenuItem>
                                    <MenuItem key={100} value={100}>%100 Sapma</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl required style={{ width: "16%", marginLeft: "5%" }}>
                                <InputLabel htmlFor="waterComparison">Su Kıyası</InputLabel>
                                <Select
                                    value={this.state.waterComparison}
                                    onChange={this.handleChangeWaterComparison}
                                    id="waterComparison"
                                    name="waterComparison"
                                >
                                    <MenuItem key={0} value={0}>Seçilen Analiz Dönemi Ortalaması</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        : ""
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Ayaları Kaydet
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(UpdateSharingSettingsForm);