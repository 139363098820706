import React, { Component } from 'react';
// components
import { Chip } from "@material-ui/core";
import Table from '../Items/Table';
class AdditionalExpenseDatasTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Ek Giderler Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                {
                    title: "Ek Gider Başlığı", field: "title",
                    render: rowData => {
                        return (
                            rowData.title
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılacak Bloklar",
                    render: rowData => {
                        return (
                            rowData.blocks.map((block) => {
                                return <Chip key={block._id} style={{ backgroundColor: "#2ebd7b", color: "#fff" }} label={block.name} />
                            })
                        );
                    }
                },
                {
                    title: "Borç Tipi", field: "installmentStatus",
                    lookup: {
                        0: "Sürekli",
                        1: "Taksitli",
                    },
                },
                {
                    title: "Taksit Sayısı", hidden: true,
                    render: rowData => {
                        return (
                            rowData.installmentCount
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Dönem Sayısı",
                    render: rowData => {
                        return (
                            rowData.sharingInstallmentCount
                        );
                    }
                },
                {
                    title: "Fatura Tipi", field: "invoiceType", hidden: true,
                    lookup: {
                        0: "Sabit Tutarı Dağıt",
                        1: "Faturayı Dağıt",
                    },
                },
                {
                    title: "Girilen Fatura Tutarı", field: "invoiceBalance",
                    render: rowData => {
                        return (
                            rowData.invoiceBalance
                        );
                    }
                },
                {
                    title: "Sabit Tutar", field: "unitPrice",
                    render: rowData => {
                        return (
                            rowData.unitPrice
                        );
                    }
                },
                {
                    title: "Dağıtım Tipi", field: "sharingType",
                    lookup: {
                        0: "M² Üzerinden",
                        1: "Daire Sayısına Eşit",
                    }
                },
                {
                    title: "Daire Durumu", field: "apartmentStatus",
                    lookup: {
                        0: "Tüm Daireler",
                        1: "Sadece Dolu Daireler",
                    }
                },
            ],
            data: this.props.additionalExpenseDatas
        };
        let tableActions = [];
        if (this.props.person.permissions.additonalExpenseData.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }
        if (this.props.person.permissions.additonalExpenseData.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default AdditionalExpenseDatasTable;