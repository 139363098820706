import React, { Component } from 'react';
import { Divider, Button, MenuItem, Menu, FormControl, FormLabel, Switch, InputLabel } from "@material-ui/core";
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import ModalDialog from '../Items/ModalDialog';
import SearchSelect from 'react-select';
import swal from 'sweetalert';

import { getLastActivePeriod, getLastClosedPeriod, addPeriod, updateRequestSharing } from '../../actions/period';
import { getAllSubscriptions } from '../../actions/subscription';
import { addSubscriptionInvoice } from '../../actions/subscriptionInvoice';
import { priceFormat } from '../../functions/helper';
import RequestSharingForm from '../Persons/RequestSharingForm';

class FastOperation extends Component {


    async UNSAFE_componentWillMount() {
        await this.props.getLastActivePeriod(this.props.project._id, this.props.token);
    }

    state = {
        fastOperation: null,
        periodInformationDialog: false,
        blocksOpenDialog: false,
        counterListOpenDialog: false,
        addInvoiceOpenDialog: false,
        block: { value: "", label: "Lütfen bir blok seçin.." },
        subscription: { value: "", label: "Lütfen abonelik seçin..", type: "" },
        invoiceCheck: false,
        image: null,
    }

    handleFastOperationMenuOpen = event => {
        this.setState({ fastOperation: event.currentTarget });
    };

    handleFastOperationMenuClose = () => {
        this.setState({ fastOperation: null });
    };

    handleOpenDialog = (name) => {
        this.handleFastOperationMenuClose();
        this.setState({ [name]: true });
    };

    handleAddInvoice = async () => {
        await this.props.getAllSubscriptions(this.props.project._id, this.props.token);
        this.setState({ addInvoiceOpenDialog: true });
    }

    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleAddInvoiceSubmit = async (e, state) => {

        let activePeriod = this.props.lastActivePeriod;

        if (state.subscription.value === "") {
            return this.props.enqueueSnackbar("Bir fatura aboneliği seçmek zorundasınız! ", {
                variant: "error",
                autoHideDuration: 5000
            });
        }

        if (!state.invoiceCheck) {
            return this.props.enqueueSnackbar("Lütfen tesisat numrasını kontrol ettiğinizi onaylayın. ", {
                variant: "error",
                autoHideDuration: 5000
            });
        }

        let invoice = new FormData();
        invoice.append('project', this.props.project._id);
        invoice.append('subscription', state.subscription.value);
        invoice.append('balance', priceFormat(e.target.balance.value));
        invoice.append('consumption', priceFormat(e.target.consumption.value));


        if (!this.props.lastActivePeriod) {
            await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
            let newPeriod = {
                project: this.props.project._id,
                periodDate: new Date(),
                readingBalance: parseFloat(this.props.project.readingPrice),
                invoiceBalance: parseFloat(this.props.project.invoiceBalance),
            };

            if (this.props.lastClosedPeriod) {
                newPeriod.lastClosedPeriodDate = this.props.lastClosedPeriod.periodDate;
                newPeriod.lastPaymentDate = this.props.lastClosedPeriod.lastPaymentDate;
                newPeriod.paymentPlace = this.props.lastClosedPeriod.paymentPlace;
                newPeriod.ibanNumber = this.props.lastClosedPeriod.ibanNumber;
                newPeriod.accountName = this.props.lastClosedPeriod.accountName;
                newPeriod.periodNote = this.props.lastClosedPeriod.periodNote;
            }

            await this.props.addPeriod(newPeriod, this.props.token);
            if (this.props.addPeriodResult.code === 200) {
                activePeriod = this.props.addPeriodResult.data.period;
            } else {
                this.setState({ addInvoiceOpenDialog: false });
                this.props.enqueueSnackbar("Fatura ekleme işlemi işlemi başarısız. Sistem yetkilisi ile görüşün.", {
                    variant: "error",
                    persist: true,
                    action: (
                        <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                    ),
                });
            }
        }

        invoice.append('period', activePeriod._id);

        if (state.subscription.type === "5d0a312392706a151bc178df") {
            invoice.append('heatLossRate', this.props.project.heatLossRate);
            invoice.append('boilerTemperature', this.props.project.boilerTemperature);
            invoice.append('thermalCoefficient', this.props.project.thermalCoefficient);
            invoice.append('sharingPeriod', this.props.project.sharingPeriod);
        }

        if (state.subscription.type === "5d0a312392706a151bc178e0") { // soguk su ise
            if (state.coldWaterSharingCriterion === 2) {
                invoice.append('unitPrice', this.props.project.coldWaterSharingUnitPrice);
            } else {
                invoice.append('unitPrice', 0);
            }
        }

        if (state.image) {
            if (state.image.length > 0) {
                for (var i = 0; i < state.image.length; i++) {
                    invoice.append('image', state.image[i]);
                }
            }
        }

        return swal({
            title: "Emin misin?",
            text: "Fatura ekleniyor!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addSubscriptionInvoice(invoice, this.props.token);
                    if (this.props.addSubscriptionInvoiceResult.code === 200) {
                        this.setState({ addInvoiceOpenDialog: false });
                        this.props.enqueueSnackbar("Fatura başarıyla eklendi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });

                        window.location.href = "/period/" + activePeriod._id + "?tab=0"
                    } else {
                        this.props.enqueueSnackbar("Fatura eklenirken bir hata oluştu. " + this.props.addSubscriptionInvoiceResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleAddCounterValue = async (e, state) => {

        let activePeriod = this.props.lastActivePeriod;
        if (!this.props.lastActivePeriod) {
            await this.props.getLastClosedPeriod(this.props.project._id, this.props.token);
            let newPeriod = {
                project: this.props.project._id,
                periodDate: new Date(),
                readingBalance: parseFloat(this.props.project.readingPrice),
                invoiceBalance: parseFloat(this.props.project.invoiceBalance),
            };

            if (this.props.lastClosedPeriod) {
                newPeriod.lastClosedPeriodDate = this.props.lastClosedPeriod.periodDate;
                newPeriod.lastPaymentDate = this.props.lastClosedPeriod.lastPaymentDate;
                newPeriod.paymentPlace = this.props.lastClosedPeriod.paymentPlace;
                newPeriod.ibanNumber = this.props.lastClosedPeriod.ibanNumber;
                newPeriod.accountName = this.props.lastClosedPeriod.accountName;
                newPeriod.periodNote = this.props.lastClosedPeriod.periodNote;
            }

            await this.props.addPeriod(newPeriod, this.props.token);
            if (this.props.addPeriodResult.code === 200) {
                activePeriod = this.props.addPeriodResult.data.period;
            } else {
                this.setState({ addInvoiceOpenDialog: false });
                this.props.enqueueSnackbar("Veri ekleme işlemi işlemi başarısız. Sistem yetkilisi ile görüşün.", {
                    variant: "error",
                    persist: true,
                    action: (
                        <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                    ),
                });
            }
        }


        return window.location.href = "/period/" + activePeriod._id + "?tab=2";
    }

    onChangeHandlerFiles = async event => {

        if (event.target.files.length > 0) {
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (acceptedImageTypes.includes(event.target.files[0].type)) {
                await this.setState({
                    image: event.target.files,
                });
            } else {
                this.errorMessage();
            }
        }
    }

    errorMessage = () => {
        return this.props.enqueueSnackbar('Lütfen bir resim dosyası yükleyin.', {
            variant: "error",
            persist: true,
            action: (
                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
            ),
        });
    }

    openPerodInformation = async () => {
        this.setState({ periodInformationDialog: true })
    }

    handleEditPeriodInformationSubmit = (e, state) => {
        const period = {
            _id: e.target._id.value,
            sharingRequestStatus: true,
            periodDate: e.target.periodDate.value,
            lastPaymentDate: e.target.lastPaymentDate.value,
            paymentPlace: e.target.paymentPlace.value,
            accountName: e.target.accountName.value,
            ibanNumber: e.target.ibanNumber.value,
            periodNote: e.target.periodNote.value
        }
        return swal({
            title: "Emin misin?",
            text: "Bu içeriği güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateRequestSharing({ period, person: this.props.person }, this.props.token);
                    if (this.props.updateRequestSharingResult.code === 200) {
                        this.setState({ periodInformationDialog: false });
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarısız. " + this.props.updateRequestSharingResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }


            });
    }

    handleCloseDialog = () => {
        this.setState({ periodInformationDialog: false });
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button variant="contained" onClick={this.handleFastOperationMenuOpen} className="hizliIslem" style={{ color: "#fff", backgroundColor: "#1bcc1b", padding: "20px", fontSize: "20px" }}>Hızlı İşlem</Button>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.fastOperation}
                    keepMounted
                    open={Boolean(this.state.fastOperation)}
                    onClose={this.handleFastOperationMenuClose}
                    style={{ marginTop: "55px", width: "190px" }}
                >
                    <MenuItem onClick={this.handleFastOperationMenuClose}>
                        <a className="menuItemLink" href={"/project/" + this.props.project._id + "?tab=0"}>Blok Listesi</a>
                    </MenuItem>
                    <MenuItem onClick={() => { this.handleOpenDialog("blocksOpenDialog") }}>Daire Listesi</MenuItem>
                    <MenuItem onClick={() => { this.handleOpenDialog("counterListOpenDialog") }}>Sayaç Listesi</MenuItem>
                    <Divider style={{ height: "5px", background: "#796363" }} />
                    {/* {
                        this.props.person.permissions.period.addInvoice ? <MenuItem onClick={this.handleAddInvoice}>Fatura Ekle</MenuItem> : ""
                    }
                    {
                        this.props.person.permissions.period.updateApartmentCounterData ? <MenuItem onClick={this.handleAddCounterValue}>Veri Girişi Yap</MenuItem> : ""
                    } */}

                    {
                        this.props.lastActivePeriod ? <div>
                            <Divider style={{ height: "5px", background: "#796363" }} />
                            <MenuItem>
                                <a className="menuItemLink" href={"/period/" + this.props.lastActivePeriod._id + "?tab=0"}>Fatura Listesi</a>
                            </MenuItem>
                            {
                                !this.props.lastActivePeriod.sharingRequestStatus ? <MenuItem onClick={this.openPerodInformation} style={{ color: "red" }}>Paylaşım Talebini<br /> Başlat</MenuItem> : ""
                            }
                            <MenuItem><a className="menuItemLink" href={"/project/" + this.props.project._id + "?tab=4"}>Raporlar</a></MenuItem>
                        </div> : ""
                    }

                </Menu>

                <ModalDialog
                    open={this.state.periodInformationDialog}
                    title={'Dönem Bilgileri'}
                    buttons={[
                        <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                    ]}>
                    <RequestSharingForm onSubmitForm={this.handleEditPeriodInformationSubmit} activePeriod={this.props.lastActivePeriod} />
                </ModalDialog   >

                <ModalDialog
                    style={{ textAlign: "center" }}
                    open={this.state.blocksOpenDialog}
                    title={"Daire Listesi İçin Blok Seçin"}
                    buttons={[
                        <Button key="2" onClick={() => { this.setState({ blocksOpenDialog: false }) }} color="default">Kapat</Button>
                    ]}>
                    <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", height: "175px" }}>
                        <FormLabel>Bloklar</FormLabel>
                        <br />
                        <SearchSelect
                            value={this.state.block}
                            onChange={(block) => { window.location.href = "/block/" + block.value + "?tab=0" }}
                            isClearable={true}
                            options={
                                this.props.project.blocks.map((block) => {
                                    return { value: block._id, label: block.name }
                                })
                            }
                        />
                    </FormControl>
                </ModalDialog>
                <ModalDialog
                    style={{ textAlign: "center" }}
                    open={this.state.counterListOpenDialog}
                    title={"Sayaç Listesi İçin Blok Seçin"}
                    buttons={[
                        <Button key="2" onClick={() => { this.setState({ counterListOpenDialog: false }) }} color="default">Kapat</Button>
                    ]}>
                    <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", height: "175px" }}>
                        <FormLabel>Bloklar</FormLabel>
                        <br />
                        <SearchSelect
                            value={this.state.block}
                            onChange={(block) => { window.location.href = "/block/" + block.value + "?tab=1" }}
                            isClearable={true}
                            options={
                                this.props.project.blocks.map((block) => {
                                    return { value: block._id, label: block.name }
                                })
                            }
                        />
                    </FormControl>
                </ModalDialog>
                <ModalDialog
                    open={this.state.addInvoiceOpenDialog}
                    title={"Yeni Fatura Ekleme"}
                    buttons={[
                        <Button key="2" onClick={() => { this.setState({ addInvoiceOpenDialog: false }) }} color="default">Kapat</Button>
                    ]}>
                    <form encType="multipart/form-data" onSubmit={(e) => { e.preventDefault(); this.handleAddInvoiceSubmit(e, this.state) }}>
                        <FormControl required style={{ width: "100%", display: "inline-block" }}>
                            <label style={{ fontSize: "25px" }}>Abonelik / Tesisat Numarası: </label>
                            <br />
                            <SearchSelect
                                value={this.state.subscription}
                                onChange={(subscription) => { this.setState({ subscription }) }}
                                isClearable={true}
                                options={
                                    this.props.subscriptions.map((subscription) => {
                                        return { value: subscription._id, label: subscription.type.name + " / " + subscription.subscriberNumber, type: subscription.type._id }
                                    })
                                }
                            />
                            <Switch checked={this.state.invoiceCheck} onChange={this.handleChangeStatus('invoiceCheck')} />
                            <label style={{ fontSize: "18px", marginTop: "5px", color: "blue" }}>Tesisat numarasını kontrol ettim</label>
                        </FormControl>
                        <br /><br />
                        <FormControl required style={{ width: "100%", display: "inline-block" }}>
                            <label style={{ fontSize: "25px" }}>Fatura Tutarını Girin: </label>
                            <br />
                            <input className="inputInvoice" defaultValue={0} style={{ width: "15%", padding: "10px", marginBottom: "15px" }} name="balance" type="text" />
                            <label>&nbsp;TL</label>

                        </FormControl>
                        <FormControl required style={{ width: "100%", display: "inline-block" }}>
                            <label style={{ fontSize: "25px" }}>Tüketim Miktarini Girin: </label>
                            <br />
                            <input className="inputInvoice" defaultValue={0} style={{ width: "15%", padding: "10px", marginBottom: "15px" }} name="consumption" type="text" />
                            <label>&nbsp;m3 / kw</label>

                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="blockListExcel">Fatura Görseli</InputLabel>
                            <br />
                            <br />
                            <br />
                            <input
                                type="file"
                                onChange={this.onChangeHandlerFiles}
                            />
                        </FormControl>
                        <br /><br />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Faturayı Ekle
                        </Button>
                    </form >
                </ModalDialog>
            </div >
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer, periodReducer, subscriptionReducer, subscriptionInvoiceReducer }) => {
    return {
        ...personReducer,
        ...periodReducer,
        ...subscriptionReducer,
        ...subscriptionInvoiceReducer,
    }
}

// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    getLastClosedPeriod,
    getLastActivePeriod,
    getAllSubscriptions,
    addSubscriptionInvoice,
    addPeriod,
    updateRequestSharing,

}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(FastOperation));