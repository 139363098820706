import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Tab, Tabs, AppBar, Grid, Divider, Switch, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import Business from '@material-ui/icons/Business';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ModalDialog from '../Items/ModalDialog'
import swal from 'sweetalert';
// actionda bulunan dispatch methodlarını sayfaya dahil ve props olarak aşşağıda tanımla
import { setActivePageTitle, setBreadCrumb } from '../../actions/person';
import { getApartment, getApartmentInvoices } from '../../actions/apartment';
import { updateApartmentCounter, deleteApartmentCounter, getApartmentCounters, addApartmentCounter } from '../../actions/apartmentCounter';

import ApartmentCountersTable from '../ApartmentCounters/ApartmentCountersTable';
import EditApartmentCounterForm from '../ApartmentCounters/EditApartmentCounterForm';
import AddApartmentCounterForm from '../ApartmentCounters/AddApartmentCounterForm';
import ApartmentInvoicesTable from './Invoices/ApartmentInvoicesTable';
import { getProjectMainCounters } from '../../actions/mainCounter';

const styles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

class ApartmentDetail extends Component {

    // component içerisinde kullanılacak state
    state = {
        value: 0,
        editCounterOpenDailog: false,
        addCounterOpenDailog: false,
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {
        // yetki kontrolü
        if (!this.props.person.permissions.apartment.read) {
            return window.location.href = "/";
        }
        const { match: { params } } = this.props;
        await this.props.getApartment(params._id, this.props.token);
        this.props.setActivePageTitle(this.props.apartment.block.project.name + " / " + this.props.apartment.block.name + " / " + this.props.apartment.name);
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "link", path: "/projects", text: "Proje Yönetimi" },
            { type: "link", path: "/project/" + this.props.apartment.block.project._id, text: this.props.apartment.block.project.name },
            { type: "link", path: "/block/" + this.props.apartment.block._id, text: this.props.apartment.block.name },
            { type: "string", text: this.props.apartment.name },
        ];
        this.props.setBreadCrumb(breadCrumb);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ addCounterOpenDailog: false, editCounterOpenDailog: false });
        this.handleChangeTab("", this.state.value)
    }

    handleChangeTab = async (event, value) => {
        this.setState({ value });
        if (value === 1) { // eğer ki sayaçlar sekmesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.apartment.block.project._id, text: this.props.apartment.block.project.name },
                { type: "link", path: "/block/" + this.props.apartment.block._id, text: this.props.apartment.block.name },
                { type: "link", path: "/apartment/" + this.props.apartment._id, text: this.props.apartment.name },
                { type: "string", text: "Sayaçlar" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getApartmentCounters(this.props.apartment._id, this.props.token);
        }
        else if (value === 2) { // eğer ki faturalar sekmesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.apartment.block.project._id, text: this.props.apartment.block.project.name },
                { type: "link", path: "/block/" + this.props.apartment.block._id, text: this.props.apartment.block.name },
                { type: "link", path: "/apartment/" + this.props.apartment._id, text: this.props.apartment.name },
                { type: "string", text: "Faturalar" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getApartmentInvoices(this.props.apartment._id, this.props.token);
        }

    };

    handleEditCounter = async (rowData) => {
        this.setState({ editCounterOpenDailog: true, apartmentCounter: Object.assign({}, rowData) });
    }

    handleAddCounter = async () => {
        await this.props.getProjectMainCounters(this.props.apartment.block.project._id, this.props.token);
        this.setState({ addCounterOpenDailog: true });
    }

    handleUpdateApartmentCounterSubmit = async (e, state) => {

        const counter = {
            _id: e.target._id.value,
            name: e.target.name.value,
            serialNumber: e.target.serialNumber.value,
            status: state.status,
            shouldChangeStatus: state.shouldChangeStatus,
            description: e.target.description.value,
        };


        return swal({
            title: "Emin misin?",
            text: "Seçilen sayayacı güncelleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateApartmentCounter(counter, this.props.token);
                    if (this.props.updateApartmentCounterResult.code === 200) {
                        this.setState({ editCounterOpenDailog: false, value: 1 });
                        await this.props.getApartmentCounters(this.props.apartment._id, this.props.token);
                        this.props.enqueueSnackbar('Sayaç başarıyla güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Sayaç güncellenirken bir hata oluştu..' + this.props.updateApartmentCounterResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleAddApartmentCounterSubmit = async (e, state) => {

        const counter = {
            apartment: state.apartment,
            name: e.target.name.value,
            serialNumber: e.target.serialNumber.value,
            project: state.project,
            mainCounter: state.mainCounter.value,
            type: state.mainCounter.type,
            status: state.status,
            counterValue: e.target.counterValue.value,
            description: e.target.description.value,
        };


        return swal({
            title: "Emin misin?",
            text: "Yeni bir sayaç ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addApartmentCounter(counter, this.props.token);
                    if (this.props.addApartmentCounterResult.code === 200) {
                        this.setState({ addCounterOpenDailog: false, value: 1 });
                        await this.props.getApartmentCounters(this.props.apartment._id, this.props.token);
                        this.props.enqueueSnackbar('Sayaç başarıyla güncellendi..', {
                            variant: "success",
                            autoHideDuration: 3000,
                        });
                    } else {
                        this.props.enqueueSnackbar('Sayaç güncellenirken bir hata oluştu..' + this.props.addApartmentCounterResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });

    }

    handleDeleteApartmentCounter = (event, rowData) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowData.map(async (apartmentCounter) => {
                        await this.props.deleteApartmentCounter(apartmentCounter, this.props.token);
                        if (this.props.deleteApartmentCounterResult.code === 200) {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarılı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            await this.props.enqueueSnackbar("Kayıt silme işlemi başarısız. " + this.props.deleteApartmentCounterResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });

                    await this.props.getApartmentCounters(this.props.apartment._id, this.props.token);

                }
            });
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                {this.props.getApartmentInvoicesLoading ? <div className="loading" /> : ""}
                {this.props.getApartmentCountersLoading ? <div className="loading" /> : ""}
                {this.props.addApartmentCounterLoading ? <div className="loading" /> : ""}


                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Daha Fazla Detaylı Bilgi</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        {this.props.getApartmentLoading ? <div className="loading" /> : ""}
                        <Grid container>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Daire Adı</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.apartment.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Ait Olduğu Blok</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.apartment.block.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Daire m²</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.apartment.squareMeters}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Kat Numarası</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.apartment.floorNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Kapı Numarası</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.apartment.doorNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Doluluk Durumu</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            <Switch checked={this.props.apartment.livingStatus} aria-label="Collapse" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Ek Gider Alınma Durumu</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            <Switch checked={this.props.apartment.additionalExpenseStatus} aria-label="Collapse" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Paylaşım Durumu</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            <Switch checked={this.props.apartment.sharingStatus} aria-label="Collapse" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Isı Kayıp Alınma Durumu</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            <Switch checked={this.props.apartment.heatLossExpenseStatus} aria-label="Collapse" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Daire Açıklaması</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">
                                            {this.props.apartment.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Divider variant="middle" />
                <br /> <br /> <br /> <br />
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTab}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="" disabled />
                        <Tab label="Sayaçlar" icon={<Business />} />
                        <Tab label="Faturalar" icon={<Business />} />
                        <Tab label="Servis Kayıtları" icon={<Business />} />
                    </Tabs>
                </AppBar>
                {
                    value === 0 && <div style={{ padding: 8 * 3 }}>

                    </div>
                }
                {
                    value === 1 && <div style={{ padding: 8 * 3 }}>
                        <ApartmentCountersTable detailAdd={true}  {...this.props} addForm={this.handleAddCounter} editForm={this.handleEditCounter} delete={this.handleDeleteApartmentCounter} />
                        <ModalDialog
                            open={this.state.editCounterOpenDailog}
                            title={'Sayaç Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditApartmentCounterForm onSubmitForm={this.handleUpdateApartmentCounterSubmit} apartmentCounter={this.state.apartmentCounter} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.addCounterOpenDailog}
                            title={'Yeni Sayaç Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddApartmentCounterForm onSubmitForm={this.handleAddApartmentCounterSubmit} apartment={this.props.apartment} mainCounters={this.props.mainCounters} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 2 && <div style={{ padding: 8 * 3 }}>
                        <ApartmentInvoicesTable {...this.props} />
                    </div>
                }
            </div >
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ blockReducer, personReducer, apartmentReducer, apartmentCounterReducer, mainCounterReducer }) => {
    return {
        ...personReducer,
        ...blockReducer,
        ...apartmentReducer,
        ...apartmentCounterReducer,
        ...mainCounterReducer,
    }
}
// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb,
    getApartment,
    updateApartmentCounter,
    deleteApartmentCounter,
    getApartmentCounters,
    getApartmentInvoices,
    getProjectMainCounters,
    addApartmentCounter
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(ApartmentDetail)));