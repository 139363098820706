import {
    ADD_SUBSCRIPTION_PENDING, ADD_SUBSCRIPTION_FULFILLED, ADD_SUBSCRIPTION_REJECTED,
    GET_PROJECT_SUBSCRIPTIONS_PENDING, GET_PROJECT_SUBSCRIPTIONS_FULFILLED, GET_PROJECT_SUBSCRIPTIONS_REJECTED,
    GET_TYPE_PROJECT_SUBSCRIPTIONS_PENDING, GET_TYPE_PROJECT_SUBSCRIPTIONS_FULFILLED, GET_TYPE_PROJECT_SUBSCRIPTIONS_REJECTED,
    UPDATE_SUBSCRIPTION_PENDING, UPDATE_SUBSCRIPTION_FULFILLED, UPDATE_SUBSCRIPTION_REJECTED,
    DELETE_SUBSCRIPTION_PENDING, DELETE_SUBSCRIPTION_FULFILLED, DELETE_SUBSCRIPTION_REJECTED,
    GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_PENDING, GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_FULFILLED, GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_REJECTED,
} from '../actions/subscription';
const initialState = {
    addSubscriptionLoading: false,
    deleteSubscriptionLoading: false,
    editSubscriptionLoading: false,
    getAllProjectSubscriptionsWithInvoicesLoading: false,
    getProjectSubscriptionsLoading: false,
    getTypeProjectSubscriptions: false,
    addSubscriptionResult: {},
    editSubscriptionResult: {},
    deleteSubscriptionResult: {},
    subscriptions: [],
}
// sharingType işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_SUBSCRIPTION_PENDING:
            return {
                ...state,
                addSubscriptionLoading: true,
            }
        case ADD_SUBSCRIPTION_FULFILLED:
            return {
                ...state,
                addSubscriptionResult: action.payload,
                addSubscriptionLoading: false,
            }
        case ADD_SUBSCRIPTION_REJECTED:
            return {
                ...state,
                addSubscriptionLoading: true,
            }

        case GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_PENDING:
            return {
                ...state,
                getAllProjectSubscriptionsWithInvoicesLoading: true,
            }
        case GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAllProjectSubscriptionsWithInvoicesLoading: false,
            }
        case GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_REJECTED:
            return {
                ...state,
                getAllProjectSubscriptionsWithInvoicesLoading: false,
            }

        case GET_PROJECT_SUBSCRIPTIONS_PENDING:
            return {
                ...state,
                getProjectSubscriptionsLoading: true,
            }
        case GET_PROJECT_SUBSCRIPTIONS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getProjectSubscriptionsLoading: false
            }
        case GET_PROJECT_SUBSCRIPTIONS_REJECTED:
            return {
                ...state,
                getProjectSubscriptionsLoading: false,
            }

        case GET_TYPE_PROJECT_SUBSCRIPTIONS_PENDING:
            return {
                ...state,
                getTypeProjectSubscriptions: true,
            }
        case GET_TYPE_PROJECT_SUBSCRIPTIONS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getTypeProjectSubscriptions: false,
            }
        case GET_TYPE_PROJECT_SUBSCRIPTIONS_REJECTED:
            return {
                ...state,
                getTypeProjectSubscriptions: false
            }

        case UPDATE_SUBSCRIPTION_PENDING:
            return {
                ...state,
                editSubscriptionLoading: true,

            }
        case UPDATE_SUBSCRIPTION_FULFILLED:
            return {
                ...state,
                editSubscriptionResult: action.payload,
                editSubscriptionLoading: false,

            }
        case UPDATE_SUBSCRIPTION_REJECTED:
            return {
                ...state,
                editSubscriptionLoading: false,

            }

        case DELETE_SUBSCRIPTION_PENDING:
            return {
                ...state,
                deleteSubscriptionLoading: true,
            }
        case DELETE_SUBSCRIPTION_FULFILLED:
            return {
                ...state,
                deleteSubscriptionResult: action.payload,
                deleteSubscriptionLoading: false,
            }
        case DELETE_SUBSCRIPTION_REJECTED:
            return {
                ...state,
                deleteSubscriptionLoading: false
            }

        default:
            return state;
    }
}