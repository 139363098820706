import React, { Component } from 'react'
import { Input, FormControlLabel, Switch, Button, FormControl, InputLabel, FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import styles from '../../styles/app';

class AddMainCounterForm extends Component {

    state = {
        distributionBlocks: [],
        filtrationCounterStatus: false,
        sharingStatus: true,
        subscription: {
            value: "",
            label: "Lütfen Bir Fatura Aboneliği Seçiniz..",
        },
        selectedSubscription: {},
    };

    handleBlocks = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const distributionBlocks = state.distributionBlocks.concat(name.toString());
                return {
                    distributionBlocks
                };
            });
        }
        else {
            this.setState(state => {
                const distributionBlocks = state.distributionBlocks.filter(item => item.toString() !== name.toString());
                return {
                    distributionBlocks,
                };
            });
        }
    };

    handleFiltrationCounter = (value) => {
        this.setState({ filtrationCounterStatus: value.target.checked })
    };

    handleSharingStatus = (value) => {
        this.setState({ sharingStatus: value.target.checked })
    };

    handleSubscription = async (subscription) => {

        this.props.subscriptions.map((item) => {
            if (item._id.toString() === subscription.value.toString()) {
                this.setState({
                    selectedSubscription: item,
                    subscription
                });
            }
            return "";
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" name="type" value={this.props.sharingType} />
                <input type="hidden" name="project" value={this.props.project._id} />
                <FormControl required style={{ width: "100%" }}>
                    <InputLabel htmlFor="name">Paylaşım Adı (Opsiyonel)</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ width: "15%" }}>
                    <div style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1rem", fontFamily: "Roboto , Helvetica , Arial , sans- serif", lineHeight: "1", padding: "20px 0px 0px 16px", }}>
                        Süzme Sayaç Durumu <Switch checked={this.state.filtrationCounterStatus} onChange={this.handleFiltrationCounter} />
                    </div>
                </FormControl>
                <FormControl required style={{ width: "15%" }}>
                    <div style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1rem", fontFamily: "Roboto , Helvetica , Arial , sans- serif", lineHeight: "1", padding: "20px 0px 0px 16px", }}>
                        Paylaşım Durumu  <Switch checked={this.state.sharingStatus} onChange={this.handleSharingStatus} />
                    </div>
                </FormControl>
                {
                    this.state.filtrationCounterStatus ? <div>
                        <FormControl required fullWidth>
                            <InputLabel htmlFor="serialNumber">Seri Numarası</InputLabel>
                            <Input
                                id="serialNumber"
                                name="serialNumber"
                                type="text"
                            />
                        </FormControl>
                        <FormControl required fullWidth>
                            <InputLabel htmlFor="counterValue">İlk Endeks</InputLabel>
                            <Input
                                id="counterValue"
                                name="counterValue"
                                type="text"
                            />
                        </FormControl>
                    </div> : ""
                }
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel style={{ marginBottom: "10px" }}>Fatura Aboneliği</FormLabel>
                    <SearchSelect
                        value={this.state.subscription}
                        onChange={this.handleSubscription}
                        options={
                            this.props.subscriptions.map((subscription) => {
                                return { value: subscription._id, label: subscription.name }
                            })
                        }
                    />
                </FormControl>
                {
                    this.state.subscription.value !== "" ? <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                        <InputLabel htmlFor="sharingBlocks">Dağıtım Yapılacak Bloklar</InputLabel>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {
                            this.state.selectedSubscription ? this.state.selectedSubscription.sharingBlocks.map((block) => {
                                return (
                                    <FormControlLabel key={block._id}
                                        control={
                                            <Switch checked={this.state.distributionBlocks.includes(block._id)} onChange={this.handleBlocks(block._id)} />
                                        }
                                        label={block.name}
                                    />
                                )
                            }) : ""
                        }
                    </FormControl> : ""
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    aboneliği oluştur ve devam et
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(AddMainCounterForm);
