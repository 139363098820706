import {
    GET_PERIOD_ADDITIONAL_EXPENSES_PENDING, GET_PERIOD_ADDITIONAL_EXPENSES_FULFILLED, GET_PERIOD_ADDITIONAL_EXPENSES_REJECTED,
    DELETE_ADDITIONAL_EXPENSES_PENDING, DELETE_ADDITIONAL_EXPENSES_FULFILLED, DELETE_ADDITIONAL_EXPENSES_REJECTED,
    ADD_PERIOD_ADDITIONAL_EXPENSE_PENDING, ADD_PERIOD_ADDITIONAL_EXPENSE_FULFILLED, ADD_PERIOD_ADDITIONAL_EXPENSE_REJECTED
} from '../actions/additionalExpense';

const initialState = {
    deleteAdditionalExpenseLoading: false,
    getAdditionalExpenseLoading: false,
    additionalExpenses: [],
    deleteAdditionalExpenseResult: {},
    addPeriodAdditionalExpenseResult: {},
    addPeriodAdditionalExpenseLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_PERIOD_ADDITIONAL_EXPENSE_PENDING:
            return {
                ...state,
                getAdditionalExpenseLoading: true,
            }
        case ADD_PERIOD_ADDITIONAL_EXPENSE_FULFILLED:
            return {
                ...state,
                addPeriodAdditionalExpenseResult: action.payload,
                addPeriodAdditionalExpenseLoading: false,
            }
        case ADD_PERIOD_ADDITIONAL_EXPENSE_REJECTED:
            return {
                ...state,
                getAdditionalExpenseLoading: false,
            }

        case GET_PERIOD_ADDITIONAL_EXPENSES_PENDING:
            return {
                ...state,
                getAdditionalExpenseLoading: true,
            }
        case GET_PERIOD_ADDITIONAL_EXPENSES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAdditionalExpenseLoading: false,
            }
        case GET_PERIOD_ADDITIONAL_EXPENSES_REJECTED:
            return {
                ...state,
                getAdditionalExpenseLoading: false,
            }

        case DELETE_ADDITIONAL_EXPENSES_PENDING:
            return {
                ...state,
                deleteAdditionalExpenseLoading: true,
            }
        case DELETE_ADDITIONAL_EXPENSES_FULFILLED:
            return {
                ...state,
                deleteAdditionalExpenseResult: action.payload,
                deleteAdditionalExpenseLoading: false
            }
        case DELETE_ADDITIONAL_EXPENSES_REJECTED:
            return {
                ...state,
                deleteAdditionalExpenseLoading: false
            }
        default:
            return state;
    }
}