import React, { Component } from 'react'
import { Button, FormControl, InputLabel, FormControlLabel, Switch, } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class GetApartmentsExcelList extends Component {

    state = {
        blocks: this.props.blocks.map(block => block._id),
    };

    handleBlocks = name => async event => {

        if (event.target.checked) {
            this.setState(state => {
                const blocks = state.blocks.concat(name.toString());
                return {
                    blocks
                };
            });
        }
        else {
            this.setState(state => {
                const blocks = state.blocks.filter(item => item.toString() !== name.toString());
                return {
                    blocks,
                };
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }} required>
                    <InputLabel htmlFor="blocks">Listede İstenen Bloklar</InputLabel>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {
                        this.props.blocks.map((block) => {
                            return (
                                <FormControlLabel key={block._id}
                                    control={
                                        <Switch checked={this.state.blocks.includes(block._id)} onChange={this.handleBlocks(block._id)} />
                                    }
                                    label={block.name}
                                />
                            )
                        })
                    }
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Güncel Listeyi İndir
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(GetApartmentsExcelList);
