import React, { Component } from 'react'
import { Input, Button, FormControl, FormLabel, InputLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import styles from '../../styles/app';

class AddApartmentCounterForm extends Component {

    state = {
        apartment: this.props.apartment._id,
        project: this.props.apartment.block.project._id,
        mainCounter: { value: "", label: "Lütfen bir ana sayaç seçiniz..", type: "" },
        status: true,
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "40%", height: "175px" }}>
                    <FormLabel>Bağlı olduğu ana sayaç</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.mainCounter}
                        onChange={(mainCounter) => { this.setState({ mainCounter }) }}
                        isClearable={true}
                        options={
                            this.props.mainCounters.map((mainCounter) => {
                                return { value: mainCounter._id, label: mainCounter.name, type: mainCounter.type._id }
                            })
                        }
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="name">Sayaç Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="serialNumber">Seri Nuamrası</InputLabel>
                    <Input
                        id="serialNumber"
                        name="serialNumber"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="counterValue">Son Endeks</InputLabel>
                    <Input
                        id="counterValue"
                        name="counterValue"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="status">Sayaç Durumu</InputLabel>
                    <br />
                    <br />
                    <Switch name="status" checked={this.state.status} onChange={event => { this.setState({ [event.target.name]: event.target.checked }); }} value="status" />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Yeni Sayaç Ekle
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(AddApartmentCounterForm);
