import React, { Component } from 'react'
import { Button, Input, InputLabel, FormControl } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class AddCounterDataForm extends Component {

    state = {
        counter: this.props.apartmentCounterData.lastClosedData[0].counter,
        oldCounterValue: this.props.apartmentCounterData.lastClosedData[0].counterValue
    };

    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>

                <FormControl fullWidth>
                    <InputLabel htmlFor="counterValue">Sayaç Seri No</InputLabel>
                    <Input
                        style={{ fontSize: "30px" }}
                        type="text"
                        disabled={true}
                        defaultValue={this.props.apartmentCounterData.serialNumber}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="counterValue">İlk Endeksi</InputLabel>
                    <Input
                        style={{ fontSize: "30px" }}
                        type="text"
                        disabled={true}
                        defaultValue={this.props.apartmentCounterData.lastClosedData[0].counterValue}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="counterValue">Son Endeks</InputLabel>
                    <Input
                        style={{ fontSize: "30px" }}
                        id="counterValue"
                        name="counterValue"
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="periodDescription">Sayaç Durum Notu </InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="periodDescription"
                        name="periodDescription"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sayaç Verisini Ekle
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(AddCounterDataForm);
