import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_SUBSCRIPTION_PENDING = "ADD_SUBSCRIPTION_PENDING";
export const ADD_SUBSCRIPTION_FULFILLED = "ADD_SUBSCRIPTION_FULFILLED";
export const ADD_SUBSCRIPTION_REJECTED = "ADD_SUBSCRIPTION_REJECTED";

export const GET_PROJECT_SUBSCRIPTIONS_PENDING = "GET_PROJECT_SUBSCRIPTIONS_PENDING";
export const GET_PROJECT_SUBSCRIPTIONS_FULFILLED = "GET_PROJECT_SUBSCRIPTIONS_FULFILLED";
export const GET_PROJECT_SUBSCRIPTIONS_REJECTED = "GET_PROJECT_SUBSCRIPTIONS_REJECTED";

export const UPDATE_SUBSCRIPTION_PENDING = "UPDATE_SUBSCRIPTION_PENDING";
export const UPDATE_SUBSCRIPTION_FULFILLED = "UPDATE_SUBSCRIPTION_FULFILLED";
export const UPDATE_SUBSCRIPTION_REJECTED = "UPDATE_SUBSCRIPTION_REJECTED";

export const DELETE_SUBSCRIPTION_PENDING = "DELETE_SUBSCRIPTION_PENDING";
export const DELETE_SUBSCRIPTION_FULFILLED = "DELETE_SUBSCRIPTION_FULFILLED";
export const DELETE_SUBSCRIPTION_REJECTED = "DELETE_SUBSCRIPTION_REJECTED";

export const GET_TYPE_PROJECT_SUBSCRIPTIONS_PENDING = "GET_TYPE_PROJECT_SUBSCRIPTIONS_PENDING";
export const GET_TYPE_PROJECT_SUBSCRIPTIONS_FULFILLED = "GET_TYPE_PROJECT_SUBSCRIPTIONS_FULFILLED";
export const GET_TYPE_PROJECT_SUBSCRIPTIONS_REJECTED = "GET_TYPE_PROJECT_SUBSCRIPTIONS_REJECTED";

export const GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_PENDING = "GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_PENDING";
export const GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_FULFILLED = "GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_FULFILLED";
export const GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_REJECTED = "GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES_REJECTED";




export function addSubscription(subscription, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_SUBSCRIPTION",
            payload: axios.post(`${API_URL}/subscription`, subscription, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllSubscriptions(project, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PROJECT_SUBSCRIPTIONS",
            payload: axios.get(`${API_URL}/subscription/project/${project}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllTypeSubscriptions(project, type, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_TYPE_PROJECT_SUBSCRIPTIONS",
            payload: axios.get(`${API_URL}/subscription/project/${project}/${type}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllProjectSubscriptionsWithInvoices(project, period, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_ALL_PROJECT_SUBSCRIPTIONS_WITH_INVOICES",
            payload: axios.get(`${API_URL}/subscription/project/${project}/period/${period}/all-with-invoices`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function editSubscription(subscription, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_SUBSCRIPTION",
            payload: axios.put(`${API_URL}/subscription`, subscription, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deleteSubscription(subscription, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_SUBSCRIPTION",
            payload: axios.delete(`${API_URL}/subscription`, { data: { ...subscription, token } })
                .then(result => result.data)
        })
    }
}
