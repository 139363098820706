import axios from 'axios';
import { API_URL } from '../config/config';
import FileDownload from 'js-file-download';

// toplu blok excel listesi 
export const getCollectiveBlockExcelList = (project, token) => {

    return axios.get(`${API_URL}/block/export-collective-block-list-excel/${project}`, { responseType: 'blob', headers: { "x-access-token": token } })
        .then((result) => {
            return FileDownload(result.data, result.headers["file-name"]);
        })
}

// toplu daire excel listesi 
export const getCollectiveApartmentExcelList = (blocks, token) => {

    return axios.get(`${API_URL}/apartment/export-collective-apartment-list-excel`, { responseType: 'blob', headers: { "x-access-token": token, blocks: JSON.stringify(blocks) } })
        .then((result) => {
            return FileDownload(result.data, result.headers["file-name"]);
        })
}


// toplu daire sayaç oluşturma listesi 
export const getCollectiveApartmentCounterExcelList = (mainCounter, blocks, token) => {
    return axios.get(`${API_URL}/main-counter/export-collective-apartment-counter-list-excel/${mainCounter}`, { responseType: 'blob', headers: { "x-access-token": token, blocks: JSON.stringify(blocks) } })
        .then((result) => {
            return FileDownload(result.data, result.headers["file-name"]);
        })
}

// güncel sayaç listesi
export const getPeriodCounterList = (params, token) => {
    return axios.get(`${API_URL}/apartment-counter/period-counter-list`, { params, responseType: 'blob', headers: { "x-access-token": token, } })
        .then((result) => {
            return FileDownload(result.data, result.headers["file-name"]);
        })
}

// güncel sayaç listesi
export const getPeriodInvoicesExcelList = (params, token) => {
    return axios.get(`${API_URL}/apartment/period-invoices-excel`, { params, responseType: 'blob', headers: { "x-access-token": token, } })
        .then((result) => {
            return FileDownload(result.data, result.headers["file-name"]);
        })
}