import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// components
import Table from '../../Items/Table';
// import { Button, Switch } from '@material-ui/core';
class PeriodInvoicesTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Dönem Daire Fatura Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                {
                    title: "Blok Adı", field: "apartment.block.name",
                    render: rowData => {
                        return (
                            rowData.apartment.block.name
                        );
                    }
                },
                {
                    title: "Daire Adı", field: "apartment.name",
                    render: rowData => {
                        return (
                            rowData.apartment.name
                        );
                    }
                },
                {
                    title: "Fatura Tipi",
                    render: rowData => {
                        let text = "";
                        if (rowData.type === 0) {
                            text = "Isınma Faturası";
                        } else if (rowData.type === 1) {
                            text = "Su Tüketim Faturası";
                        }
                        else if (rowData.type === 2) {
                            text = "Sıcak Su Isıtma Faturası";
                        }
                        else if (rowData.type === 3) {
                            text = "Isı Kayıp Gideri Faturası";
                        }
                        else if (rowData.type === 4) {
                            text = rowData.title;
                        }
                        else if (rowData.type === 5) {
                            text = "Sıcak Su Tüketim Faturası";
                        }
                        return (
                            text
                        );
                    }
                },
                {
                    title: "Toplam Tutar", field: "balance",
                    render: rowData => {
                        return (
                            rowData.balance + " ₺"
                        );
                    }
                },
                {
                    title: "Tüketim Miktarı", field: "unit",
                    render: rowData => {
                        return (
                            rowData.unit
                        );
                    }
                },
                {
                    title: "Birim Fiyat", field: "unitPrice",
                    render: rowData => {
                        return (
                            rowData.unitPrice + " ₺"
                        );
                    }
                },
                {
                    title: "Isı Kayıp", field: "heatLossBalance",
                    render: rowData => {
                        return (
                            rowData.heatLossBalance + " ₺"
                        );
                    }
                },
            ],
            data: this.props.invoices
        };

        let tableActions = [];
        if (this.props.period.status) {
            if (this.props.person.permissions.apartment.delete) {
                tableActions.push({
                    icon: 'delete',
                    tooltip: 'Sil',
                    onClick: this.props.delete,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: 'red'
                        }
                    }
                });
            }
        }

        if (!this.props.period.status) {
            tableActions.push(
                {
                    icon: 'cloud_download',
                    isFreeAction: true,
                    tooltip: 'Excel Fatura Çıktısı',
                    onClick: this.props.getInvoiceExport,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#56b191'
                        }
                    }
                }
            );
            tableActions.push(
                {
                    icon: 'picture_as_pdf',
                    isFreeAction: true,
                    tooltip: 'PDF Fatura Çıktısı',
                    onClick: this.props.getInvoicePdf,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#56b191'
                        }
                    }
                }
            );
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default PeriodInvoicesTable;