import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button } from '@material-ui/core';
class BlocksTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Blok Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                this.props.person.permissions.block.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Blok Adı", field: "name",
                    render: rowData => {
                        return (
                            <a href={"/block/" + rowData._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.name}</a>
                        );
                    }
                },
                {
                    title: "Mevcut Daire Sayısı",
                    render: rowData => {
                        return (
                            <div>
                                {rowData.apartments.length}
                            </div>
                        );
                    }
                },
            ],
            data: this.props.blocks
        };

        let tableActions = [];
        if (this.props.person.permissions.block.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.block.create) {
            tableActions.push(
                { // tekli ekle
                    icon: 'plus_one',
                    isFreeAction: true,
                    tooltip: 'Yeni Kayıt Ekle',
                    onClick: this.props.addForm,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: 'green'
                        }
                    }
                },
                { // toplu ekle
                    icon: 'filter_9_plus',
                    isFreeAction: true,
                    tooltip: 'Toplu Blok Ekle',
                    onClick: this.props.addCollectiveForm,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#ff5600'
                        }
                    }
                },
            );
        }
        tableActions.push(
            { // toplu block listesi exceli
                icon: 'cloud_download',
                isFreeAction: true,
                tooltip: 'Toplu Blok Exceli İndir',
                onClick: () => this.props.downloadCollectiveBlockExcelList(this.props.project._id),
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: '#56b191'
                    }
                }
            }
        );
        if (this.props.person.permissions.block.update) {
            tableActions.push(
                { // toplu block listesi exceli
                    icon: 'cloud_upload',
                    isFreeAction: true,
                    tooltip: 'Excel Yükleme',
                    onClick: this.props.uploadCollectiveBlockExcelList,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#17c5da'
                        }
                    }
                }
            );
        }

        tableActions.push(
            { // toplu block listesi exceli
                icon: 'cloud_download',
                isFreeAction: true,
                tooltip: 'Toplu Daire Exceli İndir',
                onClick: this.props.getApartmentList,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: '#ff8547'
                    }
                }
            }
        );
        if (this.props.person.permissions.apartment.update) {
            tableActions.push(
                {
                    icon: 'cloud_upload',
                    isFreeAction: true,
                    tooltip: 'Excel Yükleme',
                    onClick: this.props.uploadCollectiveApartmentExcelList,
                    iconProps: {
                        style: {
                            fontSize: 40,
                            color: '#ff8547'
                        }
                    }
                }
            );
        }

        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default BlocksTable;