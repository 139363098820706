import React, { Component } from 'react';
import { Input, Button, FormControl, InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class AddAddressesForm extends Component {

    state = {
    };

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" value={this.props.person._id} name="person" />
                <FormControl fullWidth>
                    <InputLabel htmlFor="name">Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="address">Adres</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="address"
                        name="address"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    KAYDET
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(AddAddressesForm);