import {
    ADD_PERIOD_PENDING, ADD_PERIOD_FULFILLED, ADD_PERIOD_REJECTED,
    GET_LAST_CLOSED_PERIOD_PENDING, GET_LAST_CLOSED_PERIOD_FULFILLED, GET_LAST_CLOSED_PERIOD_REJECTED,
    GET_PERIODS_PENDING, GET_PERIODS_FULFILLED, GET_PERIODS_REJECTED,
    GET_PERIOD_PENDING, GET_PERIOD_FULFILLED, GET_PERIOD_REJECTED,
    GET_LAST_ACTIVE_PERIOD_PENDING, GET_LAST_ACTIVE_PERIOD_FULFILLED, GET_LAST_ACTIVE_PERIOD_REJECTED,
    SHARING_PERIOD_PENDING, SHARING_PERIOD_FULFILLED, SHARING_PERIOD_REJECTED,
    CLOSE_PERIOD_PENDING, CLOSE_PERIOD_FULFILLED, CLOSE_PERIOD_REJECTED,
    ACTIVE_PERIOD_PENDING, ACTIVE_PERIOD_FULFILLED, ACTIVE_PERIOD_REJECTED,
    UPDATE_PERIOD_PENDING, UPDATE_PERIOD_FULFILLED, UPDATE_PERIOD_REJECTED,
    DELETE_PERIOD_PENDING, DELETE_PERIOD_FULFILLED, DELETE_PERIOD_REJECTED,
    UPDATE_REQUEST_SHARING_PENDING, UPDATE_REQUEST_SHARING_FULFILLED, UPDATE_REQUEST_SHARING_REJECTED
} from '../actions/period';
const initialState = {
    addPeriodResult: {},
    updatePeriodResult: {},
    lastClosedPeriod: {},
    lastActivePeriod: {},
    periods: [],
    period: {},
    sharingPeriodResult: {},
    closePeriodResult: {},
    activePeriodResult: {},
    deletePeriodResult: {},
    updateRequestSharingResult: {},
    addPeriodLoading: false,
    updatePeriodLoading: false,
    deletePeriodLoading: false,
    activePeriodLoading: false,
    closePeriodRLoading: false,
    sharingPeriodLoading: false,
    getPeriodsLoading: false,
    getPeriodLoading: false,
    getLastActivePeriodLoading: false,
    getLastClosedPeriodLoading: false,
    updateRequestSharingLoading: false,

}
// person işlemleri için bir reducer oluştur ve export et
export default (state = initialState, action) => {

    switch (action.type) {
        case DELETE_PERIOD_PENDING:
            return {
                ...state,
                deletePeriodLoading: true,
            }
        case DELETE_PERIOD_FULFILLED:
            return {
                ...state,
                deletePeriodResult: action.payload,
                deletePeriodLoading: false,
            }
        case DELETE_PERIOD_REJECTED:
            return {
                ...state,
                deletePeriodLoading: false
            }
        case UPDATE_PERIOD_PENDING:
            return {
                ...state,
                updatePeriodLoading: true,
            }
        case UPDATE_PERIOD_FULFILLED:
            return {
                ...state,
                updatePeriodResult: action.payload,
                updatePeriodLoading: false,
            }
        case UPDATE_PERIOD_REJECTED:
            return {
                ...state,
                updatePeriodLoading: false,
            }
        case UPDATE_REQUEST_SHARING_PENDING:
            return {
                ...state,
                updateRequestSharingLoading: true,
            }
        case UPDATE_REQUEST_SHARING_FULFILLED:
            return {
                ...state,
                updateRequestSharingResult: action.payload,
                updateRequestSharingLoading: false,
            }
        case UPDATE_REQUEST_SHARING_REJECTED:
            return {
                ...state,
                updateRequestSharingLoading: false,
            }
        case ACTIVE_PERIOD_PENDING:
            return {
                ...state,
                activePeriodLoading: true,
            }
        case ACTIVE_PERIOD_FULFILLED:
            return {
                ...state,
                activePeriodResult: action.payload,
                activePeriodLoading: false,
            }
        case ACTIVE_PERIOD_REJECTED:
            return {
                ...state,
                activePeriodLoading: false,
            }

        case CLOSE_PERIOD_PENDING:
            return {
                ...state,
                closePeriodRLoading: true,

            }
        case CLOSE_PERIOD_FULFILLED:
            return {
                ...state,
                closePeriodResult: action.payload,
                closePeriodRLoading: false,
            }
        case CLOSE_PERIOD_REJECTED:
            return {
                ...state,
                closePeriodRLoading: false,
            }

        case SHARING_PERIOD_PENDING:
            return {
                ...state,
                sharingPeriodLoading: true,
            }
        case SHARING_PERIOD_FULFILLED:
            return {
                ...state,
                sharingPeriodResult: action.payload,
                sharingPeriodLoading: false
            }
        case SHARING_PERIOD_REJECTED:
            return {
                ...state,
                sharingPeriodLoading: false,
            }

        case ADD_PERIOD_PENDING:
            return {
                ...state,
                addPeriodLoading: true
            }
        case ADD_PERIOD_FULFILLED:
            return {
                ...state,
                addPeriodResult: action.payload,
                addPeriodLoading: false,
            }
        case ADD_PERIOD_REJECTED:
            return {
                ...state,
                addPeriodLoading: false,
            }
        case GET_LAST_CLOSED_PERIOD_PENDING:
            return {
                ...state,
                getLastClosedPeriodLoading: true,
            }
        case GET_LAST_CLOSED_PERIOD_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getLastClosedPeriodLoading: false
            }
        case GET_LAST_CLOSED_PERIOD_REJECTED:
            return {
                ...state,
                getLastClosedPeriodLoading: false,
            }
        case GET_LAST_ACTIVE_PERIOD_PENDING:
            return {
                ...state,
                getLastActivePeriodLoading: true,
            }
        case GET_LAST_ACTIVE_PERIOD_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getLastActivePeriodLoading: false,
            }
        case GET_LAST_ACTIVE_PERIOD_REJECTED:
            return {
                ...state,
                getLastActivePeriodLoading: false,
            }
        case GET_PERIODS_PENDING:
            return {
                ...state,
                getPeriodsLoading: true,
            }
        case GET_PERIODS_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getPeriodsLoading: false,
            }
        case GET_PERIODS_REJECTED:
            return {
                ...state,
                getPeriodsLoading: false,
            }

        case GET_PERIOD_PENDING:
            return {
                ...state,
                getPeriodLoading: true,
            }
        case GET_PERIOD_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getPeriodLoading: false
            }
        case GET_PERIOD_REJECTED:
            return {
                ...state,
                getPeriodLoading: false

            }
        default:
            return state;
    }
}