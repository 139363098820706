import React, { Component } from 'react'
import { Button, FormControl, InputLabel, Select, MenuItem, Input } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class ComparisonForm extends Component {

    state = {
        rowDatas: this.props.rowDatas,
        comparisonCriterion: 0,
    };

    handleChangeComparisonCriterion = (element) => {
        this.setState({ comparisonCriterion: element.target.value })
    }
    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="comparisonCriterion">Kıyas Kriteri</InputLabel>
                    <Select
                        value={this.state.comparisonCriterion}
                        onChange={this.handleChangeComparisonCriterion}
                        id="comparisonCriterion"
                        name="comparisonCriterion"
                    >
                        <MenuItem key={0} value={0}>Metrekare Kıyası</MenuItem>
                        <MenuItem key={1} value={1}>Tüketimi Manuel Belirle</MenuItem>
                        <MenuItem key={2} value={2}>15 Derece Kıyas Değerine Göre</MenuItem>
                        <MenuItem key={3} value={3}>Ayne Metrekareye Sahip Daire Ortalamasına Göre</MenuItem>
                        <MenuItem key={4} value={4}>+10 -10 Metrekareye Sahip Daire Ortalamasına Göre </MenuItem>
                    </Select>
                </FormControl>
                {
                    this.state.comparisonCriterion === 1 ? <FormControl required fullWidth>
                        <InputLabel htmlFor="manuelConsumption">Kıyas Değeri</InputLabel>
                        <Input
                            id="manuelConsumption"
                            name="manuelConsumption"
                            type="text"
                        />
                    </FormControl>
                        : ""
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    KIYAS YAP
                </Button>
            </form >
        )
    }
}
export default withStyles(styles)(ComparisonForm);
