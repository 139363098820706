import axios from 'axios';
import { API_URL } from '../config/config';

export const GET_PERIOD_ADDITIONAL_EXPENSES_PENDING = "GET_PERIOD_ADDITIONAL_EXPENSES_PENDING";
export const GET_PERIOD_ADDITIONAL_EXPENSES_FULFILLED = "GET_PERIOD_ADDITIONAL_EXPENSES_FULFILLED";
export const GET_PERIOD_ADDITIONAL_EXPENSES_REJECTED = "GET_PERIOD_ADDITIONAL_EXPENSES_REJECTED";

export const DELETE_ADDITIONAL_EXPENSES_PENDING = "DELETE_ADDITIONAL_EXPENSES_PENDING";
export const DELETE_ADDITIONAL_EXPENSES_FULFILLED = "DELETE_ADDITIONAL_EXPENSES_FULFILLED";
export const DELETE_ADDITIONAL_EXPENSES_REJECTED = "DELETE_ADDITIONAL_EXPENSES_REJECTED";

export const ADD_PERIOD_ADDITIONAL_EXPENSE_PENDING = "ADD_PERIOD_ADDITIONAL_EXPENSE_PENDING";
export const ADD_PERIOD_ADDITIONAL_EXPENSE_FULFILLED = "ADD_PERIOD_ADDITIONAL_EXPENSE_FULFILLED";
export const ADD_PERIOD_ADDITIONAL_EXPENSE_REJECTED = "ADD_PERIOD_ADDITIONAL_EXPENSE_REJECTED";

export function addPeriodAdditionalExpense(expense, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_PERIOD_ADDITIONAL_EXPENSE",
            payload: axios.post(`${API_URL}/additional-expense/`, expense, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function getPeriodAdditionalExpenses(period, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_PERIOD_ADDITIONAL_EXPENSES",
            payload: axios.get(`${API_URL}/additional-expense/period/${period}`, {
                headers: { "x-access-token": token }
            })
                .then(result => result.data)
        });
    }
}

export function deleteAdditionalExpense(additionalExpense, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_ADDITIONAL_EXPENSES",
            payload: axios.delete(`${API_URL}/additional-expense`, { data: { ...additionalExpense, token: token } })
                .then(result => result.data)
        })
    }
}