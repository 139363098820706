import axios from 'axios';
import { API_URL } from '../config/config';

export const ADD_INVOICE_PENDING = "ADD_INVOICE_PENDING";
export const ADD_INVOICE_FULFILLED = "ADD_INVOICE_FULFILLED";
export const ADD_INVOICE_REJECTED = " ADD_INVOICE_REJECTED";

export function addSubscriptionInvoice(invoice, token) {
    return async dispatch => {
        await dispatch({
            type: "ADD_INVOICE",
            payload: axios.post(`${API_URL}/subscription-invoice`, invoice, { headers: { "x-access-token": token, 'content-type': 'multipart/form-data' } })
                .then(result => result.data)
        })
    }
}