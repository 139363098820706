import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button } from '@material-ui/core';
class SubscriptionsInvoicesTable extends Component {
    render() {
        const tableData = {
            title: "Fatura Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },

            columns: [
                this.props.person.permissions.period.addInvoice ? this.props.period.status ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <div>
                                <Button style={{ fontStyle: "italic", fontWeight: "800" }} onClick={() => this.props.addInvoice(rowData)}>Yeni Fatura Ekle</Button>
                                <br />
                                {
                                    rowData.invoices.length > 0 ? <span style={{ fontSize: "12px !important", fontStyle: "italic", fontWeight: "800", cursor: "pointer" }} onClick={() => this.props.showInvoice(rowData)}>Fatura Görselini Görüntüle</span> : ""
                                }
                            </div>


                        )
                    },
                } : {} : {},
                {
                    title: "Abonelik Adı", field: "name",
                    render: rowData => {
                        return (
                            rowData.name
                        );
                    }
                },
                {
                    title: "Abonelik Numarası",
                    render: rowData => {
                        return (
                            rowData.subscriberNumber
                        );
                    }
                },
                {
                    title: "Abonelik Tipi",
                    render: rowData => {
                        return (
                            rowData.type.name
                        );
                    }
                },
                {
                    title: "Fatura Üzerindeki Tüketim",
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].consumption : ""
                        );
                    }
                },
                {
                    title: "Fatura Tutarı",
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].balance : ""
                        );
                    }
                },
                {
                    title: "Kazan Derecesi", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].boilerTemperature : ""
                        );
                    }
                },
                {
                    title: "Isıl Katsayısı", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].thermalCoefficient : ""
                        );
                    }
                },
                // alıntı
                {
                    title: "Dağıtım Kriteri", hidden: true,
                    render: rowData => {
                        let text = "";

                        if (rowData.invoices.length > 0) {
                            if (rowData.type._id === "5d0a312392706a151bc178df") { // ısıtma kalorimetre
                                if (rowData.invoices[0].gasSharingCriterion === 0) {
                                    text = "Isıtma : %30 - %70 Standart Paylaşım";
                                }
                                else if (rowData.invoices[0].gasSharingCriterion === 1) {
                                    text = "Isıtma : Dairelere Eşit";
                                }
                                else if (rowData.invoices[0].gasSharingCriterion === 2) {
                                    text = "Isıtma : Metrekareye Göre Eşit";
                                }
                                else if (rowData.invoices[0].gasSharingCriterion === 3) {
                                    text = "Isıtma : Sadece Dolu Dairelere Eşit";
                                }
                                else if (rowData.invoices[0].gasSharingCriterion === 4) {
                                    text = "Isıtma : Sadece Dolu Dairelere Eşit";
                                }

                                if (rowData.invoices[0].hotWaterSharingCriterion === 0) {
                                    text += " , Sıcak Su : Formüle Göre";
                                }
                                else if (rowData.invoices[0].hotWaterSharingCriterion === 1) {
                                    text += " , Sıcak Su : %30 - %70 Ayrılarak";
                                }
                            }
                            else if (rowData.type._id === "5d0a312392706a151bc178e0") { // soğuk su

                                if (rowData.invoices[0].coldWaterSharingCriterion === 0) {
                                    text = "Soğuk Su : Gelen Fatura Üzerinden Birim Fiyat Al";
                                }
                                else if (rowData.invoices[0].coldWaterSharingCriterion === 1) {
                                    text = "Soğuk Su : Gelen Faturanın Tamamını Topla";
                                }
                                else if (rowData.invoices[0].coldWaterSharingCriterion === 2) {
                                    text = "Soğuk Su : Manule Birim Fiyat Üzerinden";
                                }
                            }
                        }
                        return (
                            text
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Sayaç Sayısı", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].sharingCounterCount : ""
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Tüketim", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].sharingConsumption : ""
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Toplam Tutar", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].sharingBalance : ""
                        );
                    }
                },
                {
                    title: "Tüketime Dağıtım Yapılan Tutar", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].totalConsumptionBalance : ""
                        );
                    }
                },
                {
                    title: "Isı Kayıp İçin Dağıtım Yapılan Tutar", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].totalHeatLossBalance : ""
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan m²", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].totalSquareMeters : ""
                        );
                    }
                },
                {
                    title: "Tüketim Birim Fiyat", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].unitPrice : ""
                        );
                    }
                },
                {
                    title: "Isı Kayıp Birim Fiyat", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].heatLossUnitPrice : ""
                        );
                    }
                },
                {
                    title: "Dağıtım Yapılan Isıtma Bedeli Fiyat", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].totalHeatingBalance : ""
                        );
                    }
                },
                {
                    title: "Isıtma Bedeli Birim Fiyat", hidden: true,
                    render: rowData => {
                        return (
                            rowData.invoices.length > 0 ? rowData.invoices[0].heatingUnitPrice : ""
                        );
                    }
                },
            ],
            data: this.props.subscriptions
        };
        let tableActions = [];
        return (
            <div>
                <Table
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default SubscriptionsInvoicesTable;