import React, { Component } from 'react';
// components
import Table from '../Items/Table';
import { Button, Switch, Chip } from '@material-ui/core';
import Moment from 'react-moment';
class ProjectsTable extends Component {
    render() {
        const tableData = {
            title: "Mevcut Proje Listesi",
            options: {
                paging: true,
                selection: true,
                grouping: true,
                filtering: true,
                columnsButton: true,
                exportButton: false,
                actionsColumnIndex: 4,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 1000]
            },
            columns: [
                {
                    title: "Proje Adı",
                    field: "name",
                    render: rowData => {
                        return (
                            <a title={"Fatura Eklemek İçin Tıklayın"} href={"/project/" + rowData._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.name}</a>
                        )
                    },
                },
                this.props.person.permissions.project.update ? {
                    title: 'İşlem',
                    render: rowData => {
                        return (
                            <Button onClick={() => this.props.editForm(rowData)}>Güncelle</Button>
                        )
                    },
                } : {},
                {
                    title: "Proje Durumu", field: "status", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.status} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                {
                    title: "Bayi Adı", hidden: true,
                    field: "reseller.title",
                    render: rowData => {
                        return (
                            <a href={"/reseller/" + rowData.reseller._id} style={{ color: "#000000", fontWeight: "500" }}>{rowData.reseller.title}</a>
                        )
                    },
                },
                {
                    title: "Kat İstasyonu", field: "floorStation", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {<Switch checked={rowData.floorStation} aria-label="Collapse" />}
                            </div>
                        )
                    }
                },
                { title: "Okunma Ücreti", field: "readingPrice", hidden: true, },
                { title: "İl", field: "city.name", hidden: true, },
                { title: "İlçe", field: "county.name", hidden: true, },
                { title: "Adres", field: "address", hidden: true, },
                {
                    title: "Oluşturma Tarihi", field: "createdAt", hidden: true,
                    render: rowData => {
                        return (
                            <Moment format="DD-MM-YYYY hh:mm:ss">
                                {rowData.createdAt}
                            </Moment>
                        )
                    }
                },
                { title: "Faturada Görünen Okuma Ücreti", field: "invoiceBalance", hidden: true, },
                { title: "Zam Oranı", field: "raiseRate", hidden: true, },
                {
                    title: "Komisyon Oranı", field: "commission", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {"%" + rowData.commission}
                            </div>
                        )
                    }
                },
                {
                    title: "Isı Kayıp Oranı", field: "heatLossRate", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {"%" + rowData.heatLossRate}
                            </div>
                        )
                    }
                },
                {
                    title: "Okuma Dönemi", field: "readingPeriodId", hidden: true,
                    render: rowData => {
                        return (
                            <div>
                                {rowData.readingPeriodId === 0 ? <Button style={{ margin: "0px", fontSize: "12px", backgroundColor: "orange", color: "#5f4a4a" }} >Sadece Kış</Button> : <Button style={{ margin: "0px", fontSize: "12px", backgroundColor: "#23ff48", color: "#5f4a4a" }} >Yaz Kış</Button>}
                            </div>
                        )
                    }
                },
                {
                    title: "Sayaç Tipleri", field: "sharingTypes", hidden: true,
                    render: rowData => {
                        return (
                            <div>{
                                rowData.sharingTypes.map((type) => {
                                    return <Chip key={rowData._id + "-" + type._id} style={{ color: "#fff", backgroundColor: type.color }} label={type.name} />
                                })
                            }</div>
                        )
                    }
                },
                { title: "Açıklama", field: "description", hidden: true, },
            ],
            data: this.props.projects
        };

        let tableActions = [];
        if (this.props.person.permissions.project.delete) {
            tableActions.push({
                icon: 'delete',
                tooltip: 'Sil',
                onClick: this.props.delete,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'red'
                    }
                }
            });
        }

        if (this.props.person.permissions.project.create) {
            tableActions.push({
                icon: 'plus_one',
                isFreeAction: true,
                tooltip: 'Yeni Kayıt Ekle',
                onClick: this.props.addForm,
                iconProps: {
                    style: {
                        fontSize: 40,
                        color: 'green'
                    }
                }
            });
        }

        return (
            <div>
                <Table
                    isLoading={this.props.getResellersLoading}
                    tableData={tableData}
                    actions={tableActions.length > 0 ? tableActions : null} />
            </div>
        )
    }
}


export default ProjectsTable;