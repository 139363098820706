import React, { Component } from 'react'
import { Input, Button, FormControl, InputLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class ChangeApartmentCounterForm extends Component {

    state = {
        consumptionDifference: 0,
        oldCounterId: this.props.apartmentCounter._id,
        oldCounterValue: 0,
        transfer: true,
        oldCounterImage: null,
        newCounterImage: null
    };

    inputChange = async (element) => {
        this.setState({
            consumptionDifference: element.target.value - this.props.apartmentCounter.lastClosedData[0].counterValue,
            oldCounterValue: element.target.value
        })
    }
    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    onChangeHandlerFiles = async event => {
        if (event.target.files.length > 0) {
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (acceptedImageTypes.includes(event.target.files[0].type)) {
                await this.setState({
                    [event.target.name]: event.target.files,
                });
            } else {
                this.errorMessage();
            }
        }
    }

    errorMessage = () => {
        return this.props.enqueueSnackbar('Lütfen bir resim dosyası yükleyin.', {
            variant: "error",
            persist: true,
            action: (
                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
            ),
        });
    }

    render() {
        const { classes } = this.props;
        console.log(this.state)
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <h1>Eski Sayaç</h1>
                <FormControl required fullWidth>
                    <InputLabel>Sayaç Adı</InputLabel>
                    <Input
                        type="text"
                        defaultValue={this.props.apartmentCounter.name}
                        disabled={true}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel>Seri Nuamrası</InputLabel>
                    <Input
                        type="text"
                        defaultValue={this.props.apartmentCounter.serialNumber}
                        disabled={true}
                    />
                </FormControl>
                <FormControl required style={{ width: "20%" }}>
                    <InputLabel htmlFor="oldCounterValue">Güncel Endex</InputLabel>
                    <Input
                        id="oldCounterValue"
                        name="oldCounterValue"
                        type="text"
                        onChange={this.inputChange}
                    />
                </FormControl>
                <FormControl required style={{ width: "20%", marginLeft: "10%" }}>
                    <h1>Son Okunan Endeks: {this.props.apartmentCounter.lastClosedData[0].counterValue}</h1>
                </FormControl>
                <FormControl required style={{ width: "20%", marginLeft: "10%" }}>
                    <h1>Tüketim Farkı: {this.state.consumptionDifference}</h1>
                </FormControl>
                <FormControl fullWidth style={{ width: "20%" }}>
                    <InputLabel>SAYAÇ GÖRSELİ YÜKLEYİNİZ</InputLabel>
                    <br />
                    <br />
                    <br />
                    <input
                        type="file"
                        name="oldCounterImage"
                        onChange={this.onChangeHandlerFiles}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="oldDescription"
                        name="oldDescription"
                        type="text"
                        defaultValue={this.props.apartmentCounter.description}
                    />
                </FormControl>


                <div style={{ width: "100%", borderBottom: "5px solid #000" }}></div>
                <h1>Yeni Sayaç</h1>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="name">Sayaç Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={this.props.apartmentCounter.name}
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="serialNumber">Seri Nuamrası</InputLabel>
                    <Input
                        id="serialNumber"
                        name="serialNumber"
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ width: "30%" }}>
                    <InputLabel htmlFor="serialNumber">Güncel Endex</InputLabel>
                    <Input
                        id="counterValue"
                        name="counterValue"
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ width: "30%", marginLeft: "10%", display: "inline-block", marginTop: "25px" }}>
                    <Switch checked={this.state.transfer} onChange={this.handleChangeStatus('transfer')} />
                    {
                        <label style={{ fontSize: "18px", marginTop: "5px", color: "red" }}>Tüketim farkı bir sonraki dönem paylaşımına yansıtılsın mı?</label>
                    }
                </FormControl>
                <FormControl fullWidth style={{ width: "20%" }}>
                    <InputLabel>SAYAÇ GÖRSELİ YÜKLEYİNİZ</InputLabel>
                    <br />
                    <br />
                    <br />
                    <input
                        type="file"
                        name="newCounterImage"
                        onChange={this.onChangeHandlerFiles}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    SAYAÇ DEĞİŞİMİNİ GERÇEKLEŞTİR
                </Button>
            </form >
        )
    }
}

export default withStyles(styles)(ChangeApartmentCounterForm);
