import {
    ADD_NOTE_PENDING, ADD_NOTE_FULFILLED, ADD_NOTE_REJECTED,
    GET_ALL_NOTES_PENDING, GET_ALL_NOTES_FULFILLED, GET_ALL_NOTES_REJECTED,
    DELETE_NOTE_PENDING, DELETE_NOTE_FULFILLED, DELETE_NOTE_REJECTED,
    UPDATE_NOTE_PENDING, UPDATE_NOTE_FULFILLED, UPDATE_NOTE_REJECTED,

} from '../actions/note';

const initialState = {
    addNoteLoading: false,
    getAllNotesLoading: false,
    updateNoteloading: false,
    deleteNoteLoading: false,
    notes: [],
    addNoteResult: {},
    deleteNoteResult: {},
    updateNoteResult: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTE_PENDING:
            return {
                ...state,
                addNoteLoading: true,
            }
        case ADD_NOTE_FULFILLED:
            return {
                ...state,
                addNoteResult: action.payload,
                addNoteLoading: false,
            }
        case ADD_NOTE_REJECTED:
            return {
                ...state,
                addNoteLoading: false,
            }
        case GET_ALL_NOTES_PENDING:
            return {
                ...state,
                getAllNotesLoading: true,
            }
        case GET_ALL_NOTES_FULFILLED:
            return {
                ...state,
                ...action.payload.data,
                getAllNotesLoading: false,
            }
        case GET_ALL_NOTES_REJECTED:
            return {
                ...state,
                getAllNotesLoading: false,
            }
        case UPDATE_NOTE_PENDING:
            return {
                ...state,
                updateNoteloading: true,
            }
        case UPDATE_NOTE_FULFILLED:
            return {
                ...state,
                updateNoteResult: action.payload,
                updateNoteloading: false
            }
        case UPDATE_NOTE_REJECTED:
            return {
                ...state,
                updateNoteloading: false,
            }
        case DELETE_NOTE_PENDING:
            return {
                ...state,
                deleteNoteLoading: true,
            }
        case DELETE_NOTE_FULFILLED:
            return {
                ...state,
                deleteNoteResult: action.payload,
                deleteNoteLoading: false,
            }
        case DELETE_NOTE_REJECTED:
            return {
                ...state,
                deleteNoteLoading: false
            }
        default:
            return state;
    }
}
