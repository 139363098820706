import axios from 'axios';
import { API_URL } from '../config/config';

export const UPDATE_MAIN_COUNTER_DATA_PENDING = "UPDATE_MAIN_COUNTER_DATA_PENDING";
export const UPDATE_MAIN_COUNTER_DATA_FULFILLED = "UPDATE_MAIN_COUNTER_DATA_FULFILLED";
export const UPDATE_MAIN_COUNTER_DATA_REJECTED = "UPDATE_MAIN_COUNTER_DATA_REJECTED";

export function updateMainCounterData(mainCounterData, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_MAIN_COUNTER_DATA",
            payload: axios.put(`${API_URL}/main-counter-data`, mainCounterData, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}
