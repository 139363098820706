import axios from 'axios';
import { API_URL } from '../config/config';

export const LOGIN_PERSON_PENDING = "LOGIN_PERSON_PENDING";
export const LOGIN_PERSON_FULFILLED = "LOGIN_PERSON_FULFILLED";
export const LOGIN_PERSON_REJECTED = "LOGIN_PERSON_REJECTED";

export const GET_PERSON_PENDING = "GET_PERSON_PENDING";
export const GET_PERSON_FULFILLED = "GET_PERSON_FULFILLED";
export const GET_PERSON_REJECTED = "GET_PERSON_REJECTED";

export const ADD_PERSON_PENDING = "ADD_PERSON_PENDING";
export const ADD_PERSON_FULFILLED = "ADD_PERSON_FULFILLED";
export const ADD_PERSON_REJECTED = "ADD_PERSON_REJECTED";

export const UPDATE_PERSON_PENDING = "UPDATE_PERSON_PENDING";
export const UPDATE_PERSON_FULFILLED = "UPDATE_PERSON_FULFILLED";
export const UPDATE_PERSON_REJECTED = "UPDATE_PERSON_REJECTED";

export const DELETE_PERSON_PENDING = "DELETE_PERSON_PENDING";
export const DELETE_PERSON_FULFILLED = "DELETE_PERSON_FULFILLED";
export const DELETE_PERSON_REJECTED = "DELETE_PERSON_REJECTED";

export const RESET_PERSON_PASSWORD_PENDING = "RESET_PERSON_PASSWORD_PENDING";
export const RESET_PERSON_PASSWORD_FULFILLED = "RESET_PERSON_PASSWORD_FULFILLED";
export const RESET_PERSON_PASSWORD_REJECTED = "RESET_PERSON_PASSWORD_REJECTED";

export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const FAST_OPERATION = "FAST_OPERATION";


export const GET_PERSONS_PENDING = "GET_PERSONS_PENDING";
export const GET_PERSONS_FULFILLED = "GET_PERSONS_FULFILLED";
export const GET_PERSONS_REJECTED = "GET_PERSONS_REJECTED";


export const GET_RESELLERS_PENDING = "GET_RESELLERS_PENDING";
export const GET_RESELLERS_FULFILLED = "GET_RESELLERS_FULFILLED";
export const GET_RESELLERS_REJECTED = "GET_RESELLERS_REJECTED";


export const GET_RESELLER_PENDING = "GET_RESELLER_PENDING";
export const GET_RESELLER_FULFILLED = "GET_RESELLER_FULFILLED";
export const GET_RESELLER_REJECTED = "GET_RESELLER_REJECTED";

export const GET_RESELLER_PERSONS_PENDING = "GET_RESELLER_PERSONS_PENDING";
export const GET_RESELLER_PERSONS_FULFILLED = "GET_RESELLER_PERSONS_FULFILLED";
export const GET_RESELLER_PERSONS_REJECTED = "GET_RESELLER_PERSONS_REJECTED";

export const GET_DEPARTMENT_PERSONS_PENDING = "GET_DEPARTMENT_PERSONS_PENDING";
export const GET_DEPARTMENT_PERSONS_FULFILLED = "GET_DEPARTMENT_PERSONS_FULFILLED";
export const GET_DEPARTMENT_PERSONS_REJECTED = "GET_DEPARTMENT_PERSONS_REJECTED";

export function loginPerson({ email, password }) {

    return async dispatch => {
        await dispatch({
            type: "LOGIN_PERSON",
            payload: axios.post(`${API_URL}/login`, { email, password })
                .then(result => result.data)
        })
    }
}

export function setActivePageTitle(title) {

    return async dispatch => {
        await dispatch({
            type: "SET_PAGE_TITLE",
            payload: {
                title
            }
        })
    }
}

export function setFastOperation(component) {

    return async dispatch => {
        await dispatch({
            type: "FAST_OPERATION",
            payload: {
                fastOperation: component
            }
        })
    }
}

export function setBreadCrumb(breadCrumb) {

    return async dispatch => {
        await dispatch({
            type: "SET_BREADCRUMB",
            payload: {
                breadCrumb
            }
        })
    }
}

export function getDepartmentPersons(department, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_DEPARTMENT_PERSONS",
            payload: axios.get(`${API_URL}/person/get-department-persons/${department}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllPersons(token) {

    return async dispatch => {
        await dispatch({
            type: "GET_PERSONS",
            payload: axios.get(`${API_URL}/person/all-person-list`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function addPerson(person, token) {

    return async dispatch => {
        await dispatch({
            type: "ADD_PERSON",
            payload: axios.post(`${API_URL}/person `, person, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function updatePerson(person, token) {
    return async dispatch => {
        await dispatch({
            type: "UPDATE_PERSON",
            payload: axios.put(`${API_URL}/person`, person, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function deletePerson(person, token) {
    return async dispatch => {
        await dispatch({
            type: "DELETE_PERSON",
            payload: axios.delete(`${API_URL}/person`, { data: { ...person, token: token } })
                .then(result => result.data)
        })
    }
}

export function getAllResellers(token) {

    return async dispatch => {
        await dispatch({
            type: "GET_RESELLERS",
            payload: axios.get(`${API_URL}/person/all-reseller-list`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getReseller(_id, token) {

    return async dispatch => {
        await dispatch({
            type: "GET_RESELLER",
            payload: axios.get(`${API_URL}/person/get-reseller/${_id}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getAllResellerPersons(reseller, token) {
    return async dispatch => {
        await dispatch({
            type: "GET_RESELLER_PERSONS",
            payload: axios.get(`${API_URL}/person/all-reseller-person-list/${reseller}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function getPerson(_id, token) {

    return async dispatch => {
        await dispatch({
            type: "GET_PERSON",
            payload: axios.get(`${API_URL}/person/${_id}`, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

export function resetPersonPassword(person, token) {
    return async dispatch => {
        await dispatch({
            type: "RESET_PERSON_PASSWORD",
            payload: axios.put(`${API_URL}/person/reset-password`, person, { headers: { "x-access-token": token } })
                .then(result => result.data)
        })
    }
}

