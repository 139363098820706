import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, FormControl, Switch, InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import styles from '../../styles/app';

class AddStockCardForm extends Component {

    state = {

    }
    handleChangeStatus = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ width: "20%", marginLeft: "5%" }}>
                    <InputLabel htmlFor="serialNumberNecessity">Seri No Zorunluluğu</InputLabel>
                    <br />
                    <br />
                    <Switch checked={this.state.serialNumberNecessity} onChange={this.handleChangeStatus('serialNumberNecessity')} value="serialNumberNecessity" />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="productionYear">Üretim Yılı</InputLabel>
                    <Input
                        id="productionYear"
                        label="productionYear"
                        type="datetime-local"
                        defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="manufacturer">Üretici Firma</InputLabel>
                    <Input
                        id="manufacturer"
                        name="manufacturer"
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="WarrantyPeriod">Garanti Süresi</InputLabel>
                    <Input
                        id="WarrantyPeriod"
                        name="WarrantyPeriod"
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="buyingPrice">Alış Fiyatı</InputLabel>
                    <Input
                        id="buyingPrice"
                        name="buyingPrice"
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="salePrice">Satış Fiyatı</InputLabel>
                    <Input
                        id="salePrice"
                        name="salePrice"
                        type="text"
                    />
                </FormControl>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Stoğu Kaydet
                </Button>
            </form>
        )
    }
}

AddStockCardForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddStockCardForm);