import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from '../../styles/app';

class AddBlockForm extends Component {

    state = {

    };
    render() {
        const { classes } = this.props;
        return (
            <form encType="multipart/form-data" className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <input type="hidden" name="project" value={this.props.project._id} />
                <FormControl required fullWidth>
                    <InputLabel htmlFor="name">Blok Adı</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="floorStart">Kat Başlangıç</InputLabel>
                    <Input
                        id="floorStart"
                        name="floorStart"
                        type="number"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="floorEnd">Kat Bitiş</InputLabel>
                    <Input
                        id="floorEnd"
                        name="floorEnd"
                        type="number"
                    />
                </FormControl>
                <FormControl required fullWidth>
                    <InputLabel htmlFor="apartmentCount">Daire Sayısı</InputLabel>
                    <Input
                        id="apartmentCount"
                        name="apartmentCount"
                        type="text"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    BLOK EKLE
                </Button>
            </form >
        )
    }
}

AddBlockForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddBlockForm);
