import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from "@material-ui/core/styles/withStyles";
import SearchSelect from 'react-select';
import moment from 'moment';
import { Input, Button, FormControl, InputLabel, FormLabel } from "@material-ui/core";
import styles from '../../styles/app';

class EditNoteForm extends Component {

    async UNSAFE_componentWillMount() {
        await this.props.getDepartmentPersons(this.props.note.department._id, this.props.token);
    }

    state = {
        department: { value: this.props.note.department._id, label: this.props.note.department.name },
        persons: this.props.note ? this.props.note.persons.map((person) => { return { value: person._id, label: person.title } }) : [],
        personsStatus: true,
    }


    handleDepartmentChange = async (department) => {
        if (department.value === "") {
            this.setState({ department, personsStatus: false })
        }
        else {
            // eger ki farklı bir departman seçildiyse
            if (department.value !== this.state.department.value) {
                this.setState({ persons: [] });
            }
            await this.props.getDepartmentPersons(department.value, this.props.token);
            this.setState({ department, personsStatus: true })
        }
    }

    handlePersonChange = async (persons) => {
        this.setState({ persons })
    }
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); this.props.onSubmitForm(e, this.state) }}>
                <FormControl margin="normal" required fullWidth>
                    <input
                        type="hidden"
                        name="_id"
                        defaultValue={this.props.note._id}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="title">Not Başlığı</InputLabel>
                    <Input
                        id="title"
                        name="title"
                        defaultValue={this.props.note.title}
                        type="text"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="description">Açıklama</InputLabel>
                    <Input
                        id="description"
                        name="description"
                        defaultValue={this.props.note.description}
                        type="text"
                    />
                </FormControl>
                <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%" }}>
                    <FormLabel>Departman</FormLabel>
                    <br />
                    <SearchSelect
                        value={this.state.department}
                        onChange={this.handleDepartmentChange}
                        options={
                            this.props.departments.map((department) => {
                                return { value: department._id, label: department.name }
                            })
                        }
                    />
                </FormControl>

                {
                    this.state.personsStatus ?
                        <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "100%", }}>
                            <FormLabel>Personel</FormLabel>
                            <br />
                            <SearchSelect
                                isMulti={true}
                                value={this.state.persons}
                                onChange={(persons) => { this.setState({ persons }) }}
                                isDisabled={!this.state.personsStatus}
                                options={
                                    this.props.departmentPersons.map((person) => {
                                        return { value: person._id, label: person.title }
                                    })
                                }
                            />
                        </FormControl>
                        : ""
                }

                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="type">Tipi</InputLabel>
                    <Input
                        id="type"
                        name="type"
                        defaultValue={this.props.note.type}
                        type="text"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="validityDate">Geçerlilik Tarihi</InputLabel>
                    <Input
                        id="validityDate"
                        label="validityDate"
                        type="datetime-local"
                        defaultValue={moment(this.props.note.validityDate).format("YYYY-MM-DDTHH:mm")}
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Notu güncelle
                        </Button>
            </form>
        )
    }
}

EditNoteForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditNoteForm);