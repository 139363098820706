import React, { Component } from 'react';
// store bağlanmak için react-redux altındaki connecti sayfaya dahil et
import moment from 'moment';
import queryString from 'query-string';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { Chip, Divider, Grid, Typography, AppBar, TextField, Button, MenuItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { setActivePageTitle, setBreadCrumb, setFastOperation } from '../../actions/person';
import { getPeriod, sharingPeriod, closePeriod, activePeriod, updateRequestSharing } from '../../actions/period';
import { getAllProjectSubscriptionsWithInvoices } from '../../actions/subscription';
import { addSubscriptionInvoice } from '../../actions/subscriptionInvoice';
import { updateMainCounterData } from '../../actions/mainCounterData';
import { getPeriodMainCounterDatas } from '../../actions/mainCounter';
import { getPeriodCountersWithData, uploadCounterListExcel, checkPeriodAnalysis, setComparison, updateApartmentCounterData, notDefective, setDefective, addApartmentCounterData } from '../../actions/apartmentCounter';
import { getApartmentPeriodInvoices, deleteInvoice } from '../../actions/apartment';
import { getAllBlocks } from '../../actions/block';
import { getPeriodAdditionalExpenses, deleteAdditionalExpense, addPeriodAdditionalExpense } from '../../actions/additionalExpense';
import { getPeriodCounterList, getPeriodInvoicesExcelList } from '../../functions/fileDownload';
import SubscriptionsInvoicesTable from '../Subscriptions/SubscriptionsInvoicesTable'
import ModalDialog from '../Items/ModalDialog';
import AddSubscriptionInvoice from '../Subscriptions/AddSubscriptionInvoice';
import swal from 'sweetalert';
// import MainCounterDatasTable from '../MainCounterDatas/MainCounterDatasTable';
// import EditMainCounterDataForm from '../MainCounterDatas/EditMainCounterDataForm';
import PeriodDatasTable from '../ApartmentCounters/PeriodDatasTable';
import GetFilesForm from '../ApartmentCounters/GetFilesForm'
import UploadCounterDataExcelForm from '../ApartmentCounters/UploadCounterDataExcelForm';
import ComparisonForm from '../ApartmentCounters/ComparisonForm';
import EditCounterDataForm from '../ApartmentCounters/EditCounterDataForm';
import AddCounterDataForm from '../ApartmentCounters/AddCounterDataForm';
import PeriodInvoicesTable from '../Apartments/Invoices/PeriodInvoicesTable';
import AdditionalExpensesTable from '../AdditionalExpensesData/AdditionalExpenses/AdditionalExpensesTable';
import GetInvoicesForm from '../Apartments/Invoices/GetInvoicesForm';
import FastOperation from '../Items/FastOperation';
import ShowInvoice from '../Subscriptions/ShowInvoice';
import '../../styles/form.css';
import { priceFormat } from '../../functions/helper';
import RequestSharingForm from '../Persons/RequestSharingForm';
import AddPeriodAdditionalExpenseForm from '../AdditionalExpensesData/AdditionalExpenses/AddPeriodAdditionalExpenseForm';
import SearchSelect from 'react-select';
import { FormControl, FormLabel } from "@material-ui/core";
import { getProjectMainCounters } from '../../actions/mainCounter';

const styles = {
    title: {
        fontSize: 14,
        fontWeight: 800
    },
};

class PeriodDetail extends Component {
    // component içerisinde kullanılacak state
    state = {
        addSubscriptionInvoiceOpenDialog: false,
        editMainCounterOpenDialog: false,
        periodInformationDialog: false,
        addApartmentCounterDataOpenDialog: false,
        editApartmentCounterDataOpenDialog: false,
        getFilesOpenDialog: false,
        uploadExcelFormOpenDialog: false,
        comparisonFormOpenDialog: false,
        additionalExpenseOpenDialog: false,
        addAdditionalExpenseOpenDialog: false,
        getInvoicesFilesOpenDialog: false,
        getInvoicesPdfOpenDialog: false,
        showSubscriptionInvoiceOpenDialog: false,
        subscription: {},
        block: { value: " ", label: "lütfen blok seçiniz" },
        value: null,
        selectedSubscription: {
            name: '',
            type: {
                name: ""
            }
        },
        mainCounterData: {
            mainCounter: {
                name: ""
            }
        },
        rowDatas: []
    }

    // component yüklenirken yapılacak işlemleri gerçekleştir
    async UNSAFE_componentWillMount() {

        // yetki kontrolü
        if (!this.props.person.permissions.period.read) {
            return window.location.href = "/";
        }

        const { match: { params } } = this.props;
        await Promise.all([await this.props.getPeriod(params._id, this.props.token)]);
        await this.props.setActivePageTitle(this.props.period.project.name + " - PROJESİ / " + this.props.period.name + " - Paylaşım Dönemi");
        let breadCrumb = [
            { type: "link", path: "/", text: "Tebaş Otomasyon" },
            { type: "link", path: "/projects", text: "Proje Yönetimi" },
            { type: "link", path: "/project/" + this.props.period.project._id, text: this.props.period.project.name },
            { type: "string", path: "/period/" + this.props.period._id, text: this.props.period.name + " - Paylaşım Dönemi" },
        ];
        await this.props.setBreadCrumb(breadCrumb);
        await this.props.setFastOperation(<FastOperation project={this.props.period.project} />);
        let string = window.location.href.split("?");
        if (string.length > 1) {
            const query = queryString.parse(string[1]);
            this.handleChangeTab(parseFloat(query.tab))
        }
        await this.props.getAllBlocks(this.props.period.project._id, this.props.token);
       // await this.props.getProjectMainCounters(this.props.block.project._id, this.props.token);
    }

    // açık diyalogları kapat
    handleCloseDialog = () => {
        this.setState({ periodInformationDialog: false, addAdditionalExpenseOpenDialog: false, showSubscriptionInvoiceOpenDialog: false, getInvoicesPdfOpenDialog: false, getInvoicesFilesOpenDialog: false, additionalExpenseOpenDialog: false, addApartmentCounterDataOpenDialog: false, editApartmentCounterDataOpenDialog: false, comparisonFormOpenDialog: false, uploadExcelFormOpenDialog: false, addSubscriptionInvoiceOpenDialog: false, editMainCounterOpenDialog: false, getFilesOpenDialog: false });
    }

    handleChangeTab = async (value) => {
        this.setState({ value });
        if (value === 0) { // eğer ki block sekmesine tıklandıysa
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.period.project._id, text: this.props.period.project.name },
                { type: "link", path: "/period/" + this.props.period._id, text: this.props.period.name + " - Paylaşım Dönemi" },
                { type: "string", text: "Faturalar" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getAllProjectSubscriptionsWithInvoices(this.props.period.project._id, this.props.period._id, this.props.token);
        }
        else if (value === 1) { // Ek Giderler
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.period.project._id, text: this.props.period.project.name },
                { type: "link", path: "/period/" + this.props.period._id, text: this.props.period.name + " - Paylaşım Dönemi" },
                { type: "string", text: "Ek Giderler" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getPeriodAdditionalExpenses(this.props.period._id, this.props.token);
        }
        else if (value === 2) { // Daire Sayaç Verileri
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.period.project._id, text: this.props.period.project.name },
                { type: "link", path: "/period/" + this.props.period._id, text: this.props.period.name + " - Paylaşım Dönemi" },
                { type: "string", text: "Daire Sayaç Verileri" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
        }
        else if (value === 3) { // Daire Sayaç Verileri
            let breadCrumb = [
                { type: "link", path: "/", text: "Tebaş Otomasyon" },
                { type: "link", path: "/projects", text: "Proje Yönetimi" },
                { type: "link", path: "/project/" + this.props.period.project._id, text: this.props.period.project.name },
                { type: "link", path: "/period/" + this.props.period._id, text: this.props.period.name + " - Paylaşım Dönemi" },
                { type: "string", text: "Daire Faturaları Verileri" },
            ];
            await this.props.setBreadCrumb(breadCrumb);
            await this.props.getApartmentPeriodInvoices(this.props.period._id, this.props.token);
        }
    };

    handleAddInvoice = async (selectedSubscription) => {
        this.setState({ selectedSubscription, addSubscriptionInvoiceOpenDialog: true });
    }

    handleShowInvoice = async (selectedSubscription) => {
        this.setState({ selectedSubscription, showSubscriptionInvoiceOpenDialog: true });
    }

    handleAddInvoiceSubmit = async (e, state) => {
        if (!state.invoiceCheck) {
            return this.props.enqueueSnackbar("Lütfen tesisat numrasını kontrol ettiğinizi onaylayın. ", {
                variant: "error",
                autoHideDuration: 5000
            });
        }
        
        let invoice = new FormData();
        invoice.append('project', this.props.period.project._id);
        invoice.append('period', e.target.period.value);
        invoice.append('subscription', e.target.subscription.value);
        invoice.append('balance', priceFormat(e.target.balance.value));
        invoice.append('consumption', priceFormat(e.target.consumption.value));
        invoice.append('gasSharingCriterion', state.gasSharingCriterion);
        invoice.append('hotWaterSharingCriterion', state.hotWaterSharingCriterion);
        invoice.append('coldWaterSharingCriterion', state.coldWaterSharingCriterion);
        invoice.append('sharingPeriod', state.sharingPeriod);


        if (state.type === "5d0a312392706a151bc178df") {
            invoice.append('heatLossRate', e.target.heatLossRate.value);
            invoice.append('boilerTemperature', e.target.boilerTemperature.value);
            invoice.append('thermalCoefficient', e.target.thermalCoefficient.value);
        }

        if (state.type === "5d0a312392706a151bc178e0") { // soguk su ise
            if (state.coldWaterSharingCriterion === 2) {
                invoice.append('unitPrice', e.target.coldWaterSharingUnitPrice.value);
            } else {
                invoice.append('unitPrice', 0);
            }
        }

        if (state.image) {
            if (state.image.length > 0) {
                for (var i = 0; i < state.image.length; i++) {
                    invoice.append('image', state.image[i]);
                }
            }
        }

        return swal({
            title: "Emin misin?",
            text: "Yeni fatura ekliceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addSubscriptionInvoice(invoice, this.props.token);
                    if (this.props.addSubscriptionInvoiceResult.code === 200) {
                        this.setState({ addSubscriptionInvoiceOpenDialog: false, value: 0 });
                        await this.props.getAllProjectSubscriptionsWithInvoices(this.props.period.project._id, this.props.period._id, this.props.token);
                        this.props.enqueueSnackbar("Fatura başarıyla eklendi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Fatura eklenirken bir hata oluştu. " + this.props.addSubscriptionInvoiceResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleEditMainCounter = async (mainCounterData) => {
        this.setState({ editMainCounterOpenDialog: true, mainCounterData: Object.assign({}, mainCounterData) });
    }


    handleGetFiles = async () => {
        this.setState({ getFilesOpenDialog: true });
    }

    handleUploadExcelForm = () => {
        this.setState({ uploadExcelFormOpenDialog: true });
    }

    handleGetFilesSubmit = (e, state) => {

        return swal({
            title: "Emin misin?",
            text: "Güncel Sayaç Listesini İndirmek İstiyor Musun?",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await getPeriodCounterList(state, this.props.token);
                    this.setState({ getFilesOpenDialog: false });
                }
            });
    }

    handleUpdateCounterDataExcelSubmit = async (e, state) => {
        const formData = new FormData()
        if (state.counterListExcel.length > 0) {
            for (var i = 0; i < state.counterListExcel.length; i++) {
                formData.append('counterListExcel', state.counterListExcel[i])
            }
        }
        return swal({
            title: "Emin misin?",
            text: "Toplu sayaç verisi ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.uploadCounterListExcel(formData, this.props.token);
                    if (this.props.uploadCounterListExcelResult.code === 200) {
                        this.setState({ uploadExcelFormOpenDialog: false, value: 2 });
                        await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
                        this.props.enqueueSnackbar(this.props.uploadCounterListExcelResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar(this.props.uploadCounterListExcelResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleSetComparison = async (event, rowDatas) => {
        this.setState({ comparisonFormOpenDialog: true, rowDatas });
    }

    handeleCheckAnalysis = async () => {
        return swal({
            title: "Emin misin?",
            text: "Tüm okuma verilerini analiz etmek istiyor musun?",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.checkPeriodAnalysis(this.props.period._id, this.props.period.project._id, this.props.token);
                    if (this.props.checkPeriodAnalysisResult.code === 200) {
                        await this.props.enqueueSnackbar("Başarılı" + this.props.checkPeriodAnalysisResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        await this.props.enqueueSnackbar("Hata! " + this.props.checkPeriodAnalysisResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
                await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
            });
    }

    handleSetComparisonSubmit = (e, state) => {

        let comparison = {
            criterion: state.comparisonCriterion,
            manuelConsumption: state.comparisonCriterion === 1 ? e.target.manuelConsumption.value : 0
        };
        return swal({
            title: "Emin misin?",
            text: "Bu seçili olan içeriklere kıyas yapacaksın.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {

                    await Promise.all(await state.rowDatas.map(async (apartmentCounter) => {
                        await this.props.setComparison({ dataId: apartmentCounter.lastNewData[0]._id, squareMeters: apartmentCounter.apartment.squareMeters, ...comparison }, this.props.token);
                        if (this.props.setComparisonResult.code === 200) {
                            await this.props.enqueueSnackbar(this.props.setComparisonResult.message, {
                                variant: "success",
                                autoHideDuration: 100
                            });
                        } else {
                            await this.props.enqueueSnackbar(this.props.setComparisonResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                        return this.setState({ comparisonFormOpenDialog: false });
                    }));
                    await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
                }

            });
    }

    handleEditApartmentCounterData = async (apartmentCounterData) => {
        this.setState({ editApartmentCounterDataOpenDialog: true, apartmentCounterData: Object.assign({}, apartmentCounterData.lastNewData[0]) });
    }

    handleAddApartmentCounterData = async (apartmentCounterData) => {
        this.setState({ addApartmentCounterDataOpenDialog: true, apartmentCounterData: Object.assign({}, apartmentCounterData) });
    }

    handleEditApartmentCounterDataSubmit = (e, state) => {

        let data = {
            counterValue: e.target.counterValue.value,
            periodDescription: e.target.periodDescription.value,
            _id: state._id,
            counter: state.counter,
            problemState: false,
            analysisStatus: false,
            comparisonMakingStatus: false,
            comparisonCriterion: 0,
            fifteenDegreesProblem: false,
            fifteenDegreesValue: 0,
            fifteenDegreesMessage: null,
            minusConsumptionProblem: false,
            minusConsumptionMessage: null,
            maximumConsumptionProblem: false,
            maximumConsumptionMessage: null,
            lastThreeMonthProblem: false,
            lastThreeMonthMessage: null,
        };
        return swal({
            title: "Emin misin?",
            text: "Sayaç verisi güncellenecek!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {

                    await this.props.updateApartmentCounterData(data, this.props.token);
                    if (this.props.updateApartmentCounterDataResult.code === 200) {
                        this.setState({ editApartmentCounterDataOpenDialog: false, value: 2 });
                        await this.props.enqueueSnackbar("Aktif sayaç verisi başarıyla güncellendi", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        await this.props.enqueueSnackbar(this.props.updateApartmentCounterDataResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                    await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
                }

            });
    }

    handleAddApartmentCounterDataSubmit = (e, state) => {

        let data = {
            counterValue: e.target.counterValue.value,
            periodDescription: e.target.periodDescription.value,
            counter: state.counter,
            period: this.props.period._id,
        };

        let text = "";
        if (state.oldCounterValue > data.counterValue) {
            text = "Girilen endeks bir önceki endeks değerinden küçüktür. ";
        }

        return swal({
            title: text + "Emin misin?",
            text: "Sayaç verisi eklenecek!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {

                    await this.props.addApartmentCounterData(data, this.props.token);
                    if (this.props.addApartmentCounterDataResult.code === 200) {
                        this.setState({ addApartmentCounterDataOpenDialog: false, value: 2 });
                        await this.props.enqueueSnackbar("Sayaç verisi başarıyla eklendi", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        await this.props.enqueueSnackbar(this.props.addApartmentCounterDataResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                    await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
                }

            });
    }

    handleSharingPeriod = async () => {
        let data = {
            period: this.props.period._id,
        };
        return swal({
            title: "Emin misin?",
            text: "Tüm Faturaları Paylaşacaksın!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {

                    await this.props.sharingPeriod(data, this.props.token);
                    if (this.props.sharingPeriodResult.code === 200) {
                        await this.props.enqueueSnackbar(this.props.sharingPeriodResult.message, {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                        this.setState({ value: 3 });
                        await this.props.getAllProjectSubscriptionsWithInvoices(this.props.period.project._id, this.props.period._id, this.props.token);
                        await this.props.getPeriod(this.props.period._id, this.props.token);
                    } else {
                        await this.props.enqueueSnackbar(this.props.sharingPeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleDeleteApartmentInvoice = (event, rowDatas) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri sileceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.deleteInvoice(rowDatas.map(data => data._id), this.props.token);
                    this.setState({ value: 3 });
                    await this.props.enqueueSnackbar("İşlem Tamamlandı.", {
                        variant: "success",
                        autoHideDuration: 3000
                    });
                    await this.props.getApartmentPeriodInvoices(this.props.period._id, this.props.token);
                }
            });
    }

    handleNotDefective = (event, rowDatas) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri arızalı değil olarak güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowDatas.map(async (data) => {
                        await this.props.notDefective(data.lastNewData[0]._id, this.props.token);
                        if (this.props.notDefectiveResult.code === 200) {
                            this.setState({ value: 2 });
                            await this.props.enqueueSnackbar("İşlem Tamamlandı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            this.props.enqueueSnackbar("Veri güncellenirken hata oluştu. " + this.props.notDefectiveResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
                }
            });
    }

    handleSetDefective = (event, rowDatas) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikleri arızalı olarak güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await rowDatas.map(async (data) => {
                        await this.props.setDefective(data.lastNewData[0]._id, this.props.token);
                        if (this.props.setDefectiveResult.code === 200) {
                            this.setState({ value: 2 });
                            await this.props.enqueueSnackbar("İşlem Tamamlandı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            this.props.enqueueSnackbar("Veri güncellenirken hata oluştu. " + this.props.setDefectiveResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                    });
                    await this.props.getPeriodCountersWithData({ project: this.props.period.project._id, period: this.props.period._id }, this.props.token);
                }
            });
    }

    handleDeleteAdditionalExpense = (event, rowDatas) => {
        return swal({
            title: "Emin misin?",
            text: "Seçili olan içerikler silinecek",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await Promise.all(rowDatas.map(async (data) => {
                        await this.props.deleteAdditionalExpense(data, this.props.token);
                        if (this.props.deleteAdditionalExpenseResult.code === 200) {
                            await this.props.enqueueSnackbar("İşlem Tamamlandı.", {
                                variant: "success",
                                autoHideDuration: 3000
                            });
                        } else {
                            this.props.enqueueSnackbar("Veri güncellenirken hata oluştu. " + this.props.deleteAdditionalExpenseResult.message, {
                                variant: "error",
                                persist: true,
                                action: (
                                    <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                                ),
                            });
                        }
                        return "";
                    }));
                    this.setState({ value: 1 });
                    await this.props.getPeriodAdditionalExpenses(this.props.period._id, this.props.token);
                }
            });
    }

    handleGetInvoiceExport = async () => {
        this.setState({ getInvoicesFilesOpenDialog: true });
    }

    handleGetInvoicePdf = async () => {
        this.setState({ getInvoicesPdfOpenDialog: true });
    }

    handleGetInvoiceExportSubmit = async (e, state) => {
        await getPeriodInvoicesExcelList(state, this.props.token);
        this.setState({ getInvoicesFilesOpenDialog: false });
    }

    handleGetInvoiceExportPdfSubmit = (e, state) => {
        this.setState({ getInvoicesFilesOpenDialog: false });
        return window.open("/pdf-invioce-list?" + queryString.stringify(state), '_blank').focus()
    }

    handleClosePeriod = async () => {
        return swal({
            title: "Emin misin?",
            text: "Dönemi kapatacaksın!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.closePeriod(this.props.period, this.props.token);
                    if (this.props.closePeriodResult.code === 200) {
                        await Promise.all([await this.props.getPeriod(this.props.period._id, this.props.token)]);
                        this.props.enqueueSnackbar("Dönem başarıyla kapatıldı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Dönem kapatılırken bir hata oluştu. " + this.props.closePeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }

    handleActivePeriod = async () => {
        return swal({
            title: "Emin misin?",
            text: "Dönemi aktif edeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.activePeriod(this.props.period, this.props.token);
                    if (this.props.activePeriodResult.code === 200) {
                        await Promise.all([await this.props.getPeriod(this.props.period._id, this.props.token)]);
                        this.props.enqueueSnackbar("Dönem başarıyla aktif edildi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Dönem kapatılırken bir hata oluştu. " + this.props.activePeriodResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }
    openPerodInformation = async () => {
        this.setState({ periodInformationDialog: true })
    }

    handleEditPeriodInformationSubmit = (e, state) => {
        const period = {
            _id: e.target._id.value,
            sharingRequestStatus: true,
            periodDate: e.target.periodDate.value,
            lastPaymentDate: e.target.lastPaymentDate.value,
            paymentPlace: e.target.paymentPlace.value,
            accountName: e.target.accountName.value,
            ibanNumber: e.target.ibanNumber.value,
            periodNote: e.target.periodNote.value
        }
        return swal({
            title: "Emin misin?",
            text: "Bu içeriği güncelleyeceksin.",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.updateRequestSharing({ period, person: this.props.person }, this.props.token);
                    if (this.props.updateRequestSharingResult.code === 200) {
                        this.setState({ periodInformationDialog: false });
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarılı.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                    } else {
                        this.props.enqueueSnackbar("Kayıt güncelleme işlemi başarısız. " + this.props.updateRequestSharingResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }


            });
    }


    handleAddAdditionalExpense = async () => {
        this.setState({ addAdditionalExpenseOpenDialog: true });
    }

    handleAddAdditionalExpenseSubmit = async (e, state) => {

        let expense = {
            title: state.title,
            period: state.period,
            blocks: state.blocks,
            invoiceType: state.invoiceType,
            invoiceBalance: state.invoiceBalance,
            sharingType: state.sharingType,
            unitPrice: state.invoiceType === 0 ? state.unitPrice : 0,
            apartmentStatus: state.apartmentStatus,
        };

        return swal({
            title: "Emin misin?",
            text: "Yeni bir ek gider ekleyeceksin!",
            icon: "warning",
            buttons: ["Hayır!", "Evet!"],
        })
            .then(async (value) => {
                if (value) {
                    await this.props.addPeriodAdditionalExpense(expense, this.props.token);
                    if (this.props.addPeriodAdditionalExpenseResult.code === 200) {
                        this.setState({ addAdditionalExpenseOpenDialog: false, value: 1 });
                        await this.props.getPeriodAdditionalExpenses(this.props.period._id, this.props.token);
                        await this.props.enqueueSnackbar("Ek Gider Başarıyla Eklendi.", {
                            variant: "success",
                            autoHideDuration: 3000
                        });
                        this.setState({ value: 5, addAdditionalExpenseDataOpenDialog: false });

                    } else {
                        await this.props.enqueueSnackbar(this.props.addPeriodAdditionalExpenseResult.message, {
                            variant: "error",
                            persist: true,
                            action: (
                                <div style={{ color: "#000", fontSize: "13px", cursor: "pointer", fontWeight: "500" }} onClick={() => this.props.closeSnackbar()}>KAPAT</div>
                            ),
                        });
                    }
                }
            });
    }


    handleBlockChange = async (block) => {
        this.setState({ block })
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                {this.props.getAdditionalExpenseLoading ? <div className="loading" /> : ""}
                {this.props.getPeriodCountersWithDataLoading ? <div className="loading" /> : ""}
                {this.props.getProjectsLoading ? <div className="loading" /> : ""}
                {this.props.addInvoiceLoading ? <div className="loading" /> : ""}
                {this.props.updateMainCounterDataLoading ? <div className="loading" /> : ""}
                {this.props.getPeriodMainCounterDatasLoading ? <div className="loading" /> : ""}
                {this.props.getAdditionalExpenseLoading ? <div className="loading" /> : ""}
                {this.props.getApartmentPeriodInvoicesLoading ? <div className="loading" /> : ""}
                {this.props.getApartmentLoading ? <div className="loading" /> : ""}
                {this.props.addInvoiceLoading ? <div className="loading" /> : ""}
                {this.props.setDefectiveApartmentCounterLoading ? <div className="loading" /> : ""}
                {this.props.setComparisonApartmentCounterLoading ? <div className="loading" /> : ""}
                {this.props.sharingPeriodLoading ? <div className="loading" /> : ""}
                {this.props.deleteApartmentInvoiceLoading ? <div className="loading" /> : ""}
                {this.props.addPeriodAdditionalExpenseLoading ? <div className="loading" /> : ""}
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Daha Fazla Detaylı Bilgi</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        {this.props.getPeriodsLoading ? <div className="loading" /> : ""}
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Dönem Adı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            value={this.props.period.name || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Dönem Durumu
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <br />
                                        <Chip
                                            style={this.props.period.status ? { color: "#fff", backgroundColor: "#29bd29" } : { color: "#fff", backgroundColor: "#cc2020" }}
                                            label={this.props.period.status ? "Açık Dönem" : "Kapalı Dönem"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Okuma Ücreti
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.readingBalance + " TL" || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Daire Faturalarında Görünen Okuma Ücreti
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.invoiceBalance + " TL" || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Son Ödeme Tarihi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.lastPaymentDate || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Ödeme Yeri
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.paymentPlace || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            İban Numarası
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            multiline={true}
                                            row={5}
                                            className={classes.textField}
                                            value={this.props.period.ibanNumber || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Hesap İsmi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.accountName || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Tüketim Dönemi Başlangıcı
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.consumptionPeriodStart || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Tüketim Dönemi Bitişi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.consumptionPeriodEnd || ""}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Dönem Tarihi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.periodDate ? moment(this.props.period.periodDate).format("DD-MM-YYYY") : " - "}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            En Son Kapanmış Dönemin Tarihi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.lastClosedPeriodDate ? moment(this.props.period.lastClosedPeriodDate).format("DD-MM-YYYY") : " - "}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Dönemin Oluşturulma tarihi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.createdAt ? moment(this.props.period.createdAt).format("DD-MM-YYYY : HH:SS") : " - "}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            Dönemin Kapanma Tarihi
                                </Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            className={classes.textField}
                                            value={this.props.period.closedDate ? moment(this.props.period.closedDate).format("DD-MM-YYYY") : " - "}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dönem Notu</Typography>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            disabled={true}
                                            multiline={true}
                                            row={5}
                                            className={classes.textField}
                                            value={this.props.period.periodNote ? this.props.period.periodNote : " - "}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>

                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <br></br>
                {
                    this.props.period.status ?
                        this.props.person.permissions.period.sharingInvoice ?
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSharingPeriod}
                                        className={classes.submit}
                                    >
                                        Fatura Paylaş
                                    </Button>
                                </Grid>
                            </Grid>
                            : "" : <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleActivePeriod}
                                    className={classes.submit}
                                    style={{ backgroundColor: "green" }}
                                >
                                    Dönemi Aktif Et
                                </Button>
                            </Grid>
                        </Grid>
                }
                {
                    this.props.period.status ?
                        this.props.period.sharingStatus ? <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: "15px" }}>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleClosePeriod}
                                    className={classes.submit}

                                >
                                    Dönemi Kapat
                            </Button>
                            </Grid>
                        </Grid> : "" : ""
                }
                {
                    this.props.person.personType !== 0 ? <MenuItem onClick={this.openPerodInformation} style={{ color: "red", fontSize: "30px" }}>Paylaşım Talebini Başlat</MenuItem> : ""
                }
                {
                    <ModalDialog
                        open={this.state.periodInformationDialog}
                        title={'Dönem Bilgileri'}
                        buttons={[
                            <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                        ]}>
                        <RequestSharingForm onSubmitForm={this.handleEditPeriodInformationSubmit} activePeriod={this.props.lastActivePeriod} />
                    </ModalDialog   >
                }

                <Divider variant="middle" />
                <br /> <br /> <br /> <br />
                <AppBar position="static" color="default">
                    <div className="tab">
                        <button onClick={() => { this.handleChangeTab(0) }} className={classNames(["tablinks", value === 0 ? "tabActive" : ""])}>Faturalar</button>
                        <button onClick={() => { this.handleChangeTab(1) }} className={classNames(["tablinks", value === 1 ? "tabActive" : ""])}>Ek Giderler</button>
                        <button onClick={() => { this.handleChangeTab(2) }} className={classNames(["tablinks", value === 2 ? "tabActive" : ""])}>Daire Sayaç Listesi</button>
                        <button onClick={() => { this.handleChangeTab(3) }} className={classNames(["tablinks", value === 3 ? "tabActive" : ""])}>Daire Faturaları</button>
                    </div>
                </AppBar>
                {
                    value === 0 &&
                    <div>
                        {/* {this.props.getPeriodsLoading ? <div className="loading" /> : ""} */}
                        <SubscriptionsInvoicesTable {...this.props} showInvoice={this.handleShowInvoice} addInvoice={this.handleAddInvoice} />
                        <ModalDialog
                            open={this.state.addSubscriptionInvoiceOpenDialog}
                            title={this.state.selectedSubscription.type.name + " / " + this.state.selectedSubscription.name + " / " + this.props.period.name + ' - Dönem Faturası Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>

                            <AddSubscriptionInvoice onSubmitForm={this.handleAddInvoiceSubmit} period={this.props.period._id} project={this.props.period.project} subscription={this.state.selectedSubscription} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.showSubscriptionInvoiceOpenDialog}
                            title={this.state.selectedSubscription.name + " Eklenen Fatura"}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>

                            <ShowInvoice subscription={this.state.selectedSubscription} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 1 &&
                    <div>
                        <AdditionalExpensesTable {...this.props} delete={this.handleDeleteAdditionalExpense} addForm={this.handleAddAdditionalExpense} />
                        <ModalDialog
                            open={this.state.addAdditionalExpenseOpenDialog}
                            title={this.props.period.name + ' - Yeni Ek Gider Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddPeriodAdditionalExpenseForm period={this.props.period} onSubmitForm={this.handleAddAdditionalExpenseSubmit} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 2 &&
                    <div>
                        {
                            <FormControl required style={{ marginBottom: "15px", marginTop: "20px", marginRight: "10px", width: "30%" }}>
                                <FormLabel>Blok Seçiniz!</FormLabel>
                                <br />
                                <SearchSelect
                                    value={this.state.block}
                                    onChange={this.handleBlockChange}
                                    options={
                                        this.props.blocks.map((block) => {
                                            return { value: block._id, label: block.name }
                                        })
                                    }
                                />
                            </FormControl>
                        }
                        {
                            this.state.block.value !== "" ? <FormControl required style={{ marginBottom: "15px", marginTop: "20px", width: "30%" }}>
                                <FormLabel>Abonelik Seçiniz!</FormLabel>
                                <br />
                                <SearchSelect
                                    value={this.state.subscription}
                                    onChange={(subscription) => { this.setState({ subscription }) }}
                                    options={
                                        this.props.departmentPersons.length > 0 ? this.props.subscription.map((subscription) => {
                                            return { value: subscription._id, label: subscription.title }
                                        }) : []
                                    }
                                />
                            </FormControl> : ""
                        }
                        <PeriodDatasTable {...this.props} addForm={this.handleAddApartmentCounterData} editForm={this.handleEditApartmentCounterData} notDefective={this.handleNotDefective} setDefective={this.handleSetDefective} getFiles={this.handleGetFiles} uploadExcel={this.handleUploadExcelForm} checkAnalysis={this.handeleCheckAnalysis} setComparison={this.handleSetComparison} />
                        <ModalDialog
                            open={this.state.addApartmentCounterDataOpenDialog}
                            title={'Sayaç Verisi Ekleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <AddCounterDataForm onSubmitForm={this.handleAddApartmentCounterDataSubmit} apartmentCounterData={this.state.apartmentCounterData} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.editApartmentCounterDataOpenDialog}
                            title={'Sayaç Verisi Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <EditCounterDataForm onSubmitForm={this.handleEditApartmentCounterDataSubmit} apartmentCounterData={this.state.apartmentCounterData} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.getFilesOpenDialog}
                            title={'Güncel Sayaç Verisi İndir'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <GetFilesForm onSubmitForm={this.handleGetFilesSubmit} period={this.props.period} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.uploadExcelFormOpenDialog}
                            title={'Toplu Sayaç Verisi Güncelleme'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <UploadCounterDataExcelForm enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleUpdateCounterDataExcelSubmit} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.comparisonFormOpenDialog}
                            title={'Kıyas Formu'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <ComparisonForm rowDatas={this.state.rowDatas} enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onSubmitForm={this.handleSetComparisonSubmit} />
                        </ModalDialog>
                    </div>
                }
                {
                    value === 3 &&
                    <div>
                        <PeriodInvoicesTable getInvoicePdf={this.handleGetInvoicePdf} getInvoiceExport={this.handleGetInvoiceExport} {...this.props} delete={this.handleDeleteApartmentInvoice} />
                        <ModalDialog
                            open={this.state.getInvoicesFilesOpenDialog}
                            title={'Güncel Fatura Excel Listesi'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <GetInvoicesForm onSubmitForm={this.handleGetInvoiceExportSubmit} period={this.props.period} />
                        </ModalDialog>
                        <ModalDialog
                            open={this.state.getInvoicesPdfOpenDialog}
                            title={'Güncel Fatura Excel Listesi'}
                            buttons={[
                                <Button key="2" onClick={this.handleCloseDialog} color="default">Kapat</Button>
                            ]}>
                            <GetInvoicesForm onSubmitForm={this.handleGetInvoiceExportPdfSubmit} period={this.props.period} />
                        </ModalDialog>
                    </div>
                }
            </div >
        )
    }
}

// reducer'lar tarafından gönderilen state verilerini component içerisinde kullanmak için componente props geç
const mapStateToProps = ({ personReducer, periodReducer, subscriptionReducer, subscriptionInvoiceReducer, blockReducer, mainCounterReducer, mainCounterDataReducer, apartmentCounterReducer, apartmentReducer, additionalExpenseReducer }) => {
    return {
        ...personReducer,
        ...periodReducer,
        ...subscriptionReducer,
        ...subscriptionInvoiceReducer,
        ...mainCounterDataReducer,
        ...apartmentCounterReducer,
        ...apartmentReducer,
        ...additionalExpenseReducer,
        ...mainCounterReducer,
        ...blockReducer,
    }
}

// actionda bulunan methodları çalıştırabilmek için props olarak componente geç
const mapDispatchToProps = {
    setActivePageTitle,
    setBreadCrumb,
    getPeriod,
    getAllProjectSubscriptionsWithInvoices,
    addSubscriptionInvoice,
    getPeriodMainCounterDatas,
    updateMainCounterData,
    getPeriodCountersWithData,
    uploadCounterListExcel,
    checkPeriodAnalysis,
    setComparison,
    updateApartmentCounterData,
    sharingPeriod,
    getApartmentPeriodInvoices,
    deleteInvoice,
    getPeriodAdditionalExpenses,
    notDefective,
    setDefective,
    deleteAdditionalExpense,
    addApartmentCounterData,
    setFastOperation,
    closePeriod,
    activePeriod,
    updateRequestSharing,
    addPeriodAdditionalExpense,
    getAllBlocks,
    getProjectMainCounters

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withSnackbar(PeriodDetail)));