import {
    ADD_PROPOSAL_PENDING, ADD_PROPOSAL_FULFILLED, ADD_PROPOSAL_REJECTED,
    GET_ALL_PROPOSAL_PENDING, GET_ALL_PROPOSAL_FULFILLED, GET_ALL_PROPOSAL_REJECTED,
    DELETE_PROPOSAL_PENDING, DELETE_PROPOSAL_FULFILLED, DELETE_PROPOSAL_REJECTED
} from '../actions/proposal';

const initialState = {
    addProposalLoading: false,
    getProposalLoading: false,
    deleteProposalLoading: false,
    proposals: [],
    addProposalResult: {},
    deleteProposalResult: {}
}
export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_PROPOSAL_PENDING:
            return {
                ...state,
                addProposalLoading: true,
            }
        case ADD_PROPOSAL_FULFILLED:
            return {
                ...state,
                addProposalResult: action.payload,
                addProposalLoading: false,
            }
        case ADD_PROPOSAL_REJECTED:
            return {
                ...state,
                addProposalLoading: false,
            }
        case GET_ALL_PROPOSAL_PENDING:
            return {
                ...state,
                getProposalLoading: true,
            }
        case GET_ALL_PROPOSAL_FULFILLED:
            return {
                ...state,
                getProposalLoading: false,
                ...action.payload.data,
            }
        case GET_ALL_PROPOSAL_REJECTED:
            return {
                ...state,
                getProposalLoading: false,
            }
        case DELETE_PROPOSAL_PENDING:
            return {
                ...state,
                deleteProposalLoading: true,
            }
        case DELETE_PROPOSAL_FULFILLED:
            return {
                ...state,
                deleteProposalResult: action.payload,
                deleteProposalLoading: false,
            }
        case DELETE_PROPOSAL_REJECTED:
            return {
                ...state,
                deleteProposalLoading: false
            }
        default:
            return state;
    }
}
